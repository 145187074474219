import {
  Card,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Popover,
  Radio,
  Row,
  Tag,
  Tooltip
} from "antd";
import moment from "moment";
import React, { memo, useState } from "react";
import { Comments } from "../../../Modules/Dashboard/TrackedPolicyDetails/TrackedPolicyExceptionHandling/Comments";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";

const ReviewEndorsementExceptionManagement = memo(
  function ReviewEndorsementExceptionManagement({
    requireReviewType,
    requireReviewSubType,
    policyDetails,
    onAcceptOrDeny,
    isCrossCountry,
    reqRevDurationChange,
    reqRevDisabledDate,
    reqRevSetCustomDate,
    onExceptionCommentChange,
    endorsement
  }) {
    const dropdownInitialState = {
      visible: false
    };
    const [dropdownState, dropdownSetstate] = useState(dropdownInitialState);
    const [selectedDropDownId, setSelectedDropDownId] = useState("");
    const handleDropdownVisibleChange = (visible) => {
      dropdownSetstate({ visible });
    };

    const getDurationWrapper = (obj) => {
      const { sharePolicyEndorsementReview: _sharePolicyEndorsementReview } =
        obj || {};

      const _selectedOption = _sharePolicyEndorsementReview
        ? _sharePolicyEndorsementReview.hasOwnProperty("reqEndReviewExpiryDate")
          ? "req-endorsement-expiration-date"
          : _sharePolicyEndorsementReview.hasOwnProperty(
              "reqEndReviewCustomDate"
            )
          ? "req-endorsement-custom-expiration-date"
          : _sharePolicyEndorsementReview.hasOwnProperty(
              "endorsementReviewPerpetual"
            )
          ? "req-endorsement-perpetual"
          : undefined
        : undefined;

      return (
        <div>
          <Radio.Group
            onChange={(e) => {
              reqRevDurationChange(e, {
                ...obj,
                requireReviewType,
                requireReviewSubType
              });
            }}
            value={_selectedOption}
            style={{ padding: 0 }}
          >
            <Row justify="end" align="middle" type="flex">
              <Col span={12}>
                <Radio value={"req-endorsement-expiration-date"}>
                  Expiration Date
                </Radio>
              </Col>
              <Col span={12}>
                <div style={{ padding: "0 10px" }}>
                  {(policyDetails.effectiveTo.length > 10 &&
                    moment(policyDetails.effectiveTo)
                      .utc()
                      .format(CERTIFICIAL.APP_FORMAT.MOMENT_MMDDYYYY)) ||
                    moment(policyDetails.effectiveTo).format(
                      CERTIFICIAL.APP_FORMAT.MOMENT_MMDDYYYY
                    )}
                </div>
              </Col>
            </Row>
            <Row justify="end" align="middle" type="flex">
              <Col span={12}>
                <Radio value={"req-endorsement-custom-expiration-date"}>
                  {isCrossCountry ? "Expiration Date" : "Custom Date"}
                </Radio>
              </Col>
              <Col span={12}>
                <div style={{ padding: "0 10px" }}>
                  <DatePicker
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    disabled={
                      obj &&
                      obj.sharePolicyEndorsementReview &&
                      obj.sharePolicyEndorsementReview.hasOwnProperty(
                        "reqEndReviewCustomDate"
                      )
                        ? false
                        : true
                    }
                    format={CERTIFICIAL.APP_FORMAT.MOMENT_MMDDYYYY}
                    disabledDate={reqRevDisabledDate}
                    onChange={(date, dateString) => {
                      reqRevDurationChange(
                        {
                          target: {
                            value: "req-endorsement-custom-expiration-date"
                          }
                        },

                        {
                          ...obj,
                          reqEndReviewCustomDate:
                            moment(date).format("YYYY[-]MM[-]DD"),
                          requireReviewType: "project",
                          requireReviewSubType
                        }
                      );
                    }}
                    value={
                      _sharePolicyEndorsementReview &&
                      _sharePolicyEndorsementReview.reqEndReviewCustomDate
                        ? moment(
                            _sharePolicyEndorsementReview.reqEndReviewCustomDate
                          )
                        : undefined
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </div>
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={12}>
                <Radio value={"req-endorsement-perpetual"}>{"Perpetual"}</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </div>
      );
    };

    const getAcceptDenyComponent = (obj) => (
      <Radio.Group
        onChange={(e) => {
          onAcceptOrDeny(requireReviewType, {
            ...obj,
            requireReviewType,
            requireReviewSubType,
            isEndorsementReviewAccepted: e.target.value
          });
        }}
        options={[
          { label: "Accept", value: true },
          { label: "Deny", value: false }
        ]}
      />
    );

    const getDurationSelection = (obj, index) => {
      const { sharePolicyEndorsementReview: _sharePolicyEndorsementReview } =
        obj || {};

      const _selectedOption = _sharePolicyEndorsementReview
        ? _sharePolicyEndorsementReview.hasOwnProperty("reqEndReviewExpiryDate")
          ? _sharePolicyEndorsementReview.reqEndReviewExpiryDate
          : _sharePolicyEndorsementReview.hasOwnProperty(
              "reqEndReviewCustomDate"
            )
          ? _sharePolicyEndorsementReview.reqEndReviewCustomDate
          : _sharePolicyEndorsementReview.hasOwnProperty(
              "endorsementReviewPerpetual"
            )
          ? "Perpetual"
          : undefined
        : undefined;

      const dropDownProps =
        isCrossCountry && selectedDropDownId && selectedDropDownId === obj.id
          ? { visible: dropdownState.visible }
          : {};

      return (
        <Row>
          <Popover
            {...dropDownProps}
            content={getDurationWrapper(obj)}
            onVisibleChange={handleDropdownVisibleChange}
            key={`Duration-${index}`}
            trigger={["click"]}
            getPopupContainer={(trigger) => trigger.parentNode}
            title={<h4>Please select duration</h4>}
            placement="right"
            autoAdjustOverflow={true}
            arrowPointAtCenter={true}
          >
            <h4
              className="dropdown-duration"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedDropDownId(obj.id);
              }}
            >
              Duration <Icon type="down" />{" "}
            </h4>
          </Popover>
          {_selectedOption ? (
            <Tooltip
              title={
                _selectedOption === "Perpetual"
                  ? _selectedOption
                  : `Expiration Date ${moment(_selectedOption).format(
                      CERTIFICIAL.APP_FORMAT.MOMENT_MMDDYYYY
                    )}`
              }
              placement="right"
              destroyTooltipOnHide={true}
              autoAdjustOverflow={true}
            >
              <Tag
                color={
                  _sharePolicyEndorsementReview &&
                  _sharePolicyEndorsementReview.isEndorsementReviewAccepted
                    ? "green"
                    : "red"
                }
              >
                {_selectedOption === "Perpetual"
                  ? "Perpetual"
                  : moment(_selectedOption).format(
                      CERTIFICIAL.APP_FORMAT.MOMENT_MMDDYYYY
                    )}
              </Tag>
            </Tooltip>
          ) : null}
        </Row>
      );
    };

    const getNotesComponent = (obj) => (
      <Tooltip
        title={
          (obj.sharePolicyEndorsementReview &&
            obj.sharePolicyEndorsementReview.exceptionComment) ||
          ""
        }
        placement={"rightBottom"}
      >
        <Popover
          content={
            <div style={{ width: 280 }}>
              <Comments
                onCommentChange={(e) => {
                  onExceptionCommentChange(
                    { ...obj, requireReviewType, requireReviewSubType },
                    e.target.value
                  );
                }}
                comment={
                  (obj.sharePolicyEndorsementReview &&
                    obj.sharePolicyEndorsementReview.exceptionComment) ||
                  ""
                }
                wordCapacity={1000}
                minRows={2}
              />
            </div>
          }
          title={"Notes"}
          placement="right"
          trigger="click"
        >
          <h4 className="requires-review-notes">
            {`Notes `}
            <Icon type="form" />
          </h4>
        </Popover>
      </Tooltip>
    );
    return !endorsement || !policyDetails ? null : (
      <Row justify="end" type="flex" align="middle">
        <Col span={12}>{getAcceptDenyComponent(endorsement)}</Col>
        <Col span={12}>
          <div>
            {getDurationSelection(endorsement, 1)}

            {getNotesComponent(endorsement)}
          </div>
        </Col>
      </Row>
    );
  }
);

export default ReviewEndorsementExceptionManagement;
