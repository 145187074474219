import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Col,
  Row,
  Alert,
  Icon,
  Divider
} from "antd";
import { withRouter } from "react-router";
import certificial from "../../../Assets/Logos/certificial-logo-old.png";
import { updatePassword_action } from "../../../Store/Modules/Application/Application.asyncRequest";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import { strongPasswordVerification } from "../../../Utils/utility/app.utility";
import { PasswordStrength } from "../PasswordStrength/PasswordStrength";
import { appHistory } from "../../../Utils/Helpers/History";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class UpdatePassword extends Component {
  state = {
    confirmDirty: false,
    errorMessage: "",
    updatePasswordError: false,
    iconLoading: false
  };

  componentDidMount() {
    _authService.logoutUser();
    _authService.clearUserInfo();
    _authService.clearUserRole();
    // To disable submit button at the beginning.
    this.props.form.validateFields((errors, values) => {});
  }

  logoStyle = {
    height: "57px",
    width: "100%",
    backgroundImage: "url(" + certificial + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "12px"
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Password does not match");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    if (!!value) {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirmPassword"], { force: true });
      }
      const strongnessOfPassword = strongPasswordVerification(value);
      if (!!strongnessOfPassword) {
        callback(strongnessOfPassword);
      }
    }
    callback();
  };

  getLoader = () => {
    this.setState({ iconLoading: true });
  };

  handleSubmit = (e) => {
    this.setState({ updatePasswordError: false });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let updatePasswordModel = {
          ...values,
          updateToken: this.props.match.params.token
        };
        updatePassword_action(updatePasswordModel).then(
          (response) => {
            this.setState({ iconLoading: false });
            this.setState({ updatePasswordError: false });
            this.props.history.push("/update-password-success");
          },
          (error) => {
            this.setState({ iconLoading: false });
            this.setState({ updatePasswordError: true });
            this.setState({ errorMessage: error.message });
          }
        );
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const passwordError =
      isFieldTouched("newPassword") && getFieldError("newPassword");
    const confirmError =
      isFieldTouched("confirmPassword") && getFieldError("confirmPassword");
    return (
      <React.Fragment>
        <Row type="flex" align="middle" justify="center">
          <Col span={8}>
            <Card size="small" hoverable>
              <div style={this.logoStyle} />
              <Divider dashed />
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col span={22} className="p-12">
                    <Form.Item
                      label="New Password"
                      hasFeedback
                      validateStatus={passwordError ? "error" : ""}
                      help={passwordError || ""}
                    >
                      {getFieldDecorator("newPassword", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter your password!"
                          },
                          {
                            validator: this.validateToNextPassword
                          }
                        ]
                      })(
                        <Input.Password
                          placeholder="Password"
                          // maxLength={12}
                          minLength={8}
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.45)" }}
                            />
                          }
                          autoComplete="new-password"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1}>
                    <div style={{ lineHeight: "4.6" }}>
                      <PasswordStrength />
                    </div>
                  </Col>
                  <Col span={22} className="p-12">
                    <Form.Item
                      label="Confirm Password"
                      hasFeedback
                      validateStatus={confirmError ? "error" : ""}
                      help={confirmError || ""}
                    >
                      {getFieldDecorator("confirmPassword", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "Please confirm your password!"
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <Input.Password
                          placeholder="Confirm Password"
                          onBlur={this.handleConfirmBlur}
                          autoComplete="new-password"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {!!this.state.updatePasswordError && this.state.errorMessage && (
                  <Row style={{ marginTop: 12 }}>
                    <Col span={22} className="p-12">
                      <Alert message={this.state.errorMessage} type="error" />
                    </Col>
                  </Row>
                )}
                <Row style={{ marginTop: 24 }}>
                  <Col span={11}>
                    <Form.Item className="p-12">
                      <Button
                        type="primary"
                        block
                        ghost
                        onClick={() => {
                          appHistory.push("/");
                        }}
                      >
                        Back To Login
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item className="p-12">
                      <Button
                        type="primary"
                        block
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                        loading={this.state.iconLoading}
                        onClick={this.getLoader}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider dashed />
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    Expired link{"? "}
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() => {
                        appHistory.push("/forgot-password");
                      }}
                    >
                      Click here
                    </Button>{" "}
                    to re-send the activation link.
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const updatePassword = withRouter(
  Form.create({ name: "updatePassword" })(UpdatePassword)
);

export default updatePassword;
