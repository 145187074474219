import React from "react";
import { appHistory } from "../../../Utils/Helpers/History";
import { Card, Col, Row, Icon, Typography, Button, Avatar } from "antd";
import { mailImage } from "../../../Assets/assets";
import { withRouter } from "react-router";
const { Title, Text } = Typography;
const RegistrationSuccess = (props) => {
  return (
    <Row
      type="flex"
      justify="center"
      gutter={[8, 16]}
      style={{ paddingTop: 20, margin: 0 }}
    >
      <Col span={12}>
        <Card>
          {props &&
          props.location &&
          props.location.state &&
          !!!props.location.state.invitedUser ? (
            <Row
              style={{
                backgroundColor: "#3596db",
                margin: "-24px -24px 10px -24px"
              }}
              type="flex"
              justify="center"
              gutter={[8, 16]}
            >
              <Col>
                {props &&
                props.location &&
                props.location.state &&
                // !!props.location.state.isExistingComp &&
                !!!props.location.state.primaryContact ? (
                  <Title style={{ color: "#fff" }} level={3}>
                    Pending Approval
                  </Title>
                ) : (
                  <Title style={{ color: "#fff" }} level={3}>
                    Pending Verification
                  </Title>
                )}
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                backgroundColor: "#3596db",
                margin: "-24px -24px 10px -24px"
              }}
              type="flex"
              justify="center"
              gutter={[8, 16]}
            >
              <Col>
                <Title style={{ color: "#fff" }} level={3}>
                  Sign Up Successful
                </Title>
              </Col>
            </Row>
          )}
          <Row
            type="flex"
            justify="center"
            gutter={[8, 16]}
            className="contact-form"
          >
            {props &&
              props.location &&
              props.location.state &&
              !!props.location.state.primaryContact && (
                <Col>
                  <Avatar src={mailImage} size={90} shape="square" />
                </Col>
              )}
          </Row>
          {props &&
            props.data != "EULA" &&
            props.location &&
            props.location.state &&
            props.location.state.primaryContact &&
            !!!props.location.state.invitedUser && (
              <Row
                style={{ padding: "24px 18px" }}
                type="flex"
                justify="center"
                gutter={[8, 16]}
              >
                <Col>
                  <Title level={3} style={{ textAlign: "center" }}>
                    Please check your email to verify your account and complete
                    the registration.
                  </Title>
                  <br />
                  <p style={{ textAlign: "center", fontSize: "16px" }}>
                    If you do not receive an email in next 15 minutes, please
                    check your ‘Spam’ folder and whitelist Certificial.
                  </p>
                </Col>
              </Row>
            )}
          {props &&
            props.data != "EULA" &&
            props.location &&
            props.location.state &&
            // props.location.state.isExistingComp &&
            !!!props.location.state.primaryContact &&
            !!!props.location.state.invitedUser && (
              <Row
                style={{ padding: "24px 18px" }}
                type="flex"
                justify="center"
                gutter={[8, 16]}
              >
                <Col>
                  <Title level={3} style={{ textAlign: "center" }}>
                    To expedite the approval, you can contact the primary
                    account holder{" "}
                    <Text>
                      {props.location.state.primaryContactName
                        ? props.location.state.primaryContactName + " "
                        : ""}
                      {props.location.state.primaryContactEmail
                        ? " (" + props.location.state.primaryContactEmail + ") "
                        : " "}{" "}
                    </Text>
                    {"  "} to join{" "}
                    {props.location.state.existingCompanyName
                      ? props.location.state.existingCompanyName
                      : ""}
                  </Title>
                </Col>
              </Row>
            )}
          {props &&
            props.data != "EULA" &&
            props.location &&
            props.location.state &&
            props.location.state.invitedUser && (
              <Row
                style={{ padding: "24px 18px" }}
                type="flex"
                justify="center"
                gutter={[8, 16]}
              >
                <Col>
                  <Title level={3} style={{ textAlign: "center" }}>
                    Your account is now active. Please go to login screen to
                    sign in.
                  </Title>
                </Col>
              </Row>
            )}
          <Row type="flex" justify="center" gutter={[8, 16]}>
            <Col style={{ paddingTop: 12 }}>
              <Button
                type="primary"
                block
                onClick={() => appHistory.push("/login")}
              >
                Go Back To Login Screen
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default withRouter(RegistrationSuccess);
