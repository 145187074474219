import { MY_PENDING_USERS_ACTION_TYPES } from "./MyPendingUsers.types";

export const resetPendingUsersRequest = () => {
    return (dispatch) =>
        dispatch({
            type: MY_PENDING_USERS_ACTION_TYPES.CLEAR_MY_PENDING_USERS
        });
};

export const requestedGetAllPendingUsersRequestSuccess = (data) => {
    return (dispatch) =>
        dispatch({
            type: MY_PENDING_USERS_ACTION_TYPES.GET_MY_PENDING_USERS.SUCCESS,
            payload: data
        });
};

export const invitedUserByEmailRequestSuccess = (data) => {
    return (dispatch) =>
        dispatch({
            type: MY_PENDING_USERS_ACTION_TYPES.INVITE_USER.SUCCESS,
            payload: data
        });
};

export const requestedGetAllPendingUsersRequestError = (error) => {
    return (dispatch) =>
        dispatch({
            type: MY_PENDING_USERS_ACTION_TYPES.GET_MY_PENDING_USERS.ERROR,
            payload: error
        });
};

export const invitedUserByEmailRequestError = (error) => {
    return (dispatch) =>
        dispatch({
            type: MY_PENDING_USERS_ACTION_TYPES.INVITE_USER.ERROR,
            payload: error
        });
};