import { Icon, Typography } from "antd";
import React, { memo, useState } from "react";
import AgentTearmsAndCondition from "../../Modules/Authorization/TearmsAndConditions/AgentTearmsAndCondition";
import UserTearmsAndCondition from "../../Modules/Authorization/TearmsAndConditions/UserTearmsAndCondition";
const { Text } = Typography;
const TermsCondition = memo(function TermsCondition({ isAgent }) {
  const [showTC, setShowTC] = useState(false);
  const handleCancel = () => {
    setShowTC(false);
  };
  const handleOk = () => {
    setShowTC(false);
  };
  return (
    <span>
      <Text style={{ cursor: "pointer" }} onClick={() => setShowTC(true)}>
        <Icon
          type="safety-certificate"
          theme="twoTone"
          twoToneColor="#0094d9"
          className="margin-right-sm"
        />
        Terms & Conditions
      </Text>
      {isAgent ? (
        <AgentTearmsAndCondition
          visible={showTC}
          onOk={handleOk}
          onCancel={handleCancel}
          agreeFooter={false}
        />
      ) : (
        <UserTearmsAndCondition
          visible={showTC}
          onOk={handleOk}
          onCancel={handleCancel}
          agreeFooter={false}
        />
      )}
    </span>
  );
});

export default TermsCondition;
