import { Avatar, Badge, Col, Icon, message, Row, Upload } from "antd";
import _ from "lodash";
import React from "react";
import { additionalDocumentIcon } from "../../Assets/assets";
import { CERTIFICIAL } from "../../Utils/Constants/Application.constants";
import { _authService } from "../../Utils/Services/AuthenticationService";
import { UploadedDocument } from "./UploadedDocument";
const { Dragger } = Upload;

const MultipleFileUpload = ({
  multi,
  fileList,
  handleChange,
  defaultFileList,
  accept,
  supportedFormats,
  showSupportedFormats = true,
  actionURL,
  customSizeMessage,
  isadditionalDocument,
  useCustomUploadList,
  selectedRowKeys,
  setSelectedRowKeys,
  showUploadedDocs,
  documentsList,
  removeDoc,
  isSmartCoiFilePresent
}) => {
  const token = _authService.getUserToken();

  const _documentsList =
    documentsList &&
    documentsList.length &&
    documentsList.map((doc) => ({ ...doc, uid: doc.id }));

  const _fileList =
    fileList && fileList.length && _documentsList && _documentsList.length
      ? fileList.concat(_documentsList)
      : _documentsList && _documentsList.length
      ? _documentsList
      : fileList;

  const handleChangeCheck = (e) => {
    let maxSize = CERTIFICIAL.UPLOAD_FILES.MAX_SIZE; //5MB
    const isExisting = () => {
      const files =
        (_fileList &&
          _fileList.length &&
          e.file &&
          _fileList.filter((f) => f.name === e.file.name)) ||
        [];
      return !!!files.length;
    };
    if (isExisting()) {
      const filterList = _.filter(e.fileList, (o) => {
        return o.size < maxSize;
      });
      e.fileList = [];
      e.fileList = filterList;
      handleChange(e);
    }
  };

  const handleFileDelete = (itemToDelete) => {
    if (showUploadedDocs && itemToDelete && itemToDelete.id) {
      removeDoc(itemToDelete.id);
    } else {
      const updatedFileList = _.filter(
        fileList,
        (o) => o.name !== itemToDelete.name || o.uid !== itemToDelete.uid
      );
      handleChangeCheck({ fileList: updatedFileList });
    }
  };

  const uploadProps = {
    name: "file",
    fileList: _fileList,
    multiple: !!multi ? multi : false,
    action: actionURL,
    accept: accept,
    headers: {
      Authorization: ["Bearer", token].join(" ")
    },
    onChange: handleChangeCheck,
    beforeUpload: (file) => {
      if (fileList && _fileList.length) {
        const isExisting = _fileList.filter((f) => f.name === file.name);
        if (isExisting && !!isExisting.length) {
          message.warn(
            file.name + " is already uploaded, please upload new document."
          );
          return false;
        }
      }
      if (file.size > CERTIFICIAL.UPLOAD_FILES.MAX_SIZE && !customSizeMessage) {
        message.warn(
          "File" +
            " " +
            file.name +
            " " +
            "size should be less than" +
            CERTIFICIAL.UPLOAD_FILES.MAX_SIZE_MESSAGE +
            " !!"
        );
        return false;
      }
      return false;
    },
    defaultFileList: defaultFileList
  };

  const fileUploadText =`Click or drag ${isadditionalDocument ? 'file' : 'COI'}  to this area to upload document.`

  return (
    <React.Fragment>
      {isSmartCoiFilePresent ? null : <Dragger {...uploadProps} showUploadList={false}>
        <Row>
          <Col span={6}>
            <Badge
              count={_fileList && _fileList.length}
              showZero={false}
              overflowCount={10}
              offset={[-29, 6]}
            >
                <p className="ant-upload-drag-icon">
                  <Icon
                    type="inbox"
                    style={{ fontSize: "3.6rem" }}
                    className="color-primary"
                  />
                </p>
            </Badge>
          </Col>
          <Col span={14}>
            <p className="ant-upload-text">
              {fileUploadText}
            </p>

            {showSupportedFormats ? <p className="ant-upload-hint">
              Supports{" "}
              {supportedFormats
                ? supportedFormats
                : " .JPEG, .JPG, .PNG, and .PDF "}
              formats.
            </p> : null}

            <p className="ant-upload-hint">
              File size should be less than
              {CERTIFICIAL.UPLOAD_FILES.MAX_SIZE_MESSAGE}.
            </p>
          </Col>
        </Row>
      </Dragger>}
      <div>
        <UploadedDocument
          isSmartCoiFilePresent={isSmartCoiFilePresent}
          fileList={_fileList}
          isRowSelection={useCustomUploadList}
          selectedRowKeys={selectedRowKeys}
          handleFileDelete={handleFileDelete}
          onSelectedRowKeysChange={setSelectedRowKeys}
        />
      </div>
    </React.Fragment>
  );
};

export default MultipleFileUpload;
