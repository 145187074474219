import React from "react";
import { Modal, Typography, Button, Row, Col, Card } from "antd";
import EmailHeader from "../../Template/EmailHeader";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import "./LearnMore.less"
const { AUTO_RESPONSE } = APPLICATION_MESSAGES.AGENT;
const { Text } = Typography;

const LearnMoreDetails = ({visible, onOk, emailPage}) => {
  return (
	emailPage ? 
	<>
	<EmailHeader></EmailHeader>
	<Row className="learn-more-wrapper" type="flex" justify="center">
		<Col span={15}> 
		<Card title={AUTO_RESPONSE.LEARN_MORE_TITLE}>
								{AUTO_RESPONSE.LEARN_MORE_DETAILS}
				</Card>
		</Col>
	</Row>
	</>:
    <div>
      <Modal
        title={AUTO_RESPONSE.LEARN_MORE_TITLE}
        visible={visible}
		centered={true}
		wrapClassName="learn-more-wrapper"
		width={850}
		closeIcon={()=>null}
        destroyOnClose={true}
		closable={false}
		cancelButtonProps={{
			style: { display: "none" }
		}}
		cancelText={()=>null}
        onOk={onOk}
      >
		<div>
			{AUTO_RESPONSE.LEARN_MORE_DETAILS}
		</div>
      </Modal>
    </div>
  );
};

export default LearnMoreDetails;
