import React, { memo } from "react";
import ReactHelmet from 'react-helmet';
import SEOData from './seoDetails';
const LoadSeoData = ({ seoContent }) => {
    let siteTitle = SEOData.default.title;
    let metaDescription = SEOData.default.metaDescription;
    if (seoContent !== undefined) {
        if (seoContent.title !== undefined) {
            siteTitle = seoContent.title;
        }
        if (seoContent.metaDescription !== undefined) {
            metaDescription = seoContent.metaDescription;
        }
    } else {
        siteTitle = SEOData.default.title;
        metaDescription = SEOData.default.metaDescription;
    }
    document.querySelector('meta[name="description"]').setAttribute("content", metaDescription);
    return (
        <>
            <ReactHelmet>
                <title>{siteTitle}</title>
            </ReactHelmet>
        </>
    )
}

export default memo(LoadSeoData);