export const AGENTS_REQUEST_LIST_TYPE = {
  LIST_OF_REQUEST: {
    SUCCESS: "AGENT_PENDING_LIST_BY_REQUEST_SUCCESS",
    DUMMY_REQUESTS: "AGENT_PENDING_DUMMY_REQUESTS",
    ERROR: "AGENT_PENDING_ERROR"
  },
  LIST_OF_POLICY: {
    SUCCESS: "AGENT_PENDING_LIST_OF_POLICY_SUCCESS",
    ERROR: "AGENT_PENDING_LIST_OF_POLICY_ERROR",
    POLICY_NULL: "AGENT_PENDING_LIST_OF_POLICY__NULL",
    DUMMY_POLICIES: "AGENT_PENDING_DUMMY_POLICIES"
  },
  REQUEST_ACTION: {
    ERROR: "AGENT_PENDING_REQUEST_ACTION_ERROR"
  },
  LIST_OF_RESPOND: {
    SUCCESS: "AGENT_PENDING_LIST_BY_RESPOND_REQUEST_SUCCESS",
    DUMMY_RESPOND: "AGENT_PENDING_DUMMY_RESPOND",
    ERROR: "AGENT_PENDING_RESPOND_ERROR"
  },
  LIST_OF_RESPOND_POLICY: {
    SUCCESS: "AGENT_PENDING_LIST_OF_RESPOND_POLICY_SUCCESS",
    ERROR: "AGENT_PENDING_LIST_OF_RESPOND_POLICY_ERROR"
    // POLICY_NULL: "AGENT_PENDING_LIST_OF_POLICY__NULL",
    //DUMMY_RESPOND: "AGENT_PENDING_DUMMY_RESPOND"
  },
  GET_PROJECTS_LIST_BY_COMPANY: {
    SUCCESS: "AGENT_PENDING_GET_PROJECTS_LIST_BY_COMPANY_SUCCESS",
    ERROR: "AGENT_PENDING_GET_PROJECTS_LIST_BY_COMPANY_ERROR"
  },
  SET_ACTIVE_PROJECT: "REQUEST_SET_ACTIVE_PROJECT",
  CLEAR_ACTIVE_PROJECT: "REQUEST_CLEAR_ACTIVE_PROJECT",
  RESPOND_ACTION: {
    ERROR: "AGENT_PENDING_RESPOND_ACTION_ERROR"
  },
  CLEAR: {
    CLEAR_LIST_OF_COMPANIES: "AGENT_PENDING_CLEAR_LIST_OF_COMPANIES",
    CLEAR_LIST_OF_POLICY: "AGENT_PENDING_CLEAR_LIST_OF_COMPANIES"
  },
  SET_DRAWER_SIZE: 'SET_DRAWER_SIZE',
};
