import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, Icon, Button, Spin, Alert } from 'antd';
import { useLocation } from "react-router-dom";
import { _modalService } from '../../../Utils/wrappers/Modal/ModalWrapper';
import { isEmpty } from 'lodash';
import EmailHeader from '../../Template/EmailHeader';
import { appHistory } from "../../../Utils/Helpers/History";

import { setAutoResponseOptIn, getAutomaticResponseOptInValidationData } from '../../../Store/Modules/AutoResponse/AutoResponse.asyncrequest'
import UploadSignature from '../../../Components/UI/UploadSignature';
import { APPLICATION_USER_ROLES } from '../../../Utils/Constants/Application.constants';
import LearnMore from "./LearnMoreButton";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import SuccessMessage from '../../../Components/UI/Email/SuccessMessage';
import InvalidLink from '../../../Components/UI/Email/InvalidLink';
const { AUTO_RESPONSE } = APPLICATION_MESSAGES.AGENT;




export const AutoResponse = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const agentId = params.get('agentId');
	const emailKey = params.get('emailKey');
	const requestId = params.get('requestId');
	const certUploadId = params.get('certUploadId');

	const [buttonLoading, setButtonLoading] = useState(false);
	const [signatureDataURI, setSignatureDataURI] = useState(null);
	const [response, setResponse] = useState(null);


	const [loading, setLoading] = useState(false);


	const onOptInCOnfirmClickHandler = async() => {
		setButtonLoading(true);
		try{
			await setAutoResponseOptIn({
				agentId,
				certUploadId,
				emailKey,
				requestId,
				eoiCoverageTemplateId:response.eoiCoverageTemplateId,
				signatureDataURI
			})
		}catch(err){

		}
		setButtonLoading(false);
		setResponse({
			optInSuccess:true
		})
	}

	const getInvalidLinkComponent = (msg) => {
		return <InvalidLink title={msg}></InvalidLink>
	}



	const optInMessageComponent = (<Row>
		<Col>
			<p>{AUTO_RESPONSE.OPT_IN}</p>
		</Col>
	</Row>);

	const confirmComponent = (disabled) => {
		return <Row className="margin-top-sm" gutter={[16, 0]} type="flex" justify="end">
			<Col style={{ marginRight: "auto" }}><LearnMore /></Col>
			<Col>
				<Button loading={buttonLoading} disabled={disabled} type='primary' onClick={onOptInCOnfirmClickHandler}>Confirm</Button>
			</Col>
		</Row>
	}


	const getOptInComponent = () => {
		return (
			<Col span={12} >
				<Card title={`Opt-In to Auto Responses`} bordered={false}>
					{optInMessageComponent}
					{confirmComponent()}
				</Card>
			</Col>

		)
	}

	const UploadSignatureComponent = (<Row>
		<Col><p>Please generate or upload your signature below.</p></Col>
		<Col>
			<UploadSignature
				setSignatureDataUrl={(data) => { setSignatureDataURI(data) }}
				agentInfo={{...response?.agentDetails, id:agentId, role: APPLICATION_USER_ROLES.AGENT}}
			>
			</UploadSignature>
		</Col>
	</Row>);

	const getUploadSignatureConfirmComponent =()=> <Col span={15} ><Card title={`Opt-In to Auto Responses`} bordered={false}>
		{optInMessageComponent}
		{UploadSignatureComponent}
		{confirmComponent(!signatureDataURI)}
	</Card></Col>


	const getCreateAccountComponent = () => {
		return (
			<Col span={12} >
				<Card title={`Opt-In to Auto Responses`} bordered={false}>

					<Row>
						<Col>
								{AUTO_RESPONSE.CREATE_ACCOUNT}
						</Col>
					</Row>
					<Row>
					</Row>
					<Row type="flex" justify="end">
						<Col>
							<Button
								onClick={() => {
									appHistory.replace("/sign-up-agent/new");
								}} type="primary">Create an Account</Button>
						</Col>
					</Row>
				</Card></Col>)
	}


	const getSuccessMsgComponent = () => {
		return <SuccessMessage
			title={AUTO_RESPONSE.SUCCESS}
		/>
	}

	const validateLink = async () => {
		setLoading(true);
		try {
			const response = await getAutomaticResponseOptInValidationData({
				agentId,
				emailKey,
				certUploadId,
				requestId
			});
			setResponse(response);
		} catch (err) {
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		validateLink();
	}, []);


	const getComponentByStatus = () => {
		let _component;
		if (!response) {
			return null;
		}
		if(response.optInSuccess){
			_component = getSuccessMsgComponent();
		}
		else if (!isEmpty(response.validationMsg)) {
			_component = getInvalidLinkComponent(response.validationMsg);
		} else if (!response?.agentDetails?.agentRegisteredFlag) {
			_component = getCreateAccountComponent();
		} else if (!response?.agentDetails?.signaturePresentFlag) {
			_component = getUploadSignatureConfirmComponent();
		} 
		else{
			_component = getOptInComponent();
		}
		return _component;
	}

	return (
		<>
			<EmailHeader />
			<Spin
				spinning={loading}
				wrapperClassName=""
				indicator={
					<Icon
						type="loading-3-quarters"
						className="color-primary fs-36x"
						spin
					/>
				}
				tip={'Loading..'}
				size="large"
			>
				<Row type="flex" justify="center">
					{getComponentByStatus()}
				</Row>
			</Spin>
		</>
	)
}

