import { Icon, Select, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { titleMaintainer } from "../../../Utils/utility/app.utility";
import AddPolicyInsurerDetails from "../../Policy/PolicyList/PolicyDetails/PolicyInsurerDetails/AddPolicyInsurerDetails";

const { Option } = Select;

export const CustomSelectWithNewEntry = (props) => {
  const [searchedResults, setSearchedResults] = useState([]);
  const _customInsurer = [
    {
      id: "custom_insurer",
      name: "Text here",
      custom_insurer: true
    }
  ];

  const _loadMoreInsurer = [
    {
      id: "load_more__insurer",
      name: "Loading ...",
      loadMoreInsurer: true
    }
  ];

  useEffect(() => {
    if (props.isAPIRan && !props.loading) {
      if (props.hasNextPage) {
        setSearchedResults(props.dataSource.concat(_loadMoreInsurer));
      } else {
        setSearchedResults(props.dataSource.concat(_customInsurer));
      }
    }
    return () => {};
  }, [
    props.dataSource,
    props.isAPIRan,
    props.loading,
    props.isOnDemandRequest
  ]);
  return (
    <>
      {(props.isOnDemandRequest || !props.loading) &&
      searchedResults &&
      searchedResults.length > 1 ? (
        <Select {...props}>
          {searchedResults.map((data) => {
            const insurerName = data.custom_insurer
              ? ""
              : props.requestedRegion &&
                data.country.countryName !== "United States of America"
              ? `${data.name || data.companyName} ( ${
                  data.country ? data.country.countryName : ""
                } )`
              : data.name || data.companyName;

            const insurerOption =
              (insurerName + data.companyNaic).length > 40 ? (
                <Tooltip
                  placement="left"
                  title={`${insurerName} (${data.companyNaic})`}
                  // getPopupContainer={() =>
                  //   document.getElementById("policy-list")
                  // }
                >
                  {titleMaintainer(
                    insurerName,
                    44 - `${data.companyNaic}`.length
                  )}
                  <Tag
                    color="#2db7f5"
                    style={{
                      float: "right",
                      marginRight: "-1px",
                      cursor: "pointer"
                    }}
                  >
                    {data.ambestCarrierRating}
                  </Tag>
                  <Tag
                    color="blue"
                    style={{
                      float: "right",
                      marginRight: "2px",
                      cursor: "pointer"
                    }}
                  >
                    {data.companyNaic}
                  </Tag>
                </Tooltip>
              ) : (
                <span>
                  {insurerName}
                  <Tag
                    color="#2db7f5"
                    style={{
                      float: "right",
                      marginRight: "-1px",
                      cursor: "pointer"
                    }}
                  >
                    {data.ambestCarrierRating}
                  </Tag>
                  <Tag
                    color="blue"
                    style={{
                      float: "right",
                      marginRight: "2px",
                      cursor: "pointer"
                    }}
                  >
                    {data.companyNaic}
                  </Tag>
                </span>
              );
            return (
              <Option
                key={data.key || data.id}
                value={data.id || data.agencyId || props.defaultVal}
                label={data.custom_insurer ? "" : insurerName}
                style={
                  data.custom_insurer
                    ? {
                        background: "rgba(24, 144, 255, 0.05)",
                        cursor: "pointer",
                        color: "#3596db"
                      }
                    : {
                        width: "100%"
                      }
                }
              >
                {data.custom_insurer ? (
                  <span>
                    <Icon
                      type="plus-circle"
                      theme="twoTone"
                      style={{ marginRight: 6 }}
                    />
                    Add New Insurer
                  </span>
                ) : data.loadMoreInsurer ? (
                  <span>
                    <Icon
                      type="loading"
                      className="margin-0"
                      style={{
                        color: "#3596db",
                        fontSize: 14
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "8px",
                        color: "#3596db",
                        fontSize: 14
                      }}
                    >
                      Fetching Records ...
                    </span>
                  </span>
                ) : (
                  insurerOption
                )}
              </Option>
            );
          })}
        </Select>
      ) : props.isAPIRan && searchedResults && searchedResults.length === 1 ? (
        <Select {...props}>
          <Option
            key={"custom_insurer"}
            value={"custom_insurer"}
            label={""}
            style={{
              background: "rgba(24, 144, 255, 0.05)",
              cursor: "pointer",
              color: "#3596db"
            }}
          >
            <span>
              <Icon
                type="plus-circle"
                theme="twoTone"
                style={{ marginRight: 6 }}
              />
              Add New Insurer
            </span>
          </Option>
        </Select>
      ) : (
        <Select {...props}></Select>
      )}

      <AddPolicyInsurerDetails
        isdataAvailable={props.dataSource && !!props.dataSource.length}
        onAddNewInsurer={props.onAddNewInsurer}
        handleCancel={props.customInsurerHandleCancel}
        visible={props.customInsurerVisible}
        confirmLoadingCustomInsurerModal={
          props.confirmLoadingCustomInsurerModal
        }
        addInsurerModalMessage={props.addInsurerModalMessage}
      />
    </>
  );
};
