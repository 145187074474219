import { message } from "antd";
import _ from "lodash";
import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import { requestForApplicationReferenceData, getRegionWisePolicyTypeData } from "../Application/Application.asyncRequest";
import { setCertUploadStatus } from './MyProfile.action';
import { escapeHtmlTags } from '../../../Utils/utility/app.utility'
import {
  agentAddedError,
  agentInforRequestsSuccess,
  deleteAgentSignatureSuccess,
  inviteAgentError,
  inviteAgentSuccess,
  requestedUserProfileError,
  requestedUserProfileSuccess,
  uploadAgentSignatureSuccess,
  userPasswordUpdateError,
  userPasswordUpdateSucess,
  userUpdateError,
  userUpdateSucess
} from "./MyProfile.action";

export const getUserInformation = (
  userId,
  dummySessionId,
  callbackFunction
) => {
  let url = dummySessionId
    ? [ENDPOINTS.AUTHENTICATION.USER, userId, dummySessionId].join("/")
    : [ENDPOINTS.AUTHENTICATION.USER, userId].join("/");
  return (dispatch, getState) =>
    makeRequest({
      type: API_METHODS.GET,
      url
    }).then(
      (userInfo) => {
        const {
          company: { id: companyId }
        } = userInfo;
        let userObj = _.omit(_authService.getUserInfo(), ["dummySessionId"]);
        _authService.setUserInfo(userObj);
        dispatch(requestedUserProfileSuccess(userInfo));
        if (userInfo.role !== "Agent") {
          dispatch(getAgentInformation(companyId, callbackFunction));
        }
        dispatch(
          requestForApplicationReferenceData(
            userInfo && userInfo.company && userInfo.company.regionId
              ? userInfo.company.regionId
              : 1,
            callbackFunction
          )
        );

        dispatch(
          getRegionWisePolicyTypeData(userInfo.company?.regionId || 1)
        )
      },
      (error) => {
        callbackFunction && callbackFunction();
        dispatch(requestedUserProfileError(error));
      }
    );
};

export const getUpdatedUserInformation = (userId) => {
  let url = [ENDPOINTS.AUTHENTICATION.USER, userId].join("/");
  return (dispatch, getState) =>
    makeRequest({
      type: API_METHODS.GET,
      url
    }).then(
      (userInfo) => {
        let userObj = _.omit(_authService.getUserInfo(), ["dummySessionId"]);
        _authService.setUserInfo(userObj);
        dispatch(requestedUserProfileSuccess(userInfo));
      },
      (error) => {
        dispatch(requestedUserProfileError(error));
      }
    );
};

// GET AGENT INFORMATION

export const getAgentInformation = (clientComapanyId, callbackFunction) => {
  return (dispatch) => {
    makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.AGENT_BY_USER.USER_PROFILE_AGENTS, clientComapanyId].join(
        "/"
      )
    }).then(
      (AgentListRequest) => {
        dispatch(agentInforRequestsSuccess(AgentListRequest || []));
        callbackFunction && callbackFunction();
      },
      (error) => {
        callbackFunction && callbackFunction();
      }
    );
  };
};

export const editCompanyProfile = (
  formData,
  companyId,
  callback,
  stopLoading
) => {
  return makeRequest({
    type: API_METHODS.PUT,
    url: [ENDPOINTS.USER.EDIT_COMPANY_PROFILE, companyId].join("/"),
    data: formData,
    options: {
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s"
      }
    }
  }).then(
    (res) => {
      callback(res);
      return res;
    },
    (err) => {
      stopLoading(err);
      return err;
    }
  );
};

export const deleteCompanyLogo = (companyId, callback) => {
  return makeRequest({
    type: API_METHODS.DELETE,
    url: [ENDPOINTS.USER.DELETE_COMPANY_LOGO, companyId].join("/")
  })
    .then((res) => {
      callback(res);
      return res;
    })
    .then(() => {});
};

// ADD AGENT TO USER LIST
export const getListOfAgent = (value) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ENDPOINTS.ADD_AGENT.GET_AGENT_LIST, value].join("/")
  });
};
export const addAgency = (value) => {
  const obj = {
    agentId: value.agencyId,
    userId: value.userId
  };
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.ADD_AGENT.POST_ADD_AGENCY_TO_USER,
      data: obj
    }).then(
      (response) => {
        if (response !== null) {
          dispatch(addAgent(value));
          // dispatch(agentAddedSuccess(value.agency));
        }
      },
      (error) => {
        dispatch(agentAddedError(error));
      }
    );
  };
};
export const addAgent = (value) => {
  const { agencyId, agentId, userId } = value;

  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.ADD_AGENT.POST_ADD_AGENT_TO_USER,
      data: { agencyId, agentId, userId }
    }).then(
      (response) => {
        if (response !== null) {
          dispatch(getAgentInformation(value.companyId));
          message.success("Agent Added Successfully.");
        }
      },
      (error) => {
        dispatch(agentAddedError(error));
      }
    );
  };
};

/**
 * INVITE AGENT
 * */

export const inviteAgentByEmail = (value) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.ADD_AGENT.INVITE_AGENT_BY_EMAIL,
      data: value
    }).then(
      (response) => {
        if (response !== null) {
          dispatch(inviteAgentSuccess(value));
          message.success("Email Sent Successfully.");
          dispatch(getAgentInformation(value && value.currentUserCompanyId));
        }
      },
      (error) => {
        dispatch(inviteAgentError(error));
      }
    );
  };
};

export const deleteAgentSignature = (id) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.DELETE,
      url: ENDPOINTS.USER.DELETE_AGENT_SIGNTURE + "/" + id
    }).then(
      (response) => {
        dispatch(deleteAgentSignatureSuccess());
        message.success(response);
      },
      (err) => {
        message.error(
          "Unable to process request, please contact service administrator."
        );
      }
    );
  };
};


export const transferAgent = (userId, insuredCompanyId) => {
  return async(dispatch) => {
    return await makeRequest({
      type: API_METHODS.PUT,
      url: [ENDPOINTS.AGENT_DASHBOARD.AGENT_TRANSFER, userId, insuredCompanyId].join(
        "/"
      )
    })
  };
};

export const transferAgentFromSameDomain = (transferToAgentId, insuredCompanyId, requestId) => {
  return async(dispatch) => {
    return await makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.AGENT_DASHBOARD.AGENT_AUTO_TRANSFER,
      data :{
        transferToAgentId,
        requestId,
        insuredCompanyId
    }
    })
  };
};


export const uploadSignatureAPICall = (userId, dataURI)=>{
  return makeRequest({
    type: API_METHODS.POST,
    url: [ENDPOINTS.APPLICATION.UPLOAD_SIGNATURE, userId].join("/"),
    data: { dataURI },
    headers: {
      "content-type": "application/json"
    }
  })
}

export const uploadSignature = (userId, dataURI, callback) => {
  return (dispatch) => {
    return uploadSignatureAPICall(userId, dataURI).then(
     async(response) => {
        await message.success({content:"Signature uploaded successfully.", duration:2});
        dispatch(uploadAgentSignatureSuccess(response));
        callback && callback();
      },
      (err) => {
        message.error(
          "Unable to process request, please contact service administrator."
        );
      }
    );
  };
};

/**
 * UPDATE USER NAD COMPNAY INFORMATION
 * */
export const updateUserAndCompanyInfo = (obj, custObj) => {
  //let clonedPayload = {...custObj};
  // let dataNotHavingHtmlTags = escapeHtmlTags(custObj, 'updateProfile');
  // obj.set(
  //   "request",
  //   JSON.stringify({
  //     ...dataNotHavingHtmlTags,
  //   })
  // );
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.PUT,
      url: ENDPOINTS.USER.UPDATE_USER_PROFILE,
      data: obj,
      options: {
        headers: {
          "content-type":
            "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s"
        }
      }
    }).then(
      (response) => {
        dispatch(userUpdateSucess(custObj));
      },
      (error) => {
        dispatch(userUpdateError(error));
      }
    );
  };
};

// CHANGE PASSWORD
export const updatePassword = (obj) => {
  return (dispatch) => {
    return makeRequest({
      type: API_METHODS.PUT,
      url: ENDPOINTS.USER.UPDATE_PASSWORD,
      data: obj
    }).then(
      (response) => {
        dispatch(userPasswordUpdateSucess(response));
      },
      (error) => {
        dispatch(userPasswordUpdateError(error));
      }
    );
  };
};

/**
 * Update Agent Priority
 */

export const priorityChangeforAgents = (data, clientId) =>
  makeRequest({
    type: API_METHODS.PUT,
    url: clientId
      ? [ENDPOINTS.ADD_AGENT.UPDATE_AGENT_PRIORITY, clientId].join("/")
      : ENDPOINTS.ADD_AGENT.UPDATE_AGENT_PRIORITY,
    data,
    options: { shouldNotShowError: true }
  });

export const requestCertUoloadStatus = (payload) => {
  return (dispatch) => {
    if(payload.insuredCompanyId) {
      return makeRequest({
        type: API_METHODS.POST,
        url: ENDPOINTS.PENDING_INSURED_REQUEST.POST_COI_UPLOAD,
        data: payload
      }).then((response) => {
        dispatch(setCertUploadStatus(response));
      }).catch((error)=>{
        dispatch(setCertUploadStatus([]));
      })
    }
  }
}
