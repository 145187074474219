import { APPLICATION_MODULES } from "../../../Utils/Constants/Application.constants";
import { TEMPLATE_TYPES } from "./Template.types";
const PolicyTemplateState = {
  title: APPLICATION_MODULES.TEMPLATES.TITLE,
  PolicyTemplateList: [],
  AddTemplateSuccess: {
    value: null,
    message: null,
    id:null
  },
  EditTemplateSuccess: { value: null, message: null },
  DeleteTemplateSuccess: { value: null, message: null },
  isError: false,
  isAddEditLoading: false,
  isDataLoaded: false,
  isLoading: false,
  activeTab: null,
  selectedTemplate: {},
  selectedVersion: {},
  templateNameList: [],
  allTemplates_size: 0,
  allTemplates: [],
  lastEditHistory: [],
  TemplateCoverageTypes: {
    value: [],
    TemplateCoverageTypesSuccess: false,
    TemplateCoverageTypesError: false
  }
};

const Base_PolicyTemplateState = { ...PolicyTemplateState };

const TemplateReducer = (state = PolicyTemplateState, action) => {
  switch (action.type) {
    case TEMPLATE_TYPES.SET_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TEMPLATE_TYPES.SET_ADDEDITLOADING: {
      return {
        ...state,
        isAddEditLoading: action.payload
      };
    }
    case TEMPLATE_TYPES.SET_TEMPLATE_NAME_LIST: {
      return {
        ...state,
        templateNameList: action.payload
      };
    }
    case TEMPLATE_TYPES.GET_TEMPLATE_LIST.SUCCESS: {
      return {
        ...state,
        PolicyTemplateList: action.payload.data,
        allTemplates_size: action.payload.size,
        isDataLoaded: true,
        isError: false,
        isLoading: false,
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.GET_TEMPLATE_HISTORY.SUCCESS: {
      return{
        ...state,
        lastEditHistory: action.payload || []
      }
    }
    case TEMPLATE_TYPES.GET_TEMPLATE_LIST.ERROR: {
      return {
        ...state,
        PolicyTemplateList: [],
        allTemplates_size: 0,
        selectedTemplate: {},
        activeTab: null,
        isError: true,
        isDataLoaded: false,
        isLoading: false,
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.UI.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.UI.SET_SELECTED_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: action.payload,
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.UI.SET_SELECTED_VERSION: {
      return {
        ...state,
        selectedVersion: action.payload,
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.UI.CLEAR_SELECTED_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: {}
      };
    }
    case TEMPLATE_TYPES.UI.CLEAR_SELECTED_VERSION: {
      return {
        ...state,
        selectedVersion: {}
      };
    }
    case TEMPLATE_TYPES.GET_TEMPLATE_COVERAGE_TYPES.SUCCESS: {
      return {
        ...state,
        TemplateCoverageTypes: {
          value: action.payload,
          TemplateCoverageTypesSuccess: true,
          TemplateCoverageTypesError: false
        },
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.CREATE_TEMPLATE.SUCCESS: {
      return {
        ...state,
        AddTemplateSuccess: {
          value: true,
          message:
            action.payload &&
            action.payload.name + "  Template Added Successfully!",
          id:action.payload.id
        },

        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.CREATE_TEMPLATE.ERROR: {
      return {
        ...state,
        AddTemplateSuccess: {
          value: false,
          message:
            "Unable to process request, please contact service administrator.",
          id:null
        },

        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.EDIT_TEMPLATE.SUCCESS: {
      return {
        ...state,
        EditTemplateSuccess: {
          value: true,
          message: action.payload
        },
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.EDIT_TEMPLATE.ERROR: {
      return {
        ...state,
        EditTemplateSuccess: {
          value: false,
          message:
            "Unable to process request, please contact service administrator."
        },
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.GET_TEMPLATE_COVERAGE_TYPES.ERROR: {
      return {
        ...state,
        TemplateCoverageTypes: {
          value: action.payload,
          TemplateCoverageTypesSuccess: false,
          TemplateCoverageTypesError: true
        },
        AddTemplateSuccess: {
          value: null,
          message: null,
          id:null
        },
        EditTemplateSuccess: { value: null, message: null },
        DeleteTemplateSuccess: { value: null, message: null }
      };
    }
    case TEMPLATE_TYPES.DELETE_REQUIREMENT_TEMPLATE.SUCCESS: {
      return {
        ...state,
        DeleteTemplateSuccess: { value: true, message: action.payload }
      };
    }
    case TEMPLATE_TYPES.DELETE_REQUIREMENT_TEMPLATE.ERROR: {
      return {
        ...state,

        DeleteTemplateSuccess: { value: false, message: action.payload }
      };
    }
    case TEMPLATE_TYPES.CLEAR_TEMPLATES: {
      return {
        ...Base_PolicyTemplateState
      };
    }
    default:
      return state;
  }
};

export default TemplateReducer;
