import React from "react";
import QueueAnim from "rc-queue-anim";
import './CollapsePanelContent.less'
export const CollapsePanelContent = React.forwardRef((props, ref) => {
  return (
    <div
      className="policy-details-child-outer"
    >
      {/* <QueueAnim ref={ref} type="bottom"> */}
      {props.children}
      {/* </QueueAnim> */}
    </div>
  );
});
export const NestedCollapsePanelContent = React.forwardRef((props, ref) => {
  return (
    <div
      style={{
        background: "white",
        margin: "0 -16px -16px ",
        borderTop: "1px solid #d9d9d9 ",
        padding: 12
      }}
    >
      <QueueAnim ref={ref} type="bottom">
        {props.children}
      </QueueAnim>
    </div>
  );
});
