import { UPLOADCERT_TYPES } from "./UploadCert.types";
const UploadCertState = {
    isError: false,
    isLoading: false,
    uploadCertList: []
};

const UploadCertReducer = (state = UploadCertState, action) => {
    switch (action.type) {
        case UPLOADCERT_TYPES.SET_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }

        case UPLOADCERT_TYPES.GET_UPLOADCERT_LIST.SUCCESS: {
            return {
                ...state,
                uploadCertList: action.payload,
                isError: false,
                isLoading: false
            };
        }
        case UPLOADCERT_TYPES.GET_UPLOADCERT_LIST.ERROR: {
            return {
                ...state,
                uploadCertList: [],
                isError: true,
                isLoading: false
            };
        }
        case UPLOADCERT_TYPES.GET_UPLOADCERT_LIST.RESET: {
            return {
                ...state,
                uploadCertList: [],
                isError: false,
                isLoading: false
            };
        }

        default:
            return state;
    }
};

export default UploadCertReducer;
