import React from "react";
import { Result, Button } from "antd";
import { appHistory } from "../../../Utils/Helpers/History";
import { ServerErrorImage } from "../../../Assets/assets";

const ServerInternalError = ({ path }) => {
  return (
    <Result
      icon={
        <img
          src={ServerErrorImage}
          alt={"Internal Server Error"}
          height={250}
        />
      }
      title={
        <h2
          className="margin-0 color-danger"
          style={{ fontSize: "3rem", fontWeight: 800 }}
        >
          500{" "}
        </h2>
      }
      subTitle={
        <>
          <h3 className="margin-0" style={{ fontSize: "1.5rem" }}>
            Something went wrong!
          </h3>
          <h4 className="margin-0" style={{ fontSize: "1rem" }}>
            Server Error 500. Certifical staff has been notified, Thank you for
            your understanding.
          </h4>
        </>
      }
      extra={
        <Button
          type="primary"
          ghost
          onClick={() => (path ? appHistory.push(path) : appHistory.goBack())}
        >
          Back to Home
        </Button>
      }
    />
  );
};

export default ServerInternalError;
