export const EXTRACTION_POLICIES_TYPES = {
    GET_EXTRACTED_POLICIES: {
      SUCCESS: "GET_EXTRACTION_DATA_SUCCESS",
      ERROR: "GET_EXTRACTION_DATA_ERROR"
    },

    SET_EXTRACTION_PROGRESS:{
      SUCCESS: "SET_EXTRACTION_PROGRESS_SUCCESS",
      ERROR: "SET_EXTRACTION_PROGRESS_ERROR"
    },

    GET_INACTIVE_POLICIES: {
      SUCCESS: "GET_INACTIVE_DATA_SUCCESS",
      ERROR: "GET_INACTIVE_DATA_ERROR"
    },
    
    SET_EXTRACTION_STATUS:{
      SUCCESS: "SET_EXTRACTION_STATUS_SUCCESS",
      ERROR: "SET_EXTRACTION_STATUS_ERROR"
    },
    UPDATE_EXTRACTED_POLICIES:"UPDATE_EXTRACTED_POLICIES",
    SET_STEPS:"SET_STEPS",
    RESET_STATES:"RESET_STATES",
    SAVE_DATA:{
      SUCCESS:"SAVE_DATA_SUCCESS",
      ERROR:"SAVE_DATA_ERROR"
    },
    SET_GENERATE_SIGNATURE:"SET_GENERATE_SIGNATURE"
};


  