import "./ProfileDropdown.css";
import React from "react";
import { Menu, Icon, Row, Col, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { APPLICATION_USER_ROLES } from "../../Utils/Constants/Application.constants";
import { validateUserPermission } from "../../Utils/utility/app.utility";

const ProfileDropdown = (props) => {
  const { userData } = useSelector((state) => state.myProfile);
  const { currentSession } = useSelector((state) => state.app);
  const disableManageProject = validateUserPermission(userData.permissions, currentSession.role, "MANAGE_PROJECT_MENU");
  const disableSupplierAddressBook = validateUserPermission(userData.permissions, currentSession.role, "SUPPLIER_ADDRESS_BOOK_MENU");

  return (
    <div
      style={{
        margin: "-12px -16px",
        width: 215
      }}
    >
      <Menu
        onClick={props.profileMenuHandler}
        className="profile-menu"
        selectedKeys={props.location}
        mode="inline"
        theme="light"
      >
        <Menu.Item
          key="/app/myProfile"
          style={{
            paddingLeft: 12
          }}
        >
          <Row gutter={[8, 0]}>
            <Col span={4}>
              <Icon
                type="profile"
                style={{
                  fontSize: 18,
                  color: "#353535",
                  verticalAlign: "middle"
                }}
              />
            </Col>
            <Col span={20}>My Profile</Col>
          </Row>
        </Menu.Item>
        <Menu.Divider />
        {(currentSession &&
          currentSession.role !== APPLICATION_USER_ROLES.AGENT && (
            <Menu.Item
              key="/app/company-manage"
              style={{
                paddingLeft: 12
              }}
            >
              <Tooltip
                title="Click to change/create Company"
                arrowPointAtCenter
                placement="left"
              >
                <Row gutter={[8, 0]}>
                  <Col span={4}>
                    <Icon
                      type="retweet"
                      style={{
                        fontSize: 18,
                        color: "#353535",

                        verticalAlign: "middle"
                      }}
                    />
                  </Col>
                  <Col span={20}>
                    {/* {userData && userData.company && userData.company.name}{" "}  // commented company name for better space management */}
                    Switch Company
                  </Col>
                </Row>
              </Tooltip>
            </Menu.Item>
          )) ||
          null}
        <Menu.Divider />
        {currentSession &&
          currentSession.role === APPLICATION_USER_ROLES.REQUESTER && <Menu.Item
          key="manage-projects"
          style={{
            paddingLeft: 12
          }}
          disabled={!disableManageProject}
        >
          <Row gutter={[8, 0]}>
            <Col span={4}>

              <Icon
                type="profile"
                style={{
                  fontSize: 18,
                  color: "#353535",
                  verticalAlign: "middle"
                }}
              />
            </Col>
            <Col span={20}>Manage Projects</Col>
          </Row>
        </Menu.Item>}
        {currentSession &&
          currentSession.role == APPLICATION_USER_ROLES.REQUESTER && (
            <Menu.Divider />
          )}
        {(currentSession &&
          currentSession.role == APPLICATION_USER_ROLES.REQUESTER && (
            <Menu.Item
              key="/app/supplier-address-book"
              style={{
                paddingLeft: 12
              }}
              disabled={!disableSupplierAddressBook}
            >
              <Row gutter={[8, 0]}>
                <Col span={4}>
                  <Icon
                    type="book"
                    style={{
                      fontSize: 18,
                      color: "#353535",

                      verticalAlign: "middle"
                    }}
                  />
                </Col>
                <Col span={20}>Supplier Address Book</Col>
              </Row>
            </Menu.Item>
          )) ||
          null}
        {currentSession &&
          currentSession.role !== APPLICATION_USER_ROLES.AGENT && (
            <Menu.Divider />
          )}
        {currentSession &&
          currentSession.role === APPLICATION_USER_ROLES.AGENT &&
          userData &&
          userData.company &&
          userData.company.primaryContactId !== userData.id && (
            <Menu.Item
              key="/app/my-team"
              style={{
                paddingLeft: 12
              }}
            >
              <Row gutter={[8, 0]}>
                <Col span={4}>
                  <Icon
                    type="team"
                    style={{
                      fontSize: 18,
                      color: "#353535",
                      verticalAlign: "middle"
                    }}
                  />
                </Col>
                <Col span={20}>My Team</Col>
              </Row>
            </Menu.Item>
          )}
             
        {currentSession &&
          currentSession.role === APPLICATION_USER_ROLES.AGENT &&
          userData &&
          userData.company &&
          userData.company.primaryContactId !== userData.id && <Menu.Divider />}
        {userData &&
          userData.company &&
          userData.company.primaryContactId === userData.id && (
            <Menu.Item
              key="/app/company-admin"
              style={{
                paddingLeft: 12
              }}
            >
              <Row gutter={[8, 0]}>
                <Col span={4}>
                  <Icon
                    type="solution"
                    style={{
                      fontSize: 18,
                      color: "#353535",
                      verticalAlign: "middle"
                    }}
                  />
                </Col>
                <Col span={20}>Company Admin</Col>
              </Row>
            </Menu.Item>
          )}
        {userData &&
          userData.company &&
          userData.company.primaryContactId === userData.id && <Menu.Divider />}
        {currentSession &&
          currentSession.role === APPLICATION_USER_ROLES.AGENT && (
            <Menu.Item
              key="/app/my-address-book"
              style={{
                paddingLeft: 12
              }}
            >
              <Row gutter={[8, 0]}>
                <Col span={4}>
                  <Icon
                    type="book"
                    style={{
                      fontSize: 18,
                      color: "#353535",
                      verticalAlign: "middle"
                    }}
                  />
                </Col>
                <Col span={20}>Agency Address Book</Col>
              </Row>
            </Menu.Item>
          )}
        {currentSession &&
          currentSession.role === APPLICATION_USER_ROLES.AGENT && (
            <Menu.Divider />
          )}
        <Menu.Item
          key="logout"
          style={{
            background: " rgba(83, 105, 248, 0.2)",
            margin: 0,
            paddingLeft: 12
          }}
        >
          <Row gutter={[8, 0]}>
            <Col span={4}>
              <Icon
                type="poweroff"
                style={{
                  fontSize: 18,
                  color: "#353535",

                  verticalAlign: "middle"
                }}
              />
            </Col>
            <Col span={20}>Log Out </Col>
          </Row>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default ProfileDropdown;
