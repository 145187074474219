import React from "react";
import { Empty, Icon, Col, Row, Tooltip, Typography } from "antd";
import moment from "moment";
import { emptyImage } from "../../../../../Assets/assets";
import { CERTIFICIAL } from "../../../../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../../../../Utils/Constants/ApplicationMessages.constants";
const { Text } = Typography;
export const PolicyInsurerDetails = ({
  validationData = {},
  policyInsurerName,
  policyInsurerNAIC,
  policyInsurerEffectiveFrom,
  policyInsurerEffectiveTo,
  ambestCarrierRating,
  financialSizeCategoryAlpha,
  policyInsurerAmbestCarrierRating,
  isCarrierPresent
}) => {
  return (
    <div
      style={{
        padding: "3px 12px",
        marginBottom: 6,
        background: "rgba(83, 105, 248, 0.1)",
        borderTop: "1px solid rgba(83, 105, 248, 0.2)",
        borderBottom: "1px solid rgba(83, 105, 248, 0.2)"
      }}
    >
      {(
        <Row gutter={[8,8]}>
          <Col span={6}>
            <div className={validationData?.isPolicyInsurerNameError ? "extraction-data-error margin-0 module-sub-title":"margin-0 module-sub-title"}>Insurer Name</div>
            <h5 title={policyInsurerName} className="text-ellipsis">
              {policyInsurerName}
            </h5>
          </Col>
          <Col  span={4}>
            <div className={validationData?.isPolicyInsurerNAICError ? "extraction-data-error margin-0 module-sub-title":"margin-0 module-sub-title"}>Insurer NAIC</div>
            <h5> {policyInsurerNAIC}</h5>
          </Col>
          { ambestCarrierRating && (
            <Col span={4}>
              <div className="margin-0 module-sub-title">AM Best Rating</div>
              <h5 title={ambestCarrierRating} className="text-ellipsis">
                {ambestCarrierRating}
              </h5>
            </Col>
          )
          }
          {policyInsurerAmbestCarrierRating && (
            <Col span={5}>
              <div className="margin-0 module-sub-title">
                Current AM Best Rating
              </div>
              <h5
                title={policyInsurerAmbestCarrierRating}
                className="text-ellipsis"
              >
                {policyInsurerAmbestCarrierRating}
                {!!!isCarrierPresent && (
                  <span style={{ display: "inline-block", marginLeft: "5px" }}>
                    <Tooltip
                      title={
                        APPLICATION_MESSAGES.REQUESTOR_DASHBOARD.RATING_UNMATCH
                      }
                    >
                      <Text
                        style={{
                          fontSize: "13px",
                          color: "rgb(0, 148, 217)"
                        }}
                      >
                        {" "}
                        <Icon type="info-circle" />
                      </Text>
                    </Tooltip>
                  </span>
                )}
              </h5>
            </Col>
          )}
           {
            <Col span={3}>
              <div className="margin-0 module-sub-title">Financial Size</div>
              <h5 className="text-ellipsis">
                {financialSizeCategoryAlpha ? financialSizeCategoryAlpha : "NA"}
              </h5>
            </Col>
          }
          <Col span={6}>
            <div className={validationData?.isDateError ? "extraction-data-error margin-0 module-sub-title": "margin-0 module-sub-title"}>Effective Dates</div>
            <h5>
              {(policyInsurerEffectiveFrom && (
                <>
                  {(policyInsurerEffectiveFrom.length > 10 &&
                    moment(policyInsurerEffectiveFrom)
                      .utc()
                      .format(CERTIFICIAL.APP_FORMAT.DATE)) ||
                    moment(policyInsurerEffectiveFrom).format(
                      CERTIFICIAL.APP_FORMAT.DATE
                    )}
                </>
              )) ||
                null}
              {(policyInsurerEffectiveTo && (
                <span>
                  <Icon
                    type="arrows-alt"
                    rotate={45}
                    style={{ padding: "0 6px" }}
                  />
                  {(policyInsurerEffectiveTo.length > 10 &&
                    moment(policyInsurerEffectiveTo)
                      .utc()
                      .format(CERTIFICIAL.APP_FORMAT.DATE)) ||
                    moment(policyInsurerEffectiveTo).format(
                      CERTIFICIAL.APP_FORMAT.DATE
                    )}
                </span>
              )) ||
                null}
            </h5>
          </Col>
        </Row>
      ) || (
        <Empty
          image={emptyImage}
          imageStyle={{
            height: 36
          }}
          description={"No insurer details available"}
        />
      )}
    </div>
  );
};
