import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Modal, Typography, Button } from "antd";
import { scrollBarThumb } from "../../../Components/UI/ScrollBarThumb/ScrollBarThumb";
const { Text } = Typography;
const UserTearmsAndCondition = (props) => {
  return (
    <div>
      <Modal
        wrapClassName="userTearms"
        title="Terms and Conditions"
        visible={props.visible}
        destroyOnClose={true}
        onCancel={props.onCancel}
        footer={
          props.agreeFooter
            ? [
                <Button key="submit" type="primary" onClick={props.onOk}>
                  I Agree
                </Button>
              ]
            : null
        }
      >
        <div style={{ overflow: "hidden", height: 400 }}>
          <Scrollbars
            universal={true}
            renderThumbVertical={scrollBarThumb}
            renderThumbHorizontal={scrollBarThumb}
          >
            <div style={{ padding: 24 }}>
              <Text strong>End User License Agreement</Text> <br />
              <p>
                <Text strong> Last revised on September 23, 2020</Text>
              </p>
              <p>
                <Text>
                  This End User License Agreement (the "EULA") is a binding
                  legal agreement between you, as an individual or entity, and
                  Certificial Inc. By using this application ("Software"), you
                  agree to be bound by the terms of this EULA. If you do not
                  agree to the EULA, do not check the "I accept the terms" box
                  and do not use the Software.
                </Text>
              </p>
              <p>
                <Text strong>
                  You agree that installation or use of the Software signifies
                  that you have read, understood, and agree to be bound by the
                  EULA.
                </Text>
              </p>
              <p>
                <Text>
                  The Software is provided to you under this EULA solely for
                  your private or commercial use as an Insured. Use of the
                  Software for initiating a request for Insurance verification
                  (“Requestor”), or any other services (“Certificial Service”)
                  within an organization or the use of multiple profile accounts
                  requires a commercial license for the Software.
                </Text>
              </p>
              <p>
                <Text strong>1. Description of Software </Text>
                <br />
                <Text>
                  The Software is a cloud hosted software application that
                  enables you to access policy details, execute 3rd party
                  requests and interact with Insurance Agents.
                </Text>
              </p>
              <p>
                <Text strong> 2. License </Text>
                <br />
                <Text>
                  Certificial hereby grants you, subject to the terms and
                  conditions of this Agreement, a non-exclusive,
                  non-transferable license to use the Software for your own
                  Personal or Commercial use as an Insured to execute 3rd Party
                  requests, interact with Insurance Agents and maintain Policy
                  Holder details.
                </Text>
              </p>
              <p>
                <Text strong> 3. Title </Text>
                <br />
                <Text>
                  Title, ownership and all rights (including without limitation
                  intellectual property rights) in and to the Software shall
                  remain with Certificial. Except for those rights expressly
                  granted in this EULA, no other rights are granted, whether
                  express or implied.
                </Text>
              </p>
              <p>
                <Text strong>4. Restrictions </Text>
                <br />
                <Text>
                  You understand and agree that you shall only use the Software
                  in a manner that complies with any and all applicable laws in
                  the jurisdictions in which you use the Software. Your use
                  shall be in accordance with applicable restrictions concerning
                  privacy and intellectual property rights.
                </Text>
              </p>
              <p>
                <Text strong>You may not: </Text>
                <br />
                <ul>
                  <li>
                    <Text>Create derivative works based on the Software</Text>
                  </li>
                  <li>
                    <Text>
                      Use the software for any purpose other than as described
                      herein
                    </Text>
                  </li>
                  <li>
                    <Text>Copy or reproduce the Software;</Text>
                  </li>
                  <li>
                    <Text>
                      Sell, assign, license, disclose, distribute or otherwise
                      transfer or make available the Software or any copies of
                      the Software in any form to any third parties;
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Alter, translate, decompile, reverse assemble or reverse
                      engineer the Software, or attempt to do any of the
                      foregoing, except to the extent this prohibition is not
                      permitted under an applicable law; or
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Remove or alter any proprietary notices or marks on the
                      Software.
                    </Text>
                  </li>
                </ul>
              </p>
              <p>
                <Text strong>5. Personal Information and Privacy</Text>
                <br />
                <Text>
                  We may ask you to provide certain information about you during
                  the registration process. All personal information that you
                  provide to us will be governed by the{" "}
                  <a
                    href="https://www.certificial.com/privacy-policy"
                    target="_blank"
                    style={{ cursor: "pointer" }}
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  . By choosing to use the Software and/or the Certificial
                  Service, you indicate your understanding and acceptance of
                  Privacy Policy. You understand and agree that Certificial may
                  disclose information if required to do so by law or in the
                  good faith belief that such disclosure is reasonably necessary
                  to comply with legal process, enforce the terms of this EULA,
                  or protect the rights, property, or safety of Certificial, its
                  users, or the public.
                </Text>
              </p>
              <p>
                {" "}
                <Text strong>6. No Warranty</Text>
                <br />
                <Text>
                  CERTIFICIAL DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN
                  THE SOFTWARE WILL MEET ANY REQUIREMENTS OR NEEDS YOU MAY HAVE,
                  OR THAT THE SOFTWARE WILL OPERATE ERROR FREE, OR IN AN
                  UNINTERRUPTED MANNER, OR THAT THE SOFTWARE IS FULLY COMPATIBLE
                  WITH ANY PARTICULAR PLATFORM. UPON IDENTIFICATION OF AN
                  INTERRUPTION, CERTIFICIAL WILL RESOLVE THE ISSUE IN A TIMELY
                  MANNER. THE SOFTWARE IS OFFERED ON AN "AS-IS" BASIS AND NO
                  WARRANTY, EITHER EXPRESS OR IMPLIED, IS GIVEN. CERTIFICIAL
                  EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                  EXPRESS OR IMPLIED. SOME JURISDICTIONS DO NOT ALLOW THE WAIVER
                  OR EXCLUSION OF IMPLIED WARRANTIES SO THEY MAY NOT APPLY TO
                  YOU.
                </Text>
              </p>
              <p>
                <Text strong>7. Right to Terminate or Modify Software </Text>
                <br />
                <Text>
                  Certificial may modify the Software and this EULA with notice
                  to you either in email or by publishing notice on the Website,
                  including but not limited to charging fees for the Software,
                  or changing the functionality or appearance of the Software.
                  In the event Certificial modifies the Software or the EULA,
                  you may terminate this EULA and cease use of the Software.
                  Certificial may terminate your use of the Software, the EULA
                  or the Certificial Service at any time, with or without
                  notice.
                </Text>
              </p>
              <p>
                {" "}
                <Text strong>8. Indemnification</Text>
                <br />
                <Text>
                  By accepting the EULA, you agree to indemnify and otherwise
                  hold harmless Certificial Inc, its officers, employers,
                  agents, subsidiaries, affiliates and other partners from any
                  direct, indirect, incidental, special, consequential or
                  exemplary damages arising out of, relating to, or resulting
                  from your use of the Software or any other matter relating to
                  the Software.
                </Text>
              </p>
              <p>
                {" "}
                <Text strong>9. Limitation of Liability</Text>
                <br />
                <Text>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT CERTIFICIAL SHALL NOT
                  BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                  OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES
                  FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                  LOSSES (EVEN IF CERTIFICIAL HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES). IN NO EVENT WILL CERTIFICIAL’S
                  AGGREGATE LIABILITY TO YOU EXCEED THE AMOUNT OF LICENSING FEES
                  PAID BY YOU TO CERTIFICIAL. THESE LIMITATIONS AND EXCLUSIONS
                  WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF
                  ANY LIMITED REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE
                  LIMITATIONS OF DAMAGES AND/OR EXCLUSIONS OF LIABILITY FOR
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
                  ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </Text>
              </p>
              <p>
                {" "}
                <Text strong>10. General</Text>
                <br />
                <Text>
                  The EULA between you and Certificial, Inc will be governed by
                  and construed in accordance with the laws of the State of
                  Delaware without regard to conflict of laws principles. The
                  EULA constitutes the entire agreement between you and
                  Certificial regarding the Software. If any provision of this
                  EULA is held by a court of competent jurisdiction to be
                  contrary to law, such provision will be changed and
                  interpreted so as to best accomplish the objectives of the
                  original provision to the fullest extent allowed by law and
                  the remaining provisions of this EULA will remain in full
                  force and effect. You may not assign this EULA, and any
                  assignment of this EULA by you will be null and void.
                  Certificial logo and name are trademarks of Certificial Inc.
                  You agree not to display or use these trademarks in any manner
                  without Certificial’s prior, written permission. The section
                  titles and numbering of this EULA are displayed for
                  convenience and have no legal effect.
                </Text>
              </p>
            </div>{" "}
          </Scrollbars>
        </div>
      </Modal>
    </div>
  );
};

export default UserTearmsAndCondition;
