import { AGENTS_REQUEST_LIST_TYPE } from "./AgentPendingRequests.types";
import * as _ from "lodash";
import {
  CERTIFICIAL
} from "../../../Utils/Constants/Application.constants";

const AgentPendingRequestInitialState = {
  title: "My Pending Requests",
  isDataAvailable: false,
  isError: false,
  isLoading: false,
  sharePolicyRequests: {},
  respondPolicyRequests: [],
  agentRequestList: [],
  agentRespondRequestList: [],
  specialEndorsement: [],
  respondEndorsement: [],
  policyVerificationRequests: [],
  requestCompanyData: {},
  respondCompanyData: {},
  projectsForSelectedCompany: [],
  activeProject: null,
  drawerSize: CERTIFICIAL.PRIMARY_DRAWER_SIZE,
};

const base_AgentPendingRequestInitialState = {
  ...AgentPendingRequestInitialState
};

const AgentPendingRequestReducer = (
  state = AgentPendingRequestInitialState,
  { type, payload }
) => {
  switch (type) {
    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_REQUEST.DUMMY_REQUESTS: {
      return {
        ...state,
        agentRequestList: state.agentRequestList.concat(
          [...new Array(payload)].map(() => ({
            loading: true,
            name: {}
          }))
        )
      };
    }
    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_REQUEST.SUCCESS: {
      return {
        ...state,
        agentRequestList: payload
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_POLICY.SUCCESS: {
      let newRequestCompanyData = _.find(
        state.agentRequestList,
        (data) => data.id === (payload && payload.policyShareId)
      );

      let newAgentRequestData = _.map(state.agentRequestList, (obj) => {
        return {
          ...obj,
          ...{
            isActive: obj.id === (payload && payload.policyShareId)
          }
        };
      });

      let specialEndorsementData = [];
      payload &&
        payload.response &&
        payload.response.specialEndorsement.forEach((obj) => {
          let newEndorsement = {};
          newEndorsement.comments = obj.comments;
          newEndorsement.value = obj.comments;
          newEndorsement.id = obj.id;
          newEndorsement.specialEndorsement =
            (obj.specialEndorsementId &&
              obj.specialEndorsementId.specialEndorsement) ||
            obj.specialEndorsement;
          newEndorsement.specialEndorsementId =
            obj.specialEndorsementId && obj.specialEndorsementId;
          specialEndorsementData.push(newEndorsement);
        });

      return {
        ...state,
        policyList: [],
        sharePolicyRequests: payload.response,
        agentRequestList: newAgentRequestData,
        requestCompanyData: newRequestCompanyData,
        specialEndorsement: specialEndorsementData
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_REQUEST.ERROR: {
      return {
        ...state,
        agentCompanyList: []
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_POLICY.ERROR: {
      return { ...state, sharePolicyRequests: [] };
    }

    case AGENTS_REQUEST_LIST_TYPE.REQUEST_ACTION.ERROR: {
      return { ...state };
    }

    case AGENTS_REQUEST_LIST_TYPE.CLEAR.CLEAR_LIST_OF_COMPANIES: {
      return {
        ...base_AgentPendingRequestInitialState
      };
    }

    //Respond_Request

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_RESPOND.DUMMY_RESPOND: {
      return {
        ...state,
        agentRespondRequestList: state.agentRespondRequestList.concat(
          [...new Array(payload)].map(() => ({
            loading: true,
            name: {}
          }))
        )
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_RESPOND.SUCCESS: {
      return {
        ...state,
        agentRespondRequestList: payload
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_RESPOND.ERROR: {
      return {
        ...state,
        agentRespondRequestList: []
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_RESPOND_POLICY.SUCCESS: {
      let newRespondCompanyData = _.find(
        state.agentRespondRequestList,
        (data) => data.id === (payload && payload.policyShareId)
      );

      let newAgentRespondRequestData = _.map(
        state.agentRespondRequestList,
        (obj) => {
          return {
            ...obj,
            ...{
              isActive: obj.id === (payload && payload.policyShareId)
            }
          };
        }
      );

      let specialEndorsementData = [];
      payload &&
        payload.response &&
        payload.response.specialEndorsement.forEach((obj) => {
          let newEndorsement = {};
          newEndorsement.comments = obj.comments;
          newEndorsement.value = obj.comments;
          newEndorsement.id = obj.id;
          newEndorsement.specialEndorsement =
            obj.specialEndorsementId &&
            obj.specialEndorsementId.specialEndorsement;
          newEndorsement.specialEndorsementId =
            obj.specialEndorsementId && obj.specialEndorsementId.id;
          specialEndorsementData.push(newEndorsement);
        });

      return {
        ...state,
        respondPolicyRequests: payload.response,
        agentRespondRequestList: newAgentRespondRequestData,
        respondCompanyData: newRespondCompanyData,
        respondEndorsement: specialEndorsementData
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.LIST_OF_RESPOND_POLICY.ERROR: {
      return {
        ...state,
        respondPolicyRequests: []
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.RESPOND_ACTION.ERROR: {
      return { ...state };
    }

    case AGENTS_REQUEST_LIST_TYPE.GET_PROJECTS_LIST_BY_COMPANY.SUCCESS: {
      return {
        ...state,
        projectsForSelectedCompany: payload
      };
    }
    case AGENTS_REQUEST_LIST_TYPE.GET_PROJECTS_LIST_BY_COMPANY.ERROR: {
      return {
        ...state
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.SET_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: payload
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.CLEAR_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: null
      };
    }

    case AGENTS_REQUEST_LIST_TYPE.SET_DRAWER_SIZE: {
      return {
        ...state,
        drawerSize: payload
      };
    }

    default:
      return state;
  }
};

export default AgentPendingRequestReducer;
