
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Alert,
    Icon
} from "antd";
import React, { useState } from "react";
import CertificialAutoComplete from "../../../Components/UI/CertificialAutoComplete/CertificialAutoComplete";
import { withRouter } from "react-router";
import {
    CERTIFICIAL,
    APPLICATION_USER_ROLES
} from "../../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import {
    onSearchAgency,
    onSearchCompanies
} from "../../../Store/Modules/SearchCompanies/SearchCompanies.asyncRequest";
import {
    getExistingUserInformationViaEmail
} from "../../../Store/Modules/Application/Application.asyncRequest";
import "./SignUpVerifyUser.less";
import { uniqBy } from "lodash";
const signupVerifyUser = ({ form: { getFieldDecorator, validateFields, getFieldValue, setFields, getFieldError, isFieldTouched }, role, onNextClick, enableForm, paramName }) => {
    const ref = React.createRef();
    const [showCompanyListDropdown, setShowCompanyListDropdown] = useState(false);
    const [companyData, setCompanyData] = useState([]);
    const [emailDomainCompanies, setEmailDomainCompanies] = useState([]);
    const [emailVerifiedAndAvailable, setEmailVerifiedAndAvailable] = useState(null);
    const [validatedUserInfo, setValidatedUserInfo] = useState(null);
    const [searchCompanyStringError, setSearchCompanyStringError] = useState("")
    const [fetchingCompanyData, setFetchingCompanyData] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [excludedDomainFlg, setExcludedDomainFlag] = useState(false);
    const isAgentForm = role === APPLICATION_USER_ROLES.AGENT;

    const searchCompanyError =
        (searchCompanyStringError ||
            (isFieldTouched("searchCompany") && getFieldError("searchCompany")));


    const selectNewCompanyOption = [{
        id: "new",
        name: isAgentForm ? "Create a new agency profile":"Create a new company profile"
    }]

    const noRecordFoundOption = [{
        id: "no_record_found",
        name: "No Results Found..."
    },
    ...selectNewCompanyOption
    ]

    const handleOnNextClick = () => {
        validateFields((err, values) => {
            if (err) {
                return;
            }
            const id = isAgentForm && emailDomainCompanies?.length<1 && values.searchCompany !=='new' ? 'agencyId' : 'id'
            const company = companyData.find(company => company[id] === values.searchCompany);
            onNextClick(getFieldValue('email'), company, validatedUserInfo, company[id])
        })
    }

    const resetData = () => {
        setExcludedDomainFlag(false);
        setValidatedUserInfo(null);
        setCompanyData([]);
        setEmailDomainCompanies([])
        setShowCompanyListDropdown(false);
        setEmailVerifiedAndAvailable(null);
    }

    const handleExistingUserFound = (response) => {
        setValidatedUserInfo(response);
        setCompanyData([response?.company]);
        setEmailDomainCompanies([response?.company])
        setShowCompanyListDropdown(true);
        setTimeout(() => {
            setFields({
                "searchCompany": { value: response?.company?.id }
            })
        }, 0)
    }

    const handleNewUser = (response) => {
        let emailDomainCompanies = [];
        if(response?.excludedDomainFlg){
            setExcludedDomainFlag(true)
        }
        else{
            emailDomainCompanies = response?.emailDomainComanies || [];
        }
        setEmailDomainCompanies([...(emailDomainCompanies || [])])
        let companyData = emailDomainCompanies?.length ? [...emailDomainCompanies, ...selectNewCompanyOption] : noRecordFoundOption;
        setCompanyData(companyData);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields(['email'], async(err) => {
            if (err) {
                return;
            }
            setSubmitLoading(true);
            resetData();
            try {
                const response = await getExistingUserInformationViaEmail(getFieldValue('email'), role);
                response?.company ? handleExistingUserFound(response) : handleNewUser(response);
                setShowCompanyListDropdown(true);
            }
            catch (err) {
                setEmailVerifiedAndAvailable(err.message)
            } finally {
                setSubmitLoading(false);
            }
        })
    }

    const onCompanySelection = (value) => {
        setSearchCompanyStringError("");
        if(!value){
            setCompanyData(noRecordFoundOption)
        }
    }

    const handleEmailChange = () => {
        if (!showCompanyListDropdown) {
            return;
        }
        setFields({
            "searchCompany": { value: null }
        })
        resetData();
    }

    const handleSearchCompany = (companyData) => {
        if (companyData.length === 0) {
            setCompanyData(noRecordFoundOption)
            return
        }
        setCompanyData([...companyData, ...selectNewCompanyOption])
    }

    const handleGlobalCompanySearch = async(searchCompanyString, isEmailDomainPresent = false) => {
        setSearchCompanyStringError("");
        const searchMethod = isAgentForm ? onSearchAgency : onSearchCompanies;
        setFetchingCompanyData(true)
        const response = await searchMethod(searchCompanyString).catch(err => { })
        setFetchingCompanyData(false)
        const companyList = isAgentForm ? response : response?.companies;
        if(isEmailDomainPresent === true) {
            return companyList;
        }
        handleSearchCompany(companyList || []);
    }

    const handleEmailDomainCompanySearch = async (searchCompanyString) => {
        let companyList = await handleGlobalCompanySearch(searchCompanyString, true) || [];
        
        companyList = companyList.map((item) => {
            return { ...item, id: item.agencyId, name: item.companyName }
        });
        
        let combined = [...companyList, ...emailDomainCompanies];
        const filteredCompanies = (uniqBy(combined, 'id') || []).filter(company => ((company?.name|| "").toLowerCase()).includes((searchCompanyString+'').toLowerCase()));
        
        
        handleSearchCompany(filteredCompanies);
    }

    const searchOptions = async (searchCompanyString) => {
        //Filter out from email domain companies
        if (emailDomainCompanies?.length) {
            handleEmailDomainCompanySearch(searchCompanyString);
            return;
        }

        if (searchCompanyString.trim()?.length === 0) {
            setSearchCompanyStringError("");
            setCompanyData(noRecordFoundOption)
            return;
        }

        if (searchCompanyString.length < 3) {
            setCompanyData([])
            setSearchCompanyStringError("Type at least the first three letters of your " +
                (isAgentForm ? "Agency name" : " Company name"));
            return;
        }

        //Filter out from global company list
        handleGlobalCompanySearch(searchCompanyString)
    };

    const getEmailDomainMsg = ()=>{
        let emailDomainCompaniesMsg;
        if(excludedDomainFlg){
            emailDomainCompaniesMsg = messages.PUBLIC_DOMAIN_MSG;
        }
        else{
            emailDomainCompaniesMsg = validatedUserInfo ? messages.ALREADY_MAPPED_USER : emailDomainCompanies?.length ? messages.RECORD_FOUND : messages.NO_RECORD_FOUND;
        }
        return emailDomainCompaniesMsg;
    }
    
    
    const messages = !isAgentForm ? APPLICATION_MESSAGES.SIGN_IN.CLIENT : APPLICATION_MESSAGES.SIGN_IN.AGENT;
    const welcomeMessage = messages.WELCOME;
    const linkMessage = messages.LINK;
    const emailDomainCompaniesMsg = getEmailDomainMsg();
    const title = messages.TITLE;
    const autoCompletePlaceholder = messages.AUTOCOMPLETE_PLACEHOLDER;
    const emailInputLabel = messages.EMAIL_INPUT_LABEL;
    const formStyle = { display: enableForm ? 'none' : 'block'}
    const searchRecordPlaceholder = "E-mail"

    return (<div style={formStyle} id="verify-user-form">
        <Row className="p-12 welcome-message">
            <h2>{welcomeMessage}</h2>
            <div dangerouslySetInnerHTML={{ __html: linkMessage }}></div>
        </Row>
        <h3 className="p-12 title">{title}</h3>
        <div className="p-12">
            <Form onSubmit={(e) => handleSubmit(e)}>
                <div>{emailInputLabel}</div>
                <Row>
                    <Col span={16}>
                        <Form.Item>
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        transform: (value) =>
                                            (value && value.trim()) || null
                                    },
                                    {
                                        pattern: CERTIFICIAL.REGEX_PATTERNS.EMAIL,
                                        whitespace: true,
                                        message: "Please enter valid email address."
                                    },
                                    {
                                        required: true,
                                        message: "Please enter your email address."
                                    },
                                    {
                                        transform: (value) => (value && value.trim()) || null
                                    }
                                ],
                            })(<Input
                                prefix={
                                    <Icon
                                      type="mail"
                                      style={{
                                        color: "rgba(0,0,0,.25)"
                                      }}
                                    />
                                  }
                                   type="email" style={{ width: "30vw" }} 
                                   onChange={() => { handleEmailChange() }}
                                   placeholder={searchRecordPlaceholder} 
                                   />)
                            }
                        </Form.Item>
                        {emailVerifiedAndAvailable ? (
                            <Alert
                                style={{ width: "30vw" }}
                                message={emailVerifiedAndAvailable}
                                type="error"
                                showIcon
                                className="margin-top-sm"
                            />
                        ) : null}
                    </Col>
                    <Col className="submit-buttom" span="6">
                        <Button loading={submitLoading} onClick={(e) => handleSubmit(e)} type="primary">
                            Submit
                        </Button>
                    </Col>
                </Row>
                {showCompanyListDropdown && <Row className="auto-complete">
                    <div>{emailDomainCompaniesMsg}</div>
                    <Col span={24}>
                        <Form.Item
                            validateStatus={searchCompanyError ? "error" : ""}
                            help={searchCompanyError || ""}
                        >
                            {getFieldDecorator("searchCompany", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please select company"
                                    }
                                ]
                            })(<CertificialAutoComplete
                                iscreateRequest="SignUp"
                                requestedRegion={true}
                                defaultOpen={!validatedUserInfo}
                                ref={ref}
                                containerId="verify-user-form"
                                source={companyData}
                                fetchText={"Searching"}
                                placeholder={autoCompletePlaceholder}
                                disabled={!!validatedUserInfo}
                                onChange={onCompanySelection}
                                searchOptions={searchOptions}
                                fetching={fetchingCompanyData}
                                showAddress={true} //TRUE: for Agent signup & user signup showing address next to agency name
                            />)}
                        </Form.Item>
                    </Col>
                </Row>}
            </Form>
        </div>
        <Row className="next-button">
            <Col offset={20} className="p-30">
                <Button
                    onClick={() => handleOnNextClick()}
                    type="primary"
                    htmlType="submit"
                    disabled={!getFieldValue('searchCompany')}
                >
                    Next
                </Button>
            </Col>
        </Row>
    </div>)
}

export default withRouter(Form.create({ name: "signupVerifyUser" })(signupVerifyUser));;