export const MY_PENDING_USERS_ACTION_TYPES = {
    CLEAR_MY_PENDING_USERS: "CLEAR_MY_PENDING_USERS",
    GET_MY_PENDING_USERS: {
        SUCCESS: "GET_MY_PENDING_USERS_SUCCESS",
        ERROR: "GET_MY_PENDING_USERS_ERROR"
    },
    RESPOND_MY_PENDING_USERS: {
        SUCCESS: "RESPOND_MY_PENDING_USERS_SUCCESS",
        ERROR: "RESPOND_MY_PENDING_USERS_ERROR"
    },
    INVITE_USER: {
        SUCCESS: "INVITE_USER_EMAIL_SUCCESS",
        ERROR: "INVITE_USER_EMAIL_ERROR"
    }
};
