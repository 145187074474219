import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Icon,
  Input,
  List,
  message,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Table,
  typography
} from "antd";
import _ from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import { tableIcon } from "../../../Assets/assets";
import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import { generateLimitColumns } from "../../../Utils/utility/app.utility";
import CertInfoTooltip from "../../UI/CertTooltip/CertInfoTooltip";
const { Text } = typography;
const { RangePicker } = DatePicker;

const popOverContent = (complianceData) => (
  <List
    bordered
    dataSource={[{}]}
    size="small"
    renderItem={(item) => (
      <List.Item>
        <div>
          <Text>Created By : John Smith</Text>
          <br />
          <Text>Company : Berkadia</Text>
          <br />
          <Text>Requested Date : 2020-02-11 12:49:22</Text>
          <br />
          <Text>$0.00 (Required Limit: $500000.00)</Text>
        </div>
      </List.Item>
    )}
  />
);

const ExceptionAllowContainer = ({
  settime,
  loading,
  isDisabledDueToArchive,
}) => (
  <div>
    <Card
      title={
        <>
          <Icon type="question-circle-o" style={{ color: "red" }} />
          &nbsp;&nbsp; Allow this Exception
        </>
      }
      bordered={false}
      loading={loading}
      style={{ width: 300, margin: "-12px -16px", marginLeft: "-38px" }}
    >
      <Text>Please select the time range to allow this exception.</Text>
      <br />
      <br />
      <RangePicker
        disabled={isDisabledDueToArchive}
        size="small"
        onChange={(date, dateString) => settime(dateString)}
      />
      <br />
      <br />
      <Text type="warning">Are you sure to proceed with this exception?</Text>
    </Card>
  </div>
);

const LimitTable = ({
  isLimitError,
  readOnlyMode,
  isDisabledDueToArchive,
  tableColumns,
  tableData,
  setTimeDuration,
  TimeDuration,
  currentRole,
  loading,
  title,
  isEditAgentForm,
  sortByFields,
  isRequestFromAgent,
  isLimitComplianceIssue
}) => {
  const columns = _.map(
    tableColumns,
    (col) =>
      (col.useLocalMethod && {
        ...col,
        ...{
          render:
            col.useLocalMethod === "getLimitForLimitType"
              ? (limit, type) => getLimitForLimitType(limit, type, currentRole)
              : null
        }
      }) ||
      (col.title === "Limit Type" && {
        ...col,
        ...{
          width: "55%",
          render: (value, row, index) => {
            const obj = {
              children: value,
              props: {}
            };
            if (row.name === "Bodily Injury And Property Damage Liability") {
              // obj.props.rowSpan = 2;
              obj.props.colSpan = 3;
            }
            if (row.name === "General Aggregate") {
              // obj.props.rowSpan = 2;
              obj.children = <span className="noborder-bottom">{value}</span>;
            }
            if (
              row.name === "Personal Injury Liability" ||
              row.name === "Personal And Advertising Injury Liability"
            ) {
              obj.children = (
                <div className="limit-table-radio-wrapper">
                  <Radio.Group
                    disabled={isDisabledDueToArchive}
                    value={
                      row.uiConfig
                        ? row.uiConfig
                        : row.defaultChecked
                        ? parseInt(value) === 0 ? '$': value
                        : ""
                    }
                    onChange={(info) => {
                      const mod = {
                        row,
                        ...info
                      };
                      return this.handleLimitTypeChanges(mod);
                    }}
                  >
                    <Radio value={value}>{value}</Radio>
                    <Radio value={"Personal And Advertising Injury Liability"}>
                      Personal And Advertising Injury Liability
                    </Radio>
                  </Radio.Group>
                </div>
              );
            }
            if (
              (row.limitTableLocation === "createRequest" ||
                row.limitTableLocation === "viewTemplate" ||
                row.limitTableLocation === "pendingRequest" ||
                row.limitTableLocation === "viewRequest" ||
                row.limitTableLocation === "requestorDashboard") &&
              (row.name === "Personal Injury Liability" ||
                row.name === "Personal And Advertising Injury Liability")
            ) {
              const options = [
                { label: value, value },
                {
                  label: "Personal And Advertising Injury Liability",
                  value: "Personal And Advertising Injury Liability"
                }
              ];
              obj.children = (
                <div>
                  <Checkbox.Group
                    disabled={isDisabledDueToArchive || readOnlyMode}
                    // options={options}
                    defaultValue={
                      typeof row.uiConfig === "undefined"
                        ? [value]
                        : row.uiConfig
                        ? row.uiConfig.split(",")
                        : [row.uiConfig]
                    }
                    onChange={(info) => {
                      const mod = {
                        row,
                        selection: info
                      };
                      return this.handleLimitTypeChanges(mod);
                    }}
                  >
                    <Checkbox
                      value={options[0].value}
                      className={
                        row.missingLimits &&
                        row.missingLimits.indexOf(options[0].value) > -1
                          ? "missing-limit-issue"
                          : "missing-limit"
                      }
                    >
                      {options[0].value}
                    </Checkbox>
                    <Checkbox
                      value={options[1].value}
                      style={{ margin: 0 }}
                      className={
                        row.missingLimits &&
                        row.missingLimits.indexOf(options[1].value) > -1
                          ? "missing-limit-issue"
                          : "missing-limit"
                      }
                    >
                      {options[1].value}
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              );
            }
            if (
              row.countryId === 2 &&
              (row.name === "P.O.E.D" ||
                row.name === "Building" ||
                row.name === "Equipment" ||
                row.name === "Stock" ||
                row.name === "C.O.E.D")
            ) {
              const options = [
                { label: value, value },
                { label: "RC", value: "RC" },
                { label: "ACV", value: "ACV" }
              ];
              obj.children = (
                <div>
                  <Checkbox.Group
                    disabled={isDisabledDueToArchive || readOnlyMode}
                    // options={options}
                    defaultValue={
                      typeof row.uiConfig === "undefined"
                        ? [value]
                        : row.uiConfig
                        ? row.uiConfig.split(",")
                        : [row.uiConfig]
                    }
                    onChange={(info) => {
                      const mod = {
                        row,
                        selection: info
                      };
                      return this.handleLimitTypeChanges(mod);
                    }}
                  >
                    <Checkbox
                      value={options[0].value}
                      className={
                        row.missingLimits &&
                        row.missingLimits.indexOf(options[0].value) > -1
                          ? "missing-limit-issue"
                          : "missing-limit"
                      }
                    >
                      {options[0].value}
                    </Checkbox>
                    <Checkbox
                      value={options[1].value}
                      style={{ margin: 0 }}
                      className={
                        row.missingLimits &&
                        row.missingLimits.indexOf(options[1].value) > -1
                          ? "missing-limit-issue"
                          : "missing-limit"
                      }
                    >
                      {options[1].value}
                    </Checkbox>
                    <Checkbox
                      value={options[2].value}
                      style={{ margin: 0 }}
                      className={
                        row.missingLimits &&
                        row.missingLimits.indexOf(options[2].value) > -1
                          ? "missing-limit-issue"
                          : "missing-limit"
                      }
                    >
                      {options[2].value}
                    </Checkbox>
                  </Checkbox.Group>
                </div>
              );
            }

            if (
              row.name === "Other1" ||
              row.name === "Other2" ||
              (row.name === "Cargo" && row.countryId === 2)
            ) {
              obj.children = (
                <div>
                  {row.name === "Cargo" && row.name}
                  <Input
                    disabled={isDisabledDueToArchive || readOnlyMode}
                    value={parseInt(row.limit) === 0 ? '$' : row.limit}
                    onChange={(info) => {
                      const mod = {
                        row,
                        ...info
                      };
                      return this.handleLimitTypeChanges(mod);
                    }}
                  />
                </div>
              );
            }
            if (_CANADAFILTER(row)) {
              obj.props.rowSpan = 0;
            }
            return obj;
          }
        }
      }) ||
      (col.title === "Limits" && {
        ...col,
        ...(isLimitError && {className:"extraction-data-error"}),
        ...{
          render: (value, row, index) => {
            const obj = {
              children: (
                <NumberFormat
                  allowEmptyFormatting={true}
                  value={parseInt(value) === 0 ? '$' : value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={row.currencySymbol ? row.currencySymbol : "$"}
                />
              ),
              props: {}
            };
            if (
              row.limitValue &&
              typeof row.limitValue === "object" &&
              row.limitValue.length &&
              row.limitTableLocation === "pendingRequest"
            ) {
              if (row.limitValue.length === 1) {
                obj.children = row.limitValue[0];
              } else {
                const newVal = [...row.limitValue].splice(2);
                obj.children = (
                  <Popover
                    content={
                      <div>
                        <p>
                          <span>Policy Number : {row.limitValue[0]}</span>
                          <span></span>
                        </p>
                        <p>
                          <span>Last Modified : {row.limitValue[1]}</span>
                          <span></span>
                        </p>
                      </div>
                    }
                    destroyTooltipOnHide={true}
                  >
                    <div className="limit-compliance-wrapper">
                      {newVal.map((o, key) => (
                        <span
                          key={key}
                          style={key === 0 ? { display: "block" } : {}}
                        >
                          {o}
                        </span>
                      ))}
                    </div>
                  </Popover>
                );
              }
            } else if (
              row.limitValue &&
              typeof row.limitValue === "object" &&
              row.limitValue.length
            ) {
              if (row.limitValue.length === 1) {
                obj.children = row.limitValue[0];
              } else {
                const newVal = [...row.limitValue].splice(3);
                obj.children = (
                  <Popover
                    content={
                      <div>
                        <div>Policy Number: {row.limitValue[0]} </div>
                        <div>Company: {row.limitValue[1]} </div>
                        <div>Requested Date: {row.limitValue[2]}</div>
                      </div>
                    }
                  >
                    <div className="limit-compliance-wrapper">
                      {newVal &&
                        newVal.map((o, key) => (
                          <span
                            key={key}
                            style={key === 0 ? { display: "block" } : {}}
                          >
                            {o}
                          </span>
                        ))}
                    </div>
                  </Popover>
                );
              }
            }
            if (row.name === "Bodily Injury And Property Damage Liability") {
              obj.props.colSpan = 0;
            }
            if (_CANADAFILTER(row)) {
              obj.props.rowSpan = 0;
            }

            return obj;
          }
        }
      }) ||
      (col.title === "Deductible" && {
        ...col,
        ...{
          render: (value, row, index) => {
            const obj = {
              children: (
                <NumberFormat
                  value={parseInt(value) === 0 ? '$' : value}
                  allowEmptyFormatting={true}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={row.currencySymbol ? row.currencySymbol : "$"}
                />
              ),
              props: {}
            };
            if (
              row.deductibleValue &&
              typeof row.deductibleValue === "object" &&
              row.deductibleValue.length &&
              row.limitTableLocation === "pendingRequest"
            ) {
              if (row.deductibleValue.length === 1) {
                obj.children = row.deductibleValue[0];
              } else {
                const newVal = [...row.deductibleValue].splice(2);
                obj.children = (
                  <Popover
                    content={
                      <div>
                        <p>
                          <span>Policy Number : {row.deductibleValue[0]}</span>
                          <span></span>
                        </p>
                        <p>
                          <span>Last Modified : {row.deductibleValue[1]}</span>
                          <span></span>
                        </p>
                      </div>
                    }
                    destroyTooltipOnHide={true}
                  >
                    <div className="limit-compliance-wrapper">
                      {newVal.map((o, key) => (
                        <span
                          key={key}
                          style={key === 0 ? { display: "block" } : {}}
                        >
                          {o}
                        </span>
                      ))}
                    </div>
                  </Popover>
                );
              }
            } else if (
              row.deductibleValue &&
              typeof row.deductibleValue === "object" &&
              row.deductibleValue.length
            ) {
              if (row.deductibleValue.length === 1) {
                obj.children = row.deductibleValue[0];
              } else {
                const newVal = [...row.deductibleValue].splice(3);
                obj.children = (
                  <Popover
                    content={
                      <div>
                        <div>Policy Number: {row.deductibleValue[0]} </div>
                        <div>Company: {row.deductibleValue[1]} </div>
                        <div>Requested Date: {row.deductibleValue[2]}</div>
                      </div>
                    }
                  >
                    <div className="limit-compliance-wrapper">
                      {newVal &&
                        newVal.map((o, key) => (
                          <span
                            key={key}
                            style={key === 0 ? { display: "block" } : {}}
                          >
                            {o}
                          </span>
                        ))}
                    </div>
                  </Popover>
                );
              }
            }
            if (row.name === "Bodily Injury And Property Damage Liability") {
              obj.props.colSpan = 0;
            }
            if (_CANADAFILTER(row)) {
              obj.props.rowSpan = 0;
            }

            return obj;
          }
        }
      }) ||
      col
  );

  const getLimitForLimitType = (limit, type, currentRole) =>
    type.isComplianced && type.invested && type.invested < type.limit ? (
      <Row gutter={[8, 0]}>
        <Popover
          placement="leftTop"
          title={type.policyLimitType}
          destroyTooltipOnHide={true}
          content={popOverContent(limit)}
        >
          <Col span={18}>{limit} </Col>
        </Popover>
        <Popconfirm
          disabled={isDisabledDueToArchive}
          destroyTooltipOnHide={true}
          title={
            <ExceptionAllowContainer
              settime={setTimeDuration}
              isDisabledDueToArchive={isDisabledDueToArchive}
            />
          }
          placement="left"
          okText={"Confirm"}
          onCancel={() => message.warning("Cancelled the exception!")}
          onConfirm={() =>
            message.success(
              "Exception is handled and proceding for template creation!" +
                TimeDuration.join(", ")
            )
          }
          icon={null}
        >
          {(currentRole === APPLICATION_USER_ROLES.REQUESTER && (
            <Col span={6}>
              <Button size="small">Allow</Button>
            </Col>
          )) ||
            null}
        </Popconfirm>
      </Row>
    ) : (
      limit
    );

  const _CANADAFILTER = (row) => {
    return (
      ((row.limitTableLocation === "createRequest" ||
        row.limitTableLocation === "viewTemplate" ||
        row.limitTableLocation === "pendingRequest" ||
        row.limitTableLocation === "viewRequest") &&
        (row.name === "Other1" ||
          row.name === "Other2" ||
          (row.name === "Cargo" && row.countryId === 2))) ||
      row.name === "Personal And Advertising Injury Liability" ||
      row.name === "Other Sub 1" ||
      (row.name && row.name.indexOf("RC Checkbox") > -1) ||
      (row.name && row.name.indexOf("ACV Checkbox") > -1)
    );
  };

  const getTableData = (tableData, countryId) => {
    let data = [];
    if (tableData && tableData.length && countryId === 2) {
      data = _.filter(tableData, (row) => !_CANADAFILTER(row));
    } else {
      data = tableData;
    }
    return data;
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: true, // Column configuration not to be checked
      name: record.name,
      defaultChecked: record.isSelectedForCoi === true
    })
  };

  const tableDataToShow =
    (tableData &&
      !!tableData.length &&
      getTableData(tableData, _.head(tableData).countryId)) ||
    [];

    const additionalColumns = generateLimitColumns(
      columns,
      tableData && tableData[0] && tableData[0].countryId
    ).map(col => {
      return {
        ...col,
        defaultSortOrder: "descend",
        sorter: (a, b) => a.name > b.name ? -1 : a.name < b.name ? 1 : 0,
      }
    })

  return (
    <>
      <Card
        size="small"
        bordered={true}
        className="widget-card"
        loading={loading}
        bodyStyle={{
          padding: 0,
          borderTop: "1px solid rgba(83, 105, 248, 0.2)"
        }}
        title={
          <div style={{ display: "inline-flex" }}>
            <Avatar
              src={tableIcon}
              size="default"
              shape="square"
              className="margin-right-sm"
            />
            <div>
              <h6 className="margin-0">Limit Types</h6>
              <h5 className="margin-0">
                {title}
                {(isRequestFromAgent && isLimitComplianceIssue && (
                  <CertInfoTooltip
                    placement="topLeft"
                    title={
                      APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO.MISSING_LIMITS
                    }
                    className="compliance-issue-info"
                  />
                )) ||
                  null}
              </h5>
            </div>
          </div>
        }
        extra={null}
      >
        <Table
          rowKey={(rec) => rec.id || rec.limitTypeId}
          columns={generateLimitColumns(
            columns,
            tableData && tableData[0] && tableData[0].countryId
          )}
          dataSource={
            tableDataToShow &&
            tableDataToShow.filter(
              (item) => !(item.isExternal || item.is_external)
            )
          }
          className={"limit-table"}
          size="small"
          rowClassName={(item, idx) => (item.isCompliance ? "compliance" : "")}
          pagination={{
            total:
              tableDataToShow &&
              tableDataToShow.filter(
                (item) => !(item.isExternal || item.is_external)
              ).length,
            pageSize: 10,
            hideOnSinglePage: true
          }}
          loading={false}
          title={null}
          rowSelection={isEditAgentForm ? rowSelection : null}
        />
      </Card>
      {tableDataToShow &&
        tableDataToShow.filter((item) => item.isExternal || item.is_external)
          .length > 0 && (
          <Card
            size="small"
            bordered={true}
            className="widget-card"
            loading={loading}
            bodyStyle={{
              padding: 0,
              borderTop: "1px solid rgba(83, 105, 248, 0.2)"
            }}
            title={
              <div style={{ display: "inline-flex" }}>
                <Avatar
                  src={tableIcon}
                  size="default"
                  shape="square"
                  className="margin-right-sm"
                />
                <div>
                  <h6 className="margin-0">Additional Limit Types</h6>
                  <h5 className="margin-0">
                    {title}
                    {(isRequestFromAgent && isLimitComplianceIssue && (
                      <CertInfoTooltip
                        placement="topLeft"
                        title={
                          APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO
                            .MISSING_LIMITS
                        }
                        className="compliance-issue-info"
                      />
                    )) ||
                      null}
                  </h5>
                </div>
              </div>
            }
            extra={null}
          >
            <Table
              rowKey={(rec) => rec.id || rec.limitTypeId}
              columns={additionalColumns}
              dataSource={
                tableDataToShow &&
                tableDataToShow.filter(
                  (item) => item.isExternal || item.is_external
                )
              }
              className={"limit-table"}
              size="small"
              rowClassName={(item, idx) =>
                item.isCompliance ? "compliance" : ""
              }
              pagination={{
                total:
                  tableDataToShow &&
                  tableDataToShow.filter(
                    (item) => item.isExternal || item.is_external
                  ).length,
                pageSize: 10,
                hideOnSinglePage: true
              }}
              rowSelection={isEditAgentForm ? rowSelection : null}
            />
          </Card>
        )}
    </>
  );
};

export default LimitTable;
