import { SHARED_POLICIES_ACTION_TYPES } from "./InsurerSharedPolicies.types";

export const getSelectedCompanyByIdSuccess = (obj) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER.SUCCESS,
  payload: obj
});
export const getSelectedCompanyByIdError = (obj) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER.ERROR,
  payload: obj
});

export const getSelectedCompanyByIdSuccessListView = (obj, isSearch) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_LIST_VIEW.SUCCESS,
  payload: { payload: obj, isSearch: isSearch }
});
export const getSelectedCompanyByIdErrorListView = (obj) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_LIST_VIEW.ERROR,
  payload: obj
});

export const saveTotalPaggonation = (count) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SAVE_TOTAL_PAGINATION,
  payload: count
});
export const insurerDummySharedPolicies = () => ({
  type: SHARED_POLICIES_ACTION_TYPES.DUMMY_SHARED_COMPANIES,
  payload: 2
});

export const insurerSharedPoliciesSuccess = (payload, isSearch) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_SHARED_COMPANIES_LIST_VIEW.SUCCESS,
  payload: { payload: payload, isSearch: isSearch }
});

export const insurerSharedPoliciesError = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_SHARED_COMPANIES_LIST_VIEW.ERROR,
  payload
});

export const setCompanySelection = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SET_ACTIVE_SHARED_COMPANIES,
  payload
});
export const clearSharedPolicies = () => ({
  type: SHARED_POLICIES_ACTION_TYPES.CLEAR_SHARED_COMPANIES
});
export const clearSharedPoliciesListView = () => ({
  type: SHARED_POLICIES_ACTION_TYPES.CLEAR_SHARED_COMPANIES_LIST_VIEW
});
export const clearSharedPoliciesGraphView = () => ({
  type: SHARED_POLICIES_ACTION_TYPES.CLEAR_SHARED_COMPANIES_GRAPH_VIEW
});

export const getSharedPolicyGraphSuccess = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_SHARED_POLICY_GRAPH_VIEW.SUCCESS,
  payload: payload
});
export const getSharedPolicyGraphError = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_SHARED_POLICY_GRAPH_VIEW.ERROR,
  payload: payload
});
export const setSelectedCompanyDrawerPolicies = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER_POLICIES.SUCCESS,
  payload: payload
});
export const deleteSharedPolicySuccess = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER_POLICY_DELETE
    .SUCCESS,
  payload: payload
});
export const deleteSharedPolicyError = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SELECTED_COMPANY_DRAWER_POLICY_DELETE
    .ERROR,
  payload: payload
});
/**
 * Sharable Policies
 */

export const setAgentClientSharablePolicies = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_CLIENT_POLICIES_TO_SHARE_FOR_AGENT.SUCCESS,
  payload
});

export const insurerSharablePoliciesSuccess = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.SUCCESS,
  payload
});



export const insurerSharablePoliciesError = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.ERROR,
  payload
});

export const clearSharablePolicies = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.CLEAR_MY_POLICIES_TO_SHARE
});

export const insurerSharedProjectsSuccess = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_SHARED_PROJECTS_GRAPH_VIEW.SUCCESS,
  payload
});
export const insurerSharedProjectsError = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.GET_SHARED_PROJECTS_GRAPH_VIEW.ERROR,
  payload
});

export const setInsurerSharedProject = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SET_ACTIVE_PROJECT,
  payload
});
export const clearInsurerSharedProject = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.CLEAR_ACTIVE_PROJECT,
  payload
});

export const saveCertUploadReRespondStatus = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SAVE_CERT_UPLOAD_STATUS_RE_RESPOND,
  payload: payload
});

export const insurerReSharableDataSuccess = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SET_INSURER_RESHARE_DATA.SUCCESS,
  payload
});

export const insurerReSharableDataError = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.SET_INSURER_RESHARE_DATA.ERROR,
  payload
});

export const insurerReSharableDataReset = () => ({
  type: SHARED_POLICIES_ACTION_TYPES.SET_INSURER_RESHARE_DATA.RESET
});
export const setRefreshCompanyId = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.SET,
  payload
});

export const clearRefreshCompanyId = (payload) => ({
  type: SHARED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.CLEAR
});
