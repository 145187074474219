import { ACTION_TYPES } from "./Application.types";
import { callGetSupplierCheckAPI, login_action, doesInsuredHaveOpenRequests } from "./Application.asyncRequest";
import { appHistory } from "../../../Utils/Helpers/History";
import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
import { resetPolicies } from "../MyPolicies/Mypolicies.actions";
import { clearSharedPolicies } from "../InsurerSharedPolicies/InsurerSharedPolicies.action";
import { resetPendingUsersRequest } from "../MyPendingUsers/MyPendingUsers.action";
import { resetProfile } from "../MyProfile/MyProfile.action";
import { clearPendingInsuredRequests } from "../pendingInsuredRequests/pendingInsuredRequests.action";
import { clearTrackedPolicies } from "../RequestorDashboard/RequestorDashboard.action";
import { resetTemplates } from "../Template/Template.action";
import { clearRequestOfVerifications } from "../RequestorRequestPolicies/RequestorRequestPolicies.action";

/**
 * LOADING
 */

export const enableListLoading = () => ({
  type: ACTION_TYPES.LIST_LOADING
});

export const disableListLoading = () => ({
  type: ACTION_TYPES.CLEAR_LIST_LOADING
});

export const enableCompanyDetailsLoading = () => ({
  type: ACTION_TYPES.SHARED_COMPANY_DETAILS_LOADING
});

export const disableCompanyDetailsLoading = () => ({
  type: ACTION_TYPES.CLEAR_SHARED_COMPANY_DETAILS_LOADING
});

export const enableListDetailsloading = () => ({
  type: ACTION_TYPES.LIST_DETAILS_LOADING
});

export const disableListDetailsloading = () => ({
  type: ACTION_TYPES.CLEAR_LIST_DETAILS_LOADING
});

export const enableCustomLabelLoading = (payload) => ({
  type: ACTION_TYPES.CUSTOM_LABEL_LOADING,
  payload
});

export const disableCustomLabelLoading = (payload) => ({
  type: ACTION_TYPES.CLEAR_CUSTOM_LABEL_LOADING,
  payload
});

/**
 * APPLICATION MANIPULATION
 */

export const changeUserRole = (requstedRole, isToggled, id) => {
  return async(dispatch) => {
    dispatch({
      type: ACTION_TYPES.APPLICATION.CHANGE_USER_ROLE,
      payload: requstedRole
    });
    if (isToggled) {
      if (requstedRole === APPLICATION_USER_ROLES.INSURER) {
        appHistory.push("/app/shared-policies");
        if(await doesInsuredHaveOpenRequests(id)){
          appHistory.push("/app/pending-requests");
        }
      } else if (requstedRole === APPLICATION_USER_ROLES.AGENT) {
        appHistory.push("/app/agent-clients");
      } else {
          appHistory.push("/app/dashboard");
          dispatch(callGetSupplierCheckAPI(id));
      }
    }
  };
};
/**
 * Application Reference Data Handling
 */
export const referanceDataSuccess = (refData) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.APPLICATION.REFERENCE_DATA_SUCCESS,
      payload: {...refData}
    });
  };
};

export const referanceDataError = (error) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.AUTH.ERROR_ON_USER_INFORMATION,
      payload: error
    });
  };
};

/**
 * AUTHENTICATION
 */
export const requestToLogin = (Credentials, callback) => {
  return (dispatch) => [
    dispatch({ type: ACTION_TYPES.AUTH.REQUEST_FOR_LOGIN }),
    dispatch(login_action(Credentials, callback))
  ];
};

export const requestedLoginSuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.AUTH.SET_USER_TOKEN_INFORMATION,
      payload: data
    });
};

export const requestedLoginError = (error) => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.AUTH.ERROR_ON_USER_INFORMATION,
      payload: { message: error.message }
    });
};

export const resetApplication = (error) => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.APPLICATION.CLEAR_APPLICATION_DATA
    });
};

export const resetStore = () => {
  return (dispatch) => [
    dispatch(resetApplication()),
    dispatch(clearSharedPolicies()),
    dispatch(resetPolicies()),
    dispatch(resetPendingUsersRequest()),
    dispatch(resetProfile()),
    dispatch(clearPendingInsuredRequests()),
    dispatch(clearTrackedPolicies()),
    dispatch(resetTemplates()),
    dispatch(clearRequestOfVerifications())
  ];
};

export const downloadCOISuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.GET_DOWNLOAD_COI_SUCCESS,
      payload: data
    });
};
export const downloadCOIError = () => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.GET_DOWNLOAD_COI_ERROR
    });
};
export const downloadCOIClearSuccess = () => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.CLEAR_DOWNLOAD_COI
    });
};

export const selectedNotificationSuccess = (data) => ({
  type: ACTION_TYPES.SELECTED_NOTIFICATION,
  payload: data
});

export const disable_welcome = () => {
  return (dispatch) =>
    dispatch({
      type: ACTION_TYPES.DISABLE_WELCOME
    });
};

export const setOpenRequests = (data) => ({
  type: ACTION_TYPES.SET_OPEN_REQUESTS,
  payload: data
});

export const setPendingApprovalCount = (data) => ({
  type: ACTION_TYPES.SET_PENDING_APPROVAL_COUNT,
  payload: data
});
export const getClientProjectTemplatesForAgentSuccess = (data) => ({
  type: ACTION_TYPES.CLIENT_PROJECT_TEMPLATES_FOR_AGENT_SUCESS,
  payload: data
});

export const clearClientProjectTemplatesForAgentError = (data) => ({
  type: ACTION_TYPES.CLEAR_CLIENT_PROJECT_TEMPLATES_FOR_AGENT
});

export const getClientProjectTemplatesPolicyShareListForAgentSuccess = (
  data
) => ({
  type: ACTION_TYPES.CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LIST_AGENT_SUCESS,
  payload: data
});

export const clearClientProjectTemplatesPolicyShareListForAgent = (data) => ({
  type: ACTION_TYPES.CLEAR_CLIENT_PROJECT_TEMPLATES_POLICY_SHARE_LISTPOLICY_SHARE_LIST_AGENT
});

export const getClientAddressBookDetailsForShareSuccess = (data) => ({
  type: ACTION_TYPES.CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT_SUCESS,
  payload: data
});

export const clearClientAddressBookDetailsForShare = (data) => ({
  type: ACTION_TYPES.CLEAR_CLIENT_ADDRESS_BOOK_DETAILS_FOR_AGENT
});

export const setSSOCompanyName = (data) => ({
  type: ACTION_TYPES.SET_SSO_COMPANYNAME,
  payload: data,
});

export const clearSSOCompanyName = () => ({
  type: ACTION_TYPES.CLEAR_SSO_COMPANYNAME,
});
