import { STORAGE } from "../utility/Storage.utility";

export const _authService = {
  getUserToken: () => STORAGE.getItem("currentUserInformation"),
  setUserToken: (token) => STORAGE.setItem("currentUserInformation", token),
  getUserRole: () => STORAGE.getItem("userRole"),
  setUserRole: (role) => STORAGE.setItem("userRole", role),
  clearUserRole: () => STORAGE.clearItem("userRole"),
  isUserAuthenticated: () => !!STORAGE.getItem("currentUserInformation"),
  logoutUser: () => STORAGE.clearItem("currentUserInformation"),
  setUserInfo: (userInfo) => STORAGE.setItem("userInfo", userInfo),
  getUserInfo: () => STORAGE.getItem("userInfo"),
  clearUserInfo: () => STORAGE.clearItem("userInfo"),
  getIsUserTutorialOpen: () => STORAGE.getItem("isUserTutorialOpen"),
  setIsUserTutorialOpen: (isUserTutorialOpen) =>
    STORAGE.setItem("isUserTutorialOpen", isUserTutorialOpen),
};
