import React from "react";

export const scrollBarThumb = ({ style, ...props }) => {
  const thumbStyle = {
    backgroundColor: "#bbb",
    borderRadius: 3,
    cursor: "pointer",
    width: 10,
    right: 3
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
