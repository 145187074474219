import { PENDING_INSURED_REQUESTS_ACTION_TYPES } from "./pendingInsuredRequests.types";

export const requestedDummyPendingInsuredRequests = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.DUMMY_PENDING_INSURED_REQUESTS,
  payload
});
export const resetRequestedDummyPendingInsuredRequests = () => ({
  type:
    PENDING_INSURED_REQUESTS_ACTION_TYPES.RESET_DUMMY_PENDING_INSURED_REQUESTS
});

export const requestedPendingInsuredRequestsSuccess = (payload) => ({
  type:
    PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PENDING_INSURED_REQUESTS.SUCCESS,
  payload
});

export const requestedPendingInsuredRequestsError = (payload) => ({
  type:
    PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PENDING_INSURED_REQUESTS.ERROR,
  payload
});

export const getProjectListSuccess = (obj) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PROJECTS_LIST.SUCCESS,
  payload: obj
});

export const getProjectListError = (obj) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_PROJECTS_LIST.ERROR,
  payload: obj
});

export const setActiveProject = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.SET_ACTIVE_PROJECT,
  payload
});
export const clearActiveProject = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.CLEAR_ACTIVE_PROJECT
});
export const requestedRequestDetailReset = () => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_DETAILED_INSURED_REQUEST.RESET
});

export const requestedRequestDetailSuccess = (payload) => ({
  type:
    PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_DETAILED_INSURED_REQUEST.SUCCESS,
  payload
});

export const requestedRequestDetailError = (payload) => ({
  type:
    PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_DETAILED_INSURED_REQUEST.ERROR,
  payload
});

export const setCompanySelection = (payload) => ({
  type:
    PENDING_INSURED_REQUESTS_ACTION_TYPES.SET_ACTIVE_PENDING_INSURED_REQUESTS,
  payload
});

export const clearPendingInsuredRequests = () => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.CLEAR_PENDING_INSURED_REQUESTS
});

/**
 * Sharable Policies
 */

export const insurerSharablePoliciesSuccess = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.SUCCESS,
  payload
});

export const insurerSharablePoliciesError = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.ERROR,
  payload
});

export const insurerSharablePoliciesReset = () => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_POLICIES_TO_SHARE.RESET
});

export const saveCertUploadStatus = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.SAVE_CERT_UPLOAD_STATUS,
  payload: payload
});

/**
 * Sharable Docs
 */

export const insurerSharableDocsSuccess = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_DOCS_TO_SHARE.SUCCESS,
  payload
});

export const insurerSharableDocsError = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_DOCS_TO_SHARE.ERROR,
  payload
});

export const insurerSharableDocsReset = () => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_MY_DOCS_TO_SHARE.RESET
});

export const setSelectedCompany = (payload) => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.SET_SELECTED_COMPANY,
  payload
});
export const getSelectedCompany = () => ({
  type: PENDING_INSURED_REQUESTS_ACTION_TYPES.GET_SELECTED_COMPANY
});
