import { Col, Icon, Row } from "antd";
import moment from "moment";
import RcQueueAnim from "rc-queue-anim";
import React from "react";
import { certificialIcon } from "../../../Assets/assets";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";

const Maintainance = ({ fromTime, toTime }) => {
  const tz = new Date(toTime)
    .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
    .substring(4);
  let timezone = tz.match(/\b(\w)/g).join("");
  const _tz = timezone === "EST" || timezone === "EDT" ? "ET" : timezone;
  let remainder = 0;
  if(moment(toTime).minute()>0){
    remainder = 30 - (moment(toTime).minute() % 30);
  }
  return (
    <Row
      type="flex"
      style={{ height: "100vh", background: "white" }}
      align="middle"
      justify="center"
      className="maintenance-container"
    >
      <Col span={12}>
        <RcQueueAnim type={"left"} delay={300}>
          <div className="maintenance-header" key="image">
            <div style={{ padding: 12 }}>
              <img src={certificialIcon} height="72" />
            </div>
          </div>

          <div className="maintenance-body" key="body">
            <h2>Maintenance in progress.</h2>
            <p>
              The Certificial platform is undergoing scheduled maintenance and
              will be back up and running{" "}
              {toTime ? (
                <span>
                  at{" "}
                  <b style={{ color: "rgba(0, 0, 0, 0.75)" }}>
                    {/* at {toTime && moment(toTime).format("MM/DD/YYYY HH:mm A")}. */}{" "}
                    {toTime &&
                      moment(toTime).add(remainder, 'minutes').format(
                        "hh:mm A [" + _tz + "] dddd[,] MMM Do "
                      )}
                    .
                  </b>
                </span>
              ) : (
                "shortly. "
              )}
              Thank you for your patience!
            </p>
          </div>
          <div className="maintenance-footer" key="footer">
            <a
              href={
                CERTIFICIAL.MAILTO_SUPPORT_EMAIL_ADDRESS +
                "?subject=Maintenance Support&body=Hello Certificial Team,"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Icon
                type="right-circle"
                className="color-primary margin-right-sm"
                theme="filled"
              />
              Contact Certificial support
            </a>
          </div>
        </RcQueueAnim>
      </Col>
    </Row>
  );
};

export default Maintainance;
