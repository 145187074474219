import {
  USER_PROFILE_ACTION_TYPES,
  AGENT_LIST_ACTION_TYPES,
  AGENT_ADD_ACTION_TYPES,
  USER_UPDATE_ACTION_TYPE,
  USER_PASSWORD_UPDATE_ACTION_TYPE,
  INVITE_AGENT_TYPES,
  CLEAR_AGENT_SIGNATURE_TYPE,
  UPLOAD_AGENT_SIGNATURE_TYPE,
  EMAIL_ASSOCIATED_SHARABLE_COMPANIES,
  CERT_UPLOAD_STATUS
} from "./MyProfile.types";
import { ACTION_TYPES } from "../Application/Application.types";
import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
import { appHistory } from "../../../Utils/Helpers/History";

/**
 * GET USER PROFILE
 */

export const changeUserRole = (requstedRole) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.APPLICATION.CHANGE_USER_ROLE,
      payload: requstedRole
    });
    if (requstedRole === APPLICATION_USER_ROLES.INSURER) {
      appHistory.push("/app/shared-policies");
    } else {
      appHistory.push("/app/dashboard");
    }
  };
};

export const requestedUserProfileSuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: USER_PROFILE_ACTION_TYPES.GET_USER_PROFILE.SUCCESS,
      payload: {
         ...data,
        // permissions: {
        //   insured: {
        //     SHARE_POLICY: true, 
        //     RE_RESPOND: true,
        //     RE_SHARE: true,
        //     OPEN_REQUEST_RESPOND: true
        //   },
        //   requestor: {

        //     CANCEL_TRACKING: true,
        //     SUPPLIER_POLICY_LEVEL_ACTION_MENU: true, // Read only mode = false = disable
        //     SUPPLIER_PAGE_ACTION_BUTTONS: true,
        //     MANAGE_PROJECT_MENU: true,
        //     TEMPLATE_ACTION_MENU: true,
        //     ADD_NEW_TEMPLATE: true,
        //     SUPPLIER_PAGE_EDIT_SUPPLIER_SETTINGS: true,
        //     REQUESTOR_COMPLIANCE_REVIEW_DROP_DOWN: true,
        //     SUPPLIER_PAGE_UPLOAD_COI: true,
        //     ADD_NEW_REQUEST_BUTTON: true,
        //     REQUEST_COVERAGE_UPLOAD_COI: true,
        //     DELETE_REQUEST: true, // Supplier Dashboard
        //     SUPPLIER_ADDRESS_BOOK_MENU: true,
        //     REQUEST_COVERAGE_SUPPLIER_SETTINGS: true,
        //   }
        // }
      }
    });
};

export const requestedUserProfileError = (error) => {
  return (dispatch) =>
    dispatch({
      type: USER_PROFILE_ACTION_TYPES.GET_USER_PROFILE.ERROR,
      payload: error
    });
};

/**
 * GET AGENT LIST BY USER ID
 */

export const agentInforRequestsSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: AGENT_LIST_ACTION_TYPES.GET_AGENT_LIST.SUCCESS,
      payload: data
    });
  };
};

export const clearAgentInfo = () => {
  return (dispatch) => {
    dispatch({
      type: AGENT_LIST_ACTION_TYPES.CLEAR_AGENT_LIST
    });
  };
};

/**
 * GET AGENT LIST BY USER ID
 */

export const agentAddedSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: AGENT_ADD_ACTION_TYPES.AGENT_ADD_ACTION.SUCCESS,
      payload: data
    });
  };
};

export const agentAddedError = (data) => {
  return (dispatch) => {
    dispatch({
      type: AGENT_ADD_ACTION_TYPES.AGENT_ADD_ACTION.ERROR
    });
  };
};

export const inviteAgentSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: INVITE_AGENT_TYPES.INVITE_AGENT_ACTION.SUCCESS
    });
  };
};
export const inviteAgentError = (data) => {
  return (dispatch) => {
    dispatch({
      type: INVITE_AGENT_TYPES.INVITE_AGENT_ACTION.ERROR
    });
  };
};

export const userUpdateSucess = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_UPDATE_ACTION_TYPE.USER_UPDATE_ACTION.SUCCESS,
      payload: data
    });
  };
};

export const userUpdateError = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_UPDATE_ACTION_TYPE.USER_UPDATE_ACTION.ERROR
    });
  };
};

export const userPasswordUpdateSucess = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_PASSWORD_UPDATE_ACTION_TYPE.USER_PASSWORD_UPDATE_ACTION.SUCCESS
    });
  };
};

export const userPasswordUpdateError = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_PASSWORD_UPDATE_ACTION_TYPE.USER_PASSWORD_UPDATE_ACTION.ERROR,
      payload: data
    });
  };
};

export const resetProfile = () => {
  return (dispatch) => {
    dispatch({
      type: USER_PROFILE_ACTION_TYPES.CLEAR_MY_PROFILE
    });
  };
};

export const deleteAgentSignatureSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AGENT_SIGNATURE_TYPE.CLEAR_AGENT_SIGNATURE.SUCCESS
    });
  };
};

export const uploadAgentSignatureSuccess = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_AGENT_SIGNATURE_TYPE.UPLOAD_AGENT_SIGNATURE.SUCCESS,
      payload
    });
  };
};

export const deleteAgentSignatureError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AGENT_SIGNATURE_TYPE.CLEAR_AGENT_SIGNATURE.ERROR
    });
  };
};
export const updatePasswordSuccessStatus = () => {
  return (dispatch) => {
    dispatch({
      type: USER_PASSWORD_UPDATE_ACTION_TYPE.UPDATE_STATUS_ACTION.UPDATE
    });
  };
};

export const emailAssociatedSharableCompaniesSet = (payload) => {
  return (dispatch) => {
    dispatch({
      type: EMAIL_ASSOCIATED_SHARABLE_COMPANIES.SET,
      payload
    });
  };
};

export const emailAssociatedSharableCompaniesReset = () => {
  return (dispatch) => {
    dispatch({
      type: EMAIL_ASSOCIATED_SHARABLE_COMPANIES.RESET
    });
  };
};

export const setCertUploadStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CERT_UPLOAD_STATUS.SET,
      payload
    });
  };
};
