import { Button, Tooltip, Upload } from "antd";
import React from "react";
import { CERTIFICIAL_LABEL } from "../../../Utils/Constants/App.labels";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";
import { validateUploadingFile } from "../../../Utils/utility/app.utility";

export const PolicyEndorsementGroupUpload = ({
  overrideAgentTooltipFlag,
  endorsement,
  openDrawerForEndorsementGrouping,
  readOnlyMode,
  accessingModule,
  policy,
  source
}) => {
  const onClickHandle = (endorsement, file) => {
    if (endorsement.applied) {
      openDrawerForEndorsementGrouping(endorsement, file, accessingModule, policy);
    }
  };
  return (
    <div>
      <Tooltip
        title={
          overrideAgentTooltipFlag
            ? null
            : !!!endorsement.applied &&
              CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                .SELECT_ENDORSEMENT_TO_UPLOAD_DOCUMENT
        }
        placement="topRight"
      >
        <Upload
          name="avatar"
          listType="text"
          showUploadList={false}
          action={null}
          accept={CERTIFICIAL.UPLOAD_FILES.ALL_SUPPORTED_FILE_EXTENTIONS}
          directory={false}
          multiple={false}
          disabled={readOnlyMode}
          beforeUpload={(file) => {
            if (
              validateUploadingFile(
                file,
                CERTIFICIAL.UPLOAD_FILES.ALL_SUPPORTED_FILE_EXTENTION
              )
            ) {
              const cloneOfEndorsement = (accessingModule && accessingModule === 'Respond' && source!='ProjectTemplate') ? { ...endorsement, applied: true } : { ...endorsement } 
              onClickHandle(cloneOfEndorsement, file);
            }
            return false;
          }}
        >
          <Button
            className={endorsement.isEndorsementDocumentRequired ? "highlight-button":""}
            type={endorsement.isEndorsementDocumentRequired ? "danger" : "primary"}
            shape="circle"
            disabled={(accessingModule && accessingModule === 'Respond' && source!='ProjectTemplate')? false: !endorsement.applied || readOnlyMode}
            icon="cloud-upload"
            size="small"
            onClick={() => {}}
          />
        </Upload>
      </Tooltip>
    </div>
  );
};
