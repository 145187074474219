import React from 'react';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {includes as lodashInclude} from 'lodash';
import {
  APPLICATION_USER_ROLES
} from './Utils/Constants/Application.constants'
//"../../../Utils/Constants/Application.constants";



const ChatBot = () => {
    const { userData, role } = useSelector((state) => state.myProfile);
    const location = useLocation();
    const excludedPath = ["/forgot-password","/sign-up-agent/new","/sign-up", '/login', "/maintenance", "/logout", "/maintenance-activity-login","/coi-upload"];
    return(
        <>{lodashInclude(['dev','qa', 'staging', 'uat', 'production'], process.env.REACT_APP_ENV) && !lodashInclude(excludedPath, location.pathname) && role === APPLICATION_USER_ROLES.AGENT? (
        <div>
          <Helmet>
            {/* <script type="text/javascript" nonce="{place_your_nonce_value_here}" src="https://desk.zoho.com/org/695229895/gc/widgets/424093000039393248/embed/script" defer></script> */}
            <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=c59e6a75-1c2d-429e-a4d3-f5cd260e6154"> </script>
          </Helmet>
        </div>
      ) : null}
      </>
    )
}
export default ChatBot;

