import { Alert, Button, Card, Col, Form, Icon, Input, Row } from "antd";
import _ from "lodash";
import RcQueueAnim from "rc-queue-anim";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router";
import { Link, useLocation } from "react-router-dom";
import TextLoop from "react-text-loop";
import bgImage from "../../../Assets/Images/bg.svg";
import splashLogoIcon from "../../../Assets/Images/certificial-logo-white-final.png";
import { AppLoader } from "../../../Components/Loader/AppLoader";
import {
  requestToLogin,
  resetStore
} from "../../../Store/Modules/Application/Application.actions";
import {
  fetchActiveCompany,
  onSuccessLogin,
  regenerateCaptchaCode
} from "../../../Store/Modules/Application/Application.asyncRequest";
import { appHistory } from "../../../Utils/Helpers/History";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import CompanySelection from "../CompanySelection/CompanySelection";
import { getURLParams } from "./../../../Utils/Helpers/URLParams";
import "./Login.css";
import { LoginHeader } from "./LoginHeader";
import ModalPass from "../../../Components/MyProfile/ModalPass";
import ChangePasswordForm from "../../../Components/MyProfile/ChangePassword";
import { refreshImage  } from '../../../Assets/assets';
import LoginCaptcha from './LoginCaptcha';
const Login = (props) => {
  const URLData = props.location.state;
  const dispatch = useDispatch();
  const { name } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { register, handleSubmit, setValue, getValues } = useForm();
  const { error, isAuthenticated } = useSelector(
    (state) => state.app.currentSession
  );
  const [iconLoading, setLoadingIcon] = useState(false);
  const [companySelectionModalVisible, setCompanySelectionModalVisible] =
    useState(false);
  const [availableCompany, setAvailableCompany] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [ modalState, setModalState] = useState({
    visible: false,
    closable: false,
    maskClosable: false,
    isPasswordExpired: false
  });
  const [SSOInfo, setSSOInfo] = useState({});
  const [disableLoginButton, setDisableLoginButton] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [defaultCaptchaValue, setDefaultCaptchaValue] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      _authService.logoutUser();
      _authService.setIsUserTutorialOpen(false);
      _authService.clearUserInfo();
      _authService.clearUserRole();
      if (error.message == "Password Expired"){
        setModalState({
          visible: true,
          closable: true,
          maskClosable: true,
          isPasswordExpired: true,
          username: getValues().username
        })
      }
    }
    return () => {
      setLoadingIcon(false);
      appHistory.push({
        search: ""
      });
    };
  }, [isAuthenticated, error]);

  useEffect(() => {
    dispatch(resetStore());
    if (
      URLData &&
      URLData.from.search &&
      URLData.from.pathname === "/app/dashboard"
    ) {
      const URI = URLData.from.search;
      let decodedURL = "";
      try {
        decodedURL = atob(URI.substring(1));
      } catch (err) {
        decodedURL = "";
      }
      const primaryContactId = getURLParams({
        url: decodedURL,
        paramName: "primaryContactId"
      });
      const authToken = getURLParams({
        url: decodedURL,
        paramName: "token"
      });
      const companyId = getURLParams({
        url: decodedURL,
        paramName: "company"
      });

      dispatch(
        requestToLogin({
          primaryContactId: primaryContactId,
          agentBehlfOfInsured: true,
          token: authToken,
          company: companyId
        })
      );
    }
    if (name === "SSO") {
      //idpUrl = idpUrl+"?emailId="+loggedInAgentEmailId+"&amsName="+amsName+"&requestorId="+requestorId+"&loginRequestId="+loginRequestId+"&callbackURL="+callbackURL;
      setSSOInfo({
        idpUrl: queryParams.get("idpURL"),
        emailId: queryParams.get("emailId"),
        amsName: queryParams.get("amsName"),
        requestorId: queryParams.get("requestorId"),
        loginRequestId: queryParams.get("loginRequestId"),
        callbackURL: queryParams.get("callbackURL"),
      });
    }
  }, []);

  const onClickSSOLogin = () => {
    //console.log(SSOInfo, SSOInfo && SSOInfo.idpUrl)
    window.location.href = SSOInfo.idpUrl;
  };

  const onSubmit = (data) => {
    props.form.validateFields((err, values) => {
      if (!err) {
        data.captcha = values.captcha;
        dispatch(
          requestToLogin(data, (res, captcha) => {
             if(captcha){
              setCaptchaValue(captcha);
              return
             }
            if (res && res.length && res[0].multiemailUser) {
              const loggedInUser = res[0];
              _authService.setUserToken(loggedInUser.jwt);
              dispatch(
                fetchActiveCompany(
                  loggedInUser.email,
                  loggedInUser.dummySessionId,
                  (comp) => {
                    const allComp = comp.map((o) => o.company).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                    const allUser = comp.map((o) => {
                      return {
                        ...loggedInUser,
                        ...o
                      };
                    });
                    setAvailableUsers(allUser);
                    setAvailableCompany(allComp);
                    setCompanySelectionModalVisible(true);
                  }
                )
              );
            } else {
              dispatch(onSuccessLogin(Array.isArray(res) ? _.head(res) : res));
            }
          }) 
        );   
        setDefaultCaptchaValue('');   
      } else {
        setLoadingIcon(false);
      }
    });
  };

  const handleChange = (e) => {
    setValue(
      e.target.name,
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    );
  };

  useEffect(() => {
    register({ name: "username" });
    register({ name: "password" });
    register({ name: "rememberMe" });
  }, [register]);

  const { getFieldDecorator } = props.form;

  const enterIconLoading = () => {
    setLoadingIcon(true);
  };

  const regenerateCaptcha = (refreshCaptcha = false) => {
    let username = getValues().username || '';
    if (username != null && username != '') {
      setDisableLoginButton(true);
      setValue('captcha',null)
      regenerateCaptchaCode(username, refreshCaptcha).then((response) => {
        setDisableLoginButton(false);
        setCaptchaValue(response.captcha);
        setDefaultCaptchaValue('');
      }).catch((error) => {
        setDisableLoginButton(false);
        setDefaultCaptchaValue('');
      })
    }
    
  }


  const handleModalCancel = () => {
    const { form } = props;
    form.resetFields();
    setModalState((prevState) => ({
      visible: !prevState.visible,
      closable: true,
      maskClosable: true,
      isPasswordExpired: !prevState.isPasswordExpired
    }));
    setLoadingIcon(false);
  };
  const handleModalOk = (close) => {
    if(close){
      handleModalCancel();
    }
  };
  return URLData && URLData.from.search ? (
    <AppLoader
      isLoading={true}
      pastDelay={true}
      height={"70vh"}
      trivia={true}
      triviaKey={"loginScreen"}
    />
  ) : name === "grms" ? (
    <Row gutter={[16, 16]} style={{ margin: 0 }} type="flex" justify="center">
      <Col xs={20} sm={16} md={12} lg={11} xl={8}>
        <Card hoverable size="small" className="loginCard">
          <LoginHeader user={name} />

          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="login-form margin-top-md"
          >
            <Form.Item label="Email">
              {getFieldDecorator("userName", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter the E-mail."
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  name="username"
                  onChange={handleChange}
                  placeholder="E-mail"
                  disabled={props.activeMaintainance}
                  autoComplete="username"
                />
              )}
            </Form.Item>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter password."
                  }
                ]
              })(
                <Input.Password
                  name="password"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  onChange={handleChange}
                  placeholder="Password"
                  disabled={props.activeMaintainance}
                  autoComplete="current-password"
                />
              )}
            </Form.Item>
            {!!error && error.message && (
              <div className="p-12" style={{ margin: "12px 0" }}>
                <Alert message={error.message} type="error" />
              </div>
            )}

            <Form.Item className="p-20">
              <Row type="flex" justify="center">
                <Col span={24}>
                  <Link to="/forgot-password">Forgot password?</Link>
                </Col>
                <Col span={24}>
                  <Button
                    style={{
                      margin: "18px 0"
                    }}
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button-cert"
                    loading={iconLoading}
                    onClick={enterIconLoading}
                    disabled={props.activeMaintainance}
                  >
                    Log in
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
          <div className="p-12">
            Don't have an account? Select a profile below
          </div>
          <Row
            gutter={[8, 32]}
            style={{
              marginTop: 10,
              background: "rgb(245, 245, 245)",
              marginRight: -12,
              marginLeft: -12
            }}
          >
            <Col span={12} className="user-button">
              <div style={{ background: "#FFF" }}>
                <Button
                  type="link"
                  block
                  className="buttons-height  "
                  disabled={props.activeMaintainance}
                  onClick={() =>
                    appHistory.push(name ? "/sign-up/" + name : "/sign-up")
                  }
                >
                  User
                </Button>
              </div>
            </Col>
            <Col span={12} className="agent-button">
              <div style={{ background: "#FFF" }}>
                <Button
                  type="link"
                  block
                  className="buttons-height "
                  disabled={props.activeMaintainance}
                  onClick={() =>
                    appHistory.push(
                      name ? "/sign-up-agent/new/" + name : "/sign-up-agent/new"
                    )
                  }
                >
                  Agent
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  ) : name === "corrigo" ? (
    <Row gutter={[16, 16]} style={{ margin: 0 }} type="flex" justify="center">
      <Col xs={20} sm={16} md={12} lg={11} xl={8}>
        <Card hoverable size="small" className="loginCard">
          <LoginHeader source="Login" user={name} />

          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="login-form margin-top-md"
          >
            <Form.Item
              label="Email"
              style={{ marginTop: "34px" }}
              className="p-12"
            >
              {getFieldDecorator("userName", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter the E-mail."
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  name="username"
                  onChange={handleChange}
                  placeholder="E-mail"
                  disabled={props.activeMaintainance}
                  autoComplete="username"
                />
              )}
            </Form.Item>
            <Form.Item label="Password" className="p-12">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter password."
                  }
                ]
              })(
                <Input.Password
                  name="password"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  onChange={handleChange}
                  placeholder="Password"
                  disabled={props.activeMaintainance}
                  autoComplete="current-password"
                />
              )}
            </Form.Item>
            {!!error && error.message && (
              <div className="p-12" style={{ margin: "12px 0" }}>
                <Alert message={error.message} type="error" />
              </div>
            )}

            <Form.Item className="p-20">
              <Row type="flex" justify="center">
                <Col span={24}>
                  <Link to="/forgot-password">Forgot password?</Link>
                </Col>
                <Col span={24}>
                  <Button
                    style={{
                      margin: "18px 0"
                    }}
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button-cert"
                    loading={iconLoading}
                    onClick={enterIconLoading}
                    disabled={props.activeMaintainance}
                  >
                    Log in
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
          <div className="p-12">
            Don't have an account? Select a profile below
          </div>
          <Row
            gutter={[8, 32]}
            style={{
              marginTop: 10,
              background: "rgb(245, 245, 245)",
              marginRight: -12,
              marginLeft: -12
            }}
          >
            <Col span={12} className="user-button">
              <div style={{ background: "#FFF" }}>
                <Button
                  type="link"
                  block
                  className="buttons-height  "
                  disabled={props.activeMaintainance}
                  onClick={() =>
                    appHistory.push(name ? "/sign-up/" + name : "/sign-up")
                  }
                >
                  User
                </Button>
              </div>
            </Col>
            <Col span={12} className="agent-button">
              <div style={{ background: "#FFF" }}>
                <Button
                  type="link"
                  block
                  className="buttons-height "
                  disabled={props.activeMaintainance}
                  onClick={() =>
                    appHistory.push(
                      name ? "/sign-up-agent/new/" + name : "/sign-up-agent/new"
                    )
                  }
                >
                  Agent
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  ) : companySelectionModalVisible ? (
    <CompanySelection
      companySelectionModalVisible={companySelectionModalVisible}
      availableUsers={availableUsers}
      availableCompany={availableCompany}
    />
  ) : (
    <Row
      type="flex"
      style={{
        height: "100vh",
        textAlign: "center",
        background: "white",
        margin: "-60px 0"
      }}
    >
      <Col span={10}>
        <RcQueueAnim type={"left"} delay={200}>
          <div
            key="bg"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: "url(" + bgImage + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: " right",
              opacity: 0.8
            }}
          ></div>

          <div className="auth-cover" key="cover">
            <div className="logo-wrapper">
              <div key="image">
                <img
                  src={splashLogoIcon}
                  height="120"
                  style={{ objectFit: "cover", marginBottom: 24 }}
                />
              </div>
            </div>
            <div className="title-box">
              {/* <h1 className="margin-0">Certificial</h1> */}
            </div>

            <div className="sub-title-box">
              <TextLoop>
                <h3>Digital Insurance Verification.</h3>
                <h3>
                  Digitally verify and then continuously monitor
                  <br /> your Suppliers’ insurance coverage.
                </h3>
                <h3>No more insurance coverage gaps or fraud.</h3>
                <h3>
                  Digitized and automated the insurance
                  <br /> verification process.
                </h3>
                <h3>Real-Time Compliance.</h3>
              </TextLoop>
            </div>
            <div className="cover-footer-box"></div>
          </div>
        </RcQueueAnim>
      </Col>
      <Col
        span={14}
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "left",
          alignItems: "center"
        }}
      >
        {/* <Row justify="space-around" align="middle" style={{ height: "90%" }}> */}
        <div style={{ textAlign: "left", width: "45%" }}>
          <Col>
            <RcQueueAnim type={"right"} delay={200}>
              <div style={{ marginBottom: 24 }} key="text">
                <h1 style={{ color: "rgba(0, 0, 0, 0.65)" }}>Sign In</h1>
              </div>
              {name === "SSO" ? (
                <Form.Item className="p-20">
                  <Row type="flex" justify="center">
                    <Col span={24}>
                      <Button
                        style={{
                          margin: "18px 0",
                        }}
                        type="primary"
                        htmlType="button"
                        block
                        className="login-form-button-cert"
                        loading={iconLoading}
                        onClick={onClickSSOLogin}
                        disabled={props.activeMaintainance}
                      >
                        SSO Log in
                      </Button>
                    </Col>
                    <div>OR</div>
                    <Col span={24}>
                      <Link to="/">
                        <Button
                          style={{
                            margin: "18px 0",
                          }}
                          type="primary"
                          htmlType="button"
                          block
                          className="login-form-button-cert"
                          loading={iconLoading}
                          onClick={() => {
                            appHistory.push(name ? "/login/" + name : "/login");
                          }}
                          disabled={props.activeMaintainance}
                        >
                          Log in via Certificial
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
              <div key="form">
                <LoginHeader
                  user={name === "grms" || name === "corrigo" ? name : "login"}
                />
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="login-form margin-top-md"
                >
                  <Form.Item label="Email">
                    {getFieldDecorator("userName", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter the E-mail."
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        name="username"
                        onChange={handleChange}
                        placeholder="E-mail"
                        disabled={props.activeMaintainance}
                        autoComplete="username"
                        onBlur={()=>regenerateCaptcha()}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Password">
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter password."
                        }
                      ]
                    })(
                      <Input.Password
                        name="password"
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        onChange={handleChange}
                        placeholder="Password"
                        disabled={props.activeMaintainance}
                        autoComplete="current-password"
                      />
                    )}
                  </Form.Item>
                  {captchaValue && (
                  <Form.Item label="Captcha">
                    {getFieldDecorator("captcha", {
                      rules: [
                        {
                          required: true,
                          //whitespace: true,
                          message: "Please enter captcha."
                        },
                        {
                          message: 'Enter valid captcha',
                          validator: async (rule, value, callback) => {
                            if (value !== undefined && value.length >= 5 && captchaValue !== props.form.getFieldValue('captcha')) {
                              throw new Error('Enter valid captcha');
                            } else {
                              callback()
                            }
                          }
                        }
                        
                      ]
                    })(
                      <Row>
                        <Col span={8}>
                          <Input
                            name="captcha"
                            onChange={(e) => {setDefaultCaptchaValue(e.target.value)}}
                            placeholder="Captcha"
                            value={defaultCaptchaValue}
                          />
                        </Col>
                        <Col span={10}>
                          <div style={{ position: 'relative', textAlign: 'center', margin: '-4px 0px 0px 31px' }}>
                            <LoginCaptcha captchaValue = {captchaValue}/>
                          </div>
                        </Col>
                        <Col span={5}>
                          <img onClick={()=>{regenerateCaptcha(true)}} src={refreshImage} style={{cursor:'pointer',width:'24px'}}/>
                          {/* <Button size="" disabled={disableLoginButton} onClick={() => { regenerateCaptcha() }} type="primary" shape="circle" icon={"sync"} /> */}
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                  )}
                  {!!error && error.message &&  (error.message != "Password Expired") && (
                    <div style={{ margin: "12px 0" }}>
                      <Alert message={error.message} type="error" />
                    </div>
                  )}

                  <Form.Item className="p-20">
                    <Row type="flex" justify="center">
                      <Col span={24}>
                        <Link to="/forgot-password">Forgot password?</Link>
                      </Col>
                      <Col span={24}>
                        <Button
                          style={{
                            margin: "18px 0"
                          }}
                          type="primary"
                          htmlType="submit"
                          block
                          className="login-form-button-cert"
                          loading={iconLoading}
                          onClick={enterIconLoading}
                          disabled={props.activeMaintainance}
                        >
                          Log in
                        </Button>
                      </Col>
                    </Row>
                    <ModalPass
                          handleModalCancel={handleModalCancel}
                          handleModalOk={handleModalOk}
                          title={<h3 className="margin-0">Please update your password</h3>}
                          {...modalState}
                        >
                          <ChangePasswordForm
                            onPasswordUpdate={handleModalOk}
                            isPasswordExpired={modalState.isPasswordExpired}
                            username={modalState.username}
                            ></ChangePasswordForm>
                        </ModalPass>
                  </Form.Item>
                </Form>
                <div>Don't have an account? Select a profile below</div>
                <Row
                  gutter={[8, 32]}
                  style={{
                    marginTop: 10,
                    background: "#FFF",
                    marginRight: 0,
                    marginLeft: 0
                  }}
                >
                  <Col
                    span={12}
                    //  className="user-button"
                    style={{ paddingTop: "0", paddingRight: "2px" }}
                  >
                    <div style={{ background: "#FFF" }}>
                      <Button
                        type="link"
                        block
                        className="login-form-style"
                        disabled={props.activeMaintainance}
                        onClick={() =>
                          appHistory.push(
                            name ? "/sign-up/" + name : "/sign-up"
                          )
                        }
                      >
                        User
                      </Button>
                    </div>
                  </Col>
                  <Col
                    span={12}
                    style={{ paddingTop: "0", paddingLeft: "2px" }}
                    //  className="agent-button"
                  >
                    <div style={{ background: "#FFF" }}>
                      <Button
                        type="link"
                        block
                        className="login-form-style"
                        disabled={props.activeMaintainance}
                        onClick={() =>
                          appHistory.push(
                            name
                              ? "/sign-up-agent/new/" + name
                              : "/sign-up-agent/new"
                          )
                        }
                      >
                        Agent
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              )}
            </RcQueueAnim>
          </Col>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(Form.create()(Login));
