import React from "react";
import { Result, Avatar, Button } from "antd";
import { exceptionImage } from "../../Assets/assets";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "table",
            height: "50vh",
            width: "100%",
            textAlign: "center",
            overflow: "hidden"
          }}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <Result
              status="error"
              icon={<Avatar src={exceptionImage} size={72} />}
              title={
                <>
                  <h5 className="margin-0">Exception occured in code. </h5>
                  {(this.state.errorInfo && (
                    <div
                      style={{
                        whiteSpace: "pre-wrap",
                        margin: 0,
                        color: "red",
                        fontSize: 14
                      }}
                    >
                      <p className="margin-0">
                        {" "}
                        {this.state.error && this.state.error.toString()}
                      </p>
                    </div>
                  )) ||
                    null}
                </>
              }
              extra={
                <>
                  <Button
                    type="primary"
                    key="refresh"
                    icon="reload"
                    ghost
                    onClick={() => window.location.reload()}
                  >
                    Refresh Page
                  </Button>
                </>
              }
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
