import React, { useState } from 'react';
import { Drawer, Card } from 'antd';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CertificateUploadContainer } from '../../Modules/PendingInsuredRequests/CertificateUploadContainer/CertificateUploadContainer';
import { ENDPOINTS } from "../../REST/API.endpoints";
import { makeRequest } from "../../REST/API.wrapper";
import { API_METHODS } from "../../Utils/Constants/API.constants";
import { validateUploadingFile } from "../../Utils/utility/app.utility";
import {head, isEmpty} from 'lodash'
import {
    APPLICATION_USER_ROLES,
    CERTIFICIAL
} from "../../Utils/Constants/Application.constants";
import { setCertUploadStatus } from '../../Store/Modules/MyProfile/MyProfile.action';
import { emailUploadCoi } from '../../Store/Modules/UploadCert/UploadCert.asyncRequest';
import { CERT_REQUEST_PRIORITY } from "../../Utils/Constants/Application.constants";

const COIUpload = (props) => {
    const dispatch = useDispatch();
    const [certificateInsurance, setCertificateInsurance] = useState([]);
    const [additionalFilesWithInsurance, setAdditionalFilesWithInsurance] = useState([]);
    const { activeProject } = useSelector((state) => state.insurerSharedPolicies, shallowEqual);
    const { activeProject: requestorActiveProject } = useSelector((state) => state.requestorDashboard);
    const [loading, setLoading] = useState(false);
    const { userData, uploadCoiAgencyDetails } = useSelector((state) => state.myProfile, shallowEqual);
    const { role } = useSelector((state) => state.app.currentSession, shallowEqual);
    const agencyDetails = head(props.agencyDetails);
    const payloadAgencyDetails = (uploadCoiAgencyDetails || []).find((agency) => { return agency.agencyName === agencyDetails?.agencyName || agencyDetails?.agentId === agency.agentId });

    //Close Drawer 
    const closeDrawerCertUpload = () => {
        setAdditionalFilesWithInsurance([]);
        setCertificateInsurance([]);
        props.closeDrawer(false);
    };

    const emailCoiUploadHanlder = async(formData, certificateDoc, optionalDocs)=>{
        setLoading(true);
        let reqObj = {
            "agentId":props.agentId,
            "requestId":props.requestId,
            certificateDoc,
            additionallDocs:optionalDocs,
            emailKey:props.emailKey
        }

        if(!isEmpty(props.uploadedBy)) {
            // changes related to https://certificial.atlassian.net/browse/CER-33231
            reqObj = {...reqObj, uploadedBy: props.uploadedBy}
        }

        formData.append('request', JSON.stringify(reqObj));
        let response;
        try{
            response = await emailUploadCoi(formData);
            props.coiSubmitHandler(response)
        }catch(err){
            response = err;
        }
        setLoading(false);

        
    }

    const onCertUploadSubmitClick = (priority = null) => {
        const formData = new FormData();
        let optionalFilesFiltered = additionalFilesWithInsurance.filter(
            (file) => file.status === "success"
        );
        formData.append("multipartFiles", certificateInsurance[0].originFileObj);
        optionalFilesFiltered.forEach((file) => {
            formData.append("multipartFiles", file.originFileObj);
        });

        let certificateDoc = certificateInsurance[0].name;
        let optionalDocs = optionalFilesFiltered.map((file) => file.name);
        if(props?.isEmailCoiUpload){
            emailCoiUploadHanlder(formData, certificateDoc, optionalDocs);
            return
        }
        let reqObj = {
            requestId: activeProject?.requestId || requestorActiveProject?.requestId,
            projectId: activeProject?.id || requestorActiveProject?.id,
            shareId: payloadAgencyDetails.shareId,
            insuredCompanyId: payloadAgencyDetails.insuredCompanyId,
            loggedInUserId: userData && userData.id,
            dashboardType: payloadAgencyDetails.dashboardType,
            certificateDoc,
            agentId: payloadAgencyDetails.agentId,
            optionalDocs,
            certUploadId: payloadAgencyDetails.certUploadId,
            policiesToRenewal: payloadAgencyDetails.policiesToRenewal,
        };

        if(role == APPLICATION_USER_ROLES.REQUESTER){
            reqObj.priority = priority || CERT_REQUEST_PRIORITY.STANDARD;
        }   

        if (userData.role === APPLICATION_USER_ROLES.AGENT) {
            reqObj.requestId = activeProject && activeProject.requestId;
            reqObj.projectId = activeProject && activeProject.id;
        } else if (userData.role === APPLICATION_USER_ROLES.CLIENT) {
            reqObj.requestId = activeProject?.requestId || requestorActiveProject?.requestId;
            reqObj.projectId = activeProject?.id || requestorActiveProject?.id;
        }

        formData.append("request", JSON.stringify(reqObj));
        setLoading(true);
        dispatch(setCertUploadStatus([]));
        makeRequest({
            type: API_METHODS.POST,
            url: ENDPOINTS.PENDING_INSURED_REQUEST.POST_CERTIFICATE_UPLOAD_REQUEST,
            data: formData
        }).then(
            (res) => {
                props.onRefresh();
                setLoading(false);
                closeDrawerCertUpload();
            },
            (error) => {
                setLoading(false);
                props.onRefresh();
                closeDrawerCertUpload()
            }
        );
    };

    const handleCertificateUpload = (files, uploadType) => {
        if (uploadType === "CERT_INS") {
            const isValid = validateUploadingFile(
                files.file,
                CERTIFICIAL.UPLOAD_FILES.UPLOAD_FILES_PDF_CHECK
            );
            if (isValid) {
                if (files.fileList.length) {
                    setCertificateInsurance(files.fileList.splice(-1));
                    setLoading(false);
                } else if (files.fileList) {
                    setCertificateInsurance(files.fileList);
                    setLoading(false);
                }
            }
        } else if (uploadType === "CERT_ADD") {
            const filteredList =
                (files.fileList &&
                    files.fileList.length &&
                    files.fileList.map((file) => ({
                        ...file,
                        status: (validateUploadingFile(file) && "success") || "error",
                        response:
                            (validateUploadingFile(file) && "success") ||
                            "File not supported. Please upload image or pdf only, as mentioned above."
                    }))) ||
                [];
            setAdditionalFilesWithInsurance(filteredList);
        }
    };
    return (

        props.isEmailCoiUpload ? <CertificateUploadContainer
                certificateFile={certificateInsurance}
                additionalFilesWithInsurance={additionalFilesWithInsurance}
                handleFileUpload={handleCertificateUpload}
                onCertUploadSubmitClick={onCertUploadSubmitClick}
                loading={loading}
                showDuplicateTransactions={false}
                isEmailCoiUpload={props.isEmailCoiUpload}
                insuredCompanyName={props.insuredCompanyName}
                childComponenet = {props?.childComponenet}
            />:
        <Drawer
            title="Certificate Upload"
            keyboard={false}
            placement="right"
            closable={true}
            onClose={() => { closeDrawerCertUpload() }}
            visible={props.visible}
            destroyOnClose={true}
            maskClosable={true}
            width={props.width}
            bodyStyle={{ marginBottom: 90 , padding:"10px 0px 0px 15px"}}
        >
            <CertificateUploadContainer
                certificateFile={certificateInsurance}
                additionalFilesWithInsurance={additionalFilesWithInsurance}
                handleFileUpload={handleCertificateUpload}
                onCertUploadSubmitClick={onCertUploadSubmitClick}
                loading={loading}
                showDuplicateTransactions={false}
            />
            
        </Drawer>
    )
}

export default COIUpload;