import React from "react";
import { Loader } from "./Loader";
import { Button, Result, Avatar, Typography } from "antd";
import { errorImage } from "../../Assets/assets";
import { CERTIFICIAL_LABEL } from "../../Utils/Constants/App.labels";
import TextLoop from "react-text-loop";
const { Text } = Typography;

export const AppLoader = ({
  isLoading,
  pastDelay,
  error,
  height,
  timedOut,
  retry,
  message,
  trivia,
  triviaKey
}) => {
  // const customMessage =
  //   triviaKey === "application" ? (
  //     <div>
  //       <Text strong>Insurance Fact # </Text>
  //       <TextLoop>
  //         <Text>
  //           The Padres have signed the same player for 23 years so he doesn’t
  //           lose his health insurance.
  //         </Text>
  //         <Text>America Ferrera had her smile insured for $10,000,000.</Text>
  //         <Text>
  //           The word ‘insurance’ originally meant an ‘engagement to marry’.
  //         </Text>
  //       </TextLoop>{" "}
  //     </div>
  //   ) : (
  //     message || CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE
  //   );

  if (isLoading) {
    if (timedOut) {
      return (
        <div
          style={{
            display: "table",
            height: (height && height) || "80vh",
            width: "100%",
            textAlign: "center",
            overflow: "hidden"
          }}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <Result
              status="error"
              icon={<Avatar src={errorImage} size={72} />}
              title={"Taking to load page, We are working on fixing it."}
              extra={
                <Button
                  type="primary"
                  key="refresh"
                  icon="reload"
                  ghost
                  onClick={() => window.location.reload()}
                >
                  Retry
                </Button>
              }
            />
          </div>
        </div>
      );
    } else if (pastDelay) {
      return (
        <div
          style={{
            display: "table",
            height: (height && height) || "80vh",
            width: "100%",
            textAlign: "center",
            overflow: "hidden"
          }}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <div>
              <Loader
                size={36}
                tip={message || CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "table",
            height: (height && height) || "80vh",
            width: "100%",
            textAlign: "center",
            overflow: "hidden"
          }}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <div>
              <Loader size={36} />
            </div>
          </div>
        </div>
      );
    }
  } else if (error !== null && error !== undefined) {
    if (error.constructor === SyntaxError) {
      return () => retry();
    } else {
      return (
        <div
          style={{
            display: "table",
            height: (height && height) || "80vh",
            width: "100%",
            textAlign: "center",
            overflow: "hidden"
          }}
        >
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <Result
              status="error"
              icon={<Avatar src={errorImage} size={72} />}
              title={
                "Unable to process request, please contact service administrator."
              }
              extra={
                <Button
                  type="primary"
                  key="refresh"
                  icon="reload"
                  ghost
                  onClick={() => window.location.reload()}
                >
                  Refresh Page
                </Button>
              }
            />
          </div>
        </div>
      );
    }
  } else return null;
};
