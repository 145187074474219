import { Alert, Card, Checkbox, Col, Icon, Input, Row, Spin } from "antd";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import { CERTIFICIAL_LABEL } from "../../Utils/Constants/App.labels";
import { APPLICATION_MESSAGES } from "../../Utils/Constants/ApplicationMessages.constants";
import "./GenerateSignature.less";

const GenerateSignature = ({
  signName,
  suggSelection,
  selectedSuggestion,
  clickAgreeESign,
  agreeESign,
  source,
  canRemoveSelectedESign
}) => {
  const [initLoading, setInitLoading] = useState(false);
  const [inputSignature, setInputSignature] = useState(
    (selectedSuggestion && selectedSuggestion.inputSignature) || signName
  );
  const [remainingChar, setRemainingChar] = useState(61);
  const [signatureSuggetions, setSignatureSuggetions] = useState([
    { fontName: "MAYQUEEN", id: "auto", scale: 1 },
    { fontName: "MrsSaintDelafieldRegular", id: "accountant", scale: 1 },
    { fontName: "Qinyana", id: "qinyana", scale: 0.8 },
    { fontName: "PhontPhreaksHandwriting", id: "agreement", scale: 0.8 },
    { fontName: "ReeyRegular", id: "authorized", scale: 0.5 },
    { fontName: "Angkanya", id: "angkanya", scale: 1 }
  ]);
  const handleChange = (event) => {
    if (event && event.target && event.target.value.length < 62) {
      const value = event.target.value || "";
      setInputSignature(value);
      setRemainingChar(61 - value.length);
    }
  };
  const suggestionSelection = (ev, sugg) => {
    setInitLoading(true);
    html2canvas(ev.currentTarget.children[0]).then((canvas) => {
      const imgageData = canvas.toDataURL("image/png");
      sugg = { ...sugg, inputSignature, dataURI: imgageData };
      suggSelection(sugg);
      setInitLoading(false);
    });
  };
  useEffect(() => {
    const signLength = signName && signName.length;
    setRemainingChar(61 - signLength);
  }, [signName]);
  return (
    <div className="signature-widget-wrapper">
      <Spin
        className="customclass"
        spinning={initLoading}
        indicator={
          <Icon
            type="loading-3-quarters"
            className="color-primary fs-24x"
            spin
          />
        }
        tip={CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE}
      >
      {source == "sharePolicy" && (
          <>
            <Alert
              message={APPLICATION_MESSAGES.APP.SIGNATURE.ALERT}
              type="warning"
              showIcon
              banner
              style={{
                margin: "-13px -17px 18px",
                borderBottom: "2px solid #f5a505"
              }}
            />
            <Checkbox
              onChange={clickAgreeESign}
              className="agree-wrapper"
              checked={agreeESign}
              disabled={!canRemoveSelectedESign}
            >
              {APPLICATION_MESSAGES.APP.SIGNATURE.IAGREE}
            </Checkbox>
          </>
        )}
        <Card
          size="small"
          className="widget-card"
          style={agreeESign ? {} : { pointerEvents: "none", opacity: 0.3 }}
        >
          <Row gutter={[8, 16]} className="input-wrapper">
            <Col>
              <Input
                value={inputSignature}
                placeholder="Your Full Name"
                onChange={handleChange}
                disabled={
                  !agreeESign ||
                  (selectedSuggestion && !!selectedSuggestion.fontName)
                }
              />
            </Col>
          </Row>
          <Row gutter={[8, 16]} className="suggestions-wrapper">
            <Col>
              {signatureSuggetions.map((sugg, idx) => {
                return (
                  <Col key={idx} span={12}>
                    <div
                      className={
                        selectedSuggestion &&
                        sugg.fontName === selectedSuggestion.fontName
                          ? "suggestion-container selected"
                          : "suggestion-container"
                      }
                      onClick={(e) => suggestionSelection(e, sugg)}
                    >
                      <span
                        id={sugg && sugg.id}
                        style={{
                          padding: "15px 1px",
                          fontFamily: sugg.fontName,
                          fontSize:
                            inputSignature && inputSignature.length < 15
                              ? 40 * sugg.scale
                              : inputSignature && inputSignature.length < 22
                              ? 40 * sugg.scale * 0.7
                              : inputSignature && inputSignature.length < 30
                              ? 40 * sugg.scale * 0.6
                              : inputSignature && inputSignature.length < 40
                              ? 40 * sugg.scale * 0.45
                              : inputSignature && inputSignature.length < 50
                              ? 40 * sugg.scale * 0.35
                              : 40 * sugg.scale * 0.25
                        }}
                      >
                        {inputSignature}
                      </span>
                    </div>
                  </Col>
                );
              })}
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

export default GenerateSignature;
