import React,{useEffect, useState} from "react";
import { Select, Icon, Typography, Card, Avatar } from "antd";
import { useSelector, shallowEqual } from "react-redux";
import { agencyIcon, bookmarkIcon } from "../../../Assets/assets";
import {CERTIFICIAL_POLICY_TYPES} from '../../../Utils/Constants/Application.constants';
import _ from "lodash";
const { Option } = Select;
const { Text } = Typography;
export const PolicyAgency = ({
  width,
  isTitleRequired,
  selectedPolicyAgencyType,
  onPolicyAgencySelection,
  onAcordSelected,
  readOnlyMode,
  shouldMakeApiCall,
  showEmptyMessage,
  loading,
  policyType,
  insuredCompany
}) => {
  const [makeSelectDisable, setFormSelectDisable] = useState(false);
  const { agentList, role } = useSelector((state) => state.myProfile, shallowEqual);
  const { selectedPolicyDetails, policies } = useSelector((state) => state.myPolicies);

  useEffect(()=>{
    setFormSelectDisable(false);
  },[])
  const onChange = (value, option) => {
    if (option && option.key) {
      onPolicyAgencySelection(
        _.head(_.filter(agentList, (a) => a.agentId === parseInt(option.key)))
      );
    } else {
      onPolicyAgencySelection(null);
    }
  };

  const showAcord25 = () => {
    if(insuredCompany.countryCode === 2) { // for canada insured no need to show default form selection
      return false;
    }
    return policyType.policyType === CERTIFICIAL_POLICY_TYPES.CRIME || policyType.policyType === CERTIFICIAL_POLICY_TYPES.BOILER || policyType.policyType === CERTIFICIAL_POLICY_TYPES.INLAND_MARINE
  }

  const onAcordVal = (value) => {
    if(value) {
      onAcordSelected({selectedAcordForm:value});
    }
  }

  return shouldMakeApiCall ? null : (
    <div style={isTitleRequired ? {} : {}}>
      <Card
        size="small"
        bordered={readOnlyMode}
        className={[
          "widget-card",
          readOnlyMode ? "" : "flush-header-padding "
        ].join(" ")}
        loading={loading}
        title={
          (isTitleRequired && (
            <h4>
              <Avatar
                src={agencyIcon}
                size="small"
                shape="square"
                className="margin-right-sm"
              />
              Policy Agency
            </h4>
          )) ||
          null
        }
        id="agency-container"
        extra={null}
      >
        <Select
          value={selectedPolicyAgencyType}
          style={{ width: width ? width : "250px" }}
          onChange={onChange}
          disabled={!agentList || readOnlyMode}
          allowClear={false}
          placeholder={
            !agentList || readOnlyMode ? "No Agency Assigned" : "Select Agency"
          }
          getPopupContainer={() => document.getElementById("agency-container")}
        >
          {agentList &&
            agentList.map((policyAgency) => {
              const {
                agencyId,
                agencyName,
                agentName,
                agentId,
                agentEmail
              } = policyAgency;
              return (
                <Option
                  key={agentId}
                  value={agencyId ? agencyName + " " + agentName : agentEmail}
                >
                  {agencyId ? agentName : null}
                  <Text strong>
                    {agencyId ? " ( " + agencyName + " ) " : agentEmail}
                  </Text>
                </Option>
              );
            })}
        </Select>
        <br />
        {showEmptyMessage && !agentList ? (
          <Text type="secondary" style={{ fontSize: 12 }}>
            <Icon type="info-circle" className="margin-right-sm" />
            No agency is associated with this company. Please add new agency in
            my profile.
          </Text>
        ) : null}
        {policyType && policyType.policyType === 'Property' ? (<div style={{ display: "flex", float:'right', marginTop:'-60px', marginRight:'-200px'}}>
           {policyType.refpolicyLimitTypes && policyType.refpolicyLimitTypes[0] &&  policyType.refpolicyLimitTypes[0].subCategory && policyType.refpolicyLimitTypes[0].subCategory[0] && policyType.refpolicyLimitTypes[0].subCategory[0].countryId !== 2 &&
            <div>
              <h4>
                <Avatar
                  src={bookmarkIcon}
                  size="small"
                  shape="square"
                  className="margin-right-sm"
                />Default Commercial Property Forms
              </h4>
              <div style={{ marginRight: "360px", marginTop: '5px' }}>
                <Select
                  style={{ width: 220 }}
                  defaultValue={selectedPolicyDetails.selectedAcordForm ? selectedPolicyDetails.selectedAcordForm : 'ACORD 28'}
                  onChange={(val) => onAcordVal(val)}
                  disabled={makeSelectDisable}
                >
                  <Option value="ACORD 24">ACORD 24</Option>
                  <Option value="ACORD 25">ACORD 25</Option>
                  <Option value="ACORD 27">ACORD 27</Option>
                  <Option value="ACORD 28">ACORD 28</Option>
                </Select>
              </div>
            </div>
                }
                </div>) : policyType && showAcord25() ?(
                  <div style={{float:'right', marginTop:'-60px', marginRight:'-200px'}}>
                  <h4>
                    <Avatar
                      src={bookmarkIcon}
                      size="small"
                      shape="square"
                      className="margin-right-sm"
                    />Default Forms
                  </h4>
                  <div style={{ marginRight: "360px", marginTop: '5px' }}>
                    <Select
                      style={{ width: 220 }}
                      defaultValue={selectedPolicyDetails.selectedAcordForm ? selectedPolicyDetails.selectedAcordForm : showAcord25()? "ACORD 25" : "ACORD 24"}
                      onChange={(val) => onAcordVal(val)}
                      disabled={makeSelectDisable}
                    >
                      <Option value="ACORD 24">ACORD 24</Option>
                      <Option value="ACORD 25">ACORD 25</Option>
                    </Select>
                  </div>
                </div>
                ):null}
      </Card>
    </div>
  );
};
