import React from "react";
import { Checkbox, Icon, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { isBothCslAndSplitLimitsPresent as isBothCslAndSplitLimitsPresentMethod } from "../../../Utils/Helpers/policyLimitTable";

export const CslCheckbox = ({
  onClsCheck,
  checked,
  readOnlyMode,
  policyLimits,
  showCslTooltip,
  isAgentScreen,
  isEmailCoiUpload
}) => {


  const validatePolicyLimits = ()=>{
    if(isEmpty(policyLimits)){
      return false;
    }
    const isBothCslAndSplitLimitsPresent = isBothCslAndSplitLimitsPresentMethod(policyLimits);
    return isBothCslAndSplitLimitsPresent;
  }

  const shouldDisableCheckbox = readOnlyMode ? readOnlyMode : validatePolicyLimits();
  let checkboxLabel = isAgentScreen ? 'Requestor accepts Combined Single Limit or Split Limits.':'Accept either Combined Single Limit or Split Limits';
  if(isEmailCoiUpload) {
    // from coiuploadpage isEmailCoiUpload comes true
    checkboxLabel = "Requestor accepts Combined Single Limit or Split Limits."
  }
  return (
    <div className={"policyDataChekbox"} style={{display:"inline", alignItems:"center"}}>
     <Checkbox
      disabled={shouldDisableCheckbox}
      onChange={(e) =>
        onClsCheck(e.target.checked)
      }
      checked={checked}
    >
        {checkboxLabel}
    </Checkbox>
       {showCslTooltip ? <Tooltip
        destroyTooltipOnHide={true}
        placement="topLeft"
        title={
         `This setting may be used if your insurance requirements allow for either a Combined Single Limit, or a combination of
         Bodily Injury (Per person), Bodily Injury (Per accident), and Property Damage. Either the Combined Single Limit must be met, or each of the Split Limits must
         be met for compliance`
        }
        overlayStyle={{ maxWidth: "50%" }}
      >
        <Icon
          type="info-circle"
          theme="twoTone"
          twoToneColor="#3596db"
        />
      </Tooltip> : null}
    
    </div>

  );
};
