import React from "react";
import { Checkbox, Card, Avatar, Tooltip, Icon } from "antd";
import {
  coverageIcon
} from "../../../../../Assets/assets";
import { useSelector } from "react-redux";
import { compact } from "lodash";



export const ExceptionForInsured = ({
  handleExceptionForInsured,
  exceptions,
  readOnly
}) => {

  const { monopolisticState } = useSelector(
    (state) => state.app.applicationReference
  );
  const checkedValues = compact((exceptions || []).map(exception => {
    if (exception.applied) {
      return exception.id
    }
    return null;
  }))
  return (<Card
    size="small"
    bordered={false}
    title={
      <h4>
        <Avatar
          src={coverageIcon}
          size="small"
          shape="square"
          className="margin-right-sm"
        />
        Enable Exceptions for Insureds who register with a Monopolistic State
        <Tooltip
          destroyTooltipOnHide={true}
          placement="topLeft"
          title={
            <ul style={{ padding: "10px 16px 0px 22px" }}>
              {(monopolisticState|| []).map(state => {
                return <li>{state.stateName}</li>
              })}
            </ul>
          }
          overlayStyle={{ maxWidth: "50%" }}
        >
          <Icon
            style={{ marginLeft: "6px" }}
            type="info-circle"
            theme="twoTone"
            twoToneColor="#3596db"
          />
        </Tooltip>
      </h4>
    }
    extra={null} >
    <Checkbox.Group
      value={checkedValues}
      onChange={handleExceptionForInsured}
    >
      {exceptions.map(coverage => <div className="margin-bottom-sm"><Checkbox disabled={readOnly} key={coverage.id} value={coverage.id}>{coverage.exceptionName}</Checkbox></div>)}
    </Checkbox.Group>
  </Card>
  );

}
