import React, { memo, useState } from "react";
import { Row, Radio, Checkbox, Card, Avatar } from "antd";
import { CERTIFICIAL_LABEL } from "../../../../../Utils/Constants/App.labels";
import css from "./ExclusionPolicyCoverage.module.less";
import {
  coverageIcon
} from "../../../../../Assets/assets";

export const ExclusionPolicyCoverage = memo(function ExclusionPolicyCoverage({
  handleExclusionData,
  coverage,
  onReadOnly,
}) {
  const [exclusions, setExclusions] = useState(false);
  const [exlusionTypeValue, setExclusionTypeValue ] = useState("");

  const onExclusionAdded = (enableEexclusionDetection, exclusionsValue) => {
    setExclusionTypeValue (enableEexclusionDetection);
    let modData = {
      exlusionType: exclusionsValue,
      enableEexclusionDetection: exclusionsValue
        ? enableEexclusionDetection
        : "",
    };
    handleExclusionData(modData);
  };

  return (
    <Card
    size="small"
    bordered={false}
    title={
      <h4>
        <Avatar
          src={coverageIcon}
          size="small"
          shape="square"
          className="margin-right-sm"
        />
        Exclusions
      </h4>
    }
    extra={null} >
      <Checkbox
        disabled={onReadOnly || false}
        onChange={(e) => {
          setExclusions(e.target.checked);
          onExclusionAdded("DECLINE", e.target.checked);
        }}
        defaultChecked={!!coverage?.enableEexclusionDetection}
      >
        {CERTIFICIAL_LABEL.MODULES.REQUESTOR.EXCLUSION.ENABLE_EXCLUSION}
      </Checkbox>
      {(exclusions || !!coverage?.enableEexclusionDetection) && (
        <div className={css.radioButton}>
          <Row>
            <Radio.Group
              disabled={onReadOnly || false}
              defaultValue={coverage?.exclusionType}
              value={exlusionTypeValue || coverage?.exclusionType}
              onChange={(info) => {
                onExclusionAdded(info.target.value, true);
              }}
            >
              <Radio value="DECLINE" className={css.radioButtonPadding}>
                Decline Exclusions
              </Radio>
              <Radio value="REVIEW" className={css.radioButtonPadding}>
                Review Exclusions
              </Radio>
            </Radio.Group>
          </Row>{" "}
        </div>
      )}
    </Card>
  );
});
