import { Icon, Tooltip } from "antd";
import React, { memo } from "react";

const CertInfoTooltip = memo(function CertInfoTooltip({
  placement,
  title,
  className
}) {
  return (
    <Tooltip placement={placement || "top"} title={() => title}>
      <Icon type="info-circle" className={className} />
    </Tooltip>
  );
});

export default CertInfoTooltip;
