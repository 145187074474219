import { Card, Checkbox, Divider, Icon, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import CertInfoTooltip from "../CertTooltip/CertInfoTooltip";

export const CertRatingCompliance = ({
  rating,
  title,
  readonly,
  isCompliance,
  isRequireReview,
  isTitleRequired,
  allowEditForException,
  addRatingInException,
  headingTitle,
  ratingRequestId,
  page,
  isRequestFromAgent,
  currentAmbestRating
}) => {
  const addRatingException = (requestId) => (e) => {
    addRatingInException({
      isApplied: e.target.checked,
      requestId: requestId,
      currentAmbestRating: currentAmbestRating
    });
  };
  return rating ? (
    <Card
      size="small"
      bordered={readonly}
      className="widget-card ambestRaring-container"
      extra={null}
      bodyStyle={{ display: "flex", justifyContent: "space-between" }}
      title={
        (isTitleRequired && (
          <h4
            style={
              isRequireReview
                ? {
                    color: "#faad14"
                  }
                : isCompliance
                ? {
                    color: "rgba(243, 11, 23, .8)"
                  }
                : {
                    color: "#3596db"
                  }
            }
          >
            {allowEditForException && (
              <Checkbox
                className="exceptional-handle-ckeck"
                onChange={addRatingException(ratingRequestId)}
              />
            )}
            &nbsp;&nbsp;{headingTitle}
          </h4>
        )) ||
        null
      }
    >
      <div style={{ flex: "auto" }}>
        <h4
          style={{
            margin: "0"
          }}
        >
          {/* <Avatar
            src={isCompliance ? starIconAlt : starIcon}
            size="small"
            shape="square"
            style={{ marginTop: "-5px" }}
            className="margin-right-sm"
          /> */}
          <Text
            style={
              isRequireReview
                ? {
                    color: "#ec7211"
                  }
                : isCompliance
                ? {
                    color: "#f30b17"
                  }
                : {
                    color: "#3596db"
                  }
            }
          >
            &nbsp;&nbsp;{title || "Requested AMBest Rating"}
            {(isRequestFromAgent && isCompliance && (
              <CertInfoTooltip
                placement="topLeft"
                title={
                  APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO.CARRIER_RATING
                }
                className="compliance-issue-info"
              />
            )) ||
              null}
            {isRequireReview ? (
              <Tooltip
                placement="top"
                title={"Exact match could not be found in AMBest data"}
                arrowPointAtCenter
                destroyTooltipOnHide
              >
                <Icon
                  type="info-circle"
                  theme="outlined"
                  style={{
                    marginLeft: "6px",
                    fontSize: "16px",
                    color: "#ec7211"
                  }}
                />
              </Tooltip>
            ) : null}
          </Text>
          <Text
            style={
              isRequireReview
                ? {
                    float: "right",
                    color: "#ec7211"
                  }
                : isCompliance
                ? {
                    float: "right",
                    color: "#f30b17"
                  }
                : {
                    float: "right",
                    color: "#3596db"
                  }
            }
          >
            {rating}
          </Text>
        </h4>
      </div>
      {currentAmbestRating && (
        <div style={{ flex: "auto" }}>
          <h4
            style={{
              margin: "0"
            }}
          >
            <Divider type="vertical" />
            <Text>&nbsp;&nbsp;{"Current AMBest Rating"}</Text>
            <Text
              className={isCompliance ? "compliance-red" : ""}
              style={
                isCompliance
                  ? {
                      float: "right",
                      color: "#f30b17"
                    }
                  : {
                      float: "right",
                      color: "#3596db"
                    }
              }
            >
              {currentAmbestRating}
            </Text>
          </h4>
        </div>
      )}
    </Card>
  ) : null;
};
