export const TRACKED_POLICIES_ACTION_TYPES = {
  IS_FILTER_APPLIED: "IS_FILTER_APPLIED",
  DUMMY_TRACKED_COMPANIES: "DUMMY_TRACKED_COMPANIES",
  CLEAR_TRACKED_COMPANIES: "CLEAR_TRACKED_COMPANIES",
  REFRESH_COMPANY_STATUS: {
    SET: "SET_REFRESH_COMPANY",
    CLEAR: "CLEAR_REFRESH_COMPANY"
  },
  GET_TRACKED_COMPANIES: {
    SUCCESS: "GET_TRACKED_COMPANIES_SUCCESS",
    ERROR: "GET_TRACKED_COMPANIES_ERROR"
  },
  SET_ACTIVE_TRACKED_COMPANIES: {
    SUCCESS: "SET_ACTIVE_TRACKED_COMPANIES_SUCCESS",
    ERROR: "SET_ACTIVE_TRACKED_COMPANIES_ERROR"
  },
  LIST_OF_COMPANY: {
    FILTER_SUCCESS: "FILTER_LIST_OF_DASHBOARD_COMPANY"
  },
  CLEAR: {
    //CLEAR_LIST_OF_COMPANIES: "CLEAR_LIST_OF_COMPANIES",
    CLEAR_LIST_OF_POLICY: "CLEAR_LIST_OF_COMPANIES"
  },
  CANCEL_SELECETED_COMPNAY: {
    SUCCESS: "CANCEL_SELECETED_COMPNAY_SUCCESS",
    ERROR: "CANCEL_SELECETED_COMPNAY_ERROR"
  },
  CANCEL_SELECETED_POLICY: {
    SUCCESS: "CANCEL_SELECETED_POLICY_SUCCESS",
    ERROR: "CANCEL_SELECETED_POLICY_ERROR"
  },
  HANDLE_EXCEPTION: {
    SUCCESS: "HANDLE_EXCEPTION_SUCCESS",
    ERROR: "HANDLE_EXCEPTION_ERROR",
    RESET: "HANDLE_EXCEPTION_RESET",
    MODAL: "HANDLE_EXCEPTION_MODAL",
    LOADER: "HANDLE_EXCEPTION_LOADER"
  },
  DOC_UPLOAD_HISTORY: {
    SUCCESS: "DOC_UPLOAD_SUCCESS",
    ERROR: "DOC_UPLOAD_ERROR"
  },
  GET_UPLOAD_HISTORY: {
    SUCCESS: "GET_UPLOAD_SUCCESS",
    ERROR: "GET_UPLOAD_ERROR"
  },
  DELETE_UPLOAD_HISTORY: {
    SUCCESS: "DELETE_UPLOAD_HISTORY",
    ERROR: "DELETE_UPLOAD_HISTORY"
  },
  GET_EXCEPTION_DATA: {
    SUCCESS: "GET_EXCEPTION_DATA_SUCCESS"
  },
  GET_PROJECTS_LIST: {
    SUCCESS: "GET_PROJECTS_LIST_SUCCESS",
    ERROR: "GET_PROJECTS_LIST_ERROR"
  },
  UPDATE_COMPLIANCE_STATUS: "UPDATE_COMPLIANCE_STATUS",
  SET_ACTIVE_PROJECT: "SET_ACTIVE_PROJECT",
  CLEAR_ACTIVE_PROJECT: "CLEAR_ACTIVE_PROJECT",
  // SET_ACTIVE_TRACKED_COMPANIES: "SET_ACTIVE_TRACKED_COMPANIES",
  SET_SHOW_INSURED_OPTION: "SET_SHOW_INSURED_OPTION",
  SET_SHOW_INSURED_LIST: "SET_SHOW_INSURED_LIST",
  SET_SHOW_INSURED_LIST_VALUE: "SET_SHOW_INSURED_LIST_VALUE",
  RESET_SHOW_INSURED_LIST_VALUE: "RESET_SHOW_INSURED_LIST_VALUE",
  SET_SEARCH_STRING: "SET_SEARCH_STRING",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
  RESET_REQUESTOR_DASHBOARD_COMPANY: "RESET_REQUESTOR_DASHBOARD_COMPANY",
  SET_COMPANY_LEVEL_FILTERS:"SET_COMPANY_LEVEL_FILTERS",
  SET_POLICY_LEVEL_FILTERS:"SET_POLICY_LEVEL_FILTERS",
  SET_DASHBOARD_DRILLDOWN_FILTERS:"SET_DASHBOARD_DRILLDOWN_FILTERS",
  RESET_FILTERS:"RESET_FILTERS"
};
