import React from "react";
import { Route, Redirect } from "react-router-dom";

import { _authService } from "../Utils/Services/AuthenticationService";
import { useSelector } from "react-redux";
import LoadSeoData from '../SeoData';
export const PrivateRoute = ({ Component, componentProps, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.app.currentSession);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return _authService.isUserAuthenticated() ? (
            <>
              <LoadSeoData/>
              <Component {...props} {...componentProps} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
        return <>
        <LoadSeoData/>
          <Component {...props} {...componentProps} />
          </>;
      }}
    />
  );
};
