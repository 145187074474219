import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import {
  AGENT_ADD_ACTION_TYPES,
  AGENT_LIST_ACTION_TYPES,
  AGENT_PROJECTS,
  CLEAR_AGENT_SIGNATURE_TYPE,
  EMAIL_ASSOCIATED_SHARABLE_COMPANIES,
  INVITE_AGENT_TYPES,
  UPLOAD_AGENT_SIGNATURE_TYPE,
  USER_PASSWORD_UPDATE_ACTION_TYPE,
  USER_PROFILE_ACTION_TYPES,
  USER_PROJECTS,
  USER_UPDATE_ACTION_TYPE,
  CERT_UPLOAD_STATUS
} from "./MyProfile.types";

const MyProfileInitialState = {
  isError: false,
  isDataAvailable: false,
  userData: {},
  role:
    _authService.getUserRole() &&
    _authService.getUserRole() === APPLICATION_USER_ROLES.AGENT
      ? _authService.getUserRole()
      : APPLICATION_USER_ROLES.REQUESTER,
  error: {},
  isAgentList: false,
  agentList: [],
  isAgentAdded: false,
  passwordUpdateSuccess: false,
  userUpdate: false,
  inviteAgentSuccess: false,
  userProjectsTemplates: [],
  agentProjectsTemplates: [],
  emailAssociatedSharableCompanies: [],
  uploadCoiAgencyDetails: []
};

const Base_MyProfileInitialState = { ...MyProfileInitialState };

const MyProfileReducer = (state = MyProfileInitialState, action) => {
  switch (action.type) {
    case USER_PROFILE_ACTION_TYPES.GET_USER_PROFILE.SUCCESS: {
      return {
        agentList: state.agentList,
        isError: false,
        isDataAvailable: true,
        userData: {
          ...action.payload,
          company:
            action.payload && action.payload.company
              ? { ...action.payload.company }
              : {},
          customer:
            action.payload && action.payload.customer
              ? {
                  ...action.payload.customer,
                  subscription: action.payload.customer.subscription
                    ? {
                        ...action.payload.customer.subscription
                      }
                    : {}
                }
              : {}
        },
        role:
          action.payload.role === APPLICATION_USER_ROLES.AGENT
            ? action.payload.role
            : APPLICATION_USER_ROLES.REQUESTER,
        error: {}
      };
    }
    case USER_PROFILE_ACTION_TYPES.GET_USER_PROFILE.ERROR: {
      return {
        isError: true,
        isDataAvailable: false,
        userData: {},
        error: action.payload,
        role: APPLICATION_USER_ROLES.REQUESTER
      };
    }
    case AGENT_LIST_ACTION_TYPES.GET_AGENT_LIST.SUCCESS: {
      const agentList = action.payload;
      return {
        ...state,
        isAgentList: (agentList && !!agentList.length) || false,
        agentList
      };
    }
    case AGENT_LIST_ACTION_TYPES.CLEAR_AGENT_LIST: {
      return {
        ...state,
        isAgentList: false,
        agentList: []
      };
    }
    case AGENT_ADD_ACTION_TYPES.AGENT_ADD_ACTION.SUCCESS: {
      const agency = action.payload;
      let newAGency = [];
      if (state.agentList && state.agentList.length) {
        newAGency = [...state.agentList, agency];
      } else {
        newAGency.push(agency);
      }

      return {
        ...state,
        isAgentList: true,
        agentList: newAGency
      };
    }
    case AGENT_ADD_ACTION_TYPES.AGENT_ADD_ACTION.ERROR: {
      return {
        ...state,
        isAgentAdded: false
      };
    }
    case INVITE_AGENT_TYPES.INVITE_AGENT_ACTION.SUCCESS: {
      return {
        ...state,
        inviteAgentSuccess: true
      };
    }
    case INVITE_AGENT_TYPES.INVITE_AGENT_ACTION.ERROR: {
      return {
        ...state,
        inviteAgentSuccess: false
      };
    }
    case USER_UPDATE_ACTION_TYPE.USER_UPDATE_ACTION.SUCCESS: {
      const requestData = action.payload;
      requestData.esignature = "";
      return {
        ...state,
        userData: {
          ...state.userData,
          ...requestData,
          zipCode: requestData.postalCode,
          thumbUrl: requestData.thumbUrl
        },
        userUpdate: true
      };
    }
    case USER_UPDATE_ACTION_TYPE.USER_UPDATE_ACTION.ERROR: {
      return {
        ...state,
        userUpdate: false
      };
    }
    case USER_PASSWORD_UPDATE_ACTION_TYPE.USER_PASSWORD_UPDATE_ACTION.SUCCESS: {
      return {
        ...state,
        passwordUpdateSuccess: true
      };
    }
    case USER_PASSWORD_UPDATE_ACTION_TYPE.USER_PASSWORD_UPDATE_ACTION.ERROR: {
      return {
        ...state,
        passwordUpdateSuccess: false,
        isError: true,
        error: action.payload
      };
    }
    case USER_PROFILE_ACTION_TYPES.CLEAR_MY_PROFILE: {
      return {
        ...Base_MyProfileInitialState
      };
    }
    case USER_PASSWORD_UPDATE_ACTION_TYPE.UPDATE_STATUS_ACTION.UPDATE: {
      return {
        ...state,
        passwordUpdateSuccess: false
      };
    }
    case CLEAR_AGENT_SIGNATURE_TYPE.CLEAR_AGENT_SIGNATURE.SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          esignature: "",
          thumbUrl: ""
        }
      };
    }
    case UPLOAD_AGENT_SIGNATURE_TYPE.UPLOAD_AGENT_SIGNATURE.SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          esignature: action.payload,
          thumbUrl: action.payload
        }
      };
    }
    case USER_PROJECTS.GET_PROJECT_TEMPLATES.SUCCESS: {
      const templates = action.payload;
      return {
        ...state,
        userProjectsTemplates: templates
      };
    }
    case AGENT_PROJECTS.GET_PROJECT_TEMPLATES.SUCCESS: {
      const templates = action.payload;
      return {
        ...state,
        agentProjectsTemplates: templates
      };
    }
    case USER_PROJECTS.GET_PROJECT_TEMPLATES.ERROR: {
      return state;
    }
    case USER_PROJECTS.CREATE_PROJECT_TEMPLATE.SUCCESS: {
      return state;
    }
    case USER_PROJECTS.CREATE_PROJECT_TEMPLATE.ERROR: {
      return state;
    }
    case USER_PROJECTS.UPDATE_PROJECT_TEMPLATE.SUCCESS: {
      return state;
    }
    case USER_PROJECTS.UPDATE_PROJECT_TEMPLATE.ERROR: {
      return state;
    }
    case USER_PROJECTS.DELETE_PROJECT_TEMPLATE.SUCCESS: {
      return state;
    }
    case USER_PROJECTS.DELETE_PROJECT_TEMPLATE.ERROR: {
      return state;
    }
    case USER_PROJECTS.CLEAR_PROJECT_TEMPLATES: {
      return { ...state, userProjectsTemplates: [] };
    }
    case AGENT_PROJECTS.CLEAR_PROJECT_TEMPLATES: {
      return { ...state, agentProjectsTemplates: [] };
    }
    case EMAIL_ASSOCIATED_SHARABLE_COMPANIES.SET: {
      return {
        ...state,
        emailAssociatedSharableCompanies: action.payload
      };
    }
    case EMAIL_ASSOCIATED_SHARABLE_COMPANIES.RESET: {
      return {
        ...state,
        emailAssociatedSharableCompanies: []
      };
    }
    case CERT_UPLOAD_STATUS.SET: {
      return {
        ...state,
        uploadCoiAgencyDetails: action.payload
      };
    }

    default:
      return state;
  }
};

export default MyProfileReducer;
