import React from 'react'
import { Icon, Alert } from 'antd';
import "./EmailComponents.less";

const SuccessMessage = ({title, subTitle})=>{
	return (<div className="validation-msg">
	<Alert
				banner
				showIcon={false}
				message={
					(<div>
						<p style={{ textAlign: "center" }}>
							<Icon style={{ fontSize: "45px", color: "#49ad17" }} type="check-circle" theme="filled" className="tickColor" />
						</p>
						<p className="color-primary title"
						>{title}</p>
						{subTitle ? <p className="sub-title">{subTitle}</p> : null}
						<p className="sub-title">You may now close this window.</p>
					</div>)}
			/>
			</div>)
}

export default SuccessMessage;