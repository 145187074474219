import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";

export const getAutomaticResponseOptInValidationData = (params = {}) => {
	return makeRequest({
		type: API_METHODS.POST,
		url: ENDPOINTS.AUTO_RESPONSE.VALIDATE_LINK,
		data: { ...params },
		options: {
			shouldNotUserAuthHeaders: true
		}
	});
}

export const setAutoResponseOptIn = (params = {}) => {
	return makeRequest({
		type: API_METHODS.POST,
		url: ENDPOINTS.AUTO_RESPONSE.OPT_IN,
		data: { ...params },
		options: {
			shouldNotUserAuthHeaders: true
		}
	});
}

export const setAutoResponseOptInWithRespond = (params = {}) => {
	return makeRequest({
		type: API_METHODS.POST,
		url: ENDPOINTS.AUTO_RESPONSE.OPT_IN_WITH_RESPOND,
		data: { ...params }
	});
}