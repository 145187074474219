import { API_METHODS } from "../Utils/Constants/API.constants";
import { axiosInstance } from "./Axios.instance";
import axios from "axios";
import { handleEventSource} from "../Utils/EventSource/EventSource";

import { deepTrim } from "../Utils/utility/app.utility";

export const makeRequest = async(request, autoRefreshPayload={}) => {
  let guid = null;
  if(autoRefreshPayload?.enable){
    guid = await handleEventSource(autoRefreshPayload.autoRefreshCallback, autoRefreshPayload.eventSourceList || []);
  }
  switch (request.type) {
    case API_METHODS.GET:
      return axiosInstance.get(request.url, request.options);
    case API_METHODS.PUT:
      if(guid && request?.data){
        request.data.guid = guid
      }
      return axiosInstance.put(
        request.url,
        deepTrim(request.data),
        request.options
      );
    case API_METHODS.POST:
        if(guid && request?.data){
          request.data.guid = guid
        }
      return axiosInstance.post(
        request.url,
        deepTrim(request.data),
        request.options
      );
    case API_METHODS.PATCH:
      return axiosInstance.patch(
        request.url,
        deepTrim(request.data),
        request.options
      );
    case API_METHODS.DELETE:
      return axiosInstance.delete(request.url, request.options);
    case API_METHODS.OPTIONS:
      return axiosInstance.options(request.url, request.options);
    case API_METHODS.HEAD:
      return axiosInstance.head(request.url, request.options);
    case API_METHODS.ALL:
      return axios.all(request.allRequests);
    case API_METHODS.JSON_FORM_DATA: {
      if(guid && request?.JsonPart){
          request.JsonPart.guid = guid
      }
      const formData = new FormData();
      formData.append(
        request.jsonPartkey ? request.jsonPartkey : "jsonPartRequest",
        JSON.stringify(deepTrim(request.JsonPart))
      );
      if (request.files && request.files.length) {
        request.files.forEach((file) => {
          formData.append(
            request.filesKey ? request.filesKey : "muitipartFile",
            file.originFileObj,
            file.originFileObj.name
          );
        });
      } else {
        formData.append(
          request.filesKey ? request.filesKey : "muitipartFile",
          null
        );
      }
      if (request.extraUploadedFiles && request.extraUploadedFiles.length) {
        request.extraUploadedFiles.forEach((file) => {
          formData.append(
            request.extraUploadedFilesKey
              ? request.extraUploadedFilesKey
              : "muitipartExtraFile",
            file.originFileObj,
            file.originFileObj.name
          );
        });
      }
      return axiosInstance[request.method](request.url, formData, {
        headers: { "content-type": "" },
        ...request.options
      });
    }
    default:
      return axiosInstance.request(request.options);
  }
};
