function appNotificationWorker() {
  var self = this;
  self.importScripts(
    "https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js"
  );
  // postMessage(notificationRequest());
  self.makeRequest = function (token, url, method, userKey) {
    // Create the XHR request
    var request = new XMLHttpRequest();

    // Return it as a Promise
    return new Promise(function (resolve, reject) {
      // Setup our listener to process compeleted requests
      request.onreadystatechange = function () {
        // Only run if the request is complete
        if (request.readyState !== 4) return;

        // Process the response
        if (request.status >= 200 && request.status < 300) {
          // window.localStorage.setItem();
          resolve(request);
        } else {
          // If failed
          reject({
            status: request.status,
            statusText: request.statusText
          });
        }
      };

      // Setup our HTTP request
      request.open(method || "GET", url, true);
      request.setRequestHeader("Authorization", token);
      request.setRequestHeader("userKey", userKey);
      request.setRequestHeader(
        "Access-Control-Expose-Headers",
        "JWT_REFRESHED"
      );
      request.getResponseHeader("JWT_REFRESHED");
      // Send the request
      request.send();
    });
  };

  self.onmessage = function (oEvent) {
    const { type, url, userId, param, authToken } = oEvent.data;

    if (userId) {
      let params = "";
      if (param) {
        params = param;
      } else {
        params = `page=1&size=1&userId=${userId}`;
      }
      self
        .makeRequest(authToken, url + "?" + params, type, userId)
        .then((req) => {
          return {
            headerInfo: req.getAllResponseHeaders(),
            data: JSON.parse(req.response).data
          };
        })
        .then(
          (res) => {
            const { headerInfo, data } = res;
            const { totalNotificationsCount, unreadNotificationCount } = data;
            if (param) {
              self.postMessage({
                status: "success",
                data
              });
            } else {
              self.postMessage({
                status: "success",
                totalNotificationsCount,
                unreadNotificationCount,
                headerInfo
              });
            }
          },
          (error) => {
            self.postMessage({ status: "error", error });
          }
        );
    }
    self.postMessage({
      status: "success"
    });
    // ;
    //
  };
}

export default class WebWorker {
  constructor(worker) {
    const code = appNotificationWorker;
    const blob = new Blob(["(" + code + ")()"]);
    return new Worker(
      URL.createObjectURL(blob, {
        type: "application/javascript; charset=utf-8"
      })
    );
  }
}
