import React, { useEffect, useState } from "react";
import { CERTIFICIAL, APPLICATION_USER_ROLES } from "../../Utils/Constants/Application.constants";
import { Col, Modal, Upload, message, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getBase64, showFile } from "../../Utils/utility/app.utility";
import {
  deleteAgentSignature,
  uploadSignature
} from "../../Store/Modules/MyProfile/MyProfile.asyncRequest";
import {
  downloadUploadedFile
} from "../../Store/Modules/Application/Application.asyncRequest";
import _ from "lodash";
import "./CreateSignatureButton.less";

const CreateSignatureButtons = ({ source, disableButtons = false, fileList, setFileList, openGenerateSignature, enableGenerateSignatureComponent, setSignatureDataUrl, agentInfo = null}) => {

  const { userData } = !!agentInfo ? {userData : agentInfo} : useSelector((state) => state.myProfile);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [signatureUrl, setSignatureUrl] = useState();
  const [fileMaxSizeError, setFileMaxSizeError] = useState(false);
  const [fileValidTypeError, setFileValidTypeError] = useState(false);
  const dispatch = useDispatch();
  const userRole = userData?.role;


  useEffect(() => {
    if (!userData) {
      return;
    }
    updateUserSignatureData(userData);
  }, [userData])


  const handleFileUploadCancel = () => setPreviewVisible(false);

  const handleFileDownload = (file) => {
    if (file && file.file) {
      showFile(file.file, "signature.png");
    } else if (signatureUrl) {
      downloadUploadedFile(signatureUrl).then(
        (res) => {
          showFile(res, "signature.png");
        },
        () => {
          message.error("Error in File download");
        }
      );
    }
  };

  const handleFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true);
  };

  const getFilebase64 = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      setSignatureDataUrl && setSignatureDataUrl(file.preview)
    }
    setPreviewImage(file.url || file.preview)
  };

  const handleFileUploadChange = async (info) => {
    if (!fileMaxSizeError && !fileValidTypeError) {
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);
      // 2. Read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          file.file = file.originFileObj;
        }
        return file;
      });
      setFileList(fileList);
      getFilebase64(info.file);
    }
  };

  const handlebeforeUpload = (file, fileList) => {
    let fileType = CERTIFICIAL.UPLOAD_FILES.UPLOAD_SIGN_SUPPORT_CHECK;
    let maxSize = CERTIFICIAL.UPLOAD_FILES.MAX_SIZE; // 20MB
    if (!_.includes(fileType, file.type)) {
      message.error("You can only upload valid image file.");
      setFileValidTypeError(true);
    } else if (file.size > maxSize) {
      setFileMaxSizeError(true)
      message.error(
        "File size must not exceed" +
        CERTIFICIAL.UPLOAD_FILES.MAX_SIZE_MESSAGE +
        "."
      );
    } else {
      setFileMaxSizeError(false);
      setFileValidTypeError(false);
    }
  };

  const deleteSignature = (file) => {
    if (userData.id && source=='myProfile') {
      dispatch(deleteAgentSignature(userData.id)).then(
        (res) => {
          setPreviewImage('');
          setSignatureUrl('')
          setSignatureDataUrl && setSignatureDataUrl(null)
        },
        (err) => {
          // this.setInitLoading(false);
        }
      );
    }else{
      setPreviewImage('');
      setSignatureUrl('')
      setSignatureDataUrl && setSignatureDataUrl(null)
    }
  };



  // Temproary Custome Request Handle For Upload Image
  const customUploadRequestHandle = ({ onSuccess, onError, file }) => {
    var reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = (...args) => {
      onSuccess("done", file);
    };
  };


  const getSignatureImage = (file) => {
    return downloadUploadedFile(file).then(
      (res) => {
        let base64String = btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        return "data:image/png;base64," + base64String;
      },
      () => {
        message.error("Error in File download");
      }
    );
  }

  const updateUserSignatureData = (data) => {

    if (!data.esignature) {
      return;
    }
    let signatureImage = "";
    getSignatureImage(data.esignature).then((res) => {
      signatureImage = res;
      const temp = [
        {
          uid: "-1",
          name: "No Signature",
          status: "done",
          url: signatureImage ? signatureImage : "",
          file: "",
          thumbUrl: signatureImage ? signatureImage : "",
          preview: signatureImage ? signatureImage : ""
        }
      ];
      setFileList([...temp])
      setPreviewImage(signatureImage);
      setSignatureUrl(data.esignature);
    });
  }

  const getGenerateSignatureClassNames = () => {
    const classNames = ["generate-signature-btn"]
    if (!disableButtons) {
      enableGenerateSignatureComponent && classNames.push("selected")
    }
    return classNames;
  }

  const UploadSignatureClassNames = () => {
    return "ant-upload-text"
  }

  const fileSizeAlertStyling = source == "myProfile" ?
    {
      color: "#000c"
    }
    :
    {
      color: "#000c",
      position: "relative",
      bottom: "60px",
      display: "inline-block",
      left: "15px"
    }
  const fileSizeAlert = <h5 style={fileSizeAlertStyling}>
    <span className="d-block">
      ** File size should be less than
      {CERTIFICIAL.UPLOAD_FILES.MAX_SIZE_MESSAGE}
    </span>
    <span>
      ** Supported file formats include JPG, PNG, JPEG
    </span>
  </h5>;
  const uploadButton = (
    <div>
      <div className={UploadSignatureClassNames()}>
        {userRole !== APPLICATION_USER_ROLES.AGENT
          ? "Upload Logo"
          : "Upload Signature"}
      </div>
    </div>
  );
  const generateSignatureBtn = userRole ===
    APPLICATION_USER_ROLES.AGENT && (
      <Button
        disabled={disableButtons}
        type="primary"
        onClick={openGenerateSignature}
        className={getGenerateSignatureClassNames()}
      >
        Generate Signature
      </Button>
    );

  return (<>
    <Col
      className="create-signature"
      span={24}
      style={
        userRole === APPLICATION_USER_ROLES.AGENT
          ? { minHeight: 120 }
          : {}
      }
    >
      {fileList?.length === 0 &&
        generateSignatureBtn}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleFileUploadCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewImage}
        />
      </Modal>
      {fileList?.length === 0 ? <div className="divider">Or</div> : null}
      {userRole === APPLICATION_USER_ROLES.AGENT ? (
        <div className="upload-signature">
          <Upload
            disabled={disableButtons}
            action="#"
            accept={
              CERTIFICIAL.UPLOAD_FILES.UPLOAD_SIGN_SUPPORT_CHECK
            }
            listType="picture-card"
            fileList={fileList}
            customRequest={customUploadRequestHandle}
            onPreview={handleFilePreview}
            onDownload={handleFileDownload}
            onChange={handleFileUploadChange}
            beforeUpload={handlebeforeUpload}
            onRemove={
              deleteSignature
              // !(this.state.role !== APPLICATION_USER_ROLES.AGENT)
            }
          >
            {fileList.length > 1
              ? null
              : fileList?.length === 0 &&
              uploadButton}
          </Upload>
        </div>
      ) : null}
      {source != "myProfile" ? (fileSizeAlert) : null}
    </Col>

    {(userRole === APPLICATION_USER_ROLES.AGENT && source == "myProfile") ? (
      <Col span={24}>
        {fileSizeAlert}
      </Col>
    ) : null}


  </>
  );

};

export default CreateSignatureButtons;





