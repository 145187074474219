import React from "react";
import certificial from "../../../Assets/Logos/certificial-logo-old.png";
import {
  grmsLogoImage,
  certLogoOldImage,
  corrigoLogoImage
} from "../../../Assets/assets";
import test1Logo from "../../../Assets/Logos/test1.png";
import { Typography, Avatar, Button, Icon } from "antd";
const { Text } = Typography;
export const LoginHeader = ({ user, componentStyle, source,onBackbuttonclick, enableForm }) => {
  switch (user) {
    case "grms":
      return (
        <div style={{ margin: "-12px", marginBottom: 12 }}>
          <Avatar
            src={grmsLogoImage}
            style={{ borderRadius: 0 }}
            size={120}
            shape="square"
          />

          <div style={{ float: "right" }}>
            <h2
              style={{
                // fontFamily: "Raleway",
                padding: 12,
                marginTop: 9,
                marginBottom: 6,
                fontSize: "1.35em",
                color: "#3596db"
              }}
            >
              Digital Insurance Verification
            </h2>
            <div style={{ textAlign: "right" }}>
              <Text style={{ paddingRight: 12, fontSize: 13 }}>Powered By</Text>
              <br />
              <div>
                <img src={certLogoOldImage} height={22} />
              </div>
            </div>
          </div>
        </div>
      );
    case "corrigo":
      return (
        <div style={{ margin: "-12px", marginBottom: 12 }}>
          <Avatar
            src={corrigoLogoImage}
            style={{ borderRadius: 0, width: 260, height: 100 }}
            size={120}
            shape="square"
          />

          <div style={{ float: "right", paddingTop: 24 }}>
            <div style={{ textAlign: "right" }}>
              <Text style={{ paddingRight: 21, fontSize: 13 }}>Powered By</Text>
              <br />
              <div style={{ paddingRight: 9 }}>
                <img src={certLogoOldImage} height={22} />
              </div>
            </div>
          </div>
        </div>
      );

    case "test":
      return (
        <div
          style={{
            height: 130,
            width: "100%",
            backgroundImage: "url(" + test1Logo + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            margin: "-12px"
          }}
        />
      );
    case "login":
      return (
        <div
        // style={{
        //   height: "57px",
        //   width: "100%",
        //   backgroundImage: "url(" + certificial + ")",
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "contain",
        //   marginTop: "24px",
        //   ...componentStyle
        // }}
        />
      );
    default:
      return (
        <div style={{position:"relative"}}>
          {enableForm && <Icon onClick={onBackbuttonclick} style={{fontSize:"28px", display:"inline",color:"#40a9ff", position:"absolute", marginLeft:"12px", top:"18%"}} type="left-circle"></Icon>}
              <div
          style={{
            height: "4vw",
            width: "21vw",
            backgroundImage: "url(" + certificial + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            marginTop: 7,
            marginBottom: 12,
            ...componentStyle
          }}
        />
        </div>
      );
  }
};
