import { Icon, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { memo } from "react";
import { CERTIFICIAL } from "../../Utils/Constants/Application.constants";
import { getBrowserName } from "../../Utils/utility/app.utility";
const { Text } = Typography;

const currentBrowser = getBrowserName();
const AMBestDisclaimer = memo(function AMBestDisclamer(props) {
  return (
    <Text style={{ cursor: "pointer" }}>
      <span>
        <Tooltip
          align={{
            points: ["bl", "tl"],
            offset: currentBrowser === "Chrome" ? [230, "-5"] : [215, "-5"],
            targetOffset: [0, 0],
            overflow: { adjustX: true, adjustY: true }
          }}
          destroyTooltipOnHide={true}
          title={
            <div style={{ color: "white", margin: 24, textAlign: "justify" }}>
              <p>
                Best's Credit Ratings™ are under continuous review and subject
                to change and/or affirmation. For the latest Best’s Credit
                Ratings™ and Best’s Credit Reports (which include Best’s Credit
                Ratings™), visit the A.M. Best website at{" "}
                <a
                  href=" http://www.ambest.com"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                  rel="noopener noreferrer"
                >
                  http://www.ambest.com
                </a>
                . See Guide to Best’s Credit Ratings™ for explanation of use and
                charges.
              </p>{" "}
              <p>
                Best's Credit Ratings™ reproduced herein appear under license
                from A.M. Best and do not constitute, either expressly or
                impliedly, an endorsement of Certificial Inc or its
                recommendations, formulas, criteria or comparisons to any other
                ratings, rating scales or rating organizations which are
                published or referenced herein. A.M. Best is not responsible for
                transcription errors made in presenting Best's Credit Ratings™.
                Best’s Credit Ratings™ are proprietary and may not be reproduced
                or distributed without the express written permission of A.M.
                Best Company.
              </p>{" "}
              <p>
                A Best’s Financial Strength Rating opinion addresses the
                relative ability of an insurer to meet its ongoing insurance
                obligations. It is not a warranty of a company’s financial
                strength and ability to meet its obligations to policyholders.
                View our Important Notice: Best's Credit Ratings for a
                disclaimer notice and complete details at{" "}
                <a
                  href="http://www.ambest.com/ratings/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  http://www.ambest.com/ratings/index.html
                </a>
                .
              </p>
            </div>
          }
          overlayStyle={{ maxWidth: "50%" }}
        >
          <Icon
            type="info-circle"
            theme="twoTone"
            twoToneColor="#3596db"
            className="margin-right-sm"
          />
          AM Best Ratings Last Updated:{" "}
          {moment().format(CERTIFICIAL.APP_FORMAT.MM_DD_YYYY)}
        </Tooltip>
      </span>
    </Text>
  );
});

export default AMBestDisclaimer;
