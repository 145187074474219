import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Card,
  Col,
  Row,
  Alert,
  Divider,
  Icon
} from "antd";
import certificial from "../../../Assets/Logos/certificial-logo-old.png";
import { forgotPassword_action } from "../../../Store/Modules/Application/Application.asyncRequest";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class ForgotPassword extends Component {
  state = {
    confirmDirty: false,
    errorMessage: "",
    forgotPasswordError: false,
    iconLoading: false
  };

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields((errors, values) => {});
  }

  logoStyle = {
    height: "57px",
    width: "100%",
    backgroundImage: "url(" + certificial + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "24px"
  };

  getLoader = () => {
    this.setState({ iconLoading: true });
  };

  handleSubmit = (e) => {
    this.setState({ forgotPasswordError: false });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { email } = values;
        let emailAddress = values && {
          ...values,
          email: email && email.trim()
        };
        forgotPassword_action(emailAddress).then(
          (response) => {
            this.setState({ iconLoading: false });
            this.setState({ forgotPasswordError: false });
            this.props.history.push("/forgot-password-success");
          },
          (error) => {
            this.setState({ iconLoading: false });
            this.setState({ forgotPasswordError: true });
            this.setState({ errorMessage: error.message });
          }
        );
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const emailError = isFieldTouched("email") && getFieldError("email");

    return (
      <React.Fragment>
        <Row type="flex" align="middle" justify="center">
          <Col span={8}>
            <Card size="small">
              <div style={this.logoStyle} />
              <Divider type="horizontal" dashed style={{ margin: "12px 0" }} />
              <Form onSubmit={this.handleSubmit}>
                <Form.Item
                  className="p-12"
                  validateStatus={emailError ? "error" : ""}
                  help={emailError || ""}
                  label="E-mail"
                  extra={
                    <p>
                      {" "}
                      <Icon type="info-circle" /> Please enter your registered
                      email address.
                    </p>
                  }
                >
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        transform: (value) => (value && value.trim()) || null
                      },
                      {
                        pattern: CERTIFICIAL.REGEX_PATTERNS.EMAIL,
                        whitespace: true,
                        message: "Invalid email address!"
                      },
                      {
                        required: true,
                        message: "Please enter your email address!"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>

                {!!this.state.forgotPasswordError && this.state.errorMessage && (
                  <div className="p-12" style={{ marginBottom: 12 }}>
                    <Alert message={this.state.errorMessage} type="error" />
                  </div>
                )}

                <Form.Item className="p-12" style={{ marginTop: 12 }}>
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                    loading={this.state.iconLoading}
                    onClick={this.getLoader}
                  >
                    Send
                  </Button>
                </Form.Item>
                <Divider type="horizontal" dashed />
                <Row>
                  <Col
                    span={24}
                    className="p-12"
                    style={{
                      textAlign: "center",
                      paddingBottom: 12
                    }}
                  >
                    Back to
                    <Link to="/login"> Login</Link>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const forgotPassword = withRouter(
  Form.create({ name: "forgotPassword" })(ForgotPassword)
);

export default forgotPassword;
