import React, { useEffect, useState } from "react";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";

import {useSelector, shallowEqual, useDispatch } from "react-redux";
import CreateSignatureButtons from "../../../Components/MyProfile/CreateSignatureButtons";
import {APPLICATION_MESSAGES} from "../../../Utils/Constants/ApplicationMessages.constants";
import { Checkbox, Row } from "antd";
import { setGenerateSignature } from "../../../Store/Modules/SmartCoi/SmartCoi.action";
import GenerateSignatureModal from "./GenerateSignatureModal";

const UploadSignature = ({source, setSignatureDataUrl, setSignatureData, signatureData={}, agentInfo = null}) => {

  const dispatch = useDispatch();
  const { userData } = !!agentInfo ? {userData : agentInfo} : useSelector((state) => state.myProfile, shallowEqual);
  const [fileList, setFileList] = useState(signatureData.fileList || []);
  const [agreeESign, setAgreeESign] = useState(signatureData?.agreeESign || false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(signatureData.selectedSuggestion || null);
  const [enableGenerateSignatureComponent, setEnableGenerateSignatureComponent] = useState(signatureData?.enableGenerateSignatureComponent || false);

  const isGenerateSignatureVisible = enableGenerateSignatureComponent && agreeESign && !fileList.length
  useEffect(()=>{
    return ()=>{dispatch(setGenerateSignature(false))}
  },[])

  useEffect(()=>{
    if(source !== CERTIFICIAL.SHARE_VIEW.SMART_COI){
      return;
    }

    setSignatureData({
      fileList,
      agreeESign,
      selectedSuggestion,
      enableGenerateSignatureComponent
    })
    
  },[fileList, agreeESign, selectedSuggestion, enableGenerateSignatureComponent])

  const clickAgreeESign = (e)=>{
    setAgreeESign(e.target.checked);
    if(!e.target.checked){
      setFileList([]);
      setSelectedSuggestion(null);
      setSignatureDataUrl(null);
      dispatch(setGenerateSignature(false));
    }else{
      dispatch(setGenerateSignature(true));
    }
  }


  const suggSelection = (selectedSuggestion)=>{
    setSelectedSuggestion(selectedSuggestion);
    setSignatureDataUrl(selectedSuggestion?.dataURI)
    fileListHandler(
      [
        {
          uid: "-1",
          name: "No Signature",
          status: "done",
          url: selectedSuggestion?.dataURI,
          file: "",
          thumbUrl: selectedSuggestion?.dataURI,
          preview: selectedSuggestion?.dataURI
        }
      ]
    )

  }



  const fileListHandler = (file) =>{
    setEnableGenerateSignatureComponent(false);
    setSelectedSuggestion(null)
    setFileList(file)
  }


  return(<>
    <Checkbox
      onChange={clickAgreeESign}
      className="agree-wrapper"
      checked={agreeESign}
    >
      {APPLICATION_MESSAGES.APP.SIGNATURE.IAGREE}
    </Checkbox>
    <Row className="upload-signature-panel">
       <CreateSignatureButtons agentInfo={agentInfo} setSignatureDataUrl={setSignatureDataUrl} fontSize={"15px"} dividerStyle={{top:"55px"}} cardStyle={{width:"140px", height:"140px", fontSize:"15px"}} disableButtons={!agreeESign} fileList={fileList} setFileList={fileListHandler} openGenerateSignature={setEnableGenerateSignatureComponent} enableGenerateSignatureComponent={enableGenerateSignatureComponent} />
    </Row>
    {isGenerateSignatureVisible && <GenerateSignatureModal userData={userData} onCancel={()=>setEnableGenerateSignatureComponent(false)} onUploadGenerateSignature={suggSelection}/>}
  </>);
 
};

export default UploadSignature;
