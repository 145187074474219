export const STORAGE = {
  getItem: (key) => {
    return (
      (localStorage.getItem(key) !== "undefined" &&
        JSON.parse(localStorage.getItem(key))) ||
      null
    );
  },

  setItem: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  clearItem: (key) => localStorage.removeItem(key)
};
