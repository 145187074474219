import React from "react";
import { Tooltip, Button, Card, Icon, Spin, Typography, Collapse, Checkbox } from "antd";
import MultipleFileUpload from "../../../Components/MultipleFileUpload/MultipleFileUpload";
import { APPLICATION_USER_ROLES, CERTIFICIAL, CERT_REQUEST_PRIORITY } from "../../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import { useState } from "react";
import { TransactionList } from "./TransactionList";
import { shallowEqual, useSelector } from "react-redux";
const { Meta } = Card;
const { Text } = Typography;
const { Panel } = Collapse;
const { STANDARD, PRIORITY } = CERT_REQUEST_PRIORITY

export const CertificateUploadContainer = ({
  handleFileUpload,
  certificateFile,
  additionalFilesWithInsurance,
  onCertUploadSubmitClick,
  loading,
  error = false,
  requestId = null,
  showDuplicateTransactions = true,
  isSmartCoiFilePresent,
  source,
  isEmailCoiUpload,
  insuredCompanyName,
  childComponenet
}) => {

  const isRespondFlow = source == CERTIFICIAL.SHARE_VIEW.RESPOND;
  const [load, setLoading] = useState(false);
  const [priorityRequest, setPriorityRequest] = useState(STANDARD);
  const { role } = useSelector((state) => state.app.currentSession, shallowEqual);
  const showPriorityIndicator = role === APPLICATION_USER_ROLES.REQUESTER;
 
  
  const cardActionStyle = {
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    boxShadow: "none"
  };

  const wrapperStyle = isRespondFlow ? {padding:"0px", marginTop:"0px"}:{}

  const priorityRequestChangeHandler = (e)=>{
    setPriorityRequest(e.target.checked? PRIORITY : STANDARD);
  }

  let uploadCoiTitleText = 'Please upload a current certificate of Insurance which meets the requested requirements in PDF format';
  if(isEmailCoiUpload && insuredCompanyName){
    uploadCoiTitleText = <span>{uploadCoiTitleText} for <b>{insuredCompanyName}</b>.</span>
  }else{
    uploadCoiTitleText = <h4 className="margin-0">{uploadCoiTitleText+'.'}</h4>
  }
  const component = !error ? (<div style={wrapperStyle} className="policy-respond-container-wrapper steps-content">
    {!isSmartCoiFilePresent && showDuplicateTransactions ? <TransactionList requestId={requestId} setLoading={setLoading}/> : null}
   <div className="file-upload-wrapper">
    <div style={{ margin: "10px 10px" }}>
      {isSmartCoiFilePresent ? 
    <h4 className="margin-0">
          Thank you for submitting a COI for this request. Our team will notify you as soon as it's been processed. To save time in future, please consider enabling Smart COIs for your client.
    </h4>
    :
       <>
       {uploadCoiTitleText}
       {showPriorityIndicator ? <div className="margin-top-sm">
          <Checkbox checked={priorityRequest == PRIORITY ? true : false} onChange={priorityRequestChangeHandler} className="priority-request">Priority Request</Checkbox>
          <Tooltip
          destroyTooltipOnHide={true}
          title={
            "Use this option for priority requests only"
          }
          overlayStyle={{ maxWidth: "50%" }}
        >
          <Icon
            className="margin-left-xs"
            type="info-circle"
            theme="twoTone"
            twoToneColor="#3596db"
          />
        </Tooltip>
       </div> : null}
      </>}
      <div className="margin-top-md">
        <MultipleFileUpload
          accept={CERTIFICIAL.UPLOAD_FILES.ONLY_PDF_SUPPORTED_FILE_EXTENTIONS}
          supportedFormats={".PDF "}
          defaultFileList={certificateFile}
          fileList={certificateFile}
          customSizeMessage={true}
          multi={false}
          isSmartCoiFilePresent={isSmartCoiFilePresent}
          handleChange={(file) => handleFileUpload(file, "CERT_INS")}
        />
      </div>
    </div>
      <div style={{ padding: "10px 10px" }}>

        <Collapse
          expandIcon={({ isActive }) => (
            <Icon
              type="down-circle"
              className="color-primary"
              theme="filled"
              style={{ fontSize: "16px", left: "0px", top: "50%" }}
              rotate={isActive ? 180 : 0}
            />
          )}
          bordered={false}>
          <Panel header={<Text className="d-block">
            {
              APPLICATION_MESSAGES.SHARE_MY_POLICY
                .ADDITIONAL_DOCUMENT_ATTACH_LABEL(isEmailCoiUpload)
            }
          </Text>} key="1">
            <MultipleFileUpload
              accept={CERTIFICIAL.UPLOAD_FILES.ALL_SUPPORTED_FILE_EXTENTIONS}
              supportedFormats={".JPEG, .PNG, .GIF, .PDF "}
              defaultFileList={additionalFilesWithInsurance}
              fileList={additionalFilesWithInsurance}
              multi={true}
              handleChange={(files) => handleFileUpload(files, "CERT_ADD")}
              isadditionalDocument={true}
            />

          </Panel>
        </Collapse>
        {isEmailCoiUpload && childComponenet !== undefined? (childComponenet):null}
      </div>
    </div>
    
    <div className={isEmailCoiUpload?"steps-steps-action-email":"steps-action"}>
      <div style={{ float: "right"}}>
        <Button
          type="primary"
          disabled={(certificateFile && !certificateFile.length) || loading}
          ghost
          icon={loading ? "loading" : ""}
          onClick={()=>onCertUploadSubmitClick(priorityRequest)}
        >
          Submit
        </Button>
      </div>
    </div>
  </div>) : (<div className="policy-respond-container-wrapper steps-content">
    <Card
      style={{ width: "100%", marginTop: 16 }}
      actions={[
        <Button icon="cross" type="link" style={cardActionStyle}>
          Cancel
        </Button>,
        <Button icon="reload" type="link" style={cardActionStyle}>
          Retry
        </Button>,
        <Button icon="check" type="link" style={cardActionStyle}>
          Continue
        </Button>
      ]}
    >
      <Meta
        title={
          <Text type="danger">
            <Icon style={{ margin: "0 3px" }} type="cross" />
            Cert Processing error
          </Text>
        }
        description={
          "Unable to process Certificate of Insurance. To ensure compliance,please upload a certificate in PDF format which is not a scanned or faxed image,If you wish to proceed with the orognal Certificate provided click Continue."
        }
      />
    </Card>
  </div>);

  return (
    <Spin
      wrapperClassName="coi-upload"
      indicator={
        <Icon
          type="loading-3-quarters"
          className="color-primary fs-24x"
          spin
        />
      }
      spinning={load}
    >
      {component}
    </Spin>)
};
