import React from "react";
import { CslCheckbox } from "../CslCheckBox/CslCheckbox";
import { AppliedPolicyType } from "../AppliedPolicyType/AppliedPolicyType";
import { CERTIFICIAL_POLICY_TYPES, REGION_ID } from "../../../Utils/Constants/Application.constants";
import './AppliedPolicyTypeOrCslCheckBox.less'

export const AppliedPolicyTypeOrCslCheckBox = ({
	policy,
	regionId,
	countryId,
	onAppliedPolicyTypeCheck,
	onClsCheck,
	readOnlyMode,
	showCslTooltip,
	isAgentScreen,
	isEmailCoiUpload
}) => {

	const shouldShowCslCheckbox = () => {
		return (policy.name == CERTIFICIAL_POLICY_TYPES.AUTOMOBILE_LIABILITY) || (policy.policytype == CERTIFICIAL_POLICY_TYPES.AUTOMOBILE_LIABILITY) ;
	}

	const shouldShowAppliedPolicyType = () => {
		return policy.appliedPolicyType;
	}

	const isAppliedPolicyOrClsCheckBoxPresent = () => {
		if((regionId === REGION_ID.US) || (countryId === REGION_ID.US)){
			return (shouldShowCslCheckbox() || shouldShowAppliedPolicyType());
		}
		return false;
	}

	return (
		 isAppliedPolicyOrClsCheckBoxPresent() ? 
			(
			<div className={"chs-Checkbox-Container"}>
				{shouldShowCslCheckbox() ? <CslCheckbox isEmailCoiUpload = {isEmailCoiUpload || undefined} isAgentScreen={isAgentScreen} showCslTooltip={showCslTooltip} policyLimits={policy.limitTypes} checked={policy.acceptCslOrSplitLimits} readOnlyMode={readOnlyMode} onClsCheck={onClsCheck}/> : null}
				{shouldShowAppliedPolicyType() ?
					<AppliedPolicyType
						appliedPolicyType={policy.appliedPolicyType}
						readOnlyMode={readOnlyMode}
						onAppliedPolicyTypeCheck={onAppliedPolicyTypeCheck}
					/> : null}
			</div>
		) : null
	);
}
