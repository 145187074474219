export const REQUESTOR_REQUEST_POLICY_TYPES = {
  CREATE_DUMMY_REQUESTS: "CREATE_DUMMY_REQUESTS",
  CLEAR_DUMMY_REQUESTS: "CLEAR_DUMMY_REQUESTS",
  GET_ALL_REQUEST_OF_VERIFICATIONS: {
    SUCCESS: "GET_ALL_REQUEST_OF_VERIFICATIONS_SUCCESS",
    ERROR: "GET_ALL_REQUEST_OF_VERIFICATIONS_ERROR"
  },
  GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS: {
    SUCCESS: "GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS_SUCCESS",
    ERROR: "GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS_ERROR"
  },
  CREATE_REQUEST_OF_VERIFICATION: {
    SUCCESS: "CREATE_REQUEST_OF_VERIFICATION_SUCCESS",
    ERROR: "CREATE_REQUEST_OF_VERIFICATION_ERROR"
  },
  DELETE_REQUEST_OF_VERIFICATION: {
    SUCCESS: "DELETE_REQUEST_OF_VERIFICATION_SUCCESS",
    ERROR: "DELETE_REQUEST_OF_VERIFICATION_ERROR"
  },
  CLEAR_REQUEST_OF_VERIFICATIONS: "CLEAR_REQUEST_OF_VERIFICATIONS",
  RESET_SELECTED_VERIFICATION_DETAILS: "RESET_SELECTED_VERIFICATION_DETAILS",
  IS_DATA_AVAILABLE: "IS_DATA_AVAILABLE",
  GET_PROJECTS_LIST: {
    SUCCESS: "DASHBOARD_GET_PROJECTS_LIST_SUCCESS",
    ERROR: "DASHBOARD_GET_PROJECTS_LIST_ERROR"
  },
  SET_ACTIVE_PROJECT: "REQUEST_SET_ACTIVE_PROJECT",
  CLEAR_ACTIVE_PROJECT: "REQUEST_CLEAR_ACTIVE_PROJECT"
};
