import React, { useEffect } from "react";
import { Alert, Card, Typography, Row, Table } from "antd";
import { getTransactionList } from "../../../Store/Modules/UploadCert/UploadCert.asyncRequest"
import { useState } from "react";


export const TransactionList = ({
  requestId = null,
  setLoading,
  setIsTransactionExist
}) => {

  const [transactionList, setTransactionList] = useState([])
  const columns = [{ key: 'transactionId', dataIndex: 'transactionId', title: 'Transaction ID' }, { key: 'fileName', dataIndex: 'fileName', title: 'File Name' }];
  const transactionListBlock = transactionList?.length ? (
    <div className="transaction-list" style={{ margin: "10px 10px" }}>
      <Row>The following COIs have been uploaded for processing:</Row>
      <Table scroll={{ y: 150 }} size="small" style={{ margin: "15px 0px 50px 0px" }} bordered pagination={false} dataSource={transactionList} columns={columns} />
      <Row>
        Have additional coverage to evidence for this request? Upload a COI below to create a new transaction.</Row>
      <Row>
        <Alert
          style={{ margin: "5px 0px 15px 0px" }}
          message="NOTE: To avoid processing delays and compliance related issues, please make sure each uploaded COI is unique."
          type="info"
          showIcon
        />
      </Row>
    </div>
  ) : null;

  const setTransactionsData = async () => {
    setLoading(true);
    try {
      const transactionListResponse = await getTransactionList(requestId);
      setTransactionList(transactionListResponse);
      setIsTransactionExist && setIsTransactionExist(!!transactionListResponse?.length)
    }
    catch (err) {
      setTransactionList([]);
    }
    finally {
      setLoading(false)
    }
  }

  const init = ()=>{
    setTransactionsData();
  }

  useEffect(() => {
    init();
  }, [])


  
  return (transactionList?.length ?  <div className="transaction-list" style={{ margin: "10px 10px" }}>
  <Row>The following COIs have been uploaded for processing:</Row>
  <Table scroll={{ y: 150 }} size="small" style={{ margin: "15px 0px 50px 0px" }} bordered pagination={false} dataSource={transactionList} columns={columns} />
  <Row>
    Have additional coverage to evidence for this request? Upload a COI below to create a new transaction.</Row>
  <Row>
    <Alert
      style={{ margin: "5px 0px 15px 0px" }}
      message="NOTE: To avoid processing delays and compliance related issues, please make sure each uploaded COI is unique."
      type="info"
      showIcon
    />
  </Row>
</div> : null)
};
