import React from "react";
import { Popover, Icon, Typography, Divider } from "antd";
const { Paragraph, Text } = Typography;
export const PasswordStrength = () => {
  return (
    <Popover
      placement="bottomRight"
      destroyTooltipOnHide={true}
      arrowPointAtCenter
      content={
        <div className="password-criteria-container">
          <Paragraph className="margin-0">
            <Text
              style={{
                fontSize: 14
              }}
            >
              Password Criteria
            </Text>
          </Paragraph>
          <Divider dashed style={{ margin: "6px 0" }} />
          <Paragraph className="margin-0">
            <Icon
              style={{ color: "#49ad17", marginRight: 6 }}
              type="check-circle"
            />{" "}
            At least 8 characters
          </Paragraph>
          <Paragraph className="margin-0">
            <Icon
              style={{ color: "#49ad17", marginRight: 6 }}
              type="check-circle"
            />{" "}
            At least 1 letter (a, b, c...)
          </Paragraph>
          <Paragraph className="margin-0">
            <Icon
              style={{ color: "#49ad17", marginRight: 6 }}
              type="check-circle"
            />{" "}
            At least 1 capital letter (A, B, C...)
          </Paragraph>
          <Paragraph className="margin-0">
            <Icon
              style={{ color: "#49ad17", marginRight: 6 }}
              type="check-circle"
            />{" "}
            At least 1 number (1, 2, 3...)
          </Paragraph>
          <Paragraph className="margin-0">
            <Icon
              style={{ color: "#49ad17", marginRight: 6 }}
              type="check-circle"
            />{" "}
            At least 1 special character ($, @, %...)
          </Paragraph>
        </div>
      }
    >
      <Icon
        type="info-circle"
        style={{
          color: "#3596db",
          verticalAlign: "bottom"
        }}
      />
    </Popover>
  );
};
