import { TRACKED_POLICIES_ACTION_TYPES } from "./RequestorDashboard.types";
import * as _ from "lodash";

const policyLevelFiltersInit = {
  expiryDuration:[],
  policyStatus:[],
  endorsements:[]
}


const companyLevelFiltersInit = {
  companyRecords:[],
  isReviewed:[],
  companyStatus:[],
  policyTypes:[],
  templates:[],
  projectNameFilter:[],
  agentAssignFilter:[]
}  



const requestorDashboardIitialState = {
  trackedCompanies: [],
  trackedFilterCompanies: [],
  selectedCompany: {},
  isError: false,
  isDataAvailable: false,
  // handleExceptionStatus: false,
  // handleExceptionErrorStatus: false,
  previousExceptionData: {},
  initLoadingSpinner: false,
  exceptionalModal: {
    showModal: false,
    policyDetails: []
  },
  insuredList: {
    showInsuredOption: false,
    showInsuredList: false,
    insuredListValue: {
      namedInsuredsAndClientList: [],
      totalCount: 0
    }
  },
  activeProject: null,
  projectsForSelectedCompany: [],
  refresh_companyId: null,
  totalTrackedCompaniesCount: 0,
  isFilterApplied: false,
  searchString: null,
  pageNumber: 1,
  companyLevelFilter:companyLevelFiltersInit,
  policyLevelFilter:policyLevelFiltersInit
};

const Base_requestorDashboardIitialState = { ...requestorDashboardIitialState };

const RequestorDashboardReducer = (
  state = requestorDashboardIitialState,
  { type, payload }
) => {
  switch (type) {
    case TRACKED_POLICIES_ACTION_TYPES.DUMMY_TRACKED_COMPANIES: {
      return {
        ...state,
        trackedCompanies: state.trackedCompanies.concat(
          [...new Array(payload)].map(() => ({
            loading: true,
            name: {}
          }))
        )
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.GET_TRACKED_COMPANIES.SUCCESS: {
      const { handle, companyRecords, trackedCompanies, companyCount=0, newCompanyId } = payload;
      const selectedCompany = newCompanyId ? trackedCompanies.find(company=>company?.companyId===newCompanyId) : _.head(trackedCompanies);
      return {
        ...state,
        trackedCompanies,
        totalTrackedCompaniesCount:
          companyRecords && companyRecords.length && handle
            ? state.totalTrackedCompaniesCount
            : companyCount || 0,
        trackedFilterCompanies: [],
        selectedCompany,
        isDataAvailable: trackedCompanies && !!trackedCompanies.length
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.GET_TRACKED_COMPANIES.ERROR: {
      return { state, isDataAvailable: false };
    }
    case TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_TRACKED_COMPANIES: {
      const trackedCompanies = _.map(state.trackedCompanies, (obj) => {
        return { ...obj, ...{ isActive: obj.companyId === payload } };
      });
      return {
        ...state,
        trackedCompanies: trackedCompanies,
        selectedCompany: _.find(trackedCompanies, function (o) {
          if(payload?.companyId){
            return o.companyId === payload.companyId;
          }
          return o.companyId === payload;
        }),
        activeProject: null

      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.CLEAR_TRACKED_COMPANIES: {
      return { ...Base_requestorDashboardIitialState };
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_TRACKED_COMPANIES.SUCCESS: {
      let selectedCompany = {
        ..._.head(
          state.trackedCompanies.filter((obj) => {
            return (
              payload &&
              payload.policies_data &&
              obj.companyId === payload._companyId
            );
          })
        ),
        policies: (payload && payload.policies_data) || [],
        ..._.omit(payload, "policies_data")
      };

      const newTrackedCompanies = _.map(state.trackedCompanies, (obj) => {
        return {
          ...obj,
          ...{
            isActive: obj.companyId === selectedCompany.companyId
          }
        };
      });

      const selectedCompanyFilter = {
        ..._.head(
          state.trackedFilterCompanies &&
            state.trackedFilterCompanies.filter((obj) => {
              return (
                payload &&
                payload.policies_data &&
                obj.companyId === payload.policies_data[0].companyId
              );
            })
        ),
        policies:  ((payload && payload.policies_data) || []).sort((a, b) => (a?.policyType || "").localeCompare(b.policyType)),
        ..._.omit(payload, "policies_data")
      };

      const newFilterCompanies = _.map(state.trackedFilterCompanies, (obj) => {
        return {
          ...obj,
          ...{
            isActive: obj.companyId === selectedCompanyFilter.companyId
          }
        };
      });

      if (payload.shouldAddSelectedCompany) {
        const _selectedCompaniesDetails = _.filter(
          state.trackedCompanies,
          (company) => {
            return company.companyId === selectedCompany.companyId;
          }
        )[0];

        selectedCompany = {
          ...selectedCompany,
          ..._selectedCompaniesDetails
        };
      }

      return {
        ...state,
        selectedCompany: selectedCompany,
        trackedCompanies: newTrackedCompanies,
        trackedFilterCompanies: newFilterCompanies,
        isDataAvailable: newTrackedCompanies && !!newTrackedCompanies.length
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_TRACKED_COMPANIES.ERROR: {
      return {
        ...state,
        selectedCompany: {}
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.LIST_OF_COMPANY.FILTER_SUCCESS: {
      let newCompanyList = [];
      if (payload && payload.length) {
        payload.map((e1) => {
          newCompanyList.push(
            ..._.filter(state.trackedCompanies, (e2) => {
              return e2.companyId === e1;
            })
          );
        });
      } else {
        state.trackedFilterCompanies = [];
      }

      return {
        ...state,
        trackedFilterCompanies: [...newCompanyList],
        isDataAvailable: newCompanyList && !!newCompanyList.length
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.SUCCESS: {
      return { ...state, initLoadingSpinner: false };
    }
    case TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.ERROR: {
      return { ...state, initLoadingSpinner: false };
    }

    case TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.LOADER: {
      return { ...state, initLoadingSpinner: payload };
    }
    case TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.RESET: {
      return {
        ...state,
        initLoadingSpinner: false
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.GET_EXCEPTION_DATA.SUCCESS: {
      return {
        ...state,
        previousExceptionData: payload
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.MODAL: {
      if (payload && payload.showModal) {
        return {
          ...state,
          exceptionalModal: {
            showModal: true,
            policyDetails: payload.policyDetails,
            selectedAddtionalRemarks: payload.selectedAddtionalRemarks
          }
        };
      } else if (payload && !payload.showModal) {
        return {
          ...state,
          exceptionalModal: {
            showModal: false,
            policyDetails: [],
            selectedAddtionalRemarks: null
          }
        };
      } else {
        return state;
      }
    }
    case TRACKED_POLICIES_ACTION_TYPES.DOC_UPLOAD_HISTORY.SUCCESS: {
      return {
        ...state,
        historyDetails: []
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.DOC_UPLOAD_HISTORY.ERROR: {
      return {
        ...state,
        historyDetailsError: []
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.DELETE_UPLOAD_HISTORY.SUCCESS: {
      return { ...state, deleteHistorySuccess: [] };
    }
    case TRACKED_POLICIES_ACTION_TYPES.DELETE_UPLOAD_HISTORY.ERROR: {
      return { ...state, deleteHistoryError: [] };
    }
    case TRACKED_POLICIES_ACTION_TYPES.GET_UPLOAD_HISTORY.SUCCESS: {
      return {
        ...state,
        getHistoryDetails: payload
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.GET_UPLOAD_HISTORY.ERROR: {
      return {
        ...state,
        getHistoryDetails: []
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.UPDATE_COMPLIANCE_STATUS: {
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          certHolder:
            (state.selectedCompany.certHolder &&
              state.selectedCompany.certHolder.length && [
                { ...state.selectedCompany.certHolder[0], is_reviewed: payload }
              ]) ||
            []
        }
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_COMPNAY.SUCCESS: {
      const newTrackedCompanies = _.map(payload, (obj) => {
        return {
          ...obj
        };
      });
      return {
        ...state,
        trackedCompanies: newTrackedCompanies,
        isDataAvailable: newTrackedCompanies && newTrackedCompanies.length
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_COMPNAY.ERROR: {
      return {
        ...state,
        selectedCompany: {},
        isDataAvailable: false
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_POLICY.SUCCESS: {
      let changedPolicyList = [];
      let newCompanies = [];
      let _selectedCompany = { policies: [] };
      if (state?.selectedCompany?.policies?.length) {
        changedPolicyList = _.filter(state.selectedCompany.policies, (item) => {
          return payload.policyId !== "empty"
            ? item.policyId !== payload.policyId
            : item.policyTypeId !== payload.missingPolicyTypeId;
        });
        if (
          changedPolicyList.length === 0 &&
          state.selectedCompany &&
          state.selectedCompany.projectId &&
          state.selectedCompany.projectId.length === 1
        ) {
          newCompanies = _.filter(state.trackedCompanies, (item) => {
            return (
              item.companyId !== state.selectedCompany.policies[0].companyId
            );
          });
        } else {
          newCompanies = state.trackedCompanies;
        }

        _selectedCompany = { policies: changedPolicyList };
      }
      return {
        ...state,
        trackedCompanies: newCompanies,
        selectedCompany: { ...state.selectedCompany, ..._selectedCompany }
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_POLICY.ERROR: {
      return {
        ...state,
        selectedCompany:
          state && state.isFilterApplied
            ? {}
            : {
                ...state.selectedCompany,
                navigatedAway_Req_dashboard: true,
                activeProject: { ...state.activeProject },
              },
        isDataAvailable: false
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: payload
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.GET_PROJECTS_LIST.SUCCESS: {
      return {
        ...state,
        projectsForSelectedCompany: payload
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.GET_PROJECTS_LIST.ERROR: {
      return {
        ...state
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: payload
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.CLEAR_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: null
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.SET: {
      return {
        ...state,
        refresh_companyId: payload
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.CLEAR: {
      return {
        ...state,
        refresh_companyId: null
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.IS_FILTER_APPLIED: {
      return {
        ...state,
        isFilterApplied: payload
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.SET_SHOW_INSURED_OPTION: {
      return {
        ...state,
        insuredList: {
          ...state.insuredList,
          showInsuredOption: payload
        }
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.SET_SHOW_INSURED_LIST: {
      return {
        ...state,
        insuredList: {
          ...state.insuredList,
          showInsuredList: payload
        }
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.SET_SHOW_INSURED_LIST_VALUE: {
      return {
        ...state,
        insuredList: {
          ...state.insuredList,
          insuredListValue: payload,
          insuredListValue: {
            ...payload,
            namedInsuredsAndClientList:
              state.insuredList.insuredListValue.namedInsuredsAndClientList.concat(
                payload.namedInsuredsAndClientList
              )
          }
        }
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.RESET_SHOW_INSURED_LIST_VALUE: {
      return {
        ...state,
        insuredList: {
          ...state.insuredList,
          insuredListValue: {
            namedInsuredsAndClientList: [],
            totalCount: 0
          }
        }
      };
    }
    case TRACKED_POLICIES_ACTION_TYPES.RESET_REQUESTOR_DASHBOARD_COMPANY: {
      let tempList =
        (state?.trackedCompanies || []).map((o) => {
          return o.isActive
            ? {
                ...o,
                navigatedAway_Req_dashboard: true,
              }
            : {
                ...o,
                navigatedAway_Req_dashboard: false,
              };
        });
      return { ...state, trackedCompanies: tempList};
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_COMPANY_LEVEL_FILTERS: {
      return {
        ...state,
        companyLevelFilter:{
          ...state.companyLevelFilter,
          ...payload
        }
      };
    }
    
    case TRACKED_POLICIES_ACTION_TYPES.SET_POLICY_LEVEL_FILTERS: {
      return {
        ...state,
        policyLevelFilter:{
          ...state.policyLevelFilter,
          ...payload
        }
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.RESET_FILTERS: {
      return {
        ...state,
        policyLevelFilter:policyLevelFiltersInit,
        companyLevelFilter:companyLevelFiltersInit,
        isFilterApplied:false
      };
    }

    case TRACKED_POLICIES_ACTION_TYPES.SET_DASHBOARD_DRILLDOWN_FILTERS: {
      let tempCompanies = [...state.trackedCompanies];
      tempCompanies.forEach(company=>{
        if(company.navigatedAway_Req_dashboard){
          company.navigatedAway_Req_dashboard = false
        }
      })
      return {
        ...state,
        policyLevelFilter:policyLevelFiltersInit,
        companyLevelFilter:{...companyLevelFiltersInit, ...payload},
        trackedCompanies: tempCompanies,
        searchString:"",
        pageNumber:1,
        isFilterApplied:true
      };
    }

    default:
      return state;
  }
};

export default RequestorDashboardReducer;
