import { EXTRACTION_POLICIES_TYPES } from "./SmartCoi.types";
import * as _ from "lodash";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";
let steps = CERTIFICIAL.PROCESS_STEPS.SMART_COI;

const extractionDataInitialState = {
    extractedPolicies: [],
    extractionStatus:0,
    steps:[steps.PROCESS_COI, steps.EXTRACTION_STATUS, steps.VERIFY_POLICIES, steps.UPLOAD_SIGNATURE],
    extractionSuccess:false,
    isFromExtraction:false,
    dooRemarks:"",
    extractedDataSave:false,
    extractionProgress:0,
    extractionConfidence:null,
    generateSignatureChecked:false
};


const SmartCoiReducerReducer = (
  state = {...extractionDataInitialState},
  { type, payload }
) => {
    switch (type) {
        case EXTRACTION_POLICIES_TYPES.GET_EXTRACTED_POLICIES.SUCCESS:{
            return {
                ...state,
                extractedPolicies:payload.extractedPolicies,
                isFromExtraction:true,
                extractionSuccess : !!payload.extractedPolicies?.length,
               ...(payload.additionalRemarks && {dooRemarks:payload.additionalRemarks}),
               extractionStatus:2,
               extractionConfidence:payload?.extractionConfidence
            };
        }

        case EXTRACTION_POLICIES_TYPES.GET_INACTIVE_POLICIES.SUCCESS:{
            return {
                ...state,
                extractedPolicies:payload,
                isFromExtraction:false
            };
        }

        case EXTRACTION_POLICIES_TYPES.UPDATE_EXTRACTED_POLICIES:{
            return {
                ...state,
                extractedPolicies:payload
            };
        }

        case EXTRACTION_POLICIES_TYPES.SAVE_DATA.SUCCESS:{
            return {
                ...state,
                extractedDataSave:true
            };
        }

        case EXTRACTION_POLICIES_TYPES.SET_EXTRACTION_PROGRESS.SUCCESS:{
            return {
                ...state,
                extractionProgress:payload
            };
        }

        case EXTRACTION_POLICIES_TYPES.SET_EXTRACTION_STATUS.SUCCESS:{
            return {
                ...state,
                extractionStatus:payload
            };
        }

        case EXTRACTION_POLICIES_TYPES.SET_STEPS:{
            return {
                ...state,
                steps:payload
            };
        }

        case EXTRACTION_POLICIES_TYPES.RESET_STATES:{
            return {
                ...state,
                ...extractionDataInitialState
            };
        
        }

        case EXTRACTION_POLICIES_TYPES.SET_GENERATE_SIGNATURE:{
            return {
                ...state,
                generateSignatureChecked:payload
            }
        }

        default:
            return state;
    };
};

export default SmartCoiReducerReducer;
