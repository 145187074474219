import { REQUESTOR_REQUEST_POLICY_TYPES } from "./RequestorRequestPolicies.types";

/**
 * CREATE_DUMMY_REQUESTS
 */

export const createDummyRequestOfVerifications = () => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.CREATE_DUMMY_REQUESTS,
  payload: 2
});

/**
 * CLEAR_DUMMY_REQUESTS
 */

export const clearDummyRequestOfVerifications = () => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.CLEAR_DUMMY_REQUESTS
});

/**
 * GET_ALL_REQUEST_OF_VERIFICATIONS
 */

export const getAllRequestOfVerificationSuccess = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.GET_ALL_REQUEST_OF_VERIFICATIONS.SUCCESS,
  payload
});

export const getAllRequestOfVerificationError = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.GET_ALL_REQUEST_OF_VERIFICATIONS.ERROR,
  payload
});

/**
 * GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS
 */

export const getRequestOfVerificationDetailSuccess = (payload) => ({
  type:
    REQUESTOR_REQUEST_POLICY_TYPES.GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS
      .SUCCESS,
  payload
});

export const getRequestOfVerificationDetailError = (payload) => ({
  type:
    REQUESTOR_REQUEST_POLICY_TYPES.GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS
      .ERROR,
  payload
});

/**
 * GET_PROJECTS_LIST
 */

export const getRequestOfProjectsListSuccess = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.GET_PROJECTS_LIST.SUCCESS,
  payload
});

export const getRequestOfProjectsListError = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.GET_PROJECTS_LIST.ERROR,
  payload
});

/**
 * CREATE_REQUEST_OF_VERIFICATION
 */

export const createRequestOfVerificationSuccess = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.CREATE_REQUEST_OF_VERIFICATION.SUCCESS,
  payload
});

export const createRequestOfVerificationError = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.CREATE_REQUEST_OF_VERIFICATION.ERROR,
  payload
});

/**
 * DELETE_REQUEST_OF_VERIFICATION
 */

export const deleteRequestOfVerificationSuccess = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.DELETE_REQUEST_OF_VERIFICATION.SUCCESS,
  payload
});

export const deleteRequestOfVerificationError = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.DELETE_REQUEST_OF_VERIFICATION.ERROR,
  payload
});

/**
 * CLEAR_REQUEST_OF_VERIFICATIONS
 */
export const clearRequestOfVerifications = () => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.CLEAR_REQUEST_OF_VERIFICATIONS
});

/**
 * Reset selected verification details
 */
export const resetSelectedVerificationDetails = () => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.RESET_SELECTED_VERIFICATION_DETAILS
});

/**
 * No data available message management
 */
export const isDataAvailable = (val) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.IS_DATA_AVAILABLE,
  payload: val
});

/**
 * SET_ACTIVE_PROJECT
 */
export const setRequestorRequestedProject = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.SET_ACTIVE_PROJECT,
  payload
});

/**
 * CLEAR_ACTIVE_PROJECT
 */
export const clearRequestorRequestedProject = (payload) => ({
  type: REQUESTOR_REQUEST_POLICY_TYPES.CLEAR_ACTIVE_PROJECT,
  payload
});
