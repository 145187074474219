import { Avatar, Modal } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { memo } from "react";
import { disconnectedIcon } from "../../Assets/assets";

const NetworkConnectivity = memo(function NetworkConnectivity({ offline }) {
  return (
    <Modal
      title={null}
      visible={offline}
      destroyOnClose={true}
      centered={true}
      maskClosable={false}
      footer={null}
      closable={false}
      width={400}
      bodyStyle={{ padding: "60px 0" }}
    >
      <div className="text-center">
        <Avatar
          src={disconnectedIcon}
          size={96}
          shape="square"
          style={{ marginBottom: "2rem" }}
        />
        <Title level={4} className="color-primary">
          Can't connect to network.
        </Title>
        <Text>Slow or no internet connection.</Text>
        <br />
        <Text>Please check your internet settings and try again.</Text>
      </div>
    </Modal>
  );
});

export default NetworkConnectivity;
