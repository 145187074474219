import _ from "lodash";
import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import { AGENTS_DASHBOARD_LIST_TYPE } from "./AgentPendingVerification.types";
import { CLIENT_ADRESSSBOOK_TYPES } from "../ClientAddressBook/ClientAddressBook.types";



const policyLevelFiltersStateInit = {
  expiryDuration: [],
  policyStatus:  [],
  endorsements: []
}

const clientLevelFiltersStateInit = {
  companyRecords:  [],
  clientStatus: [],
  agent:""
}


const AgentClientsInitialState = {
  role:
    _authService.getUserRole() &&
    _authService.getUserRole() === APPLICATION_USER_ROLES.AGENT
      ? _authService.getUserRole()
      : APPLICATION_USER_ROLES.REQUESTER,

  agentCompanyList: [],
  agentFilterCompanyList: [],
  clientCompanyList: [],
  clientFilterCompanyList: [],
  policyList: [],
  clientPolicyList: [],
  allClientPolicyList: [],
  isApproved: false,
  clientAgency: {},
  agentProjectsTemplates: [],
  searchString: null,
  pageNumber: 1,
  agentCompanyStatus: [],
  isCertHolderPresent:false,
  clientLevelFiltersState:clientLevelFiltersStateInit,
  policyLevelFiltersState:policyLevelFiltersStateInit
};

const base_AgentClientsInitialState = { ...AgentClientsInitialState };

const AgentClientsReducer = (
  state = base_AgentClientsInitialState,
  { type, payload }
) => {
  switch (type) {
    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_COMPANY.DUMMY_COMPANIES: {
      return {
        ...state,
        agentCompanyList: state.agentCompanyList.concat(
          [...new Array(payload)].map(() => ({
            loading: true,
            name: {}
          }))
        )
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_COMPANY.SUCCESS: {
      state.agentFilterCompanyList = [];
      return {
        ...state,
        agentCompanyList: payload,
        policyList: [...state.policyList]
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_COMPANY.FILTER_SUCCESS: {
      let newCompanyList = [];

      if (payload && payload.length) {
        payload.forEach((e1) => {
          newCompanyList.push(
            ..._.filter(state.agentCompanyList, (e2) => {
              return e2.insuredCompanyId === e1;
            })
          );
        });
      } else {
        state.agentFilterCompanyList = [];
      }

      newCompanyList = _.map(newCompanyList, (obj) => {
        return {
          ...obj,
          ...{
            isActive: obj.insuredCompanyId === payload.companyId
          }
        };
      });

      return {
        ...state,
        agentFilterCompanyList: [...newCompanyList]
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_COMPANY.ERROR: {
      return {
        ...state,
        agentCompanyList: []
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.CLIENT_LIST_OF_COMPANY.SUCCESS: {
      state.clientFilterCompanyList = [];
      return {
        ...state,
        clientCompanyList: payload
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.CLIENT_LIST_OF_COMPANY.FILTER_SUCCESS: {
      let newCompanyList = [];

      if (payload && payload.length) {
        payload.forEach((e1) => {
          newCompanyList.push(
            ..._.filter(state.clientCompanyList, (e2) => {
              return e2.insuredCompanyId === e1;
            })
          );
        });
      } else {
        state.clientFilterCompanyList = [];
      }

      return {
        ...state,
        clientFilterCompanyList: [...newCompanyList]
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.CLIENT_LIST_OF_COMPANY.ERROR: {
      return {
        ...state,
        clientCompanyList: []
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_POLICY.SUCCESS: {
      const newCompanyList = _.map(state.agentCompanyList, (obj) => {
        return {
          ...obj,
          ...{
            isActive: obj.insuredCompanyId === payload.companyId
          }
        };
      });

      const agentFilterCompanyList = _.map(
        state.agentFilterCompanyList,
        (obj) => {
          return {
            ...obj,
            ...{
              isActive: obj.insuredCompanyId === payload.companyId
            }
          };
        }
      );

      const newPolicyList = _.map(payload.response, (obj) => {
        return {
          ...obj,
          ...{
            company: _.find(state.agentCompanyList, (e) => {
              return e.insuredCompanyId === payload.companyId;
            }),
            agency: payload.agency
          }
        };
      });

      return {
        ...state,
        policyList: newPolicyList,
        agentCompanyList: newCompanyList,
        agentFilterCompanyList: agentFilterCompanyList
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_POLICY.ERROR: {
      return {
        ...state,
        policyList: []
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.CLIENT_LIST_OF_POLICY.SUCCESS: {
      const newCompanyList = _.map(state.clientCompanyList, (obj) => {
        return {
          ...obj,
          ...{ isActive: obj.insuredCompanyId === payload.companyId }
        };
      });

      const clientFilterCompanyList = _.map(
        state.clientFilterCompanyList,
        (obj) => {
          return {
            ...obj,
            ...{
              isActive: obj.insuredCompanyId === payload.companyId
            }
          };
        }
      );

      const newPolicyList = _.map(payload.response, (obj) => {
        return {
          ...obj,
          ...{
            company: _.find(state.clientCompanyList, (e) => {
              return e.insuredCompanyId === payload.companyId;
            }),
            agency: payload.agency
          }
        };
      });

      return {
        ...state,
        clientPolicyList: newPolicyList,
        clientCompanyList: newCompanyList,
        clientFilterCompanyList: clientFilterCompanyList,
        replacePoliciesHistory:payload?.replacePoliciesHistory || {},
        clientAgency: payload.agency
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.CLIENT_LIST_OF_POLICY.ERROR: {
      return {
        ...state,
        clientPolicyList: [],
        clientAgency: {},
        replacePolicies:null
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.LIST_OF_POLICY.POLICY_NULL: {
      const newCompanyList = _.filter(state.agentCompanyList, (item) => {
        return item.id !== payload.companyId;
      });
      return {
        ...state,
        comapniesList: newCompanyList
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.ACTIONS.SUCCESS: {
      let changedPolicyList = [];
      let newCompanies = [];
      // AGENT POLICY LIST FILTER
      if (state.policyList.length) {
        changedPolicyList = _.filter(state.policyList, (item) => {
          return item.id !== payload.policyId;
        });
        if (changedPolicyList.length === 0) {
          newCompanies = _.filter(state.agentCompanyList, (item) => {
            return (
              item.insuredCompanyId !==
              state.policyList[0].company.insuredCompanyId
            );
          });
        } else {
          newCompanies = state.agentCompanyList;
        }
        state.policyList = [];
        state.policyList = changedPolicyList;
        if (changedPolicyList.length > 0) {
          let selectedCompany = _.find(
            newCompanies,
            (d) =>
              d.insuredCompanyId ===
              changedPolicyList[0].company.insuredCompanyId
          );
          selectedCompany["policyCount"] = changedPolicyList.length;
        }
      }
      // CLIENT POLICY LIST FILTER
      if (state.clientPolicyList) {
        const updatedPolicyIndex = _.findIndex(
          state.clientPolicyList,
          (item) => {
            const incomingStatus = _.startCase(
              _.toLower(item.verificationStatus)
            );

            return (
              item.id == payload.policyId &&
              incomingStatus !== "Expired" &&
              incomingStatus !== "Expiring"
            );
          }
        );
        const updatedPolicy = {
          ...state.clientPolicyList[updatedPolicyIndex],
          verificationStatus:
            payload.action === "reject"
              ? "Rejected"
              : payload.action === "approve"
              ? "Verified"
              : payload.action === "delete"
              ? "Canceled"
              : ""
        };
      }
      const clientPolicies = [...state.clientPolicyList];
      let updatedClientPolicies = [];
      if (payload.action === "approve" && payload.endorsementRequest) {
        clientPolicies.forEach((db) => {
          if (payload.policyId === db.id) {
            updatedClientPolicies.push({
              ...db,
              endorsements: payload.endorsementRequest
            });
          } else {
            return updatedClientPolicies.push(db);
          }
        });
      } else updatedClientPolicies = clientPolicies;

      return {
        ...state,
        policyList: state.policyList,
        clientPolicyList: updatedClientPolicies,
        agentCompanyList: newCompanies,
        isApproved: true
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.ACTIONS.ERROR: {
      return {
        ...state,
        isApproved: false
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.CLEAR.CLEAR_LIST_OF_COMPANIES: {
      let tempList =
        state &&
        state.clientCompanyList.map((o) => {
          return o.isActive
            ? {
                ...o,
                navigatedAway: true
              }
            : o;
        });
      return {
        ...state,
        clientCompanyList: tempList
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.CLEAR.CLEAR_LIST_OF_COMPANIES_DASHBOARD: {
      let tempList =
        state &&
        state.clientCompanyList.map((o) => {
          return o.isActive
            ? {
                ...o,
                navigatedAwayDashboard: true
              }
            : o;
        });
      return {
        ...state,
        clientCompanyList: tempList,
        selectedFiltersToPersist: payload?.selectedFilters  
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: payload
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.CLEAR.CLEAR_LIST_OF_POLICY: {
      return {
        ...state,
        policyList: []
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.ALL_CLIENT_LIST_OF_POLICY.SUCCESS: {
      return {
        ...state,
        allClientPolicyList: payload
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.ALL_CLIENT_LIST_OF_POLICY.ERROR: {
      return {
        ...state,
        allClientPolicyList: []
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.GET_PROJECT_TEMPLATES.SUCCESS: {
      const templates = payload;
      return {
        ...state,
        agentProjectsTemplates: templates
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.CLEAR.CLEAR_PROJECT_TEMPLATES: {
      return {
        ...state,
        agentProjectsTemplates: []
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE
      .SET_LIST_OF_ADDRESS_BOOK_GROUP_FOR_SELECTED_CLIENT.SUCCESS: {
      return {
        ...state,
        addressGroupListForSelectedClient: [...payload]
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE
      .SET_LIST_OF_ADDRESS_BOOK_GROUP_FOR_SELECTED_CLIENT.ERROR: {
      return {
        ...state,
        addressGroupListForSelectedClient: []
      };
    }
    case AGENTS_DASHBOARD_LIST_TYPE.SET_SELECTED_ADDRESS_GROUP.SUCCESS: {
      return {
        ...state,
        selectedAddressGroup: payload ? { ...payload } : null
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.SET_COMPANY_STATUS: {
      return {
        ...state,
        agentCompanyStatus: payload && payload.length
          ? [...new Set([...state.agentCompanyStatus, ...payload])]
          : [...new Set([...state.agentCompanyStatus])],
      };
    }

    case CLIENT_ADRESSSBOOK_TYPES.GET_ADDRESSBOOK.SUCCESS: {
      return {
        ...state,
        documents: [...payload.documents],
        sampleFilePath: payload.sampleFilePath
      };
    }
    case CLIENT_ADRESSSBOOK_TYPES.GET_ADDRESSBOOK.ERROR:
    case CLIENT_ADRESSSBOOK_TYPES.CLEAR_ADDRESSBOOK: {
      return { ...state, documents: [] };
    }
    case CLIENT_ADRESSSBOOK_TYPES.GET_ADDRESSES.SUCCESS: {
      return { ...state, addresses: payload };
    }
    case CLIENT_ADRESSSBOOK_TYPES.SET_LOADING: {
      return { ...state, addressesLoading: payload };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.CHECK_CERT_HOLDERS: {
      return {
        ...state,
        isCertHolderPresent:payload
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.SET_CLIENT_LEVEL_FILTERS: {
      return {
        ...state,
        clientLevelFiltersState:payload
      };
    }
    
    case AGENTS_DASHBOARD_LIST_TYPE.SET_POLICY_LEVEL_FILTERS: {
      return {
        ...state,
        policyLevelFiltersState:payload
      };
    }

    case AGENTS_DASHBOARD_LIST_TYPE.RESET_FILTERS: {
      return {
        ...state,
        policyLevelFiltersState:policyLevelFiltersStateInit,
        clientLevelFiltersState:clientLevelFiltersStateInit
      };
    }

    default:
      return state;
  }
};
export default AgentClientsReducer;
