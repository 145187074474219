export const PENDING_INSURED_REQUESTS_ACTION_TYPES = {
  DUMMY_PENDING_INSURED_REQUESTS: "DUMMY_PENDING_INSURED_REQUESTS",
  RESET_DUMMY_PENDING_INSURED_REQUESTS: "RESET_DUMMY_PENDING_INSURED_REQUESTS",
  CLEAR_PENDING_INSURED_REQUESTS: "CLEAR_PENDING_INSURED_REQUESTS",
  GET_PENDING_INSURED_REQUESTS: {
    SUCCESS: "GET_PENDING_INSURED_REQUESTS_SUCCESS",
    ERROR: "GET_PENDING_INSURED_REQUESTS_ERROR"
  },
  GET_DETAILED_INSURED_REQUEST: {
    SUCCESS: "GET_DETAILED_INSURED_REQUEST_SUCCESS",
    ERROR: "GET_DETAILED_INSURED_REQUEST_ERROR",
    RESET: "GET_DETAILED_INSURED_REQUEST_RESET"
  },
  GET_MY_POLICIES_TO_SHARE: {
    SUCCESS: "GET_MY_POLICIES_TO_RESPOND_SUCCESS",
    ERROR: "GET_MY_POLICIES_TO_RESPOND_ERROR",
    RESET: "GET_MY_POLICIES_TO_RESPOND_RESET"
  },
  GET_MY_DOCS_TO_SHARE: {
    SUCCESS: "GET_MY_DOCS_TO_SHARE_SUCCESS",
    ERROR: "GET_MY_DOCS_TO_SHARE_ERROR",
    RESET: "GET_MY_DOCS_TO_SHARE_RESET"
  },
  SET_ACTIVE_PENDING_INSURED_REQUESTS: "SET_ACTIVE_PENDING_INSURED_REQUESTS",
  SAVE_CERT_UPLOAD_STATUS: "SAVE_CERT_UPLOAD_STATUS",
  GET_PROJECTS_LIST: {
    SUCCESS: "PENDING_INSURED_GET_PROJECTS_LIST_SUCCESS",
    ERROR: "PENDING_INSURED_GET_PROJECTS_LIST_ERROR"
  },
  SET_ACTIVE_PROJECT: "SET_ACTIVE_PROJECT_FOR_ACTIVE_PENDING_INSURED_REQUEST",
  CLEAR_ACTIVE_PROJECT: "CLEAR_ACTIVE_PROJECT",
  SET_SELECTED_COMPANY: "SET_SELECTED_COMPANY",
  GET_SELECTED_COMPANY: "GET_SELECTED_COMPANY"
};
