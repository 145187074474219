import * as _ from "lodash";
import { APPLICATION_USER_ROLES } from "../Constants/Application.constants";

const NAVBAR_ITEMS = [
  {
    LABEL: "Dashboard",
    SUBHEADER: "Tracked Policies",
    NAME: "DASHBOARD",
    LINK: "dashboard",
    USERROLE: APPLICATION_USER_ROLES.REQUESTER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: "dashboard"
  },
  {
    LABEL: "Suppliers",
    SUBHEADER: "Suppliers",
    NAME: "Suppliers",
    LINK: "suppliers",
    USERROLE: APPLICATION_USER_ROLES.REQUESTER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: "dashboard"
  },
  {
    LABEL: "Templates",
    SUBHEADER: "Policy Templates",
    NAME: "TEMPLATES",
    LINK: "templates",
    USERROLE: APPLICATION_USER_ROLES.REQUESTER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: "template"
  },
  {
    LABEL: "Request Coverage",
    SUBHEADER: "Pending Request for Insurance",
    NAME: "REQUEST_POLICIES",
    LINK: "request-coverage",
    USERROLE: APPLICATION_USER_ROLES.REQUESTER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null
  },
  {
    LABEL: "Share Policies",
    SUBHEADER: "Share My Policies",
    NAME: "SHARED_POLICIES",
    LINK: "shared-policies",
    USERROLE: APPLICATION_USER_ROLES.INSURER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null
  },
  {
    LABEL: "My Policies",
    SUBHEADER: "My Policies",
    NAME: "MY_POLICIES",
    LINK: "my-policies",
    USERROLE: APPLICATION_USER_ROLES.INSURER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null
  },
  {
    LABEL: "Open Requests",
    SUBHEADER: "Open Requests",
    NAME: "PENDING_REQUESTS",
    LINK: "pending-requests",
    USERROLE: APPLICATION_USER_ROLES.INSURER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null
  },
  {
    LABEL: "Company Admin",
    SUBHEADER: "Company Admin",
    NAME: "COMPANY_ADMIN",
    LINK: "company-admin",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "Company Dashboard",
    SUBHEADER: "Company Dashboard",
    NAME: "COMPANY_DASHBOARD",
    LINK: "company-manage",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "Supplier Addresss Book",
    SUBHEADER: "Supplier Address Book",
    NAME: "SUPPLIER_ADDRESSBOOK",
    LINK: "supplier-address-book",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "My Team",
    SUBHEADER: "My Team",
    NAME: "MY_TEAM",
    LINK: "my-team",
    USERROLE: null,
    ACTUALROLE: APPLICATION_USER_ROLES.AGENT,
    ICON: null
  },
  {
    LABEL: "My Address Book",
    SUBHEADER: "My Address Book",
    NAME: "MY_ADDRESS_BOOK",
    LINK: "my-address-book",
    USERROLE: null,
    ACTUALROLE: APPLICATION_USER_ROLES.AGENT,
    ICON: null
  },
  {
    LABEL: "Pending User Requests",
    SUBHEADER: "Pending Users",
    NAME: "PENDING_USERS",
    LINK: "my-pending-users",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "My Profile",
    NAME: "MY_PROFILE",
    LINK: "myProfile",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "",
    NAME: "",
    LINK: "upgrade",
    USERROLE: null,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null
  },
  {
    LABEL: "",
    NAME: "",
    LINK: "contactus",
    USERROLE: null,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null
  },
  {
    LABEL: "Notification",
    SUBHEADER: "All Notification",
    NAME: "All_NOTIFICATION",
    LINK: "notifications",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "Clients",
    SUBHEADER: "Client Policies",
    NAME: "CLIENT_POLICIES",
    LINK: "agent-clients",
    USERROLE: APPLICATION_USER_ROLES.AGENT,
    ACTUALROLE: APPLICATION_USER_ROLES.AGENT,
    ICON: null
  },
  {
    LABEL: "Pending Approvals",
    SUBHEADER: "Pending Approvals",
    NAME: "AGENT_DASHBOARD",
    LINK: "agent-pending-approval",
    USERROLE: APPLICATION_USER_ROLES.AGENT,
    ACTUALROLE: APPLICATION_USER_ROLES.AGENT,
    ICON: null
  },
  {
    LABEL: "",
    SUBHEADER: "",
    NAME: "AGENT_MANAGE_COMPLIANCE",
    LINK: "agent-manage-compliance",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "",
    SUBHEADER: "",
    NAME: "AGENT_CLIENT_ADDRESS_BOOK",
    LINK: "client-address-book",
    USERROLE: null,
    ACTUALROLE: null,
    ICON: null
  },
  {
    LABEL: "",
    SUBHEADER: "",
    NAME: "Manage Projects",
    LINK: "manage-projects",
    USERROLE: APPLICATION_USER_ROLES.REQUESTER,
    ACTUALROLE: APPLICATION_USER_ROLES.CLIENT,
    ICON: null,
    HIDDEN: true
  },
  {
    LABEL: "Open Requests",
    SUBHEADER: "Open Requests",
    NAME: "AGENT_REQUESTS",
    LINK: "agent-insured-requests",
    USERROLE: APPLICATION_USER_ROLES.AGENT,
    ACTUALROLE: APPLICATION_USER_ROLES.AGENT,
    ICON: null
  }
];

export const NavbarConfig = NAVBAR_ITEMS;

export const NavbarMenu = {
  [APPLICATION_USER_ROLES.REQUESTER]: _.filter(NAVBAR_ITEMS, function (n) {
    return n.USERROLE === APPLICATION_USER_ROLES.REQUESTER && !n.HIDDEN;
  }),
  [APPLICATION_USER_ROLES.INSURER]: _.filter(NAVBAR_ITEMS, function (n) {
    return n.USERROLE === APPLICATION_USER_ROLES.INSURER && !n.HIDDEN;
  }),
  [APPLICATION_USER_ROLES.AGENT]: _.filter(NAVBAR_ITEMS, function (n) {
    return n.USERROLE === APPLICATION_USER_ROLES.AGENT && !n.HIDDEN;
  })
};
