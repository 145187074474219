import { Layout } from "antd";
import _ from "lodash";
import Animate from "rc-animate";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { AppLoader } from "../../Components/Loader/AppLoader";
import PendingApprovals from "../../Modules/Agent/PendingApprovals/PendingApprovals";
import ServerInternalError from "../../Modules/Exceptions/500/500";
import { PrivateRoute } from "../../Routes/PrivateRoute";
import {
  AgentClientAddressBookComponent,
  AgentPendingRequestsComponent,
  AllNotificationComponent,
  ClientComponent,
  CompanyAdminComponent,
  CompanyDashboardComponent,
  CompanyProfileComponent,
  ContactUsComponent,
  SuppliersComponent,
  DashboardComponent,
  ManageComplianceComponent,
  MyAddressBookComponent,
  MyPendingUsersComponent,
  MyPolicyComponent,
  MyProfileComponent,
  MyTeamComponent,
  PageNotFoundComponent,
  PaymentGatewayComponent,
  PendingInsuredRequestsComponent,
  RequestPolicyComponent,
  SharedPolicyComponent,
  TemplateComponent,
  UnAuthorizedErrorComponent,
  UpgradeComponent,
  UploadCertificatesComponent,
  RequestorPaymentResultsComponent,
  SupplierClientAddressBookComponent,
  ManageProjects
} from "../../Routes/Route";
import { changeUserRole } from "../../Store/Modules/Application/Application.actions";
import {
  getOpenRequestCount,
  handleUserFirstLoginonLogout,
  logout_action,
  getPendingApproval
} from "../../Store/Modules/Application/Application.asyncRequest";
import { getUserInformation, getAgentInformation } from "../../Store/Modules/MyProfile/MyProfile.asyncRequest";
import { NavbarConfig } from "../../Utils/Configurations/Navbar.config";
import { CERTIFICIAL_LABEL } from "../../Utils/Constants/App.labels";
import { APPLICATION_USER_ROLES } from "../../Utils/Constants/Application.constants";
import { appHistory } from "../../Utils/Helpers/History";
import { _authService } from "../../Utils/Services/AuthenticationService";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../Footer/AppFooter";

const { Content } = Layout;

const AppContainer = (props) => {
  const scrollBarRef = useRef();
  // const autoLogoutTimer = useAutoLogout(CERTIFICIAL.IDLE_SESSION_TIMEOUT);
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.app.currentSession);
  const { userData, userUpdate } = useSelector((state) => state.myProfile);
  const { id, firstName, lastName, userFirstLogin } = useSelector(
    (state) => state.myProfile.userData
  );
  const pathName = props.location.pathname;

  // useEffect(() => {
  //   if (autoLogoutTimer === 0) {
  //     dispatch(logout_action(id));
  //   }
  //   return () => {};
  // }, [autoLogoutTimer]);

  useEffect(() => {
    if (userData && userData.role && !userUpdate) {
      const currentNavCongfig = NavbarConfig.filter((o) => {
        return (
          pathName.slice(5, pathName.length) === o.LINK ||
          pathName.slice(5, pathName.length).indexOf(o.LINK) > -1
        );
      })[0];
      if (
        currentNavCongfig &&
        currentNavCongfig.ACTUALROLE &&
        userData.role !== currentNavCongfig.ACTUALROLE
      ) {
        if (userFirstLogin) {
          dispatch(
            handleUserFirstLoginonLogout(
              {
                userId: id,
                flag: false,
                user: firstName + " " + lastName
              },
              () => appLoaderHandler(false)
            )
          );
        } else {
          dispatch(logout_action(id, () => appLoaderHandler(false)));
        }
        _authService.logoutUser();
        _authService.clearUserRole();
        _authService.clearUserInfo();
        appHistory.push("/login");
      } else {
        if (currentNavCongfig && currentNavCongfig.USERROLE) {
          if (userData.role !== APPLICATION_USER_ROLES.AGENT) {
            handleUserRoleChange(
              currentNavCongfig.USERROLE,
              currentNavCongfig.NAME
            );
          } else {
            if (
              currentNavCongfig.USERROLE === APPLICATION_USER_ROLES.INSURER ||
              currentNavCongfig.USERROLE === APPLICATION_USER_ROLES.REQUESTER
            ) {
              handleUserRoleChange(
                APPLICATION_USER_ROLES.AGENT,
                currentNavCongfig.NAME
              );
            } else {
              handleUserRoleChange(APPLICATION_USER_ROLES.AGENT, false);
            }
          }
        } else {
          if (pathName == "/app/supplier-address-book") {
            handleUserRoleChange(
              (userData.role === APPLICATION_USER_ROLES.CLIENT &&
                !!role &&
                role === APPLICATION_USER_ROLES.CLIENT &&
                APPLICATION_USER_ROLES.REQUESTER) ||
                role ||
                (userData.role === APPLICATION_USER_ROLES.CLIENT &&
                  APPLICATION_USER_ROLES.REQUESTER) ||
                APPLICATION_USER_ROLES.AGENT,
              false
            );
          } else {
            const BLOCAKING_PATHS = [
              "/app/myProfile",
              "/app/upgrade",
              "/app/notifications",
              "/app/contactus/",
              "/app/agent-shared-policies",
              "/app/company-manage"
            ];

            handleUserRoleChange(
              (userData.role === APPLICATION_USER_ROLES.CLIENT &&
                _.indexOf(BLOCAKING_PATHS, pathName) >= -1 &&
                !!role &&
                role === APPLICATION_USER_ROLES.CLIENT &&
                APPLICATION_USER_ROLES.INSURER) ||
                role ||
                (userData.role === APPLICATION_USER_ROLES.CLIENT &&
                  APPLICATION_USER_ROLES.INSURER) ||
                APPLICATION_USER_ROLES.AGENT,
              false
            );
          }
        }
      }
    }
  }, [userData, pathName, role]);

  const [currentRole, setCurrentRole] = useState(undefined);

  useEffect(() => {
    if (!role) {
      return;
    }
    setCurrentRole(role);
    setAgentList();
  }, [role]);

  const setAgentList = async () => {
    if (role !== APPLICATION_USER_ROLES.INSURER) {
      return;
    }
    await dispatch(getAgentInformation(userData.company.id));
  }

  useEffect(() => {
    let unmounted = false;
    if (
      !unmounted &&
      currentRole &&
      currentRole !== APPLICATION_USER_ROLES.REQUESTER &&
      userData &&
      userData.id
    ) {
      dispatch(getOpenRequestCount(userData.id));
      if (currentRole === APPLICATION_USER_ROLES.AGENT)
        dispatch(getPendingApproval(userData.id));
    }
    return () => {
      unmounted = true;
    };
  }, [role, userData]);

  const [appInitLoading, setAppInitLoading] = useState(true);

  const appLoaderHandler = useCallback((value) => {
    setAppInitLoading(!!value);
  }, []);

  useEffect(() => {
    if (_authService.isUserAuthenticated()) {
      appLoaderHandler(true);
      dispatch(
        getUserInformation(
          _authService.getUserInfo().userId,
          _authService.getUserInfo().dummySessionId,
          appLoaderHandler
        )
      );
    }
    if (appHistory && appHistory.location.state) {
      appHistory.replace({ ...appHistory.location, state: {} });
    }
  }, []);

  const handleUserRoleChange = (role, name, isToggled=null) => {
    dispatch(changeUserRole(role, isToggled, id));
    setCurrentRole(role);
  };

  const location = useLocation();

  useEffect(() => {
    scrollTo("top");
    return () => {};
  }, [location]);

  let openRequestInterval;

  useEffect(() => {
    if (currentRole && currentRole !== APPLICATION_USER_ROLES.REQUESTER) {
      try {
        openRequestInterval = setInterval(() => {
          dispatch(getOpenRequestCount(id));
          if (currentRole === APPLICATION_USER_ROLES.AGENT)
            dispatch(getPendingApproval(id));
        }, 300000);
      } catch (e) {}
    }
    return () => {
      clearInterval(openRequestInterval);
    };
  }, [id]);

  const getOpenRequestscount = useCallback(() => {
    dispatch(getOpenRequestCount(id));
  }, [id]);

  const getPendingApprovalCount = useCallback(() => {
    dispatch(getPendingApproval(id));
  }, [id]);

  const scrollTo = (value) => {
    if (!!value && scrollBarRef && scrollBarRef.current) {
      const scrollTop = scrollBarRef.current.getScrollTop();
      const scrollHeight = scrollBarRef.current.getScrollHeight();
      scrollBarRef.current.scrollTop(
        (value && value === "bottom" && scrollHeight) || 0
      );
    }
  };

  return (
    <div id="app-container">
      <Layout className="layout">
        <Animate transitionName="fade" transitionAppear>
          {(appInitLoading && (
            <div key="1" className="app-container-loader">
              <AppLoader
                isLoading={true}
                pastDelay={true}
                height={"90vh"}
                message={CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE}
                trivia={true}
                triviaKey={"application"}
              />
            </div>
          )) || (
            <Route
              render={(rest) => (
                <div>
                  <AppHeader
                    roleChange={handleUserRoleChange}
                    currentRole={currentRole}
                    userInfo={userData}
                    appLoaderHandler={appLoaderHandler}
                    getOpenRequestscount={getOpenRequestscount}
                    getPendingApprovalCount={getPendingApprovalCount}
                    {...rest}
                  />

                  <Content
                    style={{
                      paddingTop: "45px"
                    }}
                  >
                    <div className={'app-container'}>
                      <div
                        style={{
                          paddingBottom: pathName==='/app/dashboard'? 0:50
                        }}
                      >
                        <Switch>
                          <PrivateRoute path="/app/dashboard">
                            <DashboardComponent currentRole={currentRole} />
                          </PrivateRoute>
                          <PrivateRoute path="/app/suppliers">
                            <SuppliersComponent currentRole={currentRole} {...props} />
                          </PrivateRoute>
                          <PrivateRoute
                            Component={TemplateComponent}
                            path="/app/templates"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={ManageProjects}
                            path="/app/manage-projects"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={RequestPolicyComponent}
                            path="/app/request-coverage"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={SharedPolicyComponent}
                            componentProps={{
                              appInitLoading,
                              isRequestFromAgent: false
                            }}
                            path="/app/shared-policies"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={MyPolicyComponent}
                            path="/app/my-policies"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={PendingInsuredRequestsComponent}
                            path="/app/pending-requests"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={AllNotificationComponent}
                            path="/app/notifications"
                          ></PrivateRoute>

                          <PrivateRoute
                            Component={MyProfileComponent}
                            componentProps={{ scrollTo: (v) => scrollTo(v) }}
                            path="/app/myProfile"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={CompanyProfileComponent}
                            componentProps={{ scrollTo: (v) => scrollTo(v) }}
                            path="/app/myProfile"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={MyTeamComponent}
                            componentProps={{
                              scrollTo: (v) => scrollTo(v),
                              role
                            }}
                            path="/app/my-team"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={
                              userData &&
                              userData.company &&
                              userData.company.primaryContactId === userData.id
                                ? CompanyAdminComponent
                                : UnAuthorizedErrorComponent
                            }
                            componentProps={{
                              scrollTo: (v) => scrollTo(v),
                              role
                            }}
                            path="/app/company-admin"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={CompanyDashboardComponent}
                            componentProps={{
                              scrollTo: (v) => scrollTo(v)
                            }}
                            path="/app/company-manage"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={ContactUsComponent}
                            path="/app/contactus/:planId"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={ClientComponent}
                            componentProps={{ appInitLoading }}
                            path="/app/agent-clients"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={AgentPendingRequestsComponent}
                            path="/app/agent-insured-requests"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={PendingApprovals}
                            path="/app/agent-pending-approval"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={ManageComplianceComponent}
                            path="/app/agent-manage-compliance"
                          />
                          <PrivateRoute
                            Component={UploadCertificatesComponent}
                            path="/app/grms/upload-certificate"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={PaymentGatewayComponent}
                            path="/app/payment-gateway"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={AgentClientAddressBookComponent}
                            componentProps={{
                              currentRole: role,
                              isAgentAddressBook:true,
                              scrollTo: (v) => scrollTo(v)
                            }}
                            path="/app/my-address-book"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={SupplierClientAddressBookComponent}
                            // componentProps={{ scrollTo: (v) => scrollTo(v) }}
                            componentProps={{
                              currentRole: role
                            }}
                            path="/app/supplier-address-book"
                          ></PrivateRoute>
                          <PrivateRoute
                            Component={UpgradeComponent}
                            componentProps={{
                              userId: userData && userData.id
                            }}
                            path="/app/upgrade"
                            ></PrivateRoute>
                          <PrivateRoute
                            Component={AgentClientAddressBookComponent}
                            componentProps={{ currentRole: role }}
                            path="/app/client-address-book"
                          ></PrivateRoute>
                          <PrivateRoute
                              Component={RequestorPaymentResultsComponent}
                              path="/app/requestor-verification-program/:result"
                            />
                          <PrivateRoute path="/app/page-not-found">
                            <PageNotFoundComponent
                              path={
                                role === APPLICATION_USER_ROLES.AGENT
                                  ? "/app/agent-clients"
                                  : "/app/dashboard"
                              }
                            />
                          </PrivateRoute>
                          <PrivateRoute path="app/unauthorized-access">
                            <UnAuthorizedErrorComponent
                              path={
                                role === APPLICATION_USER_ROLES.AGENT
                                  ? "/app/agent-clients"
                                  : "/app/dashboard"
                              }
                            />
                          </PrivateRoute>
                          <PrivateRoute path="/app/server-error">
                            <ServerInternalError
                              path={
                                role === APPLICATION_USER_ROLES.AGENT
                                  ? "/app/agent-clients"
                                  : "/app/dashboard"
                              }
                            />
                          </PrivateRoute>
                          <Redirect path="/app/*" to="/app/page-not-found" />
                        </Switch>
                      </div>
                    </div>
                  </Content>
                </div>
              )}
            />
          )}
        </Animate>
        {!appInitLoading ? <AppFooter currentUserRole={role} /> : null}
      </Layout>
    </div>
  );
};
export default withRouter(AppContainer);
