import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import {
    getUploadCertListError,
    getUploadCertListSuccess,
    setLoading
} from "./UploadCert.action";

export const getUploadCertList = () => {
    const userInfo = _authService.getUserInfo();
    return (dispatch) => {
        dispatch(setLoading(true));
        return makeRequest({
            type: API_METHODS.GET,
            // url: ENDPOINTS.UPLOADCERT.GET_UPLOADCERT_LIST
            url: [
                ENDPOINTS.UPLOADCERT.GET_UPLOADCERT_LIST,
                userInfo ? userInfo.userId : null
            ].join("/")
        }).then(
            (response) => {
                if (response && response.length > 0) {
                    dispatch(getUploadCertListSuccess(response));
                } else {
                    dispatch(getUploadCertListSuccess([]));
                }
                dispatch(setLoading(false));
            },
            (error) => {
                dispatch(getUploadCertListError(error));
                dispatch(setLoading(false));
            }
        );
    };
};

export const getTransactionList = (requestId) =>{
    return makeRequest({
        type: API_METHODS.GET,
        url: [
            ENDPOINTS.UPLOADCERT.GET_TRANSACTION_LIST,
            requestId
        ].join("/")
    })
}

export const validateUploadCoiLink = (data) => {
    return makeRequest({
        type: API_METHODS.POST,
        url: ENDPOINTS.EMAIL_UPLOAD_COI.VALIDATE,
        data,
        options: {
            shouldNotUserAuthHeaders: true
        }
    })
}

export const emailUploadCoi = (data) => {
    return makeRequest({
        type: API_METHODS.POST,
        url: ENDPOINTS.EMAIL_UPLOAD_COI.UPLOAD,
        data,
        options: {
            shouldNotUserAuthHeaders: true
        }
    })
}