import React, { useEffect, useState } from "react";
import { Card, Input } from "antd";
import _ from "lodash";
const { TextArea } = Input;

export const Comments = ({
  hasError = false,
  title,
  wordCapacity,
  onCommentChange,
  comment,
  minRows,
  disable = false,
  placeholder,
  wrapperClassName = ''
}) => {
  let defaultRemainingCount = null
  if(!_.isEmpty(comment)){
    defaultRemainingCount = wordCapacity - comment.length
  }
  const [remainingCount, setRemainingCount] = useState(defaultRemainingCount);
  const onChange = (e) => {
    onCommentChange(e);
  };

  useEffect(()=>{
    if(!wordCapacity){
      return;
    }
    setRemainingCount(wordCapacity - (comment?.length || 0));
  }, [comment])

  const getTextArea = () => {
    return (
      <React.Fragment>
        <TextArea
          className={wrapperClassName}
          disabled={disable}
          onChange={onChange}
          maxLength={wordCapacity || 1000}
          value={comment}
          placeholder={placeholder ? placeholder : [
            "Add your",
            (title && _.toLower(title)) || "notes",
            "here..."
          ].join(" ")}
          autoSize={{ minRows: minRows || 2, maxRows: 6 }}
        />
        {(
          <>{hasError && <div style={{ color: '#ff4d4f', textAlign: "left", display:"inline-block"}}>*Please enter Notes.</div>}
          {!disable &&<div style={{ float: "right", lineHeight:"5px", marginTop:"7px"}}>
          {(remainingCount &&
            wordCapacity &&
            remainingCount !== wordCapacity && (
              <small>
                Character Remaining : {remainingCount}/{wordCapacity}
              </small>
            )) ||
            (comment && wordCapacity && comment.length === wordCapacity && (
              <small>Character Remaining : 0</small>
            )) ||
            (wordCapacity && <small>Character Limit : {wordCapacity}</small>) ||
            null}
        </div>}</>
        )}
      </React.Fragment>
    );
  };

  return title ? (
    <Card
      size="small"
      bordered={true}
      className= {`widget-card ${wrapperClassName}`}
      title={<h4>{title}</h4>}
    >
      {getTextArea()}
    </Card>
  ) : (
    getTextArea()
  );
};
