import { Result } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const SSORedirect = () => {
  const [errorCode, setErrorCode] = useState("");
  const useQueryString = () => {
    const location = useLocation();
    let res = location.search.slice(1);
    return res;
  };
  const queryString = useQueryString();
  const defaultErrorCode = "8H";
  const errorCodeArray = [
    {
      code: "1A",
      message:
        "SSO Request is Invalid. Please contact your IT Support for further assistance.",
    },
    {
      code: "2B",
      message:
        "AMS IDP Token is missing. Please contact your IT Support for further assistance.",
    },
    {
      code: "3C",
      message:
        "Following mandatory fields are missing in AMS- Agent email ID, AMS Name. Please contact your IT Support for further assistance.",
    },
    {
      code: "3CA",
      message:
        "Following mandatory fields are missing in AMS- Agent email ID. Please contact your IT Support for further assistance.",
    },
    {
      code: "3CB",
      message:
        "Following mandatory fields are missing in AMS Name. Please contact your IT Support for further assistance.",
    },
    {
      code: "4D",
      message:
        "Following fields are not in correct format- Agent email ID. Please contact your IT Support for further assistance.",
    },
    {
      code: "5E",
      message:
        "AMS is not configured for SSO. Please contact your IT Support for further assistance.",
    },
    {
      code: "6F",
      message:
        "You will receive an email to verify your email ID. This is a one time verification. Please verify your email ID. Once done, you should be able to navigate to Certificial going forward.",
    },
    {
      code: "7G",
      message:
        "Agent not found in Certificial. Please contact your IT Support for further assistance.",
    },
    {
      code: "8H",
      message:
        "SSO encountered technical errors. Please contact your IT Support for further assistance.",
    },
  ];

  useEffect(() => {
    if (queryString && queryString.length) {
      const allParams = new URLSearchParams(queryString);
      const errorCodeValue = allParams.get("result");
      if (errorCodeArray.some((i) => i.code === errorCodeValue)) {
        setErrorCode(errorCodeValue);
      } else {
        setErrorCode(defaultErrorCode);
      }
    } else {
      setErrorCode(defaultErrorCode);
    }
  }, [queryString]);

  return (
    <Result
      subTitle={
        errorCode &&
        errorCode.length &&
        errorCodeArray.filter((i) => i.code === errorCode)[0].message
      }
    />
  );
};

export default SSORedirect;
