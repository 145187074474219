export const enable_app_loader = () => {
  document
    .getElementsByClassName("app-loader-container")[0]
    .setAttribute("hidden", false);
};
export const disable_app_loader = () => {
  document
    .getElementsByClassName("app-loader-container")[0]
    .setAttribute("hidden", true);
};
