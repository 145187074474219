import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert, Card, Icon, Spin, Typography } from "antd";
import { validateUploadCoiLink } from "../../../Store/Modules/UploadCert/UploadCert.asyncRequest";
import { CERTIFICIAL_LABEL } from "../../../Utils/Constants/App.labels";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import { InstructionText } from "../../../Components/Instruction/InstructionText";
import COIUpload from "../../../Components/CoiUpload";
import RequestorRequestPolicyDetailsBody from '../../../Modules/RequestPolicies/RequestorRequestPolicyContainer/RequestorRequestPolicyDetails/RequestorRequestPolicyDetailsBody/RequestorRequestPolicyDetailsBody.js'
import { AdditionalRemarksOnEndorsments } from "../../../Components/Policy/PolicyEndorsment/AdditionalRemarksOnEndorsments";
import './CoiUploadPage.less';
import _ from "lodash";
import { CertHolderInfo } from '../../../Components/UI/CertAvatar/CertHolderInfo.js';
import EmailHeader from "../../Template/EmailHeader.js";
import SuccessMessage from "../../../Components/UI/Email/SuccessMessage";
import InvalidLink from "../../../Components/UI/Email/InvalidLink";
const { Text } = Typography;

export const CoiUploadPage = (props) => {

    const invalidLinkComponent = (
        <InvalidLink
             title={"This COI Upload link is no longer valid"} 
            subTitle={"If further action is required, please check for your inbox for the most recent email related to this request for insurance."} 
            />
    );

    const successMsgComponent = (msg) => {
        return <SuccessMessage
                 title={msg}
                 subTitle={"We will process this COI and you will receive an email once completed, as well as if there are any compliance issues."}
                />
    }

    const [validationMsg, setValidationMsg] = useState(null);
    const [insuredCompanyName, setInsuredCompanyName] = useState(null);
    const [selectedPolicyRequestData, setselectedPolicyRequest] = useState(null);

    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search)

    const agentId = params.get('agentId');
    const requestId = params.get('requestId');
    const emailKey = params.get('emailKey');
    const uploadedBy = params.get('uploadedBy');


    const _validateUploadCoiLink = async () => {
        setLoading(true);
        try {
            let res = await validateUploadCoiLink({
                "agentId": agentId,
                "requestId": requestId,
                "emailKey": emailKey,
                "uploadedBy": uploadedBy
            });

            setInsuredCompanyName(res?.insuredCompanyName || '');
            setselectedPolicyRequest(res || {});

            if (res?.isValidationError) {
                setValidationMsg(invalidLinkComponent);
            }
        } catch (err) {
        }
        setLoading(false)
    }

    useEffect(() => {
        _validateUploadCoiLink()
    }, [])

    const coiSubmitHandler = (res) => {
        setValidationMsg(successMsgComponent(res))
    }

    const loadPolicyData = useMemo(() => {
        try {
            if ((selectedPolicyRequestData?.coiCompanyResponse && Object.keys(selectedPolicyRequestData?.coiCompanyResponse).length <= 0) || (selectedPolicyRequestData?.policyTypes && Object.keys(selectedPolicyRequestData?.policyTypes).length <= 0)) {
                return (null)
            }
            const instructionText = selectedPolicyRequestData?.policyTypes?.instructionText ? (
                <InstructionText defaultValue={selectedPolicyRequestData?.policyTypes?.instructionText} readOnlyMode={true} />
            ) : null;
            const companyData = selectedPolicyRequestData?.coiCompanyResponse?.companies[0] || {};
            const companyAddress = Object.keys(companyData).length ? (<div style={{ margin: '0 0 10px 0' }}>
                {/* <Alert
                    style={{backgroundColor:"#e5f1f733", border: "1px solid #5369f81a"}}
                    message={<label style={{color: "#000000d9", fontWeight:"500", fontSize: "1rem"}}>Cert Holder Info</label>}
                    description={
                        <>
                            <div>
                                <b>Name:</b> {[companyData?.name].join(" ")}{" "}
                            </div>
                            <div>
                                <b>Address:</b> {[companyData?.street, companyData?.address2, companyData?.city, companyData?.state, companyData?.postalCode].join(" ")}{" "}
                            </div>
                        </>
                    } type="info" /> */}
                <CertHolderInfo certHolderInfo={{ name: companyData?.name, address: [companyData?.street, companyData?.address2, companyData?.city, companyData?.state, companyData?.postalCode].join(" ") }} />
            </div>) : null
            if (selectedPolicyRequestData?.policyTypes?.isEvidenceOnly === true) {
                return (
                    <div style={{ margin: "20px 0 20px 0" }}>
                        {companyAddress}
                        <Alert
                            className="eoi-alert"
                            type="warning"
                            banner
                            message={APPLICATION_MESSAGES.EOI.TITLE + ' :'}
                            description={APPLICATION_MESSAGES.EOI.AGENT_ALERT_MESSAGE}
                        />

                        <div >
                            {instructionText}
                        </div>
                    </div>
                );
            } else {
                let policiesData = [...selectedPolicyRequestData?.policyTypes?.policyTypes];
                policiesData.map((policyData, key) => {
                    policiesData[key] = { ...policyData, amBestRating: selectedPolicyRequestData?.policyTypes?.requiredRating }
                })
                return (
                    <div style={{ margin: "20px 0 0 0" }}>
                        <Alert
                            style={{ margin: '0 0 10px 0' }}
                            message={
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Requirements: </label><label>Click on individual policies to see detailed requirements</label>
                                </>
                            }
                            type="warning" showIcon
                        />

                        {companyAddress}
                        {instructionText}
                        <RequestorRequestPolicyDetailsBody
                            isEmailCoiUpload={true}
                            emailPolicyTypes={selectedPolicyRequestData?.referenceDataWrapper?.policyTypes || []}
                            initLoading={loading}
                            loading={loading}
                            policyTypeRequests={
                                selectedPolicyRequestData && policiesData
                            }
                            currencySymbol={selectedPolicyRequestData?.policyTypes?.currencySymbol}
                            regionCurrency={selectedPolicyRequestData?.policyTypes?.regionCurrency}
                            regionId={selectedPolicyRequestData?.policyTypes?.regionId}
                        />
                        <div className="margin-top-lg">
                            <AdditionalRemarksOnEndorsments
                                isTitleRequired={true}
                                readOnlyMode={true}
                                selectedPolicyTypes={
                                    selectedPolicyRequestData?.policyTypes?.specialEndorsements &&
                                    selectedPolicyRequestData?.policyTypes?.specialEndorsements.map((s) => ({
                                        ...s,
                                        value: s.comments
                                    }))
                                }
                            />
                        </div>
                    </div>)

            }
        } catch {
            return (<></>)
        }

    }, [selectedPolicyRequestData]);

    return (
        <>
        <EmailHeader />
        <Spin
            spinning={loading}
            wrapperClassName="email-coi-upload-wrapper"
            indicator={
                <Icon
                    type="loading-3-quarters"
                    className="color-primary fs-36x"
                    spin
                />
            }
            tip={CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE}
            size="large"
        >
            <>
                
                {validationMsg ?
                    validationMsg :
                    (
                        <Card>
                            <COIUpload
                                agentId={agentId}
                                requestId={requestId}
                                emailKey={emailKey}
                                isEmailCoiUpload={true}
                                coiSubmitHandler={coiSubmitHandler}
                                insuredCompanyName={insuredCompanyName}
                                uploadedBy={uploadedBy}
                                childComponenet={loadPolicyData || null}
                            />
                        </Card>
                    )}
            </>
        </Spin>
        </>
        )

};
