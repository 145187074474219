import { APPLICATION_MODULES } from "../../../Utils/Constants/Application.constants";
import { MY_PENDING_USERS_ACTION_TYPES } from "./MyPendingUsers.types";

const myPendingUsersInitialState = {
    title: APPLICATION_MODULES.MY_PENDING_USERS.TITLE,
    isDataAvailable: false,
    myPendingUsers: null,
    isError: false,
    inviteUserSuccess: false
};

const Base_myPendingUsersInitialState = { ...myPendingUsersInitialState };

const MyPendingUsersReducer = (state = myPendingUsersInitialState, action) => {
    switch (action.type) {
        case MY_PENDING_USERS_ACTION_TYPES.CLEAR_MY_PENDING_USERS: {
            return { ...Base_myPendingUsersInitialState };
        }
        case MY_PENDING_USERS_ACTION_TYPES.GET_MY_PENDING_USERS.SUCCESS: {
            return { ...state, myPendingUsers: action.payload };
        }
        case MY_PENDING_USERS_ACTION_TYPES.GET_MY_PENDING_USERS.ERROR: {
            return state;
        }
        case MY_PENDING_USERS_ACTION_TYPES.INVITE_USER.SUCCESS: {
            return {
                ...state,
                inviteUserSuccess: true
            };
        }
        case MY_PENDING_USERS_ACTION_TYPES.INVITE_USER.ERROR: {
            return {
                ...state,
                inviteUserSuccess: false
            };
        }
        default:
            return state;
    }
};

export default MyPendingUsersReducer;
