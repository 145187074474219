export const USER_PROFILE_ACTION_TYPES = {
  CLEAR_MY_PROFILE: "CLEAR_MY_PROFILE",
  GET_USER_PROFILE: {
    SUCCESS: "GET_USER_PROFILE_SUCCESS",
    ERROR: "GET_USER_PROFILE_ERROR"
  },
  UPDATE_USER_PROFILE: {
    SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
    ERROR: "UPDATE_USER_PROFILE_ERROR"
  }
};

export const AGENT_LIST_ACTION_TYPES = {
  GET_AGENT_LIST: {
    SUCCESS: "GET_AGENT_LIST_SUCCESS"
  },
  CLEAR_AGENT_LIST: "CLEAR_AGENT_LIST"
};

export const AGENT_ADD_ACTION_TYPES = {
  AGENT_ADD_ACTION: {
    SUCCESS: "AGENT_ADD_ACTION_SUCCESS",
    ERROR: "AGENT_ADD_ACTION_ERROR"
  }
};

export const INVITE_AGENT_TYPES = {
  INVITE_AGENT_ACTION: {
    SUCCESS: "INVITE_AGENT_ACTION_SUCCESS",
    ERROR: "INVITE_AGENT_ACTION_ERROR"
  }
};

export const USER_UPDATE_ACTION_TYPE = {
  USER_UPDATE_ACTION: {
    SUCCESS: "USER_UPDATE_SUCCESS",
    ERROR: "USER_UPDAT_ERROR"
  }
};

export const USER_PASSWORD_UPDATE_ACTION_TYPE = {
  USER_PASSWORD_UPDATE_ACTION: {
    SUCCESS: "USER_PASSWORD_UPDATE_SUCCESS",
    ERROR: "USER_PASSWORD_UPDATE_ERROR"
  },
  UPDATE_STATUS_ACTION: {
    UPDATE: "UPDATE_PASSWORD_SUCCESS_STATUS"
  }
};
export const CLEAR_AGENT_SIGNATURE_TYPE = {
  CLEAR_AGENT_SIGNATURE: {
    SUCCESS: "CLEAR_AGENT_SIGNATURE_SUCCESS",
    ERROR: "CLEAR_AGENT_SIGNATURE_ERROR"
  }
};

export const UPLOAD_AGENT_SIGNATURE_TYPE = {
  UPLOAD_AGENT_SIGNATURE: {
    SUCCESS: "UPLOAD_AGENT_SIGNATURE_SUCCESS",
    ERROR: "UPLOAD_AGENT_SIGNATURE_ERROR"
  }
};

export const USER_PROJECTS = {
  GET_PROJECT_TEMPLATES: {
    SUCCESS: "GET_PROJECT_TEMPLATES_SUCCESS",
    ERROR: "GET_PROJECT_TEMPLATES_ERROR"
  },
  CREATE_PROJECT_TEMPLATE: {
    SUCCESS: "CREATE_PROJECT_TEMPLATE_SUCCESS",
    ERROR: "CREATE_PROJECT_TEMPLATE_ERROR"
  },
  UPDATE_PROJECT_TEMPLATE: {
    SUCCESS: "UPDATE_PROJECT_TEMPLATE_SUCCESS",
    ERROR: "UPDATE_PROJECT_TEMPLATE_ERROR"
  },
  DELETE_PROJECT_TEMPLATE: {
    SUCCESS: "DELETE_PROJECT_TEMPLATE_SUCCESS",
    ERROR: "DELETE_PROJECT_TEMPLATE_ERROR"
  },
  CLEAR_PROJECT_TEMPLATES: "CLEAR_PROJECT_TEMPLATES"
};

export const AGENT_PROJECTS = {
  GET_PROJECT_TEMPLATES: {
    SUCCESS: "GET_AGENT_PROJECT_TEMPLATES_SUCCESS",
    ERROR: "GET_AGENT_PROJECT_TEMPLATES_ERROR"
  },
  CREATE_PROJECT_TEMPLATE: {
    SUCCESS: "CREATE_AGENT_PROJECT_TEMPLATE_SUCCESS",
    ERROR: "CREATE_AGENT_PROJECT_TEMPLATE_ERROR"
  },
  UPDATE_PROJECT_TEMPLATE: {
    SUCCESS: "UPDATE_AGENT_PROJECT_TEMPLATE_SUCCESS",
    ERROR: "UPDATE_AGENT_PROJECT_TEMPLATE_ERROR"
  },
  DELETE_PROJECT_TEMPLATE: {
    SUCCESS: "DELETE_AGENT_PROJECT_TEMPLATE_SUCCESS",
    ERROR: "DELETE_AGENT_PROJECT_TEMPLATE_ERROR"
  },
  CLEAR_PROJECT_TEMPLATES: "CLEAR_AGENT_PROJECT_TEMPLATES"
};

export const EMAIL_ASSOCIATED_SHARABLE_COMPANIES = {
  SET: "EMAIL_ASSOCIATED_SHARABLE_COMPANIES_SET",
  RESET: "EMAIL_ASSOCIATED_SHARABLE_COMPANIES_RESET"
};

export const CERT_UPLOAD_STATUS = {
  SET: "CERT_UPLOAD_STATUS"
};
