import * as _ from "lodash";
import { AUTO_CSL_LIMIT, AUTO_SPLIT_LIMITS } from "../Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../Constants/ApplicationMessages.constants";




export const isAutoPolicyLimitsValid = (policy) => {
  if (policy.acceptCslOrSplitLimits) {
    return {
      isValid :isSplitLimitPresent(policy.limitTypes) && isCslLimitPresent(policy.limitTypes),
      message:APPLICATION_MESSAGES.CSL_LIMIT_WARNING
    }
  } else {
    return {
      isValid:!(isSplitLimitPresent(policy.limitTypes) && isCslLimitPresent(policy.limitTypes)),
      message:APPLICATION_MESSAGES.SPLIT_LIMIT_WARNING
    }
  }
}

export const disableLimitsBasedOnCslFlag = (policyLimitTypes, value)=>{

  if(value){
    policyLimitTypes.forEach(limit=>{
      if([...AUTO_CSL_LIMIT, ...AUTO_SPLIT_LIMITS].includes(limit.name)){
        limit.cslDisable = false;
      }
    })
  }else{
    if(isCslLimitPresent(policyLimitTypes)){
      AUTO_SPLIT_LIMITS.forEach(limitName=>{
       let limit =  policyLimitTypes.find(p=>p.name == limitName);
       limit.cslDisable = true
      })
    }else if(isSplitLimitPresent(policyLimitTypes)){
      AUTO_CSL_LIMIT.forEach(limitName=>{
        let limit =  policyLimitTypes.find(p=>p.name == limitName);
        limit.cslDisable = true
       })
    }
  }
}

export const isCslLimitPresent = (limitTypes)=>{
  let cslLimitValue = limitTypes.find(limit => AUTO_CSL_LIMIT.includes(limit.name))?.limitValue;
  let isCslLimitPresent = false;
  if (cslLimitValue) {
    isCslLimitPresent = parseFloat(cslLimitValue) > 0;
  }
  return isCslLimitPresent;
}

export const isSplitLimitPresent = (limitTypes)=>{
  let isSplitLimitPresent = AUTO_SPLIT_LIMITS.some(limitName => {
    let value = limitTypes.find(limit => limit.name == limitName)?.limitValue;
    if (!value) {
      return false;
    }
    return parseFloat(value) > 0;
  })
  return isSplitLimitPresent;
}

export const isBothCslAndSplitLimitsPresent = (limitTypes)=>{
  return isCslLimitPresent(limitTypes) && isSplitLimitPresent(limitTypes);
}
const getLimitsType = (policyType, isProperty) =>
  (isProperty ? policyType : policyType && policyType.refpolicyLimitTypes) || [];

const generateTableFromLimits = ({ policy, mode, source, dataFormat, isProperty }) => {
  let __policyLimitTableRows = [];
  __policyLimitTableRows =
    (getLimitsType(policy, isProperty).length &&
      _.map(getLimitsType(policy, isProperty), (pLimit) => {
        return {
          ...pLimit,
          key: pLimit.id,
          id: pLimit.id,
          limitType: pLimit.name,
          limits: pLimit.limits
            ? pLimit.limits
            : pLimit.value
              ? pLimit.value
              : 0,
          limitTypeId: pLimit.limitTypeId ? pLimit.limitTypeId : pLimit.id,
          deductible: pLimit.deductible ? pLimit.deductible : 0,
          countryId: pLimit.countryId ? pLimit.countryId : 1,
          uiConfig:
            typeof pLimit.uiConfig === "undefined"
              ? pLimit.name
              : pLimit.uiConfig
        };
      })) ||
    [];
  return __policyLimitTableRows;
};

export const _PolicyLimitTable = () => {
  return {
    getLimitsType,
    generateTableFromLimits
  };
};
