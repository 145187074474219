import { Avatar, Card, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GenericListForSelectedDisplay } from "../../../Modules/Template/CollapsibleContent/GenericListForSelectedDisplay";
// import LimitTable from "../../../Modules/Template/CollapsibleContent/LimitTable";
import { PolicyAgency } from "../PolicyAgency/PolicyAgency";
import { PolicyEndorsment } from "../PolicyEndorsment/PolicyEndorsment";
import { PolicyInsurerDetails } from "../PolicyList/PolicyDetails/PolicyInsurerDetails/PolicyInsurerDetails";
import { PolicyUploadedDocuments } from "../PolicyUploadedDocuments/PolicyUploadedDocuments";
import { PolicyCoverageTypes } from "../PolicyCoverageTypes/PolicyCoverageTypes";
import LimitTable from "../LimitTable/LimitTable";
import LimitTypeModal from "../../../Components/Policy/LimitTable/PolicyLimitTypeModal";
import LimitTypeTable from "../../../Components/Policy/LimitTable/PolicyLimitTypeTable";
import { APPLICATION_USER_ROLES, CERTIFICIAL_POLICY_TYPES, PROPERTY_LIMITS_KINDS } from "../../../Utils/Constants/Application.constants";
import { WorkesCompExclusion } from "../../Template/EditTemplate/policyCoverageForm/WorkersCompExclusion/WorkersCompExclusion";
import { PolicyAnyProprietor } from "../PolicyList/PolicyAnyProprietor/PolicyAnyProprietor";

export const PolicyCollapseBody = ({
  isRequestPolicyScreen,
  isPolicyEndorsment,
  policyEndorsements,
  policyEndorsementsOnCheck,
  policyEndorsementsOnEnterData,
  policyEndorsementsReadOnly,

  isPolicyLimitTypeTable,
  limitTableTitle,
  limitTableColumns,
  limitTableData,
  selectedCoverageTypes,
  selectedRequiredDocumentTypes,
  isPolicyCoverageTypes,
  isRequiredDocumentTypes,
  isPolicyAgency,
  isPolicyInsurer,
  isPolicyDocs,
  policyActions,

  isAdditionalDocs,
  additionalDocs,
  isPolicyCoverageTypesCompliance,
  isPolicyEndorsementsCompliance,
  ratingCompliance,
  boilerMachineryOptionDesc,
  coInsuranceDesc,
  marginClauseDesc,
  otherCtDesc1,
  otherCtDesc2,
  isRestrictedCoverageInput,
  regionId,
  policyType,
  activeProject,
  isPolicyCollapse,
  requestCompliance,

  isEmailCoiUpload,
  emailPolicyTypes
}) => {
  const [limitModalVisible, setLimitModalVisible] = useState(false);
  const [limitKind, setLimitKind] = useState("");
  const [limitModalTitle, setLimitModalTitle] = useState("");
  const [limitTableDataCount, setlimitTableDataCount] = useState([]);
  const { role } = useSelector((state) => state.myProfile);
  const showLimits = (limType) => {
    if (limType == "property") {
      setLimitKind(PROPERTY_LIMITS_KINDS.POLICY_LIMIT);
      setLimitModalTitle("Policy Limits");
      setLimitModalVisible(true);
    } else if (limType == "blanket") {
      setLimitKind(PROPERTY_LIMITS_KINDS.BLANKET);
      setLimitModalTitle("Blanket Limits");
      setLimitModalVisible(true);
    } else if (limType == "location") {
      setLimitKind(PROPERTY_LIMITS_KINDS.LOCATION);
      setLimitModalTitle("Location Limits");
      setLimitModalVisible(true);
    }
  };

  useEffect(() => {
    if(isPolicyCollapse){
      if(limitTableData){
        setlimitTableDataCount(limitTableData)
      }
    } else {
      let output = [];
      limitTableData && limitTableData.map(obj => {
        if(obj.policy_type_id === 5) {
          output.push(obj)
        }
        return output
     })
    setlimitTableDataCount(output)
    }
  }, [limitTableData]);

  return (
    <div>
      <LimitTypeModal
        // policyId={}
        source={"template"}
        fixedLimitData={limitTableTitle === "Property" ? limitTableDataCount : limitTableData}
        title={limitModalTitle}
        type={limitKind}
        limitVisible={limitModalVisible}
        onClose={() => setLimitModalVisible(false)}
      ></LimitTypeModal>
      {(isPolicyInsurer && (
        <PolicyInsurerDetails
          policyInsurerName={null}
          policyInsurerNAIC={null}
          financialSizeCategoryAlpha={null}
          policyInsurerEffectiveFrom={null}
          policyInsurerEffectiveTo={null}
          ambestCarrierRating={null}
        />
      )) ||
        null}
      <Row gutter={[16, 16]} style={{ padding: 9 }}>
        <Col span={12}>
          {(isPolicyAgency && (
            <PolicyAgency
              isTitleRequired={true}
              readOnlyMode={true}
              width={""}
              selectedPolicyAgencyType={() => {}}
              onPolicyAgencySelection={() => {}}
              shouldMakeApiCall={false}
            />
          )) ||
            null}
          {ratingCompliance}
          {(isPolicyEndorsment && (
            <PolicyEndorsment
              sliceEndorsementText={true}
              comp_key="isCompliance"
              policyType={limitTableTitle}
              isTitleRequired={true}
              policyEndorsements={policyEndorsements}
              onCheck={policyEndorsementsOnCheck}
              onEnterData={policyEndorsementsOnEnterData}
              readOnlyMode={policyEndorsementsReadOnly}
              includeEndorsementDocument={true}
              includeEndorsementDocumentText={"Doc Required"}
              policyEndorsementsCompliance={isPolicyEndorsementsCompliance}
            />
          )) ||
            null}
          {isRequiredDocumentTypes && (
            <GenericListForSelectedDisplay
              isTitleRequred={true}
              obj_key="documentDescription"
              obj={selectedRequiredDocumentTypes}
              title={
                limitTableTitle === "Commercial General Liability" &&
                regionId &&
                regionId.toString() === "2"
                  ? "Applicable Documents"
                  : "Additional Endorsement Documents"
              }
              isBordered={true}
              readOnly={true}
              isRequiresReviewApplicable={role !== APPLICATION_USER_ROLES.AGENT}
            />
          )}
          {(isPolicyCoverageTypes && (
            <PolicyCoverageTypes
              isEmailCoiUpload={isEmailCoiUpload}
              emailPolicyTypes = {emailPolicyTypes}
              excludeOther = {isRequestPolicyScreen}
              isRestrictedCoverageInput={isRestrictedCoverageInput}
              policyType={limitTableTitle}
              readOnlyMode={true}
              coverageTypeCompliance={isPolicyCoverageTypesCompliance}
              coverageTypes={selectedCoverageTypes.map((s) => ({
                ...s,
                isApplied: true
              }))}
              boilerMachineryOptionDesc={boilerMachineryOptionDesc}
              coInsuranceDesc={coInsuranceDesc}
              marginClauseDesc={marginClauseDesc}
              otherCtDesc1={otherCtDesc1}
              otherCtDesc2={otherCtDesc2}
              andOr={true}
              regionId={regionId}
            />
          )) ||
            null}
          {(isAdditionalDocs && (
            <PolicyUploadedDocuments
              isTitleRequred={true}
              title={
                limitTableTitle === "Commercial General Liability" &&
                regionId &&
                regionId.toString() === "2"
                  ? "Applicable Documents"
                  : "Additional Endorsement Document"
              }
              documentsList={additionalDocs}
              selectedProjectDetails={activeProject}
            />
          )) ||
            null}
        </Col>
        <Col span={12}>
          {limitTableTitle == "Property" && parseInt(regionId) !== 2 ? (
            <LimitTypeTable
              blanketLimitCount={0}
              policyLimitCount={limitTableTitle === "Property" ? limitTableDataCount && limitTableDataCount.length : limitTableData && limitTableData.length}
              // policyTypeRequests={policyType}
              locationCount={0}
              title={limitTableTitle}
              onShowLimit={showLimits}
              requestCompliance={requestCompliance}
              isEmailCoiUpload
            />
          ) : (
            (isPolicyLimitTypeTable && (
              <LimitTable
                readOnlyMode={true}
                title={limitTableTitle}
                tableColumns={limitTableColumns}
                tableData={limitTableData}
              />
            )) ||
            null
          )}
          {policyType?.policytype === CERTIFICIAL_POLICY_TYPES.WORKERS_COMPENSATION_AND_EMPLOYEES_LIABILITY && (
              <PolicyAnyProprietor
                bordered={true}
                defaultValueCheckbox={policyType?.exclusions?.anyWorkerIncluded}
                defaultValueRadio={policyType?.exclusions?.anyTextSelected}
                defaultValueComment={policyType?.exclusions?.any}
                isTitleRequired={false}
                readOnlyMode={true}
                policy={policyType}
                companyTrackedPolicy={requestCompliance}
                isColorRed={true}
                isOverSizeText={role === APPLICATION_USER_ROLES.AGENT}
              />
           )}
          {(isPolicyDocs && (
            <PolicyUploadedDocuments
              isTitleRequired={true}
              documentsList={[]}
              selectedProjectDetails={activeProject}
            />
          )) ||
            null}
        </Col>
      </Row>
      { policyType?.name === CERTIFICIAL_POLICY_TYPES.WORKERS_COMPENSATION_AND_EMPLOYEES_LIABILITY  &&<div style={{margin:"0px 8px 20px 8px"}}> <WorkesCompExclusion
                  readOnly={true}
                  coverage={policyType}
              /></div>}
      {(policyActions && policyActions.length && (
        <>
          <Divider dashed style={{ margin: "12px 0" }} />
          <Row
            gutter={[16, 16]}
            type="flex"
            justify="end"
            style={{ padding: "0 16px 16px" }}
          >
            <Col span={12}></Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {policyActions.map((pAction, idx) => (
                <React.Fragment key={idx}>{pAction}</React.Fragment>
              ))}
            </Col>
          </Row>
        </>
      )) ||
        null}
    </div>
  );
};
