export const SUPPLIER_ADRESSSBOOK_TYPES = {
  GET_ADDRESSBOOK: {
    SUCCESS: "GET_SUPPLIER_ADRESSSBOOK_SUCCESS",
    ERROR: "GET_SUPPLIER_ADRESSSBOOK_ERROR"
  },
  POST_ADDRESSBOOK: {
    SUCCESS: "POST_SUPPLIER_ADRESSSBOOK_SUCCESS",
    ERROR: "POST_SUPPLIER_ADRESSSBOOK_ERROR"
  },
  CLEAR_ADDRESSBOOK: "CLEAR_GET_SUPPLIER_ADDRESSBOOK",
  CLEAR_ADDRESSES: "CLEAR_GET_SUPPLIER_ADDRESSES",
  SET_LOADING: "SET_LOADING",
  SET_LIST_OF_ADDRESS_BOOK_GROUP: {
    SUCCESS: "SET_LIST_OF_ADDRESS_BOOK_GROUP_SUCCESS",
    ERROR: "SET_LIST_OF_ADDRESS_BOOK_GROUP_ERROR"
  },
  SET_SELECTED_ADDRESS_BOOK_GROUP: {
    SUCCESS: "SET_SELECTED_ADDRESS_BOOK_GROUP_SUCCESS",
    ERROR: "SET_SELECTED_ADDRESS_BOOK_GROUP_ERROR"
  },
  SET_SUPPLIER_ADDRESSES: "SET_SUPPLIER_ADDRESSES",
  SET_SELECTED_ADDRESS_BOOK_GROUP_ID: {
    SUCCESS: "SET_SELECTED_ADDRESS_BOOK_GROUP_ID_SUCCESS", 
  },
};
