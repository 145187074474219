import { REQUESTOR_REQUEST_POLICY_TYPES } from "./RequestorRequestPolicies.types";
import * as _ from "lodash";
const requestorVerificationRequestInitState = {
  title: "",
  verificationRequests: [],
  selectedVerificationRequest: {},
  isError: false,
  isDataAvailable: false,
  totalPageCount: null,
  projectsForSelectedCompany: [],
  activeProject: null
};

const Base_RequestorVerificationRequestInitState = {
  ...requestorVerificationRequestInitState
};

const RequestorRequestPoliciesReducer = (
  state = requestorVerificationRequestInitState,
  { type, payload }
) => {
  switch (type) {
    case REQUESTOR_REQUEST_POLICY_TYPES.CREATE_DUMMY_REQUESTS:
      return {
        ...state,
        verificationRequests: state.verificationRequests.concat(
          [...new Array(payload)].map(() => ({
            loading: true,
            name: {}
          }))
        )
      };
    case REQUESTOR_REQUEST_POLICY_TYPES.CLEAR_DUMMY_REQUESTS:
      return { ...state };

    case REQUESTOR_REQUEST_POLICY_TYPES.GET_ALL_REQUEST_OF_VERIFICATIONS
      .SUCCESS: {
      return {
        ...state,
        totalPageCount: payload.totalPageCount,
        verificationRequests: payload.newVerificationRequests,
        isDataAvailable: true,
        isError: false
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.IS_DATA_AVAILABLE: {
      return { ...state, isDataAvailable: payload };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.GET_ALL_REQUEST_OF_VERIFICATIONS
      .ERROR: {
      return {
        ...state,
        verificationRequests: [],
        selectedVerificationRequest: {},
        isDataAvailable: false,
        isError: true
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS
      .SUCCESS: {
      const newVerificationRequests = _.map(
        state.verificationRequests,
        (obj) => {
          return {
            ...obj,
            ...{
              isActive:
                (obj &&
                  obj.insuredCompanyId &&
                  payload &&
                  payload.insuredCompanyId &&
                  obj.insuredCompanyId === payload.insuredCompanyId) ||
                (obj &&
                  obj.insuredUserId &&
                  payload &&
                  payload.insuredUserId &&
                  obj.insuredUserId === payload.insuredUserId)
            }
          };
        }
      );
      return {
        ...state,
        verificationRequests: newVerificationRequests,
        selectedVerificationRequest: payload
      };
    }

    case REQUESTOR_REQUEST_POLICY_TYPES.GET_DETAILS_OF_REQUEST_OF_VERIFICATIONS
      .ERROR: {
      return {
        ...state,
        selectedVerificationRequest: {}
      };
    }

    case REQUESTOR_REQUEST_POLICY_TYPES.DELETE_REQUEST_OF_VERIFICATION
      .SUCCESS: {
      return {
        ...state,
        verificationRequests: payload,
        selectedVerificationRequest: {},
        isDataAvailable: !!payload.length,
        isError: false
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.RESET_SELECTED_VERIFICATION_DETAILS: {
      return {
        ...state,
        selectedVerificationRequest: {}
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.CLEAR_REQUEST_OF_VERIFICATIONS: {
      return {
        ...Base_RequestorVerificationRequestInitState
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.GET_PROJECTS_LIST.SUCCESS: {
      return {
        ...state,
        projectsForSelectedCompany: payload
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.GET_PROJECTS_LIST.ERROR: {
      return {
        ...state
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.SET_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: payload
      };
    }
    case REQUESTOR_REQUEST_POLICY_TYPES.CLEAR_ACTIVE_PROJECT: {
      return {
        ...state,
        activeProject: null
      };
    }

    default:
      return state;
  }
};

export default RequestorRequestPoliciesReducer;
