export const UPLOADCERT_TYPES = {
    CLEAR_UPLOADCERTS: "CLEAR_UPLOADCERTS",
    GET_UPLOADCERT_LIST: {
        SUCCESS: "GET_UPLOADCERT_LIST_SUCCESS",
        ERROR: "GET_UPLOADCERT_LIST_ERROR",
        RESET: "GET_UPLOADCERT_LIST_RESET"
    },
    SET_UPLOADCERT_EXCEL_UPLOAD: {
        SUCCESS: "SET_UPLOADCERT_EXCEL_UPLOAD_SUCCESS",
        ERROR: "SET_UPLOADCERT_EXCEL_UPLOAD_ERROR"
    },
    SET_UPLOADCERT_PDF_UPLOAD: {
        SUCCESS: "SET_UPLOADCERT_PDF_UPLOAD_SUCCESS",
        ERROR: "SET_UPLOADCERT_PDF_UPLOAD_ERROR"
    },
    SET_LOADING: "SET_LOADING",
};
