import Loadable from "react-loadable";
import { AppLoader } from "../Components/Loader/AppLoader";

export const LoginComponent = Loadable({
  loader: () => import("../Modules/Authorization/Login/Login"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const AllNotificationComponent = Loadable({
  loader: () => import("../Modules/AllNotification/AllNotification"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const ManageProjects = Loadable({
  loader: () => import("../Modules/ManageProjects/ManageProjects"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const MyProfileComponent = Loadable({
  loader: () => import("../Modules/User/MyProfile"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const MyTeamComponent = Loadable({
  loader: () => import("../Modules/MyTeam/MyTeam"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const CompanyAdminComponent = Loadable({
  loader: () => import("../Modules/CompanyAdmin/CompanyAdmin"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const AMSManagementComponent = Loadable({
  loader: () => import("../Modules/CompanyAdmin/AMSManagement/AMSManagement"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const CompanyDashboardComponent = Loadable({
  loader: () => import("../Modules/CompanyDashboard/CompanyDashboard"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const CompanyProfileComponent = Loadable({
  loader: () => import("../Modules/User/CompanyProfile"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const UserManagementComponent = Loadable({
  loader: () =>
    import("../Modules/CompanyAdmin/TeamAndUsersMenu/TeamAndUsersMenu"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const MyAddressBookComponent = Loadable({
  loader: () => import("../Modules/MyAddressBook/MyAddressBook"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const UpgradeComponent = Loadable({
  loader: () => import("../Modules/Upgrade/Upgrade"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const ContactUsComponent = Loadable({
  loader: () => import("../Modules/ContactUs/ContactUs"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const PageNotFoundComponent = Loadable({
  loader: () => import("../Modules/Exceptions/404/404"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const NetworkErrorComponent = Loadable({
  loader: () => import("../Modules/Exceptions/NetWork/NetWorkError"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const UnAuthorizedErrorComponent = Loadable({
  loader: () => import("../Modules/Exceptions/403/403"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const ServerErrorComponent = Loadable({
  loader: () => import("../Modules/Exceptions/500/500"),
  loading: AppLoader,
  delay: 1000,
  timeout: 10000
});

export const AddClientBasedOnSearchComponent = Loadable({
  loader: () =>
    import(
      "../Components/InviteClientThroughAgent/AddClientBasedOnSearch/AddClientBasedOnSearch"
    ),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const SearchCoiIdForInsurance = Loadable({
  loader: () =>
    import(
      "../Components/TrackedCompaniesComponents/CoiSearchData"
    ),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const AddNewClientFromAgentComponent = Loadable({
  loader: () =>
    import(
      "../Components/InviteClientThroughAgent/AddNewClientFromAgent/AddNewClientFromAgent"
    ),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const ManageComplianceComponent = Loadable({
  loader: () => import("../Modules/Agent/ManageCompliance/ManageCompliance"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});
export const RequestorPaymentResultsComponent = Loadable({
  loader: () =>
    import("../Modules/Exceptions/Payments/RequestorPaymentResults"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const AgentClientAddressBookComponent = Loadable({
  loader: () =>
    import(
      "../Modules/Agent/Client/AgentClientAddressBook/AgentClientAddressBook"
    ),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const SupplierClientAddressBookComponent = Loadable({
  loader: () =>
    import(
      "../Modules/Agent/Client/AgentClientAddressBook/AgentClientAddressBook"
    ),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const AppContainerComponent = Loadable({
  loader: () => import("../Layout/AppContainer/AppContainer"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const SuppliersComponent = Loadable({
  loader: () => import("../Modules/Suppliers/Suppliers"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});


export const DashboardComponent = Loadable({
  loader: () => import("../Modules/Dashboard/Dashboard"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const TemplateComponent = Loadable({
  loader: () => import("../Modules/Template/Template"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const RequestPolicyComponent = Loadable({
  loader: () => import("../Modules/RequestPolicies/RequestPolicies"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const SharedPolicyComponent = Loadable({
  loader: () => import("../Modules/SharedPolicies/SharedPolicies"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const MyPolicyComponent = Loadable({
  loader: () => import("../Modules/MyPolicies/MyPolicies"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const PendingInsuredRequestsComponent = Loadable({
  loader: () =>
    import("../Modules/PendingInsuredRequests/PendingInsuredRequests"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const ClientComponent = Loadable({
  loader: () => import("../Modules/Agent/Client/Client"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const AgentPendingRequestsComponent = Loadable({
  loader: () =>
    import("../Modules/Agent/AgentPendingRequests/AgentPendingRequests"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const VerificationUserComponent = Loadable({
  loader: () =>
    import("../Modules/Authorization/VerificationUser/VerificationUser"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const LogoutComponent = Loadable({
  loader: () => import("../Modules/Authorization/Logout/Logout"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const UploadCertificatesComponent = Loadable({
  loader: () => import("../Modules/GRMS/UploadCertificates/UploadCertificates"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const PaymentGatewayComponent = Loadable({
  loader: () => import("../Modules/PaymentGateway/PaymentGateway"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});

export const RequestorComplianceComponent = Loadable({
  loader: () => import("../Modules/RequestorCompliance/RequestorCompliance"),
  loading: AppLoader,
  delay: 0,
  timeout: 10000
});
