import React from "react";
import { Card, Col, Row, Typography, Button, Avatar } from "antd";
import { appHistory } from "../../../Utils/Helpers/History";
import { successImage } from "../../../Assets/assets";
const { Title } = Typography;
const ResetPasswordSuccess = () => {
  return (
    <Row
      type="flex"
      justify="center"
      gutter={[8, 16]}
      style={{ paddingTop: 20, margin: 0 }}
    >
      <Col span={12}>
        <Card>
          <Row
            type="flex"
            justify="center"
            gutter={[8, 16]}
            className="contact-form"
          >
            <Col>
              <Avatar src={successImage} size={56} />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[8, 16]}>
            <Col>
              <Title level={3}>Success!</Title>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[8, 16]}>
            <Col>
              <p style={{ textAlign: "center" }}>
                Your password has been successfully changed. Please try to log
                in again with your new password.
              </p>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[8, 16]}>
            <Col style={{ paddingTop: 12 }}>
              <Button
                type="primary"
                block
                onClick={() => appHistory.push("/login")}
              >
                Go Back To Login Screen
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPasswordSuccess;
