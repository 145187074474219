import React from "react";
import './EmailTemplateCommon.less';
import certificial from "../../Assets/Logos/certificial-logo-old.png";
import { Row, Col } from "antd";
 const EmailHeader = () => {
	return (<>
	<Row type="flex" justify="center">
				<Col style={{margin:'0 0 33px 0'}}>
					<img className={"logoImage"} src={certificial} />
				</Col>
			</Row>
	</>)
}
export default EmailHeader