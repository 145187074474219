import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Col,
  Row,
  Alert,
  Icon,
  Divider
} from "antd";
import { withRouter } from "react-router";
import certificial from "../../../Assets/Logos/certificial-logo-old.png";
import { resetPassword_action } from "../../../Store/Modules/Application/Application.asyncRequest";
import { _authService } from "../../../Utils/Services/AuthenticationService";
import { strongPasswordVerification } from "../../../Utils/utility/app.utility";
import { PasswordStrength } from "../PasswordStrength/PasswordStrength";
import { appHistory } from "../../../Utils/Helpers/History";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class ResetPassword extends Component {
  state = {
    confirmDirty: false,
    errorMessage: "",
    resetPasswordError: false,
    iconLoading: false
  };

  componentDidMount() {
    _authService.logoutUser();
    _authService.clearUserInfo();
    _authService.clearUserRole();
    // To disable submit button at the beginning.
    this.props.form.validateFields((errors, values) => {});
  }

  logoStyle = {
    height: "57px",
    width: "100%",
    backgroundImage: "url(" + certificial + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    marginTop: "24px"
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Password does not match");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    if (!!value) {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirmPassword"], { force: true });
      }
      const strongnessOfPassword = strongPasswordVerification(value);
      if (!!strongnessOfPassword) {
        callback(strongnessOfPassword);
      }
    }
    callback();
  };

  getLoader = () => {
    this.setState({ iconLoading: true });
  };

  handleSubmit = (e) => {
    this.setState({ resetPasswordError: false });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let resetPasswordModel = {
          ...values,
          resetToken: this.props.match.params.token
        };
        resetPassword_action(resetPasswordModel).then(
          (response) => {
            this.setState({ iconLoading: false });
            this.setState({ resetPasswordError: false });
            this.props.history.push("/reset-password-success");
          },
          (error) => {
            this.setState({ iconLoading: false });
            this.setState({ resetPasswordError: true });
            this.setState({ errorMessage: error.message });
          }
        );
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    const confirmError =
      isFieldTouched("confirmPassword") && getFieldError("confirmPassword");

    return (
      <React.Fragment>
        <Row type="flex" align="middle" justify="center">
          <Col span={8}>
            <Card size="small" hoverable>
              <div style={this.logoStyle} />
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col span={22} className="p-12">
                    <Form.Item
                      label="Password"
                      hasFeedback
                      validateStatus={passwordError ? "error" : ""}
                      help={passwordError || ""}
                    >
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter your password."
                          },
                          {
                            validator: this.validateToNextPassword
                          }
                        ]
                      })(
                        <Input.Password
                          placeholder="Password"
                          // maxLength={12}
                          minLength={8}
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.45)" }}
                            />
                          }
                          autoComplete="new-password"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <div style={{ lineHeight: "4.6" }}>
                      <PasswordStrength />
                    </div>
                  </Col>
                  <Col span={22} className="p-12">
                    <Form.Item
                      label="Confirm Password"
                      hasFeedback
                      validateStatus={confirmError ? "error" : ""}
                      help={confirmError || ""}
                    >
                      {getFieldDecorator("confirmPassword", {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "Please confirm your password!"
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <Input.Password
                          placeholder="Confirm Password"
                          onBlur={this.handleConfirmBlur}
                          autoComplete="new-confirm-password"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {!!this.state.resetPasswordError && this.state.errorMessage && (
                  <div className="p-12" style={{ marginBottom: 12 }}>
                    <Alert message={this.state.errorMessage} type="error" />
                  </div>
                )}
                <Row
                  type="flex"
                  justify="center"
                  gutter={[8, 16]}
                  style={{ paddingTop: 12, marginTop: 18 }}
                >
                  <Col span={11}>
                    <Button
                      type="primary"
                      block
                      ghost
                      onClick={() => {
                        appHistory.push("/");
                      }}
                    >
                      Back To Login
                    </Button>
                  </Col>
                  <Col span={11}>
                    <Button
                      type="primary"
                      block
                      htmlType="submit"
                      disabled={hasErrors(getFieldsError())}
                      loading={this.state.iconLoading}
                      onClick={this.getLoader}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Divider dashed />
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    Forgot password{"? "}
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() => {
                        appHistory.push("/forgot-password");
                      }}
                    >
                      Click here
                    </Button>{" "}
                    to reset the password.
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const resetPassword = withRouter(
  Form.create({ name: "resetPassword" })(ResetPassword)
);

export default resetPassword;
