export const SHARED_POLICIES_ACTION_TYPES = {
  DUMMY_SHARED_COMPANIES: "DUMMY_SHARED_COMPANIES",
  CLEAR_SHARED_COMPANIES: "CLEAR_SHARED_COMPANIES",
  CLEAR_SHARED_COMPANIES_LIST_VIEW: "CLEAR_SHARED_COMPANIES_LIST_VIEW",
  CLEAR_SHARED_COMPANIES_GRAPH_VIEW: "CLEAR_SHARED_COMPANIES_GRAPH_VIEW",
  CLEAR_MY_POLICIES_TO_SHARE: "CLEAR_MY_POLICIES_TO_SHARE",
  GET_SHARED_COMPANIES_LIST_VIEW: {
    SUCCESS: "GET_SHARED_COMPANIES_LIST_VIEW_SUCCESS",
    ERROR: "GET_SHARED_COMPANIES_LIST_VIEW_ERROR"
  },
  SET_ACTIVE_SHARED_COMPANIES: "SET_ACTIVE_SHARED_COMPANIES",
  SET_POLICY_EXISTING_LOCATIONS: "SET_POLICY_EXISTING_LOCATIONS",
  GET_SHARED_POLICY_GRAPH_VIEW: {
    SUCCESS: "GET_SHARED_POLICY_GRAPH_VIEW_SUCCESS",
    ERROR: "GET_SHARED_POLICY_GRAPH_VIEW_ERROR"
  },
  GET_CLIENT_POLICIES_TO_SHARE_FOR_AGENT: {
    SUCCESS: "GET_CLIENT_POLICIES_TO_SHARE_SUCCESS_FOR_AGENT"
  },
  GET_MY_POLICIES_TO_SHARE: {
    SUCCESS: "GET_MY_POLICIES_TO_SHARE_SUCCESS",
    ERROR: "GET_MY_POLICIES_TO_SHARE_ERROR"
  },
  SELECTED_COMPANY_DRAWER: {
    SUCCESS: "SELECTED_COMPANY_DRAWER_SUCCESS",
    ERROR: "SELECTED_COMPANY_DRAWER_ERROR"
  },
  SELECTED_COMPANY_LIST_VIEW: {
    SUCCESS: "SELECTED_COMPANY_LIST_VIEW_SUCCESS",
    ERROR: "SELECTED_COMPANY_LIST_VIEW_ERROR"
  },
  SELECTED_COMPANY_DRAWER_POLICIES: {
    SUCCESS: "SELECTED_COMPANY_DRAWER_POLICIES_SUCCESS"
  },
  SELECTED_COMPANY_DRAWER_POLICY_DELETE: {
    SUCCESS: "SELECTED_COMPANY_DRAWER_POLICY_DELETE",
    ERROR: "SELECTED_COMPANY_DRAWER_POLICY_ERROR"
  },
  GET_SHARED_PROJECTS_GRAPH_VIEW: {
    SUCCESS: "GET_SHARED_PROJECTS_GRAPH_VIEW_SUCCESS",
    ERROR: "GET_SHARED_PROJECTS_GRAPH_VIEW_ERROR"
  },
  SET_INSURER_RESHARE_DATA: {
    SUCCESS: "SET_INSURER_RESHARE_DATA_SUCCESS",
    ERROR: "SET_INSURER_RESHARE_DATA_ERROR",
    RESET: "SET_INSURER_RESHARE_DATA_RESET"
  },
  SAVE_CERT_UPLOAD_STATUS_RE_RESPOND: "SAVE_CERT_UPLOAD_STATUS_RE_RESPOND",
  SAVE_TOTAL_PAGINATION: "SAVE_TOTAL_PAGINATION",
  SET_ACTIVE_PROJECT: "INSURED_SET_ACTIVE_PROJECT",
  CLEAR_ACTIVE_PROJECT: "INSURED_CLEAR_ACTIVE_PROJECT",
  REFRESH_COMPANY_STATUS: {
    SET: "SET_REFRESH_COMPANY",
    CLEAR: "CLEAR_REFRESH_COMPANY"
  }
};
