import React, {useEffect} from "react";
import { Avatar, Card, Row, Col, Badge, Button, Modal, Icon, Tag } from "antd";
import CertInfoTooltip from "../../UI/CertTooltip/CertInfoTooltip";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import { tableIcon } from "../../../Assets/assets";
import { useState } from "react";
import { PolicyLocationLimit } from "../PolicyLocationLimit/PolicyLocationLimit";
import { CERTIFICIAL_TABLE_COLUMNS } from "../../../Utils/Constants/TableColumns.constants";
import LimitTypeModal from "./PolicyLimitTypeModal";
import { CERTIFICIAL_STATUS } from "../../../Utils/Constants/Application.constants";

const LimitTypeTable = ({
  isLimitError,
  blanketLimitCount,
  policyLimitCount,
  locationCount,
  loading,
  title,
  onShowLimit,
  policyTypeRequests,
  complianceData,
  requestCompliance,
  policyID,
  isEmailCoiUpload // true when componet called src\Modules\PendingInsuredRequests\CertificateUploadContainer\CoiUploadPage.js
}) => {
  const [nonComplianceVisible, setNonComplianceVisible] = useState(false);
  const [compliance, secomplianceData] = useState({});
  useEffect(() => {
    requestCompliance && requestCompliance.map((item) => {
      if(item.isUSPropertyLimitIssue){
        setNonComplianceVisible(true)
      }})
      if(complianceData){
        let data = []
        complianceData && complianceData.map((item) => {
        if(item.policyId === policyID ) {
          data.push(item) 
        }})
        secomplianceData(data[0])
      }
  }, [requestCompliance, policyID, complianceData]);

  return (
    <>
      <Card
        size="small"
        bordered={true}
        className="widget-card"
        loading={loading}
        bodyStyle={{
          padding: 0,
          borderTop: "1px solid rgba(83, 105, 248, 0.2)"
        }}
        title={
          <div style={{ display: "inline-flex" }}>
            <Avatar
              src={tableIcon}
              size="default"
              shape="square"
              className="margin-right-sm"
            />
            <div>
              <h6 className={isLimitError ? "extraction-data-error margin-0":"margin-0"}>Limit Types</h6>
              <h5 className={isLimitError ? "extraction-data-error margin-0":"margin-0"}>{title}</h5>
            </div>
          </div>
        }
        extra={null}
      >
        <>
          {(policyLimitCount && (
            <>
              <Col span={isEmailCoiUpload && isEmailCoiUpload === true ? 21 : 22}>
                <span
                  onClick={() => onShowLimit("property")}
                  style={(policyTypeRequests && policyTypeRequests.verificationStatus === 'Compliance') || (compliance && compliance.limitcomparison && compliance.limitcomparison.islimitissue ) || (compliance && compliance.limitissue) || (compliance && compliance.isLimitComplianceIssue) || nonComplianceVisible ? { color: "#f30b17", cursor: "pointer" } : { color: "#3596db", cursor: "pointer" }}
                >
                  {" "}
                  {policyLimitCount + " "}Policy Limits
                </span>
                {(policyTypeRequests && policyTypeRequests.verificationStatus === 'Compliance') || (compliance && compliance.limitcomparison && compliance.limitcomparison.islimitissue ) || (compliance && compliance.limitissue) || (compliance && compliance.isLimitComplianceIssue)|| nonComplianceVisible ? <span style={{ fontSize: "16px", color: "#3596db", marginLeft: '50px'}}>
                <Tag color="#f30b17" >
                  {CERTIFICIAL_STATUS.POLICY_STATUS.HYPHEN_NON_COMPLIANT}
                </Tag>
               </span> : null}
              </Col>
              <Col style={{ fontSize: "16px", color: "#3596db" }} span={2}>
                <Icon type={"eye"} onClick={() => onShowLimit("property")} />
              </Col>
            </>
          )) || <></>}

          {(blanketLimitCount && (
            <>
              <Col span={22}>
                <span
                  style={{ color: "#3596db", cursor: "pointer" }}
                  onClick={() => onShowLimit("blanket")}
                >
                  {blanketLimitCount + "  "}Blanket Limits{" "}
                </span>
              </Col>
              <Col style={{ fontSize: "16px", color: "#3596db" }} span={2}>
                <Icon type={"eye"} onClick={() => onShowLimit("blanket")} />
              </Col>
            </>
          )) || <></>}

          {(locationCount && (
            <>
              <Col span={22}>
                <span
                  onClick={() => onShowLimit("location")}
                  style={{ color: "#3596db", cursor: "pointer" }}
                >
                  {locationCount + " "}Location{locationCount > 1 ? "s" : ""}{" "}
                </span>
              </Col>
              <Col style={{ fontSize: "16px", color: "#3596db" }} span={2}>
                <Icon type={"eye"} onClick={() => onShowLimit("location")} />
              </Col>
            </>
          )) || <></>}
        </>
      </Card>
    </>
  );
};

export default LimitTypeTable;
