import React, { useEffect, useState } from "react";

const LoginCaptcha = (props) => {
    useEffect(() => {
        const c = document.getElementById("myCanvas");
        const ctx = c.getContext("2d");
        ctx.reset();
        ctx.fillText("", 50, 34);
        ctx.beginPath();
        let count = 12;
        ctx.moveTo(0, 12);
        ctx.lineTo(300, 12);
        ctx.strokeStyle = '#3894b4';
        for (count; count < 60; count++) {
            count = count + 10;
            ctx.moveTo(0, count);
            ctx.lineTo(300, count);
            ctx.strokeStyle = '#3894b4';
        }

        //ctx.font = "bold 24px Cursive";
        ctx.font = "bold 24px Tahoma";
        //ctx.font = "bold 24px Times new roman";
        //ctx.font = "bold 24px Verdana";
        //ctx.font = "bold 24px Arial";
        //ctx.font = "bold 24px Helvetica"

        ctx.fillStyle = "#3894b4"
        ctx.textAlign = "center";
        ctx.fillText(props.captchaValue, 48, 32);

        ctx.stroke();
    }, [props.captchaValue])
    return (
        <div>
            <canvas id="myCanvas" width="95" height="45" style={{ backgroundColor: "#baeeff" }}> {
                //#baeeff
                //#a4d4e4
            }
                Certificial
            </canvas>
        </div>
    )
}

export default LoginCaptcha;