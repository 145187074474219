import { Icon, Tabs, Typography, Badge } from "antd";
import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LazyAgentRepondPolicyPendingRequests,
  LazyAgentSharePolicyPendingRequests,
  LazyAgentPendingVerification
} from "../../../Routes/LazyInnerModules";
import {
  getPendingApproval,
  getOpenRequestCount
} from "../../../Store/Modules/Application/Application.asyncRequest";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import { getBadgeOffset } from "../../../Utils/utility/app.utility";
import "./PendingApprovals.css";

const { TabPane } = Tabs;
const { Title } = Typography;
const PendingApprovals = memo(function PendingApprovals(props) {
  const { pendingApprovalCount } = useSelector((state) => state.app);
  const { id } = useSelector((state) => state.myProfile.userData);
  const dispatch = useDispatch();
  const onRequestTypeSelection = (key) => {};
  const getPendingApprovalCount = useCallback(() => {
    dispatch(getPendingApproval(id));
  }, [id]);
  const getOpenRequestCountDispatch = useCallback(() => {
    dispatch(getOpenRequestCount(id));
  }, [id]);
  const tabclick = () => {
    getOpenRequestCountDispatch();
    getPendingApprovalCount();
  };
  return (
    <div>
      <Tabs
        className="agent-requests"
        defaultActiveKey="1"
        onChange={onRequestTypeSelection}
        onTabClick={tabclick}
        tabBarStyle={{
          textAlign: "right",
          marginTop: 20,
          marginBottom: 0
        }}
        tabBarGutter={11}
        destroyInactiveTabPane={true}
        tabBarExtraContent={
          <Title level={4} className="margin-0">
            Pending Approvals
          </Title>
        }
      >
        <TabPane
          tab={
            <Badge
              title={
                (pendingApprovalCount &&
                  pendingApprovalCount.countPolicyVerfication > 1000 &&
                  APPLICATION_MESSAGES.AGENT.PENDING_APPROVALS.VERIFICATIONS) ||
                null
              }
              overflowCount={999}
              offset={getBadgeOffset(
                pendingApprovalCount &&
                  pendingApprovalCount.countPolicyVerfication,
                999
              )}
              count={
                pendingApprovalCount &&
                pendingApprovalCount.countPolicyVerfication
              }
            >
              <Icon type="check-circle" />
              Policy Verification
            </Badge>
          }
          key="1"
        >
          <LazyAgentPendingVerification />
        </TabPane>
        <TabPane
          tab={
            <Badge
              title={
                (pendingApprovalCount &&
                  pendingApprovalCount.countPolicyShare > 1000 &&
                  APPLICATION_MESSAGES.AGENT.PENDING_APPROVALS.SHARES) ||
                null
              }
              overflowCount={999}
              offset={getBadgeOffset(
                pendingApprovalCount && pendingApprovalCount.countPolicyShare,
                999
              )}
              count={
                pendingApprovalCount && pendingApprovalCount.countPolicyShare
              }
            >
              <Icon type="share-alt" />
              Policy Shares
            </Badge>
          }
          key="3"
        >
          <LazyAgentSharePolicyPendingRequests />
        </TabPane>
        <TabPane
          className="agent-requests-tab"
          tab={
            <Badge
              title={
                (pendingApprovalCount &&
                  pendingApprovalCount.countPolicyRequest > 1000 &&
                  APPLICATION_MESSAGES.AGENT.PENDING_APPROVALS.REQUESTS) ||
                null
              }
              overflowCount={999}
              offset={getBadgeOffset(
                pendingApprovalCount && pendingApprovalCount.countPolicyRequest,
                999
              )}
              count={
                pendingApprovalCount && pendingApprovalCount.countPolicyRequest
              }
            >
              <Icon type="issues-close" />
              Policy Requests
            </Badge>
          }
          key="2"
        >
          <LazyAgentRepondPolicyPendingRequests />
        </TabPane>
      </Tabs>
    </div>
  );
});

export default PendingApprovals;
