import {
  Button,
  Card,
  Col,
  Collapse,
  Icon,
  Input,
  Row,
  Tooltip,
  Typography,
  Popover,
  List,
  Spin,
  Avatar,
  Form,
  message
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { Fragment, useState, useEffect } from "react";
import { tableIcon, verifiedOwnerImage } from "../../../Assets/assets";
import PropertyPolicyLimitTable from "../../Policy/PropertyPolicyLimitTable/PropertyPolicyLimitTable";
import {
  getActionIcon,
  titleMaintainer,
  getCommaSeperatedStr,
  validatePropertyLimit
} from "../../../Utils/utility/app.utility";
import { LOCATION_ACTIONS } from "../../../Utils/Constants/Application.constants";
import "./PolicyLocationLimit.css";
const { Text } = Typography;
const { Panel } = Collapse;
export const PolicyLocationLimit = ({
  panelLoading,
  onPanelOpen,
  ...props
}) => {
  return (
    <Collapse
      accordion
      defaultActiveKey={
        props.keepPanelOpen
          ? props.locationLimits &&
            props.locationLimits.length &&
            props.locationLimits[0].id
          : ""
      }
      className="policy-vehicle-collapse"
      style={{ marginBottom: "5px" }}
      destroyInactivePanel
      onChange={(key) => {
        if (onPanelOpen) {
          onPanelOpen(key);
        }
      }}
    >
      {props.locationLimits &&
        props.locationLimits.map((lim) => (
          <Panel
            header={
              <span style={{ fontWeight: 600 }}>
                {titleMaintainer(
                  props.locationLimits &&
                    getCommaSeperatedStr([
                      lim.locationNumber,
                      lim.address1,
                      lim.address2,
                      lim.city,
                      lim.state,
                      lim.county,
                      lim.zipCode
                    ]),
                  60
                )}
              </span>
            }
            className="policy-vehicle-collapse-panel"
            disabled={props.disabled}
            showArrow={props.showArrow}
            extra={props.extra}
            key={lim.id}
          >
            {!props.disabled && (
              <Spin
                className="customclass"
                indicator={
                  <Icon
                    type="loading-3-quarters"
                    className="color-primary fs-24x"
                    spin
                  />
                }
                spinning={panelLoading}
              >
                <LocationInformationReadOnly {...props} locationLimits={lim} />
              </Spin>
            )}
          </Panel>
        ))}
    </Collapse>
  );
};
export const LocationInformationReadOnly = ({
  locationLimitColumns,
  locationLimits,
  ...props
}) => {
  return (
    <div
      style={{
        width: "100%",
        marginBottom: "20px"
      }}
    >
      <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
        <Col span={6}>
          <Text>{"Location Number"}</Text>
        </Col>
        <Col span={6}>
          <Input
            size="small"
            placeholder="Location Number"
            value={locationLimits.locationNumber}
            disabled
          />
        </Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
        <Col span={6}>
          <Text>{"Address 1"}</Text>
        </Col>
        <Col span={18}>
          <Input
            size="small"
            placeholder="Address 1"
            disabled
            value={locationLimits.address1}
          />
        </Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
        <Col span={6}>
          <Text>{"Address 2"}</Text>
        </Col>
        <Col span={18}>
          <Input
            size="small"
            placeholder="Address 2"
            disabled
            value={locationLimits.address2}
          />
        </Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
        <Col span={6}>
          <Text>{"City"}</Text>
        </Col>
        <Col span={6}>
          <Input
            size="small"
            placeholder="City"
            disabled
            value={locationLimits.city}
          />
        </Col>
        <Col span={6}>
          <Text>{"State"}</Text>
        </Col>
        <Col span={6}>
          <Input
            size="small"
            placeholder="State"
            disabled
            value={locationLimits.state}
          />
        </Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
        <Col span={6}>
          <Text>{"Country"}</Text>
        </Col>
        <Col span={6}>
          <Input
            size="small"
            placeholder="Country"
            disabled
            value={locationLimits.county}
          />
        </Col>
        <Col span={6}>
          <Text>{"Zip Code"}</Text>
        </Col>
        <Col span={6}>
          <Input
            size="small"
            placeholder="zipCode"
            disabled
            value={locationLimits.zipCode}
          />
        </Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
        <Col span={6}>
          <Text>{"Description of Property"}</Text>
        </Col>
        <Col span={18}>
          <TextArea
            size="small"
            placeholder="Description of Property"
            disabled
            value={locationLimits.descriptionOfProperty}
          />
        </Col>
      </Row>
      {
        locationLimits?.buildings?.length ?
        locationLimits.buildings.map((building) => (
          <Collapse
            accordion
            className="policy-vehicle-collapse"
            style={{ margin: "5px 0" }}
            // destroyInactivePanel
          >
            <Panel
              header={
                <span style={{ fontWeight: 600 }}>Policy Building limit</span>
              }
              key={"PlLimit"}
              className="policy-vehicle-collapse-panel"
            >
              <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
                <Col span={12}>
                  <Text>{"Building Number"}</Text>
                </Col>
                <Col span={12}>
                  <Input
                    size="small"
                    placeholder="Building Number"
                    disabled
                    value={building.buildingNumber}
                  />
                </Col>
              </Row>
              <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
                <Col span={12}>
                  <Text>{"Building Description"}</Text>
                </Col>
                <Col span={24}>
                  <TextArea
                    size="small"
                    placeholder="Building Description"
                    disabled
                    value={building.buildingDescription}
                  />
                </Col>
              </Row>

              {building && building.limits && building.limits.length ? (
                <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
                  <PropertyPolicyLimitTable
                    tableColumns={locationLimitColumns}
                    dataSource={building.limits || []}
                    readOnlyMode
                    blanketTypes={props.blanketTypes}
                    // handleSave={handleSave}
                    // isEditAgentForm={props.isEditAgentForm}
                    // addPropertyPolicyLimits={addToLocationTable}
                  />
                </Row>
              ) : null}
            </Panel>
          </Collapse>
        )): null }
      {locationLimits.limits && locationLimits.limits.length ? (
        <Row style={{ margin: "5px 0" }}>
          <PropertyPolicyLimitTable
            tableColumns={locationLimitColumns}
            dataSource={locationLimits.limits || []}
            readOnlyMode
            blanketTypes={props.blanketTypes}

            // handleSave={handleSave}
            // isEditAgentForm={props.isEditAgentForm}
            // addPropertyPolicyLimits={addToLocationTable}
          />
        </Row>
      ) : null}
    </div>
  );
};

export const LocationInformation = ({ form, ...props }) => {
  //   formRef = React.createRef();
  const {
    getFieldDecorator,
    getFieldValue,
  } = form || {};

  const [buildingId, setBuildingId] = useState(0);
  const [locationId, setLocationId] = useState(null);
  const [locationNumber, setLocationNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [state_a, setState_a] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [descriptionOfProperty, setDescriptionOfProperty] = useState("");
  const [buildingCount, setBuildingCount] = useState(0);
  const [availableBuildingNumbers, setAvailableBuildingNumbers] = useState([]);
  const [buildingState, setBuildingState] = useState([]);
  const [locationLimitTable, setLocationLimitTable] = useState([]);
  const [deleteLocationLimits, setDeleteLocationLimits] = useState([]);
  const [deleteBuildingIds, setDeleteBuildingIds] = useState([]);
  const [limitCategoryUsed, setLimitCategoryUsed] = useState([]);
  // const [buildingLimitTable, setBuildingLimitTable] = useState([]);
  const [locationIndex, setLocationIndex] = useState(0);
  useEffect(() => {
    if (props.locationItem) {
      const {
        address1,
        address2,
        city,
        county,
        state,
        zipCode,
        locationNumber,
        buildings = [],
        limits = [],
        id,
        descriptionOfProperty
      } = props.locationItem;
      setLocationId(id);
      setAddress1(address1);
      setAddress2(address2);
      setCity(city);
      setCounty(county);
      setState_a(state);
      setZipCode(zipCode);
      setLocationNumber(locationNumber);
      setDescriptionOfProperty(descriptionOfProperty);
      // buildings.forEach((building) => {

      // });
      let count = 0;
      const availableBuildingNumbers = [];
      setBuildingState(
        (buildings &&
          buildings.map((item) => {
            availableBuildingNumbers.push({
              value: item.buildingNumber,
              myId: count
            });
            let myIdCount = 0;
            const buildingLimitsUsed = [];

            const buildingLimits =
              item.limits &&
              item.limits.map((bLims) => {
                buildingLimitsUsed.push(bLims.limitTypeId);
                return {
                  ...bLims,
                  myId: myIdCount++,
                  blanketId: bLims.blanketId,
                  // limitCategoryId: bLims.limitCategoryId,
                  limitTypeId: bLims.limitTypeId,
                  limitCategoryId:
                    bLims.parentLimitCategory && bLims.parentLimitCategory.id,
                  // limitTypeId:
                  //   bLims.parentLimitCategory &&
                  //   bLims.parentLimitCategory.subCategory &&
                  //   bLims.parentLimitCategory.subCategory.length &&
                  //   bLims.parentLimitCategory.subCategory[0].id,
                  limits: bLims.limits,
                  limit2: bLims.limit2,
                  coinsurance: bLims.coinsurance,
                  valuationTypeId: bLims.valuationTypeId,
                  valuationAmt: bLims.valuationAmt,
                  deductableTypeId: bLims.deductableTypeId,
                  deductible: bLims.deductible,
                  touched: false,
                  isLimitValid: true
                };
              });
            return {
              // ...item,
              id: item.id,
              buildingNumber: item.buildingNumber,
              buildingDescription: item.buildingDescription,
              buildingLimits: buildingLimits,
              buildingLimitsUsed: buildingLimitsUsed,
              myId: count++,
              deleteBuildingLimits: []
            };
          })) ||
          []
      );
      setAvailableBuildingNumbers(availableBuildingNumbers);
      setBuildingCount(count);
      setBuildingId(count);
      let limIndex = 0;
      setLimitCategoryUsed(
        (limits && limits.map((lims) => lims.limitTypeId)) || []
      );

      setLocationLimitTable(
        (limits &&
          limits.map((lims) => {
            return {
              ...lims,
              myId: limIndex++,
              limitCategoryId:
                lims.parentLimitCategory && lims.parentLimitCategory.id,
              touched: false,
              isLimitValid: true
            };
          })) ||
          []
      );
      setLocationIndex(limIndex);
    } 
    // else {
    //   addToLocationTable();
    // }
  }, [props.locationItem]);
  const validateBuildingNumber = async (rule, value, callback) => {
    if (value) {
      if (
        availableBuildingNumbers
          .filter((building) => building.myId != rule.myId)
          .map((buildObj) => buildObj.value.trim().toLowerCase())
          .includes(value.trim().toLowerCase())
      ) {
        callback("Building number Exists");
      }
    }
    // check failing condition
  };
  const add = () => {
    if (buildingCount == 0) {
      const locationsTobeDeleted = locationLimitTable
        .map((item) => item.id)
        .filter((lim) => lim);
      setDeleteLocationLimits((prevState) => [
        ...prevState,
        ...locationsTobeDeleted
      ]);
      setLocationLimitTable([]);
      setLimitCategoryUsed([]);
      getFieldDecorator("buildings[0].buildingNumber", { initialValue: "" });
      getFieldDecorator("buildings[0].buildingDescription", {
        initialValue: ""
      });
      getFieldDecorator("buildings[0].myId", {
        initialValue: 0
      });
      setBuildingCount(1);
      setBuildingState((prevState) => [
        ...prevState,
        {
          buildingNumber: "",
          buildingDescription: "",
          myId: buildingId,
          buildingLimits: [
            {
              myId: 1,
              id: null,
              blanketId: "",
              limitCategoryId: "",
              limitTypeId: "",
              limits: 0,
              limit2: 0,
              coinsurance: 0,
              deductible: 0,
              deductableTypeId: "",
              valuationAmt: 0,
              touched: false,
              isLimitValid: true
            }
          ],
          buildingLimitsUsed: []
        }
      ]);
      setBuildingId(0);
    } else {
      const values = getFieldValue("buildings");
      getFieldDecorator(`buildings[${buildingId + 1}].buildingNumber`, {
        initialValue: ""
      });
      getFieldDecorator(`buildings[${buildingId + 1}].buildingDescription`, {
        initialValue: ""
      });
      getFieldDecorator(`buildings[${buildingId + 1}].myId`, {
        initialValue: buildingId + 1
      });

      setBuildingCount(values.length + 1);
      setBuildingState((prevState) => [
        ...prevState,
        {
          buildingNumber: "",
          buildingDescription: "",
          myId: buildingId + 1,
          buildingLimits: [
            {
              myId: 1,
              id: null,
              blanketId: "",
              limitCategoryId: "",
              limitTypeId: "",
              limits: 0,
              limit2: 0,
              coinsurance: 0,
              deductible: 0,
              deductableTypeId: "",
              valuationAmt: 0,
              touched: false,
              isLimitValid: true
            }
          ],
          buildingLimitsUsed: []
        }
      ]);

      setBuildingId((prevState) => prevState + 1);
    }
    // const buildingLimits = buildingLimitTable;
    // buildingLimits.push([]);
    // setBuildingLimitTable(buildingLimits);
  };
  const remove = (index) => {
    // const building_values = getFieldValue("buildings");

    if (index >= 0 && buildingState[index].id) {
      const toBeDeletedBuilding = buildingState[index].id;
      setDeleteBuildingIds((prevState) => [...prevState, toBeDeletedBuilding]);
    }
    const building_values = buildingState;
    // const form_values = getFieldsValue();
    building_values.splice(index, 1);

    setBuildingState([...building_values]);
    setBuildingCount((prevState) => {
      return prevState - 1;
    });
    // setFieldsValue([...building_values]);
  };

  const handleSave = (row) => {
    const newData = [...locationLimitTable];
    const index = newData.findIndex((item) => row.myId === item.myId);

    const item = newData[index];
    const buildingLimitsUsed = newData
      .map((items) => items.limitTypeId)
      .filter((lim) => lim != item.limitTypeId);
    if (row.limitTypeId) {
      setLimitCategoryUsed((prevState) => [
        ...buildingLimitsUsed,
        row.limitTypeId
      ]);
    } else {
      setLimitCategoryUsed(buildingLimitsUsed);
    }
    newData.splice(index, 1, {
      ...item,
      ...row,
      id: row.id || null,
      isLimitValid: row.touched ? validatePropertyLimit(row) || "" : "true"
    });

    setLocationLimitTable(newData);
  };
  const removeLocationLimits = (myId) => {
    const locLimits = locationLimitTable;
    const index = locationLimitTable.findIndex((item) => item.myId == myId);

    if (index > -1) {
      const limit = locationLimitTable[index];
      const toBeDeletedLimitId = limit && limit.id;
      const toBeRemovedLimitTypeId = limit && limit.limitTypeId;
      if (toBeRemovedLimitTypeId) {
        const limitTypesUsed = limitCategoryUsed.filter(
          (lims) => lims != toBeRemovedLimitTypeId
        );
        setLimitCategoryUsed(limitTypesUsed);
      }

      if (toBeDeletedLimitId) {
        setDeleteLocationLimits((prevState) => [
          ...prevState,
          toBeDeletedLimitId
        ]);
      }
      locLimits.splice(index, 1);
      setLocationLimitTable((prevState) => [...locLimits]);
    }
  };
  const addBulidingLimitTable = (index) => {
    // const buildingLimits = buildingLimitTable;
    const buildingLimits = buildingState[index].buildingLimits;

    // const currentBuildingLimit = buildingLimits[index];
    buildingLimits.push({
      myId: buildingLimits.length + 1,
      id: null,
      blanketId: "",
      limitCategoryId: "",
      limitTypeId: "",
      limits: 0,
      limit2: 0,
      coinsurance: 0,
      deductible: 0,
      deductableTypeId: "",
      valuationAmt: 0,
      touched: false,
      isLimitValid: true
    });

    const buildings = buildingState;
    const currentBuildingLimit = buildings[index];

    buildings.splice(index, 1, {
      ...currentBuildingLimit,
      buildingLimits: buildingLimits
    });
    setBuildingState((prevState) => [...buildings]);
    // buildingLimits.splice(index, 1, currentBuildingLimit);
    // setBuildingLimitTable((prevstate) => [...buildingLimits]);
  };
  const removeBuildingLimits = (myId, buildingId) => {
    const currentBuildingIndex = buildingState.findIndex(
      (item) => item.myId == buildingId
    );

    const buildingLimits = buildingState[currentBuildingIndex].buildingLimits;
    const toBeDeletedLimitIndex = buildingLimits.findIndex(
      (item) => item.myId == myId
    );
    const limitObj = buildingLimits[toBeDeletedLimitIndex];
    // if(limitObj)
    buildingLimits.splice(toBeDeletedLimitIndex, 1);
    const newBuildState = [...buildingState];
    newBuildState.splice(currentBuildingIndex, 1, {
      ...buildingState[currentBuildingIndex],
      deleteBuildingLimits: limitObj.id
        ? buildingState[currentBuildingIndex].deleteBuildingLimits
          ? [
              ...buildingState[currentBuildingIndex].deleteBuildingLimits,
              limitObj.id
            ]
          : [limitObj.id]
        : buildingState[currentBuildingIndex].deleteBuildingLimits
        ? buildingState[currentBuildingIndex].deleteBuildingLimits
        : [],
      buildingLimitsUsed:
        (buildingState[currentBuildingIndex].buildingLimitsUsed &&
          buildingState[currentBuildingIndex].buildingLimitsUsed.filter(
            (item) => item != limitObj.limitTypeId
          )) ||
        [],
      buildingLimits: buildingLimits
    });
    setBuildingState((prevState) => [...newBuildState]);
  };
  const buildingEdit = (row, keyName, value) => {
    const currentBuildingIndex = buildingState.findIndex(
      (item) => item.myId == row.myId
    );
    const editedBuildingState = buildingState;
    if (keyName == "buildingNumber") {
      const buildNums = availableBuildingNumbers;
      buildNums[currentBuildingIndex] = { value: value, myId: row.myId };
      setAvailableBuildingNumbers(buildNums);
    }
    // const newBuilding = { ...buildingState[currentBuildingIndex] };
    editedBuildingState.splice(currentBuildingIndex, 1, {
      ...row,
      [keyName]: value.trim().toLowerCase()
    });
    setBuildingState((prevState) => [...editedBuildingState]);
  };
  const handleBuildingLimitSave = (row, myId) => {
    const currentLimitIndex = buildingState.findIndex(
      (item) => item.myId == myId
    );

    const newData = buildingState[currentLimitIndex].buildingLimits;

    const index = newData.findIndex((item) => row.myId === item.myId);
    const item = newData[index];
    const buildingLimitsUsed = newData
      .map((items) => items.limitTypeId)
      .filter((lim) => lim != item.limitTypeId);
    newData.splice(index, 1, {
      ...item,
      ...row,
      id: row.id || null,
      isLimitValid: row.touched ? validatePropertyLimit(row) || "" : "true"
    });
    const newBuildState = buildingState;
    newBuildState.splice(currentLimitIndex, 1, {
      ...buildingState[currentLimitIndex],
      buildingLimitsUsed: row.limitTypeId
        ? [...buildingLimitsUsed, row.limitTypeId]
        : buildingLimitsUsed,
      buildingLimits: newData
    });
    setBuildingState((prevState) => [...newBuildState]);
  };

  const addToLocationTable = () => {
    setLocationLimitTable((prevState) => [
      ...prevState,
      {
        myId: locationIndex,
        // id: locationIndex,
        blanketId: "",
        limitCategoryId: "",
        limitTypeId: "",
        limits: 0,
        limit2: 0,
        coinsurance: 0,
        deductible: 0,
        deductableTypeId: "",
        valuationAmt: 0,
        touched: false,
        isLimitValid: true
      }
    ]);
    setLocationIndex((prevState) => prevState + 1);
  };
  const validateLimits = (values) => {
    let isValid = true;
    if (locationLimitTable && locationLimitTable.length) {
      const validatedLocationLimits = locationLimitTable.map((item, index) => {
        return {
          ...item,
          touched: true,
          // id: item.limitTypeId,
          isLimitValid: validatePropertyLimit(item) || ""
        };
      });
      setLocationLimitTable(validatedLocationLimits);
      if (validatedLocationLimits.filter((item) => !item.isLimitValid).length) {
        message.error("Limits Invalid");
        return false;
      } else {
        return true;
      }
    } else if (buildingState && buildingState.length) {
      const updatedBuilding = buildingState.map((building) => {
        if (building.buildingLimits && building.buildingLimits.length) {
          const validatedLimits = building.buildingLimits.map((item) => {
            return {
              ...item,
              touched: true,
              // id: item.limitTypeId,
              isLimitValid: validatePropertyLimit(item) || ""
            };
          });

          if (validatedLimits.filter((item) => !item.isLimitValid).length > 0) {
            isValid = false;
            message.error("Limits Invalid");
          }
          return {
            ...building,
            buildingDescription:
              values.buildings[building.myId].buildingDescription,
            buildingNumber: values.buildings[building.myId].buildingNumber,
            deleteBuildingLimits:
              values.buildings[building.myId].deleteBuildingLimits,
            buildingLimits: validatedLimits
          };
        } else {
          isValid = false;
          message.error("Please add at least one building limit");
          return {
            ...building
          };
        }
      });
      setBuildingState(updatedBuilding);
      return isValid;
    } else {
      message.error("Please add atleast one limit");
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      const areBuildingsValid = validateLimits(values);
      if (!err && areBuildingsValid) {
        const buildTable = buildingState.map((item) => ({
          ...item,
          buildingDescription: values.buildings[item.myId].buildingDescription,
          buildingNumber: values.buildings[item.myId].buildingNumber
        }));
        props.handleModalOk({
          ...values,
          id: locationId,
          buildings: buildTable,
          locationLimitTable,
          deleteBuildingIds,
          deleteLocationLimits
        });
      }
    });
  };
  const doActions = (action, location) => {
    switch (action) {
      case LOCATION_ACTIONS.ADD_BUILDING.ACTION: {
        addBulidingLimitTable(location);
        break;
      }
      case LOCATION_ACTIONS.REMOVE.ACTION: {
        remove(location);
        break;
      }
    }
  };
  const formItems3 =
    buildingState &&
    buildingState.map((k, index) => {
      if (k && k.myId != undefined) {
        return (
          <Collapse
            accordion
            className="policy-vehicle-collapse"
            style={{ marginBottom: "5px" }}
            // destroyInactivePanel
          >
            <Panel
              header={
                <span style={{ fontWeight: 600 }}>Policy Building limit</span>
              }
              // extra={
              //   <LocationAction
              //     actions={[
              //       LOCATION_ACTIONS.ADD_BUILDING,
              //       LOCATION_ACTIONS.REMOVE
              //     ]}
              //     index={index}
              //     item={k}
              //     doActions={doActions}
              //   />
              // }
              key={"PlLimit"}
              className="policy-vehicle-collapse-panel"
              extra={[
                <Icon
                  style={{
                    marginLeft: "5px",
                    color: "#ff4d4f"
                  }}
                  type="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    remove(index);
                  }}
                  // style={{ width: "60%" }}
                ></Icon>
              ]}
            >
              <Col span={12} className="p-12">
                <Form.Item
                  // labelCol={{ span: 10 }}
                  // wrapperCol={{ span: 14 }}
                  key={k.myId}
                  // style={{ textAlign: "left" }}
                  label="Building Number"
                >
                  {getFieldDecorator(`buildings[${k.myId}].buildingNumber`, {
                    initialValue: k.buildingNumber,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter building number."
                      },
                      {
                        max: 20,
                        message: "Building Number should be less than 20 chars"
                      },
                      {
                        validator: validateBuildingNumber,
                        myId: k.myId
                      }
                    ]
                  })(
                    <Input
                      size="small"
                      placeholder="Building Number "
                      style={{ marginRight: 8 }}
                      onChange={(e) => {
                        if (e.target) {
                          buildingEdit(k, "buildingNumber", e.target.value);
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={24} className="p-12">
                <Form.Item
                  // labelCol={{ span: 10 }}
                  // wrapperCol={{ span: 14 }}
                  key={k.myId + "desc"}
                  label="Building Description"
                >
                  {getFieldDecorator(
                    `buildings[${k.myId}].buildingDescription`,
                    {
                      initialValue: k.buildingDescription,
                      rules: [
                        {
                          max: 100,
                          message:
                            "Building Number should be less than 100 chars"
                        }
                      ]
                    }
                  )(
                    <TextArea
                      size="small"
                      placeholder="Building Description"
                      style={{ marginRight: 8 }}
                      onChange={(e) => {
                        if (e.target) {
                          buildingEdit(
                            k,
                            "buildingDescription",
                            e.target.value
                          );
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              {/* <Col span={2}> */}
              {/* <Icon
                style={{
                  marginTop: "10px",
                  color: "#ff4d4f"
                }}
                type="delete"
                onClick={() => remove(index)}
                // style={{ width: "60%" }}
              ></Icon> */}
              {/* </Col> */}
              <Col span={24}>
                <div
                  className="pb"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <>
                    {k.buildingLimits && k.buildingLimits.length ? (
                      <h4>
                        <Avatar
                          src={tableIcon}
                          size="small"
                          shape="square"
                          className="margin-right-sm"
                        />
                        Property Building Limits
                        {props.isEditAgentForm && (
                          <Tooltip
                            placement="topLeft"
                            title="Select the limits you would like displaying during form generation."
                          >
                            <Icon
                              type="info-circle"
                              style={{
                                fontSize: 14,
                                padding: "0 5px",
                                color: "rgba(0, 0, 0, 0.65)",
                                float: "right",
                                marginTop: "15px"
                              }}
                            />
                          </Tooltip>
                        )}
                      </h4>
                    ) : null}
                    <div className="ml-auto ">
                      <Button
                        type="primary"
                        onClick={() => addBulidingLimitTable(index)}
                        size="small"
                      >
                        <Icon type="plus" /> Add Building Limits
                      </Button>
                    </div>
                  </>
                </div>
              </Col>
              {k.buildingLimits && k.buildingLimits.length ? (
                <Col className="p-12 py" span={24}>
                  <Form.Item>
                    <PropertyPolicyLimitTable
                      tableColumns={props.locationLimitColumns}
                      // dataSource={buildingLimitTable[index]}
                      dataSource={k.buildingLimits}
                      handleSave={(e) => handleBuildingLimitSave(e, k.myId)}
                      isEditAgentForm={props.isEditAgentForm}
                      deleteRow={(key) => removeBuildingLimits(key, k.myId)}
                      valuationTypeWrapper={props.valuationTypeWrapper}
                      deductableTypes={props.deductableTypes}
                      limitTypes={props.limitTypes}
                      limitTypesUsed={k.buildingLimitsUsed || []}
                      deleteByKey={"myId"}
                      blanketTypes={props.blanketTypes}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </Panel>
          </Collapse>
        );
      }
    });

  return (
    <div className="locationForm">
      <Spin
        className="customclass"
        spinning={props.initLoading}
        style={{ marginTop: "100px" }}
      >
        <Form
          onSubmit={handleSubmit}
          className="add-new-policy-form"
          style={{ marginBottom: 80 }}
        >
          <Card
            size="small"
            bordered={true}
            title={
              <Fragment>
                <h4 className="margin-0">
                  <Avatar
                    src={verifiedOwnerImage}
                    size="small"
                    shape="square"
                    className="margin-right-sm"
                  />
                  {"Policy Limit Form"}
                </h4>
              </Fragment>
            }
          >
            <Row
            // gutter={[10, 0]}
            >
              <Col span={12} className="p-12">
                <Form.Item
                  label="Location Number"
                  // labelCol={{ span: 4 }}
                  // wrapperCol={{ span: 20 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("locationNumber", {
                    initialValue: locationNumber,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter location number."
                      },
                      {
                        max: 20,
                        message:
                          "Location Number should not be greater than 20 char."
                      }
                    ]
                  })(<Input size="small" placeholder="Location Number" />)}
                </Form.Item>
              </Col>
              <Col span={12} className="p-12"></Col>
            </Row>
            <Row>
              <Col span={12} className="p-12">
                <Form.Item
                  label="Address 1"
                  // labelCol={{ span: 4 }}
                  // wrapperCol={{ span: 20 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("address1", {
                    initialValue: address1,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter address1."
                      },
                      {
                        max: 50,
                        message: "Address 1 should not be greater than 50 char."
                      }
                    ]
                  })(<Input size="small" placeholder="Address 1" />)}
                </Form.Item>
              </Col>
              <Col span={12} className="p-12">
                <Form.Item
                  label="Address 2"
                  // labelCol={{ span: 4 }}
                  // wrapperCol={{ span: 20 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("address2", {
                    initialValue: address2,
                    rules: [
                      {
                        max: 50,
                        message: "Address 2 should not be greater than 50 char."
                      }
                    ]
                  })(<Input size="small" placeholder="Address 2" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} className="p-12">
                <Form.Item
                  label="City"
                  // labelCol={{ span: 10 }}
                  // wrapperCol={{ span: 14 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("city", {
                    initialValue: city,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter City."
                      },
                      {
                        max: 50,
                        message: "City should not be greater than 50 char."
                      }
                    ]
                  })(<Input size="small" placeholder="City" />)}
                </Form.Item>
              </Col>
              <Col span={12} className="p-12">
                <Form.Item
                  label="State"
                  // labelCol={{ span: 10 }}
                  // wrapperCol={{ span: 14 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("state", {
                    initialValue: state_a,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter State."
                      },
                      {
                        max: 50,
                        message: "State should not be greater than 50 char."
                      }
                    ]
                  })(<Input maxLength={2} size="small" placeholder="State" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} className="p-12">
                <Form.Item
                  label="County"
                  // labelCol={{ span: 10 }}
                  // wrapperCol={{ span: 14 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("county", {
                    initialValue: county,
                    rules: [
                      {
                        max: 50,
                        message: "County should not be greater than 50 char."
                      }
                    ]
                  })(<Input size="small" placeholder="County" />)}
                </Form.Item>
              </Col>
              <Col span={12} className="p-12">
                <Form.Item
                  label="Zip Code"
                  // labelCol={{ span: 10 }}
                  // wrapperCol={{ span: 14 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("zipCode", {
                    initialValue: zipCode,
                    rules: [
                      {
                        pattern: /^[0-9a-zA-Z\- ]{0,10}$/,
                        message: "Invalid Zip Code / Post Code / Pin Code."
                      }
                    ]
                  })(<Input size="small" placeholder="Zip Code" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="p-12">
                <Form.Item
                  label="Description of Property"
                  // labelCol={{ span: 4 }}
                  // wrapperCol={{ span: 20 }}
                  // labelAlign="left"
                >
                  {getFieldDecorator("descriptionOfProperty", {
                    initialValue: descriptionOfProperty,
                    rules: [
                      {
                        max: 100,
                        message:
                          "Description of Property should not be greater than 100 char."
                      }
                    ]
                  })(
                    <TextArea
                      size="small"
                      placeholder="Description of Property"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="p-12">
                {formItems3}
              </Col>

              <Col span={24} className="p-12">
              <div>
                    {locationLimitTable && locationLimitTable.length == 0 ?
                    <Button type="primary" onClick={add} size="small">
                      <Icon type="plus" /> Add Buildings
                    </Button>
                    : null}
                    {buildingState && buildingState.length == 0 ? (
                      <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: "5px" }}
                        onClick={addToLocationTable}
                      >
                        <Icon type="plus" /> Add Location Limits
                      </Button>
                    ) : null}
                  </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: '25px',
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  {locationLimitTable && locationLimitTable.length > 0 ? (
                    <h4>
                      <Avatar
                        src={tableIcon}
                        size="small"
                        shape="square"
                        className="margin-right-sm"
                      />
                      Property Location Limits
                      {props.isEditAgentForm && (
                        <Tooltip
                          placement="topLeft"
                          title="Select the limits you would like displaying during form generation."
                        >
                          <Icon
                            type="info-circle"
                            style={{
                              fontSize: 14,
                              padding: "0 5px",
                              color: "rgba(0, 0, 0, 0.65)",
                              float: "right",
                              marginTop: "15px"
                            }}
                          />
                        </Tooltip>
                      )}
                    </h4>
                  ) : null}
                </div>
              </Col>

              <Col span={24}>
                <Form.Item>
                  {locationLimitTable && locationLimitTable.length > 0 ? (
                    <PropertyPolicyLimitTable
                      limitTypesUsed={limitCategoryUsed}
                      limitTypes={props.limitTypes}
                      blanketTypes={props.blanketTypes}
                      valuationTypeWrapper={props.valuationTypeWrapper}
                      deductableTypes={props.deductableTypes}
                      tableColumns={props.locationLimitColumns}
                      dataSource={locationLimitTable}
                      handleSave={handleSave}
                      // isEditAgentForm={props.isEditAgentForm}
                      addPropertyPolicyLimits={addToLocationTable}
                      deleteRow={removeLocationLimits}
                      deleteByKey={"myId"}
                    />
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <div className="steps-action" style={{ margin: 0, zIndex: 1 }}>
            <Form.Item>
              <Button type="dashed" onClick={props.handleModalCancel}>
                Cancel
              </Button>

              <Button
                style={{ marginLeft: 8 }}
                type="primary"
                htmlType="submit"
              >
                Submit & Close
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
export const LocationInformationForm = Form.create({
  name: "add_new_policy_data"
})(LocationInformation);

export const LocationAction = ({ actions, item, doActions, index }) => {
  return (
    <Popover
      overlayClassName="policy-action"
      placement="leftTop"
      title={null}
      content={
        <div
          style={{ margin: "-12px -16px" }}
          className="policy-vehicle-collapse"
        >
          <List
            rowKey={(r) => r.action}
            size="small"
            className="action-items"
            itemLayout="horizontal"
            dataSource={(actions && actions) || []}
            renderItem={(policyAction) => {
              return (
                <List.Item
                  onClick={(e) => {
                    e.stopPropagation();

                    doActions(policyAction.ACTION, index);
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        src={getActionIcon(policyAction.ICON)}
                        size="small"
                      >
                        <Icon
                          style={{ fontSize: 18 }}
                          type={policyAction.ICON}
                        />
                      </Avatar>
                    }
                    title={policyAction.TITLE}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      }
      destroyTooltipOnHide={true}
      arrowPointAtCenter={true}
      autoAdjustOverflow={true}
    >
      <Icon type="menu" className="policy-item-menu" />
      {/* <Button type="default" size="small" icon="menu" /> */}
    </Popover>
  );
};
