export const TEMPLATE_TYPES = {
  CLEAR_TEMPLATES: "CLEAR_TEMPLATES",
  GET_TEMPLATE_LIST: {
    SUCCESS: "GET_TEMPLATE_LIST_SUCCESS",
    ERROR: "GET_TEMPLATE_LIST_ERROR"
  },
  GET_TEMPLATE_COVERAGE_TYPES: {
    SUCCESS: "GET_TEMPLATE_COVERAGE_TYPES.SUCCESS",
    ERROR: "GET_TEMPLATE_COVERAGE_TYPES.ERROR"
  },
  GET_TEMPLATE_HISTORY: {
    SUCCESS: "GET_TEMPLATE_HISTORY.SUCCESS",
    ERROR: "GET_TEMPLATE_HISTORY.ERROR"
  },
  UI: {
    SET_ACTIVE_TAB: "UI_SET_ACTIVE_TAB",
    SET_SELECTED_TEMPLATE: "SET_SELECTED_TEMPLATE",
    SET_SELECTED_VERSION: "SET_SELECTED_VERSION",
    CLEAR_SELECTED_TEMPLATE: "CLEAR_SELECTED_TEMPLATE",
    CLEAR_SELECTED_VERSION: "CLEAR_SELECTED_VERSION"
  },
  CREATE_TEMPLATE: {
    SUCCESS: "CREATE_TEMPLATE_SUCCESS",
    ERROR: "CREATE_TEMPLATE_ERROR"
  },
  EDIT_TEMPLATE: {
    SUCCESS: "EDIT_TEMPLATE_SUCCESS",
    ERROR: "EDIT_TEMPLATE_ERROR"
  },
  DELETE_REQUIREMENT_TEMPLATE: {
    SUCCESS: "DELETE_REQUIREMENT_TEMPLATE_SUCCESS",
    ERROR: "DELETE_REQUIREMENT_TEMPLATE_SUCCESS"
  },

  SET_LOADING: "SET_LOADING",
  SET_TEMPLATE_NAME_LIST: "SET_TEMPLATE_NAME_LIST",
  SET_ADDEDITLOADING: "SET_ADDEDITLOADING"
};
