import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { rootReducer } from "./RootReducers";

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const middleware = applyMiddleware(...middlewares);

// export const configureStore = () =>
//   createStore(
//     rootReducer,
//     composeWithDevTools(middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   );

export const configureStore = () =>
  createStore(
    rootReducer,
     //compose(
      middleware/*,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()*/
     //)
  );
