import React from "react";
import { Modal } from "antd";
// const { Search } = Input;
function ModalPass(props) {
  return (
    <Modal
      destroyOnClose={true}
      visible={props.visible}
      onOk={props.handleModalOk}
      onCancel={props.handleModalCancel}
      footer={null}
      title={props.title}
      maskClosable={props.maskClosable}
      closable={props.closable}
    >
      {props.children}
    </Modal>
  );
}

export default ModalPass;
