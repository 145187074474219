import React, { useEffect, useState } from "react";
import {
  Icon,
  Empty,
  message,
  Pagination,
  Card,
  Modal,
  Avatar
} from "antd";
import { showFile } from "../../../Utils/utility/app.utility";
import { downloadUploadedFile } from "../../../Store/Modules/Application/Application.asyncRequest";
import { emptyImage, showDocumentsIcon } from "../../../Assets/assets";
import { getAllSelectedDocuments } from "../../../Store/Modules/InsurerSharedPolicies/InsurerSharedPolicies.asyncRequest";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";
import { useSelector } from "react-redux";

export const PolicyUploadedDocumentModal = ({
  isTitleRequred,
  onClickOfDocument,
  removeDoc,
  isBordered,
  bodyStyle,
  readOnly = true,
  title,
  selectedProjectDetails,
  onPopUpOpen,
  setOnPopUpOpen,
}) => {
  const { userData } = useSelector((state) => state.myProfile);
  const [pageNumber, setPageNumber] = useState(1);
  const [docData, setDocData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalLocationCount, setTotalLocationCount] = useState(0);

  useEffect(() => {
    if(!selectedProjectDetails?.shareId && !selectedProjectDetails?.id) return; //CER-29385
    getAllSelectedDocuments(selectedProjectDetails && 
      selectedProjectDetails.id, selectedProjectDetails && 
      selectedProjectDetails.shareId,
      userData.role === "Agent" ? userData.id : null,
      1,
      CERTIFICIAL.PAGINATION.PAGE_SIZE_10
    ).then(
      (res) => {
        setDocData(res.previousShareData);
        setTotalLocationCount(res.totalCount);
        if (res.totalCount) {
          setPageNumber(1);
        }
      }
    );
  }, [userData, selectedProjectDetails]);

  const onPageChange = (page) => {
    getAllSelectedDocuments(
      selectedProjectDetails.id,
      selectedProjectDetails.shareId,
      userData.role === "Agent" ? userData.id : null,
      page,
      CERTIFICIAL.PAGINATION.PAGE_SIZE_10
    ).then(
      (res) => {
        setDocData(res.previousShareData);
        setTotalLocationCount(res.totalCount);
        if (res.totalCount) {
          setPageNumber(page);
        }
      },
      (err) => {}
    );
  };

  const removeDocData = (value) => {
    removeDoc(value)
    setLoading(true)
    var index = docData.findIndex(function(o){
      return o.id === value;
 })
   docData.splice(index, 1);
   setDocData(docData)
   setLoading(false)
  }

  const downLoadFile = (file, fileName) => {
    setLoading(true);
    downloadUploadedFile(file).then(
      (res) => {
        message.success(fileName + " download successfully.");
        showFile(res, fileName);
        setLoading(false);
      },
      () => {
        message.error(fileName + " download failed.");
        setLoading(false);
      }
    );
  };
  return (
    <Modal
      destroyOnClose={true}
      visible={onPopUpOpen}
      footer={null}
      onCancel={() => setOnPopUpOpen(false)}
      title={
        isTitleRequred && (
          <>
            <h4 className="margin-0">
              <Avatar
                src={showDocumentsIcon}
                size="small"
                shape="square"
                className="margin-right-sm"
              />

              {title ? title : "Policy Documents"}
            </h4>
          </>
        )
      }
      closable={true}
      width={800}
      bodyStyle={{ paddingTop: "0" }}
    >
      <Card
        size="default"
        bordered={false}
        Content={
          (loading && (
            <Icon type="loading-3-quarters" className="color-primary" spin />
          )) ||
          null
        }
        className="widget-card"
        bodyStyle={
          bodyStyle
            ? bodyStyle
            : {
                padding: "6px 0",
                borderTop: "1px solid rgba(83, 105, 248, 0.2)",
              }
        }
      >{(docData &&
          docData.length &&
          docData.map((document, idx) => {
            return (
              <div
                className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text"
                key={idx}
              ><div
                  className="ant-upload-list-item-info"
                  style={{
                    padding: "0 16px",
                    marginRight: "16px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div>
                    <Icon
                      style={{
                        color: document.isCompliance
                          ? "rgba(243, 11, 23, 0.8)"
                          : "rgba(0, 0, 0, 0.65)",
                      }}
                      type="download"
                    />{" "}
                    <a
                      title={document.name}
                      onClick={() => downLoadFile(document.file, document.name)}
                      style={{
                        color: document.isCompliance
                          ? "rgba(243, 11, 23, 0.8)"
                          : "#3596db",
                      }}
                    >{document.name}
                    </a>
                    {!readOnly && (
                      <Icon
                        type="close"
                        onClick={() => removeDocData(document.id)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })) || (
          <Empty
            image={emptyImage}
            imageStyle={{
              height: 36,
            }}
            description={"No documents available"}
          />
        )}
      </Card>
      <div style={{ marginTop: "20px" }}>
        {
          <Pagination
            size="small"
            current={pageNumber}
            className="list-footer"
            pageSize={CERTIFICIAL.PAGINATION.PAGE_SIZE_10}
            total={totalLocationCount}
            onChange={onPageChange}
            showLessItems={true}
            showTotal={(total, range) => (
              <h5>
                Showing {range[0]} - {range[1]} of {total} Documents
              </h5>
            )}
          />
        }
      </div>
    </Modal>
  );
};
