import { makeRequest } from "../../../REST/API.wrapper";
import { API_METHODS } from "../../../Utils/Constants/API.constants";
import { ENDPOINTS } from "../../../REST/API.endpoints";

export const onSearchCompanies = (searchData) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.SEARCH_COMPANIES.ON_DEMAND_GET_COMPANIES,
    options: {
      params: {
        query: searchData ? searchData.trim() : ""
      },
      shouldNotUserAuthHeaders: true
    }
  });
};

export const onSearchAgency = (searchData) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.SEARCH_COMPANIES.ON_DEMAND_GET_AGENCY,
    options: {
      params: {
        query: searchData ? searchData.trim() : ""
      },
      shouldNotUserAuthHeaders: true
    }
  });
};
