import { Avatar, Spin } from "antd";
import React, { memo, useEffect, useState } from "react";

const CertAvatar = memo(function CertAvatar({
  shape,
  size,
  style,
  alt,
  className,
  icon,
  src: logoUrl,
  children
}) {
  const [loading, setLoading] = useState(false);
  const [imageStatus, setImageStatus] = useState(null);

  useEffect(() => {
    setLoading(!!logoUrl);
    setImageStatus(null);
    return () => {
      setLoading(false);
    };
  }, []);

  const imageLoaded = (status) => {
    setImageStatus(status);
    setLoading(false);
  };

  const _isLogoAvailable = (imageStatus !== "error" && logoUrl) || false;

  return (
    <Spin
      spinning={loading}
      indicator={
        <div className="certificial-dot-loader certificial-image-loader" />
      }
    >
      <Avatar
        shape={shape}
        size={size}
        style={style}
        alt={alt}
        className={
          _isLogoAvailable
            ? [
                className,
                "cert-avatar",
                imageStatus === "error" ? "avatar-bg" : "transparent-bg"
              ].join(" ")
            : "cert-avatar"
        }
        icon={
          _isLogoAvailable ? (
            <img
              src={
                logoUrl.substring(0, 5) === "data:"
                  ? logoUrl
                  : process.env.REACT_APP_IMAGE_BASE_URL +
                    encodeURIComponent(logoUrl)
              }
              width={size}
              alt={alt}
              onLoad={() => imageLoaded("success")}
              onError={() => imageLoaded("error")}
              loading="lazy"
            />
          ) : null
        }
      >
        {children}
      </Avatar>
    </Spin>
  );
});

export default CertAvatar;
