


import {
  CERTIFICIAL,
    EVENT_SOURCE_EVNAME
  } from "../../Utils/Constants/Application.constants";
import { ENDPOINTS } from "../../REST/API.endpoints";

const getGUID = async(eventSource)=>{
    return new Promise((resolve, reject)=>{
      eventSource.addEventListener(EVENT_SOURCE_EVNAME.GUI_ID, (event) => {
        const guidValue = JSON.parse(event.data);
        console.log(`Guid from server: ${guidValue}`);
        resolve(guidValue)   
      });
    })  
};


export const getEventSource = async(handler, source)=>{
    return new Promise(async(resolve, reject)=>{
      const eventSource = new EventSource(ENDPOINTS.PROGRESS); 
      eventSource.onerror = () => {
        eventSource.close();
        return resolve();
      };
      eventSource.onopen = () => {
        console.log("connection opened");
      };     

      const guidValue = await Promise.race([getGUID(eventSource), new Promise((resolve, reject)=>setTimeout(()=>resolve(null), 4000))])
      if(!guidValue){
        return resolve({eventSource})
      }

      eventSource.addEventListener(guidValue, res => {
        if(source !== CERTIFICIAL.SHARE_VIEW.SMART_COI){
          handler()
          eventSource.close();
        }else{
          handler(res)
          parseInt(res?.data) == 100 && eventSource.close();
        }

      }) 
      resolve({eventSource, guidValue});
    })
  };

  export const  handleEventSource = async(callback, eventSourceList = [], source)=>{
      const response = await getEventSource(callback, source);
      if(!response?.eventSource){
        return;
      }
      eventSourceList.push(response?.eventSource)
      if(!response.guidValue){
        return;
      }
      return response.guidValue
  }