import { MY_TEAM_TYPES } from "./MyTeam.types";

const MyTeamInitialState = {
  sampleFilePath: "",
  documents: [],
  myTeamContacts: [],
  myTeamContactsLoading: false,
  allUsers: {},
  allAssociatedCompany: [],
  currentActiveTab: 1,
};

const MyTeamReducer = (state = MyTeamInitialState, action) => {
  switch (action.type) {
    case MY_TEAM_TYPES.GET_CONTACTS.SUCCESS: {
      return { ...state, myTeamContacts: action.payload };
    }
    case MY_TEAM_TYPES.GET_CONTACTS.ERROR: {
      return { ...state };
    }
    case MY_TEAM_TYPES.SET_LOADING: {
      return { ...state, myTeamContactsLoading: action.payload };
    }
    case MY_TEAM_TYPES.SET_USERS.SUCCESS: {
      return { ...state, allUsers: action.payload };
    }
    case MY_TEAM_TYPES.SET_USERS.ERROR: {
      return { ...state, allUsers: null };
    }
    case MY_TEAM_TYPES.GET_ALL_ASSOCIATED_COMPANY.SUCCESS: {
      return { ...state, allAssociatedCompany: action.payload };
    }
    case MY_TEAM_TYPES.GET_ALL_ASSOCIATED_COMPANY.ERROR: {
      return { ...state, allAssociatedCompany: null };
    }
    case MY_TEAM_TYPES.CURRENT_ACTIVE_TAB.SET: {
      return { ...state, currentActiveTab: action.payload };
    }
    default:
      return state;
  }
};
export default MyTeamReducer;
