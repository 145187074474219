import { MY_POLICIES_ACTION_TYPES } from "./Mypolicies.types";
import { AGENT_PROJECTS, USER_PROJECTS } from "../MyProfile/MyProfile.types";

/**
 * GET ALL POLICIES
 */
export const addDummyPolicy = (limit) => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.DUMMY_POLICY,
      payload: (limit && limit) || 5
    });
};

export const resetPolicies = () => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.CLEAR_MY_POLICIES
    });
};

export const requestedGetAllPoliciesSuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.GET_ALL_POLICIES.SUCCESS,
      payload: data.policies
    });
};

export const requestedGetAllPoliciesError = (error) => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.GET_ALL_POLICIES.ERROR,
      payload: error
    });
};
export const requestedGetPolicySuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.GET_POLICY.SUCCESS,
      payload: data
    });
};

export const requestedGetPolicyError = (error) => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.GET_POLICY.ERROR,
      payload: error
    });
};

export const resetSelectedDetailsPolicies = () => {
  return (dispatch) =>
    dispatch({
      type: MY_POLICIES_ACTION_TYPES.RESET_POLICY
    });
};

export const getUserProjectTemplatesSuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: USER_PROJECTS.GET_PROJECT_TEMPLATES.SUCCESS,
      payload: data
    });
};

export const getAgentProjectTemplatesSuccess = (data) => {
  return (dispatch) =>
    dispatch({
      type: AGENT_PROJECTS.GET_PROJECT_TEMPLATES.SUCCESS,
      payload: data
    });
};

export const getUserProjectTemplatesError = (error) => {
  return (dispatch) =>
    dispatch({
      type: USER_PROJECTS.GET_PROJECT_TEMPLATES.ERROR,
      payload: error
    });
};

// export const createUserProjectTemplatesSuccess = (data) => {
//   return (dispatch) =>
//     dispatch({
//       type: USER_PROJECTS.CREATE_PROJECT_TEMPLATE.SUCCESS,
//       payload: data
//     });
// };
// export const createUserProjectTemplatesError = (error) => {
//   return (dispatch) =>
//     dispatch({
//       type: USER_PROJECTS.CREATE_PROJECT_TEMPLATE.ERROR,
//       payload: error
//     });
// };

// // export const updateUserProjectTemplatesSuccess = (data) => {
// //   return (dispatch) =>
// //     dispatch({
// //       type: USER_PROJECTS.UPDATE_PROJECT_TEMPLATE.SUCCESS,
// //       payload: data
// //     });
// // };
// // export const updateUserProjectTemplatesError = (error) => {
// //   return (dispatch) =>
// //     dispatch({
// //       type: USER_PROJECTS.UPDATE_PROJECT_TEMPLATE.ERROR,
// //       payload: error
// //     });
// // };

// // export const deleteUserProjectTemplatesSuccess = (data) => {
// //   return (dispatch) =>
// //     dispatch({
// //       type: USER_PROJECTS.DELETE_PROJECT_TEMPLATE.SUCCESS,
// //       payload: data
// //     });
// // };

// // export const deleteUserProjectTemplatesError = (error) => {
// //   return (dispatch) =>
// //     dispatch({
// //       type: USER_PROJECTS.DELETE_PROJECT_TEMPLATE.ERROR,
// //       payload: error
// //     });
// // };

export const resetUserProjectTemplates = () => {
  return (dispatch) =>
    dispatch({
      type: USER_PROJECTS.CLEAR_PROJECT_TEMPLATES
    });
};
