import { UPLOADCERT_TYPES } from "./UploadCert.types";

export const getUploadCertListSuccess = (data) => {
    return (dispatch) =>
        dispatch({
            type: UPLOADCERT_TYPES.GET_UPLOADCERT_LIST.SUCCESS,
            payload: data
        });
};

export const getUploadCertListError = (error) => {
    return (dispatch) =>
        dispatch({
            type: UPLOADCERT_TYPES.GET_UPLOADCERT_LIST.ERROR,
            payload: error
        });
};

export const resetUploadCertList = () => {
    return (dispatch) =>
        dispatch({
            type: UPLOADCERT_TYPES.GET_UPLOADCERT_LIST.RESET,
        });
};

export const setLoading = (val) => {
    return (dispatch) => {
        dispatch({
            type: UPLOADCERT_TYPES.SET_LOADING
        });
    };
};
