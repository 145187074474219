import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Modal,
  Spin,
  Layout,
  Pagination,
  Icon
} from "antd";
import Scrollbars from "react-custom-scrollbars";
import { scrollBarThumb } from "../../UI/ScrollBarThumb/ScrollBarThumb";

import { PolicyLocationLimit } from "../PolicyLocationLimit/PolicyLocationLimit";
import { CERTIFICIAL_TABLE_COLUMNS } from "../../../Utils/Constants/TableColumns.constants";
import { CERTIFICIAL } from "../../../Utils/Constants/Application.constants";
import {
  getAllLocations,
  getPropertyByKind,
  getLocationById,
  postAllLocations,
  getAllSelectedLocations
} from "../../../Store/Modules/MyPolicies/Mypolicies.asyncRequest";
import { PROPERTY_LIMITS_KINDS } from "../../../Utils/Constants/Application.constants";
import PropertyPolicyLimitTable from "../PropertyPolicyLimitTable/PropertyPolicyLimitTable";
import { getArrayofIdFromArrayOfObject } from "../../../Utils/utility/app.utility";
const { Footer } = Layout;
const { Text } = Typography;
const LimitTypeModal = ({
  type,
  policyId,
  title,
  limitVisible,
  onClose,
  fixedLocationData,
  fixedLimitData,
  fixedBlanketData,
  source,
  projectId,
  complianceComparisons,
  showDisplayLimit,
  selectedClientTemplateForShare
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalLocationCount, setTotalLocationCount] = useState(0);
  const [panelLoading, setPanelLoading] = useState(false);
  const [limits, setLimits] = useState([]);
  const [activePenal, setActivePenal] = useState("");
  const [complianceData, secomplianceData] = useState({});
  const isSmartCoiSource = source === CERTIFICIAL.SHARE_VIEW.SMART_COI;
  useEffect(() => {
    if (policyId && limitVisible) {
      showLimits(type);
    } else if (
      (fixedLimitData?.length &&
      type == PROPERTY_LIMITS_KINDS.POLICY_LIMIT) || 
      (fixedBlanketData?.length && type == PROPERTY_LIMITS_KINDS.BLANKET )
    ) {
      showLimits(type);
    }
    if(complianceComparisons){
      let data = []
      complianceComparisons && complianceComparisons.map((item) => {
if(item.policyId === policyId ) {
  data.push(item) 
}})
secomplianceData(data[0])
    }
  }, [type, policyId, limitVisible, complianceComparisons, fixedBlanketData, fixedLocationData]);
  const onCloseModal = () => {
    setPageNumber(0);
    setLoading(false);
    setTotalLocationCount(0);
    setPanelLoading(false);
    setLimits([]);
    setActivePenal("");
    onClose();
  };
  const getStaticPaginationData = (page)=>{
    let mul = 10 * (page - 1);
    let start = 0;
    let end = 10;
    start = start + mul;
    end = end + mul;
    return {start, end}
  }
  const onPageChange = (page) => {
    setPageNumber(page);
    if (fixedLocationData && fixedLocationData.length) {
      if(isSmartCoiSource){
        let paginationData = getStaticPaginationData(page)
        let sliced = fixedLocationData.slice(paginationData.start, paginationData.end);
        setLimits(sliced);
        setTotalLocationCount(fixedLocationData.length)
        return;
      }
      let res = fixedLocationData.filter((i) => i.policyId === policyId);
      res = res[0];
      if (res && res.selectAll.flag && res.selectAll.checkedBy === "USER") {
        setLoading(true);
        let dataObj = {
          clearPrevious: res.isReresponded ? true : false,
          newAddedList: [],
          projectId: res.isReresponded ? res.projectId : "",
          removeList: [],
          reshare: res.isReresponded,
          selectAll: true
        };
        postAllLocations(
          policyId,
          page,
          CERTIFICIAL.PAGINATION.PAGE_SIZE_10,
          dataObj
        ).then(
          (res) => {
            setLimits(res.locations);
            setTotalLocationCount(res.totalCount);
            setLoading(false);
            setActivePenal(res.locations && res.locations[0].id);
          },
          (err) => {}
        );
      } else if (
        res &&
        !res.selectAll.flag &&
        res.selectAll.checkedBy === "SYSTEM"
      ) {
        setLoading(true);
        let pageNo = Math.floor(
          totalLocationCount / CERTIFICIAL.PAGINATION.PAGE_SIZE_10
        );
        pageNo = pageNo + 1;
        let dataObj = {
          clearPrevious: true,
          newAddedList: res.isReresponded
            ? getArrayofIdFromArrayOfObject(res.newAdded)
            : [],
          projectId: res.isReresponded ? res.projectId : "",
          removeList: getArrayofIdFromArrayOfObject(res.deselectedLocations),
          reshare: res.isReresponded,
          selectAll: true
        };
        postAllLocations(
          policyId,
          pageNo,
          CERTIFICIAL.PAGINATION.PAGE_SIZE_10,
          dataObj
        ).then(
          (res) => {
            setLimits(res.locations);
            setTotalLocationCount(res.totalCount);
            setLoading(false);
            setActivePenal(res.locations && res.locations[0].id);
          },
          (err) => {}
        );
      } else {
        let paginationData = getStaticPaginationData(page)
        let sliced = res.selectedlocations.slice(paginationData.start, paginationData.end);
        setLimits(sliced);
        setActivePenal(res.selectedlocations && res.selectedlocations[0].id);
      }
    } else {
      if (source === "dashborad") {
        getAllSelectedLocations(
          policyId,
          projectId,
          page,
          CERTIFICIAL.PAGINATION.PAGE_SIZE_10
        ).then(
          (res) => {
            setLimits(res.locations);
            setLoading(false);
            setActivePenal(res.locations && res.locations[0].id);
          },
          (err) => {}
        );
      } else {
        getAllLocations(
          policyId,
          page,
          CERTIFICIAL.PAGINATION.PAGE_SIZE_10
        ).then(
          (res) => {
            setLimits(res.locations);
            setLoading(false);
            setActivePenal(res.locations && res.locations[0].id);
          },
          (err) => {}
        );
      }
    }
  };
  const showLimits = (limType) => {
    if (limType == PROPERTY_LIMITS_KINDS.POLICY_LIMIT) {
      if (fixedLimitData && fixedLimitData.length) {
        setLimits(fixedLimitData);
      } else {
        setLoading(true);
        getPropertyByKind(PROPERTY_LIMITS_KINDS.POLICY_LIMIT, policyId, (showDisplayLimit && selectedClientTemplateForShare)? selectedClientTemplateForShare : null, projectId).then(
          (res) => {
            setLimits(res.policyLimts);
            setLoading(false);
          },
          (err) => {}
        );
      }
    } else if (limType == PROPERTY_LIMITS_KINDS.BLANKET) {
      if(fixedBlanketData?.length){
        setLimits(fixedBlanketData);
        setLoading(false);
      }else{
        setLoading(true);
        getPropertyByKind(PROPERTY_LIMITS_KINDS.BLANKET, policyId).then(
          (res) => {
            setLimits(res.blanketLimits);
            setLoading(false);
          },
          (err) => {}
        );
      }
    } else if (limType == PROPERTY_LIMITS_KINDS.LOCATION) {
      if (fixedLocationData && fixedLocationData.length) {
        if(isSmartCoiSource){
          setPageNumber(1);
          let sliced = fixedLocationData.slice(0, 10);
          setLimits(sliced);
          setTotalLocationCount(fixedLocationData.length)
          return;
        }
        
        let res = fixedLocationData.filter((i) => i.policyId === policyId);
        res = res[0];
        if (res && res.selectAll.flag && res.selectAll.checkedBy === "USER") {
          setLoading(true);
          let dataObj = {
            clearPrevious: res.isReresponded ? true : false,
            newAddedList: [],
            projectId: res.isReresponded ? res.projectId : "",
            removeList: [],
            reshare: res.isReresponded,
            selectAll: true
          };
          postAllLocations(
            policyId,
            1,
            CERTIFICIAL.PAGINATION.PAGE_SIZE_10,
            dataObj
          ).then(
            (res) => {
              setLimits(res.locations);
              setTotalLocationCount(res.totalCount);
              if (res.totalCount) {
                setPageNumber(1);
              }
              setLoading(false);
              setActivePenal(res.locations && res.locations[0].id);
            },
            (err) => {}
          );
        } else if (
          res &&
          !res.selectAll.flag &&
          res.selectAll.checkedBy === "SYSTEM"
        ) {
          setLoading(true);
          let locations = [];
          if(res.newAdded && res.newAdded.length) {
            
            locations = res.newAdded.map(item=>{
              if(typeof item === 'object' &&
              !Array.isArray(item) &&
              item !== null) {
                return item.id
              } else {
                return item
              }
            });
          } else {
            locations = res.selectedlocations.map(item=>item.id);
          }
          let dataObj = {
            clearPrevious: false,
            newAddedList: res.isReresponded? locations : locations,
            projectId: res.isReresponded ? res.projectId : "",
            removeList: getArrayofIdFromArrayOfObject(res.deselectedLocations),
            reshare: res.isReresponded,
            selectAll: res.isReresponded === false? true: res.selectAll.flag
          };
          postAllLocations(
            policyId,
            1,
            CERTIFICIAL.PAGINATION.PAGE_SIZE_10,
            dataObj
          ).then(
            (res) => {
              setLimits(res.locations);
              setTotalLocationCount(res.totalCount);
              if (res.totalCount) {
                setPageNumber(1);
              }
              setLoading(false);
              setActivePenal(res.locations && res.locations[0].id);
            },
            (err) => {}
          );
        } else {
          setLimits(res.selectedlocations);
          if (res.selectedlocations && res.selectedlocations.length) {
            setPageNumber(1);
            setTotalLocationCount(res.selectedlocations.length);
          }
          setActivePenal(res.selectedlocations && res.selectedlocations[0].id);
        }
      } else {
        setLoading(true);
        if (source === "dashborad") {
          getAllSelectedLocations(policyId, projectId, 1, 10).then(
            (res) => {
              setLimits(res.locations);
              setTotalLocationCount(res.totalCount);
              if (res.totalCount) {
                setPageNumber(1);
              }
              setLoading(false);
              setActivePenal(res.locations && res.locations[0].id);
            },
            (err) => {}
          );
        } else {
          getAllLocations(policyId, 1, 10).then(
            (res) => {
              setLimits(res.locations);
              setTotalLocationCount(res.totalCount);
              if (res.totalCount) {
                setPageNumber(1);
              }
              setLoading(false);
              setActivePenal(res.locations && res.locations[0].id);
            },
            (err) => {}
          );
        }
      }
    }
  };
  useEffect(() => {
    if (activePenal) {
      setPanelLoading(true);
      const index = limits.findIndex((item) => item.id == activePenal);
      const updateLimit = limits;
      if(isSmartCoiSource){
        setLimits(updateLimit);
        setPanelLoading(false);
      }
      else{
        getLocationById(activePenal).then((res) => {
          if (index > -1) {
            updateLimit.splice(index, 1, { ...res });
          }
          setLimits(updateLimit);
          setPanelLoading(false);
        });
      }

    }
  }, [activePenal]);
  return (
    <Modal
      title={title}
      style={{ top: 20 }}
      width={"95%"}
      visible={limitVisible}
      onCancel={onCloseModal}
      footer={
        <Row>
          <Col span={12} style={{ textAlign: "left" }}>
            <Text style={{ fontSize: 12 }}>
              Press
              <Text code>ESC</Text>to close the {title}.
            </Text>
          </Col>
          <Col span={12}></Col>
        </Row>
      }
      bodyStyle={{
        // padding: 0,
        minHeight: "78vh",
        maxHeight: "78vh",
        height: "78vh",
        overflow: "hidden"
      }}
    >
      <Scrollbars
        universal={true}
        renderThumbVertical={scrollBarThumb}
        renderThumbHorizontal={scrollBarThumb}
      >
        <Spin
          spinning={loading}
          className="customclass"
          indicator={
            <Icon
              type="loading-3-quarters"
              className="color-primary fs-24x"
              spin
            />
          }
        >
          {type == PROPERTY_LIMITS_KINDS.POLICY_LIMIT ||
          type == PROPERTY_LIMITS_KINDS.BLANKET ? (
            <Row gutter={[10, 0]} style={{ marginTop: 5 }}>
              <PropertyPolicyLimitTable
                tableColumns={
                  type == PROPERTY_LIMITS_KINDS.POLICY_LIMIT
                    ? source == "template"
                      ? CERTIFICIAL_TABLE_COLUMNS.PROPERTY
                          .PROPERTY_LIMIT_VIEW_REQUEST
                      : CERTIFICIAL_TABLE_COLUMNS.PROPERTY.PROPERTY_LIMIT_VIEW()
                    : CERTIFICIAL_TABLE_COLUMNS.PROPERTY.BLANKET_VIEW
                }
                dataSource={ type == PROPERTY_LIMITS_KINDS.POLICY_LIMIT
                  ? source == "template"
                    ? limits
                    : (complianceData && complianceData.limitcomparison && complianceData.limitcomparison.limitcomparison) || (complianceData && complianceData.limitcomparison) ? complianceData.limitcomparison.limitcomparison || complianceData.limitcomparison: limits
                  : limits || []}
                readOnlyMode
                source={source}
              />
            </Row>
          ) : (
            <div>
              {(limits && limits.length && (
                <PolicyLocationLimit
                  locationLimitColumns={
                    CERTIFICIAL_TABLE_COLUMNS.PROPERTY.LOCATION_VIEW
                  }
                  locationLimits={limits || {}}
                  onPanelOpen={(id) => setActivePenal(id)}
                  panelLoading={panelLoading}
                />
              )) ||
                null}

              {(limits && limits.length && (
                // <Footer style={{ background: "white", padding: 0, height: 72 }}>
                <Pagination
                  size="small"
                  current={pageNumber}
                  className="list-footer"
                  pageSize={CERTIFICIAL.PAGINATION.PAGE_SIZE_10}
                  total={totalLocationCount}
                  onChange={onPageChange}
                  showLessItems={true}
                  showTotal={(total, range) => (
                    <h5>
                      Showing {range[0]} - {range[1]} of {total} locations
                    </h5>
                  )}
                />
                // </Footer>
              )) ||
                null}
            </div>
          )}
        </Spin>
      </Scrollbars>
    </Modal>
  );
};

export default LimitTypeModal;
