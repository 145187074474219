import { appHistory } from "../../../Utils/Helpers/History";
import { TRACKED_POLICIES_ACTION_TYPES } from "./RequestorDashboard.types";

export const handleExceptionSuccess = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.SUCCESS
});

export const handleExceptionError = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.ERROR
});

export const uploadHistoryModalSuccess = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.DOC_UPLOAD_HISTORY.SUCCESS,
  payload: payload
});

export const uploadHistoryModalError = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.DOC_UPLOAD_HISTORY.ERROR,
  payload: payload
});
export const deleteUploadHistorySuccess = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.DELETE_UPLOAD_HISTORY.SUCCESS,
  payload: payload
});
export const deleteUploadHistoryError = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.DELETE_UPLOAD_HISTORY.ERROR,
  payload: payload
});
export const getDocHistorySuccess = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_UPLOAD_HISTORY.SUCCESS,
  payload: payload
});

export const getDocHistoryError = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_UPLOAD_HISTORY.ERROR,
  payload: payload
});
export const setExceptionHandlingStatus = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.MODAL
});

export const setInitLoadingSpinner = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.LOADER,
  payload: payload
});

export const getExceptionDataSuccess = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_EXCEPTION_DATA.SUCCESS,
  payload: payload
});

export const exceptionHandlingModal = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.HANDLE_EXCEPTION.MODAL,
  payload: payload
});

export const requestedDummyTrackedPolicies = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.DUMMY_TRACKED_COMPANIES,
  payload: 5
});

export const requestedTrackedPoliciesSuccess = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_TRACKED_COMPANIES.SUCCESS,
  payload
});
export const setRequestedTrackedRefreshCompanyId = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.SET,
  payload
});

export const clearRequestedTrackedRefreshCompanyId = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.REFRESH_COMPANY_STATUS.CLEAR
});

export const requestedTrackedPoliciesError = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_TRACKED_COMPANIES.ERROR,
  payload
});

export const setCompanySelection = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_TRACKED_COMPANIES,
  payload
});
export const clearTrackedPolicies = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.CLEAR_TRACKED_COMPANIES
});

export const getSelectedCompanyByIdSuccessListView = (obj) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_TRACKED_COMPANIES.SUCCESS,
  payload: obj
});

export const getSelectedCompanyByIdErrorListView = (obj) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_TRACKED_COMPANIES.ERROR,
  payload: obj
});

export const dashboardFilterCompanySuccess = (obj) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.LIST_OF_COMPANY.FILTER_SUCCESS,
  payload: obj
});

// export const clearPolicyListSuccess = () => ({
//     type: TRACKED_POLICIES_ACTION_TYPES.CLEAR.CLEAR_LIST_OF_POLICY
// });

export const cancelSelectedCompanyByIdSuccess = (obj) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_COMPNAY.SUCCESS,
  payload: obj
});

export const cancelSelectedPoliciesByIdSuccess = (Id) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_POLICY.SUCCESS,
  payload: Id
});

export const cancelSelectedPoliciesByIdError = (obj) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_POLICY.ERROR,
  payload: obj
});

export const setSearchStringAction = (str) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_SEARCH_STRING,
  payload: str
});

export const setPageNumberAction = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_PAGE_NUMBER,
  payload
});

export const cancelSelectedCompanyByIdError = (obj) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.CANCEL_SELECETED_COMPNAY.ERROR,
  payload: obj
});

export const getProjectListSuccess = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_PROJECTS_LIST.SUCCESS,
  payload
});

export const getProjectListError = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.GET_PROJECTS_LIST.ERROR,
  payload
});

export const setActiveProject = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_ACTIVE_PROJECT,
  payload
});
export const clearActiveProject = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.CLEAR_ACTIVE_PROJECT
});
export const updateComplianceStatus = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.UPDATE_COMPLIANCE_STATUS,
  payload
});
export const isFilterApplied = (booleanValue) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.IS_FILTER_APPLIED,
  payload: booleanValue
});

export const setShowInsuredOption = (value) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_SHOW_INSURED_OPTION,
  payload: value
});

export const setShowInsuredList = (value) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_SHOW_INSURED_LIST,
  payload: value
});
export const setInsuredListValue = (value) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_SHOW_INSURED_LIST_VALUE,
  payload: value
});
export const reSetInsuredListValue = () => ({
  type: TRACKED_POLICIES_ACTION_TYPES.RESET_SHOW_INSURED_LIST_VALUE,
  payload: []
});

export const clearDashboardCompany = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.RESET_REQUESTOR_DASHBOARD_COMPANY,
  payload
});


export const setCompanyLevelFilters = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_COMPANY_LEVEL_FILTERS,
  payload
});

export const setDashboardDrillDownFilters = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_DASHBOARD_DRILLDOWN_FILTERS,
  payload
});

export const setPolicyLevelFilters = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.SET_POLICY_LEVEL_FILTERS,
  payload
});

export const resetFilters = (payload) => ({
  type: TRACKED_POLICIES_ACTION_TYPES.RESET_FILTERS,
  payload
});

export const handleDrilldownAction = (payload) => {
  if(!payload){
    return;
  }
  return async(dispatch) => {
    const navigationUrl = payload.navigationUrl || "/app/suppliers"
    dispatch(setDashboardDrillDownFilters({ ...payload.filter }))
    appHistory.push({ pathname: navigationUrl, state: true });
  };
};

