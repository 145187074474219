import axios from "axios";
import { BASE_PATH } from "./API.endpoints";
import { COMMON_HEADERS } from "./API.headers";
import { axiosInterceptors } from "./API.intercepters";
import { INERCEPT_QUERY_PARAM_LIST } from "../Utils/Constants/Application.constants";
const qs = require("qs");

export const axiosInstance = axios.create({
  baseURL: BASE_PATH,
  //timeout: 300000,
  headers: COMMON_HEADERS(),
  paramsSerializer: (params) => {
    Object.keys(params).forEach((key) => {
      if (
        typeof params[key] == "string" &&
        INERCEPT_QUERY_PARAM_LIST.includes(key)
      ) {
        params[key] = params[key].replace(/([%_\\])/g, "\\$1");
      }
    });
    return qs.stringify(params, { arrayFormat: "brackets" });
  }
});

axiosInterceptors();
