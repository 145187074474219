import { Icon, Typography } from "antd";
import React, { memo } from "react";
import { CERTIFICIAL } from "../../Utils/Constants/Application.constants";

const { Text } = Typography;

const ContactUsViaEmail = memo(function ContactUsViaEmail() {
  return (
    <Text>
      <Icon type="mail" theme="twoTone" className="margin-right-sm" />
      <a
        style={{
          color: "inherit",
          cursor: "pointer"
        }}
        href={
          CERTIFICIAL.MAILTO_SUPPORT_EMAIL_ADDRESS +
          "?subject=Support&body=Hello Certificial Team,"
        }
        rel="noopener noreferrer"
      >
        Contact Us
      </a>
    </Text>
  );
});

export default ContactUsViaEmail;
