import { EXTRACTION_POLICIES_TYPES } from "./SmartCoi.types";

export const getExtractedPoliciesSuccess = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.GET_EXTRACTED_POLICIES.SUCCESS,
  payload
});

export const getInactivePoliciesSuccess = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.GET_INACTIVE_POLICIES.SUCCESS,
  payload
});



export const updateExtractedPolicies = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.UPDATE_EXTRACTED_POLICIES,
  payload
});


export const extractionPoliciesSaveSuccess = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.EXTRACTION_POLICIES_SAVE_SUCCESS,
  payload
});

export const setExtractionProgress = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.SET_EXTRACTION_PROGRESS.SUCCESS,
  payload
});

export const setExtractionStatus = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.SET_EXTRACTION_STATUS.SUCCESS,
  payload
});

export const setSteps = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.SET_STEPS,
  payload
});

export const resetSmartCoiStates = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.RESET_STATES,
  payload
});

export const savePolicyDataSuccess = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.SAVE_DATA.SUCCESS,
  payload
});

export const setGenerateSignature = (payload) => ({
  type: EXTRACTION_POLICIES_TYPES.SET_GENERATE_SIGNATURE,
  payload
});


