import { Button, Form, Input, message, Icon, Row, Col, Alert } from "antd";
import React from "react";
import { connect } from "react-redux";
import { updatePassword } from "../../Store/Modules/MyProfile/MyProfile.asyncRequest";
import { logout_action } from "../../Store/Modules/Application/Application.asyncRequest";
import { strongPasswordVerification } from "../../Utils/utility/app.utility";
import { PasswordStrength } from "../../Modules/Authorization/PasswordStrength/PasswordStrength";
class ChangePassword extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    isLoader: false,
    error: {}
  };

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  componentWillUnmount() {
    this.setState({
      error: {}
    });
  }
  componentDidUpdate() {
    if (this.props.successData.passwordUpdateSuccess) {
      if (this.state.isLoader) {
        this.setState({ isLoader: false });
        this.props.form.resetFields();
      }
    }
  }

  onSubmit = () => {
    let password = this.props.form.getFieldValue("password");
    let confirmedPassword = this.props.form.getFieldValue("confirmPassword");
    this.props.form.validateFields((err, values) => {
      if (password && password === confirmedPassword) {
        this.setState({ isLoader: true });
        values.userId = this.props.successData.userData.id;
        values.email = (!!!values.userId) ? this.props.username : null;
        const { dispatchChangePassowrd } = this.props;
        dispatchChangePassowrd(values).then(
          (res) => {
            if (this.props.successData.passwordUpdateSuccess) {
              message.success("Your password has been changed successfully.");
              this.setState({ isLoader: false });
              this.props.onPasswordUpdate(true);
            } else {
              this.setState({ error: this.props.successData.error });
              this.setState({ isLoader: false });
              // this.props.onPasswordUpdate();
            }
          },
          (err) => {}
        );
      }

      if (!err) {
        const { form } = this.props;
        form.validateFields(["password"], { force: true });
        form.validateFields(["confirmPassword"], { force: true });
      }
    });
  };

  onReset = (values) => {
    if (values) {
      this.props.form.resetFields();
      this.setState({ isLoader: false, confirmDirty: false, error: {} });
    }
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && !form.getFieldValue("password")) {
      const strongnessOfPassword = strongPasswordVerification(value);
      if (!!strongnessOfPassword) {
        callback(strongnessOfPassword);
      }
    } else if (value && value !== form.getFieldValue("password")) {
      callback("Password does not match");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    if (!!value) {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirmPassword"], { force: true });
      }
      const strongnessOfPassword = strongPasswordVerification(value);
      if (!!strongnessOfPassword) {
        callback(strongnessOfPassword);
      }
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form ref={(form) => (this.form = form)}>
        <Row>
          <Col span={22}>
            <Form.Item label="Old Password" hasFeedback>
              {getFieldDecorator("oldPassword", {
                rules: [
                  {
                    transform: (value) => (value && value.trim()) || null
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your old password."
                  }
                ]
              })(
                <Input.Password
                  placeholder="Old Password"
                  // maxLength={12}
                  minLength={8}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.45)" }} />
                  }
                  autoComplete="new-password"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item label="New Password" hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    transform: (value) => (value && value.trim()) || null
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your password."
                  },
                  {
                    validator: this.validateToNextPassword
                  }
                ]
              })(
                <Input.Password
                  placeholder="Password"
                  // maxLength={12}
                  minLength={8}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.45)" }} />
                  }
                  autoComplete="new-confirm-password"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={1} offset={1}>
            <div style={{ lineHeight: "4.6" }}>
              <PasswordStrength />
            </div>
          </Col>
          <Col span={22}>
            <Form.Item label="Confirm New Password" hasFeedback>
              {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your new password."
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(
                <Input.Password
                  onBlur={this.handleConfirmBlur}
                  placeholder="Confirm Password"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.45)" }} />
                  }
                  autoComplete="new-confirm-password"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {!!this.state.error && this.state.error.message && (
          <Col span={22} style={{ margin: "12px 0" }}>
            <Alert showIcon message={this.state.error.message} type="error" />
          </Col>
        )}

        <Form.Item
          style={{ marginTop: 24, textAlign: "right", marginRight: 12 }}
        >
          <Button
            onClick={this.onReset}
            style={{ marginRight: 12 }}
            disabled={this.state.isLoader}
          >
            Reset
          </Button>
          <Button
            type="primary"
            disabled={this.hasErrors(this.props.form.getFieldsError())}
            loading={this.state.isLoader}
            onClick={this.onSubmit}
            icon="check-circle"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
const mapDispatchToProps = (dispatch) =>({
    dispatchChangePassowrd: (formValue) => dispatch(updatePassword(formValue))
});
const mapStateToProps = (state) => ({
  successData: state.myProfile
});

const ChangePasswordForm = new Form.create()(ChangePassword);
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
