export const AGENTS_DASHBOARD_LIST_TYPE = {
  LIST_OF_COMPANY: {
    SUCCESS: "LIST_BY_COMPANY_SUCCESS",
    FILTER_SUCCESS: "FILTER_LIST_OF_AGENT_COMPANY",
    ERROR: "LIST_BY_COMPANY_ERROR",
    DUMMY_COMPANIES: "DUMMY_COMPANIES"
  },
  CLIENT_LIST_OF_COMPANY: {
    SUCCESS: "CLIENT_LIST_BY_COMPANY_SUCCESS",
    FILTER_SUCCESS: "FILTER_LIST_OF_CLIENT_COMPANY",
    ERROR: "CLIENT_LIST_BY_COMPANY_ERROR",
    DUMMY_COMPANIES: "DUMMY_COMPANIES"
  },
  LIST_OF_POLICY: {
    SUCCESS: "LIST_OF_POLICY_SUCCESS",
    ERROR: "LIST_OF_POLICY_ERROR",
    POLICY_NULL: "LIST_OF_POLICY__NULL",
    DUMMY_POLICIES: "DUMMY_POLICIES"
  },
  CLIENT_LIST_OF_POLICY: {
    SUCCESS: "CLIENT_LIST_OF_POLICY_SUCCESS",
    ERROR: "CLIENT_LIST_OF_POLICY_ERROR"
  },
  ACTIONS: {
    SUCCESS: "AGENT_ACTION_SUCCESS",
    ERROR: "AGENT_ACTION_ERROR"
  },
  UPDATE_POLICY: {
    SUCCESS: "AGENT_POLICY_UPDATE",
    ERROR: "AGENT_POLICY_UPDATE"
  },
  CLEAR: {
    CLEAR_LIST_OF_COMPANIES: "CLEAR_LIST_OF_COMPANIES",
    CLEAR_LIST_OF_POLICY: "CLEAR_LIST_OF_COMPANIES",
    CLEAR_PROJECT_TEMPLATES: "CLEAR_PROJECT_TEMPLATES",
    CLEAR_LIST_OF_COMPANIES_DASHBOARD: "CLEAR_LIST_OF_COMPANIES_DASHBOARD"
  },
  GET_PROJECT_TEMPLATES: {
    SUCCESS: "GET_AGENT_TEMPLATES_SUCCESS",
    ERROR: "GET_AGENT_TEMPLATES_ERROR"
  },
  SET_LIST_OF_ADDRESS_BOOK_GROUP_FOR_SELECTED_CLIENT: {
    SUCCESS: "SET_LIST_OF_ADDRESS_BOOK_GROUP_FOR_SELECTED_CLIENT_SUCCESS",
    ERROR: "SET_LIST_OF_ADDRESS_BOOK_GROUP_FOR_SELECTED_CLIENT_ERROR"
  },
  SET_SELECTED_ADDRESS_GROUP: {
    SUCCESS: "SET_SELECTED_ADDRESS_GROUP_SUCCESS"
  },
  SET_LIST_OF_ADDRESS_FOR_SELECTED_ADDRESS_GROUP: {
    SUCCESS: "SET_LIST_OF_ADDRESS_FOR_SELECTED_ADDRESS_GROUP_SUCCESS",
    ERROR: "SET_LIST_OF_ADDRESS_FOR_SELECTED_ADDRESS_GROUP_ERROR"
  },
  ALL_CLIENT_LIST_OF_POLICY: {
    SUCCESS: "ALL_CLIENT_LIST_OF_POLICY_SUCCESS",
    ERROR: "ALL_CLIENT_LIST_OF_POLICY_ERROR"
  },
  SET_SEARCH_STRING: "SET_SEARCH_STRING",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
  SET_COMPANY_STATUS: "SET_COMPANY_STATUS",
  SET_PAGINATION_FILTER: "SET_PAGINATION_FILTER",
  CLEAR_PAGINATION_FILTER: "CLEAR_PAGINATION_FILTER",
  CHECK_CERT_HOLDERS:"CHECK_CERT_HOLDERS",
  SET_CLIENT_LEVEL_FILTERS:"SET_CLEINT_LEVEL_FITERS",
  SET_POLICY_LEVEL_FILTERS:"SET_POLICY_LEVEL_FITERS",
  RESET_FILTERS:"RESET_FILTERS"
};
