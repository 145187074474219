import { message } from "antd";
import { appHistory } from "../Utils/Helpers/History";
import { _authService } from "../Utils/Services/AuthenticationService";
import { _modalService } from "../Utils/wrappers/Modal/ModalWrapper";
import { axiosInstance } from "./Axios.instance";

export const axiosInterceptors = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      let AuthorizationHeader =
        _authService.getUserToken() && _authService.getUserToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          ...((!config.shouldNotUserAuthHeaders &&
            AuthorizationHeader && {
              Authorization: ["Bearer", AuthorizationHeader].join(" "),
              userKey:
                _authService.getUserInfo() && _authService.getUserInfo().userId
            }) ||
            {})
        }
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      message.destroy();
      let AuthorizationHeader =
        _authService.getUserToken() && _authService.getUserToken();
      if (
        !AuthorizationHeader &&
        response.data &&
        response.data.data &&
        response.data.data.jwt
      ) {
        _authService.setUserToken(response.data.data.jwt);
      }
      if (AuthorizationHeader && !!response.headers["jwt_refreshed"]) {
        _authService.setUserToken(response.headers["jwt_refreshed"]);
      }
      if (
        response.data &&
        response.data.data &&
        response.data.data.message === "You have been logged out successfully"
      ) {
        _authService.logoutUser();
        _authService.clearUserRole();
        _authService.clearUserInfo();
      }
      if (
        response.headers["content-type"] === "application/octet-stream" ||
        response.headers["content-type"] === "application/pdf"
      ) {
        return response.data;
      }
      if (
        response.data &&
        response.data.data &&
        response.data.data.message &&
        response.data.data.message ===
          "Company found with the provided email address."
      ) {
        message.success(response.data.data.message);
      }
      return response.data.data;
    },
    (error) => {
      const { response } = error;
      if (response) {
        message.destroy();
        if (
          response.config &&
          !response.config.shouldNotShowError &&
          ((response &&
            response.data &&
            response.data.data &&
            response.data.data.message === "Token is invalid") ||
            response.data.status === 403 ||
            response.data.status === 401)
        ) {
          _modalService.confirm({
            title: "Session Timeout",
            content:
              "Your session got terminated, Please login again to continue.",
            okText: "Login Again",
            closable: false,
            keyboard: false,
            width: "40%",
            cancelButtonProps: {
              disabled: true,
              style: { display: "none" }
            },
            onOk() {
              _authService.logoutUser();
              _authService.clearUserRole();
              _authService.clearUserInfo();
              _modalService.destroyAll();
              appHistory.push("/");
            },
            onCancel() {}
          });
          return Promise.reject({
            message: ""
          });
        } else {
          if (
            response.status === 404 &&
            response.data &&
            response.data.data &&
            response.data.data.error === "User or User company doesn't exist."
          ) {
            const errorMessage =
              (error.error instanceof ErrorEvent &&
                ` ${error.error.message}`) ||
              ` ${response.data.data.error}`;
            message.warn(errorMessage);
            return Promise.reject(response.data.data);
          }
          if (
            response.status === 404 &&
            response.data.data &&
            response.data.data.error ===
              "No policies elegible for COI Generation"
          ) {
            return false;
          }
          const errorMessage =
            (error.error instanceof ErrorEvent && `${error.error.message}`) ||
            (response &&
              response.data &&
              response.data.data &&
              response.data.data.message &&
              `${response.data.data.message}`) ||
            "Unable to process request, please contact service administrator.";
          if (response.config && !response.config.shouldNotShowError) {
            message.error(errorMessage);
          }
          return Promise.reject(
            (response && response.data && response.data.data) ||
              "Unable to process request, please contact service administrator."
          );
        }
      }
      return Promise.reject({
        message:
          "Unable to process request, please contact service administrator."
      });
    }
  );
};
