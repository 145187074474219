import React from "react";
import { ExclusionPolicyCoverage } from "../ExclusionPolicyCoverage/ExclusionPolicyCoverage";
import { ExceptionForInsured } from "../ExceptionForInsured/ExceptionForInsured";




export const WorkesCompExclusion = ({
  handleExceptionForInsured,
  coverage,
  readOnly,
  handleExclusionData
}) => {

  return (
    <div style={{display:"flex", width:"100%", marginBottom:"15px"}} className={readOnly ? "ant-card-bordered":""}>
      <div style={{flexGrow:"1"}}>
          <ExclusionPolicyCoverage coverage={coverage} onReadOnly={readOnly} handleExclusionData={handleExclusionData} />
      </div>
      <div style={{marginLeft:"auto"}}>
        <ExceptionForInsured readOnly={readOnly} handleExceptionForInsured={handleExceptionForInsured} exceptions={coverage.exceptionForInsured} />
      </div>
    </div>
  )
}
