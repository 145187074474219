import React from 'react';
import { formatToUSDCommaSeparated, isDeductibleWithPercentSymbol, isDeductibleWithoutSymbol, getDeductibleValueForRequestorView, getAvailableDeductibleValue } from "../utility/app.utility";
export const CERTIFICIAL_TABLE_COLUMNS = {
  PROPERTY: {
    BLANKET: [
      {
        title: "Blanket Number",
        dataIndex: "blanketNumber",
        editable: true,
        inputType: "string",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Blanket Type",
        dataIndex: "blanketType",
        editable: true,
        inputType: "select_limit",
        width: 140,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Total Blanket Limit",
        dataIndex: "totalBlanketLimit",
        editable: true,
        inputType: "number",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },

      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationTypeId",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 120,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "",
        dataIndex: "remove",
        editable: true,
        inputType: "delete",
        width: 80,
        render: (value, row, index) => {
          return "remove";
        }
      }
    ],
    PROPERTY_LIMIT: [
      {
        title: "Limit Category",
        dataIndex: "limitCategoryId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Type",
        dataIndex: "limitTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit 1",
        dataIndex: "limits",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Limit 2",
        dataIndex: "limit2",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationTypeId",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "",
        dataIndex: "remove",
        editable: true,
        inputType: "delete",
        width: 80,
        render: (value, row, index) => {
          return "remove";
        }
      }
    ],
    PROPERTY_LIMIT_REQUEST: [
      {
        title: "Limit Category",
        dataIndex: "limitCategoryId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Type",
        dataIndex: "limitTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit 1",
        dataIndex: "limitValue",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Limit 2",
        dataIndex: "limit2",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationTypeId",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "",
        dataIndex: "remove",
        editable: true,
        inputType: "delete",
        width: 80,
        render: (value, row, index) => {
          return "remove";
        }
      }
    ],
    LOCATION: [
      {
        title: "Blanket Number",
        dataIndex: "blanketId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Category",
        dataIndex: "limitCategoryId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Type",
        dataIndex: "limitTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit 1",
        dataIndex: "limits",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Limit 2",
        dataIndex: "limit2",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationTypeId",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableTypeId",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "",
        dataIndex: "remove",
        editable: true,
        inputType: "delete",
        width: 80,
        render: (value, row, index) => {
          return "";
        }
      }
    ],
    BLANKET_VIEW: [
      {
        title: "Blanket Number",
        dataIndex: "blanketNumber",
        editable: true,
        inputType: "string",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Blanket Type",
        dataIndex: "blanketTypeName",
        editable: true,
        inputType: "string",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Total Blanket Limit",
        dataIndex: "totalBlanketLimit",
        editable: true,
        inputType: "number",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: formatToUSDCommaSeparated(value),
            props: {}
          };

          return obj;
        }
      },

      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationType",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: formatToUSDCommaSeparated(value),
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableType",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: (isDeductibleWithoutSymbol(row.deductableType) || isDeductibleWithPercentSymbol(row.deductableType)) ? value : formatToUSDCommaSeparated(value),
            props: {}
          };
          return obj;
        }
      }
    ],
    PROPERTY_LIMIT_VIEW: () => ([
      {
        title: "Limit Category",
        dataIndex: "parentLimitCategory.name",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limitCategoryIssue === 1 ? (
                <>
                  {value ? (
                    <div
                      style={
                        value
                          ? { fontWeight: "bold" }
                          : { fontWeight: "bold", marginLeft: "65px" }
                      }
                    >
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.requestedParentLimitCategory ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req:{" "}
                      {row.requestedParentLimitCategory
                        ? row.requestedParentLimitCategory
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Type",
        dataIndex: "name",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limitTypeIssue === 1 ? (
                <>
                  {value ? (
                    <div
                      style={
                        value
                          ? { fontWeight: "bold" }
                          : { fontWeight: "bold", marginLeft: "65px" }
                      }
                    >
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.requestedLimitType ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req:{" "}
                      {row.requestedLimitType ? row.requestedLimitType : "-"})
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Limit 1",
        dataIndex: "limits",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          return {
            children:
              row.limitIssue === 1 ? (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    {value === 0 ? row.currencySymbol : ""}
                    {value}
                  </div>

                  {row.requested_limit ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req: {row.requested_limit ? row.currencySymbol : ""}
                      {row.requested_limit ? row.requested_limit : "-"})
                    </div>
                  ) : null}
                </>
              ) : (
                formatToUSDCommaSeparated(value)
              ),
          };
        }
      },
      {
        title: "Limit 2",
        dataIndex: "limit2",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limit2Issue === 1 ? (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    {value === 0 ? row.currencySymbol : ""}
                    {value}
                  </div>

                  {row.requested_limit2 ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req: {row.requested_limit2 ? row.currencySymbol : ""}
                      {row.requested_limit2 ? row.requested_limit2 : "-"})
                    </div>
                  ) : null}
                </>
              ) : (
                formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      },
      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children:
              row.coinsuranceIssue === 1 ? (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    {value}
                    {value === 0 ? "%" : ""}
                  </div>

                  {row.requestedCoinsurance ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req:{" "}
                      {row.requestedCoinsurance
                        ? row.requestedCoinsurance
                        : "-"}
                      {row.requestedCoinsurance ? "%" : ""})
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationType",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children:
              row.valuationTypeIssue === 1 ? (
                <>
                  {value ? (
                    <div
                      style={
                        value
                          ? { fontWeight: "bold" }
                          : { fontWeight: "bold", marginLeft: "65px" }
                      }
                    >
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.requestedValuationType ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req:{" "}
                      {row.requestedValuationType
                        ? row.requestedValuationType
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children:
              row.valuationAmtIssue === 1 ? (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    {value === 0 ? row.currencySymbol : ""}
                    {value}
                  </div>

                  {row.requestedValuationAmt ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req:{" "}
                      {row.requestedValuationAmt ? row.currencySymbol : ""}
                      {row.requestedValuationAmt
                        ? row.requestedValuationAmt
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableType",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children:
              row.deductableTypeIssue === 1 ? (
                <>
                  {value ? (
                    <div
                      style={
                        value
                          ? { fontWeight: "bold" }
                          : { fontWeight: "bold", marginLeft: "65px" }
                      }
                    >
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.requestedDeductibleType ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req:{" "}
                      {row.requestedDeductibleType
                        ? row.requestedDeductibleType
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children:
              row.deductibleIssue === 1 ? (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    {getDeductibleValueForRequestorView(row, value)}
                  </div>

                  {row.requestedDeductible ? (
                    <div style={{ color: "#f30b17" }}>
                      (Req: {getAvailableDeductibleValue(row, row.requestedDeductible)})
                    </div>
                  ) : null}
                </>
              ) : (
                (isDeductibleWithoutSymbol(row.deductableType) || isDeductibleWithPercentSymbol(row.deductableType)) ? value : formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      }
    ]),
    PROPERTY_LIMIT_VIEW_REQUEST: [
      {
        title: "Limit Category",
        dataIndex: "parentLimitCategory.name",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limitCategoryIssue === 1 ? (
                <>
                  {value ? (
                    <div style={{ color: "#f30b17" }}>
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.availableParentLimitCategory ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {row.availableParentLimitCategory
                        ? row.availableParentLimitCategory
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
            props: {},
          };

          return obj;
        }
      },
      {
        title: "Limit Type",
        dataIndex: "name",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limitTypeIssue === 1 ? (
                <>
                  {value ? (
                    <div style={{ color: "#f30b17" }}>
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.availableLimitType ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {row.availableLimitType ? row.availableLimitType : "-"})
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
            props: {},
          };

          return obj;
        }
      },
      {
        title: "Limit 1",
        dataIndex: "limitValue",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limitIssue === 1 ? (
                <>
                  <div style={{ color: "#f30b17" }}>
                    {value === 0 ? row.currencySymbol : ""}
                    {value}
                  </div>

                  {row.available_limit ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy: {row.available_limit ? row.currencySymbol : ""}
                      {row.available_limit ? row.available_limit : "-"})
                    </div>
                  ) : null}
                </>
              ) : (
                formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      },
      {
        title: "Limit 2",
        dataIndex: "limit2",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children:
              row.limit2Issue === 1 ? (
                <>
                  <div style={{ color: "#f30b17" }}>
                    {value === 0 ? row.currencySymbol : ""}
                    {value}
                  </div>

                  {row.available_limit2 ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy: {row.available_limit2 ? row.currencySymbol : ""}
                      {row.available_limit2 ? row.available_limit2 : "-"})
                    </div>
                  ) : null}
                </>
              ) : (
                formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      },
      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children:
              row.coinsuranceIssue === 1 ? (
                <>
                  <div style={{ color: "#f30b17" }}>
                    {value}
                    {value === 0 ? "%" : ""}
                  </div>

                  {row.availableCoinsurance ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {row.availableCoinsurance
                        ? row.availableCoinsurance
                        : "-"}
                      {row.availableCoinsurance ? "%" : ""})
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationType",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children:
              row.valuationTypeIssue === 1 ? (
                <>
                  {value ? (
                    <div style={{ color: "#f30b17" }}>
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.availableValuationType ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {row.availableValuationType
                        ? row.availableValuationType
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children:
              row.valuationAmtIssue === 1 ? (
                <>
                  <div style={{ color: "#f30b17" }}>
                    {value === 0 ? row.currencySymbol : ""}
                    {value}
                  </div>

                  {row.availableValuationAmt ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {row.availableValuationAmt ? row.currencySymbol : ""}
                      {row.availableValuationAmt
                        ? row.availableValuationAmt
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableType",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children:
              row.deductableTypeIssue === 1 ? (
                <>
                  {value ? (
                    <div style={{ color: "#f30b17" }}>
                      {value ? value : "-"}
                    </div>
                  ) : null}
                  {row.availableDeductableType ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {row.availableDeductableType
                        ? row.availableDeductableType
                        : "-"}
                      )
                    </div>
                  ) : null}
                </>
              ) : (
                value
              ),
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children:
              row.deductibleIssue === 1 ? (
                <>
                  <div style={{ color: "#f30b17" }}>
                    {getDeductibleValueForRequestorView(row, value)}
                  </div>

                  {row.availableDeductible ? (
                    <div style={{ fontWeight: "bold" }}>
                      (Policy:{" "}
                      {getAvailableDeductibleValue(row, row.availableDeductible)})
                    </div>
                  ) : null}
                </>
              ) : (
                (isDeductibleWithoutSymbol(row.deductableType) || isDeductibleWithPercentSymbol(row.deductableType)) ? value : formatToUSDCommaSeparated(value)
              ),
          };
          return obj;
        }
      }
    ],
    LOCATION_VIEW: [
      {
        title: "Blanket Number",
        dataIndex: "blanketNumber",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Category",
        dataIndex: "parentLimitCategory.name",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit Type",
        dataIndex: "name",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };

          return obj;
        }
      },
      {
        title: "Limit 1",
        dataIndex: "limits",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: formatToUSDCommaSeparated(value),
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Limit 2",
        dataIndex: "limit2",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: formatToUSDCommaSeparated(value),
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Coinsurance %",
        dataIndex: "coinsurance",
        editable: true,
        width: 120,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Type",
        dataIndex: "valuationType",
        editable: true,
        width: 160,
        inputType: "select_limit",
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Valuation Amount",
        dataIndex: "valuationAmt",
        editable: true,
        width: 100,
        inputType: "number",
        render: (value, row, index) => {
          const obj = {
            children: formatToUSDCommaSeparated(value),
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Type",
        dataIndex: "deductableType",
        editable: true,
        inputType: "select_limit",
        width: 160,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {}
          };
          return obj;
        }
      },
      {
        title: "Deductible Amount",
        dataIndex: "deductible",
        editable: true,
        inputType: "number",
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children:  (isDeductibleWithoutSymbol(row.deductableType) || isDeductibleWithPercentSymbol(row.deductableType)) ? value : formatToUSDCommaSeparated(value),
            props: {},
          };
          return obj;
        }
      }
    ]
  }
};
