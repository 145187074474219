import { APPLICATION_MODULES } from "../../../Utils/Constants/Application.constants";
import { MY_POLICIES_ACTION_TYPES } from "./Mypolicies.types";
import _ from "lodash";

const MyPoliciesInitialState = {
  title: APPLICATION_MODULES.MY_POLICIES.TITLE,
  policies: [],
  PolicyTypeList: [],
  selectedPolicyDetails: {},
  isError: false
};

const Base_MyPoliciesInitialState = { ...MyPoliciesInitialState };

const MyPoliciesReducer = (state = MyPoliciesInitialState, action) => {
  switch (action.type) {
    case MY_POLICIES_ACTION_TYPES.GET_ALL_POLICIES.SUCCESS: {
      const policies = action.payload ? [...action.payload] : [];
      let myPoliciesData = _.map(
        policies.length > 0 && policies,
        (policy) => policy.policyType.name
      );
      return {
        ...state,
        policies: policies,
        PolicyTypeList: _.sortedUniq(myPoliciesData.sort())
      };
    }

    case MY_POLICIES_ACTION_TYPES.GET_ALL_POLICIES.ERROR: {
      return {
        ...state,
        policies: [],
        completePolicySet: [],
        isError: true
      };
    }

    case MY_POLICIES_ACTION_TYPES.GET_POLICY.SUCCESS: {
      return { ...state, selectedPolicyDetails: action.payload };
    }

    case MY_POLICIES_ACTION_TYPES.GET_POLICY.ERROR: {
      return { ...state, selectedPolicyDetails: {} };
    }

    case MY_POLICIES_ACTION_TYPES.RESET_POLICY: {
      return { ...state, selectedPolicyDetails: {} };
    }

    case MY_POLICIES_ACTION_TYPES.CLEAR_MY_POLICIES: {
      return { ...Base_MyPoliciesInitialState };
    }
    default:
      return state;
  }
};

export default MyPoliciesReducer;
