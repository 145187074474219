import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Empty,
  Icon,
  List,
  Popover,
  Skeleton,
  Row,
  Tag,
  Typography
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import Animate from "rc-animate";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyImage, remarksIcon } from "../../../Assets/assets";
import { exceptionHandlingModal } from "../../../Store/Modules/RequestorDashboard/RequestorDashboard.action";
import { CertificialThemeContext } from "../../../theme-context";
import { CERTIFICIAL_LABEL } from "../../../Utils/Constants/App.labels";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import {
  getActionIcon,
  titleMaintainer, 
  validateUserPermission
} from "../../../Utils/utility/app.utility";
import CertAvatar from "../../UI/CertAvatar/CertAvatar";
import CertInfoTooltip from "../../UI/CertTooltip/CertInfoTooltip";
import {
  CERTIFICIAL,
  CERTIFICIAL_STATUS
} from "./../../../Utils/Constants/Application.constants";

const { Paragraph } = Typography;
const { Panel } = Collapse;

export const AdditionalRemarksOnEndorsments = ({
  emptyRemarksText = null,
  bordered,
  isTitleRequired,
  readOnlyMode,
  isAgent,
  selectedPolicyTypes,
  onAdditionalRemarksChange,
  allowEditForException,
  addRemarksInException,
  showKebabMenu,
  remarkCompliance,
  complianceIndicator,
  isTemplate,
  additionalRemarksTitle,
  source,
  isRequestFromAgent,
  isMultiAgent: _areOtherAgentsInvovledInRespond,
  isMultiAgentSupport: _isMultiAgentSupport,
  totalAgentsInvolved,
  multiAgentDetails,
  multiAgantPolicyInfo,
  shouldShowAdditionalRemarksDetails,
  isRequestorEnrollmentFeesPaid,
  disabled,
  extraBottomComponent,
  insertExtractedDooHandler,
  isEvidenceOnlyRequest
}) => {
  const CERTIFICIAL_THEME = useContext(CertificialThemeContext);
  const [policyTypes, setPolicyTypes] = useState([]);
  const {userData} = useSelector((state)=>state.myProfile);
  const {currentSession} = useSelector((state)=>state.app)

  const reShareData = useSelector((state) => state.pendingInsuredRequests);
  const disableMenuOptions = validateUserPermission(userData.permissions, currentSession.role, "SUPPLIER_POLICY_LEVEL_ACTION_MENU")
  const isRespondView = source === CERTIFICIAL.SHARE_VIEW.RESPOND;
  const isMultiAgentSupport =
    _isMultiAgentSupport &&
    multiAgentDetails.agentName &&
    !isRequestFromAgent &&
    totalAgentsInvolved > 1;
  useEffect(() => {
    if (selectedPolicyTypes && selectedPolicyTypes.length) {
      setPolicyTypes(selectedPolicyTypes.map((s) => ({ ...s, isEdit: false })));
    }
    return () => {
      setPolicyTypes([]);
    };
  }, [selectedPolicyTypes]);

  const handleRemarksException = (data) => (e) => {
    addRemarksInException({ ...data, isApplied: e.target.checked });
  };

  const generateRemark = () => {
    return selectedPolicyTypes && selectedPolicyTypes.length ? (
      <Card
        size="small"
        className={[
          "widget-card endorsement-standard-text padding-0",
          showKebabMenu ? "additional-remarks-exception" : ""
        ].join(" ")}
        title={<h4>Required Additional Remarks</h4>}
      >
        {(remarkCompliance &&
          remarkCompliance.length &&
          remarkCompliance.map((remark, idx) => {
            return (
              <div
                key={idx}
                className={
                  remark && remark.isCompliance
                    ? "coverage-compliance margin-bottom-sm"
                    : "margin-bottom-sm"
                }
              >
                <h4>{remark.specialEndorsement}</h4>

                {isRequestorEnrollmentFeesPaid ? (
                  <Skeleton paragraph={{ rows: 2 }} title={null} />
                ) : (
                  <Paragraph
                    style={
                      complianceIndicator || (remark && remark.isCompliance)
                        ? {
                            color: "#f30b17",
                            whiteSpace: "pre-line",
                            overflowWrap: "break-word"
                          }
                        : remark && remark.isRequireReview
                        ? {
                            color: "#ec7211",
                            whiteSpace: "pre-line",
                            overflowWrap: "break-word"
                          }
                        : {
                            whiteSpace: "pre-line",
                            overflowWrap: "break-word"
                          }
                    }
                    ellipsis={{ rows: 3, expandable: true }}
                  >
                    {remark.old_comments}
                  </Paragraph>
                )}
              </div>
            );
          })) ||
          null}
      </Card>
    ) : null;
  };

  const _insertExtractedDooHandler = (e)=>{
    insertExtractedDooHandler(e.target.checked, selectedPolicyTypes, reShareData.extractedDooRemarks)
  }

  const remarkComplianceStatusArr = [];
  if (remarkCompliance && remarkCompliance.length) {
    remarkCompliance.forEach((rc) => {
      if (rc.isCompliance)
        remarkComplianceStatusArr.push(
          CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE
        );
      if (rc.isRequireReview)
        remarkComplianceStatusArr.push(
          CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
        );
    });
  }
  const remarkComplianceStatus =
    remarkComplianceStatusArr.indexOf(
      CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE
    ) > -1
      ? CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE
      : source === CERTIFICIAL.SHARE_VIEW.DASHBOARD &&
        remarkComplianceStatusArr.indexOf(
          CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
        ) > -1
      ? "In Review"
      : remarkComplianceStatusArr.indexOf(
          CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
        ) > -1
      ? CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
      : "";

  const _remarksBody = () => (
    <div className="padding-md ">
      {(!!!readOnlyMode &&
        !!!isAgent &&
        !!!isTemplate &&
        selectedPolicyTypes &&
        selectedPolicyTypes.length && (
          <Alert
            style={{
              margin: "-12px -12px 12px",
              borderRadius: 0,
              borderTop: "1px solid ",
              borderBottom: "1px solid ",
              borderTopColor: CERTIFICIAL_THEME.WARNING,
              borderBottomColor: CERTIFICIAL_THEME.WARNING
            }}
            message={APPLICATION_MESSAGES.APP.ADDITIONAL_REMARK.AGENT_WARNING}
            banner
          />
        )) ||
        null}
      {readOnlyMode
        ? (selectedPolicyTypes &&
            selectedPolicyTypes.length &&
            remarkCompliance &&
            remarkCompliance.length && (
              <Card
                size="small"
                className={[
                  "padding-0 endorsement-standard-text margin-bottom-sm",
                  showKebabMenu ? "additional-remarks-exception" : ""
                ].join(" ")}
                title={
                  <h4 className="margin-0">Provided Additional Remarks</h4>
                }
              >
                {selectedPolicyTypes &&
                  selectedPolicyTypes.length &&
                  selectedPolicyTypes.map((formType, idx) => (
                    <div style={{ marginBottom: 6 }} key={idx}>
                      <h4 className="margin-0">
                        {(allowEditForException && formType.isCompliance && (
                          <Checkbox
                            className="exceptional-handle-ckeck"
                            onChange={handleRemarksException(formType)}
                          />
                        )) ||
                          null}
                        {formType.specialEndorsement}
                      </h4>
                      {readOnlyMode ? (
                        isRequestorEnrollmentFeesPaid ? (
                          <Skeleton paragraph={{ rows: 2 }} title={null} />
                        ) : (
                          <Paragraph
                            style={{
                              whiteSpace: "pre-line",
                              overflowWrap: "break-word"
                            }}
                            ellipsis={{ rows: 3, expandable: true }}
                          >
                            {!!formType.value && _.trim(formType.value) ? (
                              formType.value
                            ) : (
                              <>
                                <Icon
                                  type="info-circle"
                                  className="margin-right-sm"
                                />
                                {remarkComplianceStatus ===
                                  CERTIFICIAL_STATUS.POLICY_STATUS
                                    .REQUIRES_REVIEW && formType.isCrossCountry
                                  ? CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                                      .DYNAMIC_POLICIES_NOT_SUPPORTED_FOR_THIS_REGION_LABEL
                                  : (emptyRemarksText || CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                                      .NO_REMARKS_AVAILABLE_LABEL)}
                              </>
                            )}
                          </Paragraph>
                        )
                      ) : formType.isEdit ? (
                        <TextArea
                          onChange={(evt) =>
                            onAdditionalRemarksChange(
                              formType.specialEndorsement,
                              evt.target.value,
                              formType.id
                            )
                          }
                          value={formType.value}
                          placeholder={formType.placeholder}
                          rows={8}
                        />
                      ) : (
                        <TextArea
                          onChange={(evt) =>
                            onAdditionalRemarksChange(
                              formType.specialEndorsement,
                              evt.target.value,
                              formType.id
                            )
                          }
                          rows={8}
                          disabled={readOnlyMode}
                          value={formType.value}
                          placeholder={formType.placeholder}
                        />
                      )}
                    </div>
                  ))}
              </Card>
            )) ||
          (selectedPolicyTypes &&
            selectedPolicyTypes.length &&
            selectedPolicyTypes.map((formType, idx) => (
              <div style={{ marginBottom: 6 }} key={idx}>
                <h4>
                  {(allowEditForException &&
                    formType.isCompliance &&
                    !formType.is_review_case && (
                      <Checkbox
                        className="exceptional-handle-ckeck"
                        onChange={handleRemarksException(formType)}
                      />
                    )) ||
                    null}
                  {formType.specialEndorsement}
                </h4>
                {readOnlyMode ? (
                  isRequestorEnrollmentFeesPaid ? (
                    <Skeleton paragraph={{ rows: 2 }} title={null} />
                  ) : (
                    <Paragraph
                      style={{
                        whiteSpace: "pre-line",
                        overflowWrap: "break-word"
                      }}
                      ellipsis={{ rows: 3, expandable: true }}
                    >
                      {!!formType.value && _.trim(formType.value) ? (
                        formType.value
                      ) : (
                        <>
                          <Icon
                            type="info-circle"
                            className="margin-right-sm"
                          />{" "}
                          {remarkComplianceStatus ===
                            CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW &&
                          formType.isCrossCountry
                            ? CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                                .DYNAMIC_POLICIES_NOT_SUPPORTED_FOR_THIS_REGION_LABEL
                            : (emptyRemarksText || CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                                .NO_REMARKS_AVAILABLE_LABEL)}
                        </>
                      )}
                    </Paragraph>
                  )
                ) : formType.isEdit ? (
                  <TextArea
                    onChange={(evt) =>
                      onAdditionalRemarksChange(
                        formType.specialEndorsement,
                        evt.target.value,
                        formType.id
                      )
                    }
                    value={formType.value}
                    placeholder={formType.placeholder}
                    rows={8}
                  />
                ) : (
                  <TextArea
                    onChange={(evt) =>
                      onAdditionalRemarksChange(
                        formType.specialEndorsement,
                        evt.target.value,
                        formType.id
                      )
                    }
                    rows={8}
                    disabled={readOnlyMode}
                    value={formType.value}
                    placeholder={formType.placeholder}
                  />
                )}
              </div>
            ))) || (
            <Empty
              image={emptyImage}
              imageStyle={{
                height: 36
              }}
              description={<span>No Additional Remarks Are Applicable.</span>}
            />
          )
        : (selectedPolicyTypes &&
            selectedPolicyTypes.length &&
            selectedPolicyTypes.map((formType, idx) => (
              <div style={{ marginBottom: 6 }} key={idx}>
                <h4>
                  {(allowEditForException && formType.isCompliance && (
                    <Checkbox
                      className="exceptional-handle-ckeck"
                      onChange={handleRemarksException(formType)}
                    />
                  )) ||
                    null}
                  {formType.specialEndorsement}
                </h4>
                {readOnlyMode ? (
                  isRequestorEnrollmentFeesPaid ? (
                    <Skeleton paragraph={{ rows: 2 }} title={null} />
                  ) : (
                    <Paragraph
                      style={{
                        whiteSpace: "pre-line",
                        overflowWrap: "break-word"
                      }}
                      ellipsis={{ rows: 3, expandable: true }}
                    >
                      {!!formType.value && _.trim(formType.value) ? (
                        formType.value
                      ) : (
                        <>
                          <Icon
                            type="info-circle"
                            className="margin-right-sm"
                          />{" "}
                          {remarkComplianceStatus ===
                            CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW &&
                          formType.isCrossCountry
                            ? CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                                .DYNAMIC_POLICIES_NOT_SUPPORTED_FOR_THIS_REGION_LABEL
                            : (emptyRemarksText || CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                                .NO_REMARKS_AVAILABLE_LABEL)}
                        </>
                      )}
                    </Paragraph>
                  )
                ) : formType.isEdit ? (
                  <TextArea
                    onChange={(evt) =>
                      onAdditionalRemarksChange(
                        formType.specialEndorsement,
                        evt.target.value,
                        formType.id
                      )
                    }
                    value={formType.value}
                    placeholder={formType.placeholder}
                    rows={8}
                  />
                ) : (
                  <>
                    <TextArea
                      onChange={(evt) =>
                        onAdditionalRemarksChange(
                          formType.specialEndorsement,
                          evt.target.value,
                          formType.id
                        )
                      }
                      rows={8}
                      disabled={readOnlyMode || disabled}
                      value={formType.value}
                      placeholder={formType.placeholder}
                    />
                  </>
                )}
              </div>
            ))) || (
            <Empty
              image={emptyImage}
              imageStyle={{
                height: 36
              }}
              description={<span>No Additional Remarks Are Applicable.</span>}
            />
          )}
      {(remarkCompliance && remarkCompliance.length && generateRemark()) ||
        null}
      {extraBottomComponent}
    </div>
  );

  return readOnlyMode &&
    selectedPolicyTypes &&
    selectedPolicyTypes.length == 0 ? null : (
    <div
      className={[isMultiAgentSupport ? "multi-agent-container" : ""].join("")}
    >
      <Card
        size="small"
        bordered={bordered}
        hoverable={!!isMultiAgentSupport}
        bodyStyle={{ padding: 0 }}
        className={[
          "padding-0 ",
          isMultiAgentSupport
            ? "multi-agent-standard-text"
            : "endorsement-standard-text",
          showKebabMenu ? "additional-remarks-exception" : ""
        ].join(" ")}
        title={
          (isTitleRequired && (
            <>
            <h4 className="margin-0">
              <Avatar
                src={remarksIcon}
                size="small"
                shape="square"
                className="margin-right-sm"
              />
              {additionalRemarksTitle ||
                CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS.TITLE}
              {(isRequestFromAgent &&
                remarkCompliance &&
                remarkCompliance.length &&
                remarkComplianceStatus ===
                  CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE && (
                  <CertInfoTooltip
                    placement="topLeft"
                    title={
                      APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO
                        .ADDITIONAL_REMARKS
                    }
                    className="compliance-issue-info"
                  />
                )) ||
                null}
              {(selectedPolicyTypes &&
                selectedPolicyTypes.length &&
                remarkComplianceStatus && (
                  <Tag
                    color={
                      remarkComplianceStatus ===
                      CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE
                        ? "#f30b17"
                        : "#f5a505"
                    }
                    style={{ marginLeft: "15px" }}
                  >
                    {remarkComplianceStatus ===
                    CERTIFICIAL_STATUS.POLICY_STATUS.COMPLIANCE
                      ? CERTIFICIAL_STATUS.POLICY_STATUS.HYPHEN_NON_COMPLIANT
                      : remarkComplianceStatus}
                  </Tag>
                )) ||
                (selectedPolicyTypes &&
                  selectedPolicyTypes.length &&
                  complianceIndicator && (
                    <Tag color="#f30b17" style={{ marginLeft: "15px" }}>
                      {CERTIFICIAL_STATUS.POLICY_STATUS.HYPHEN_NON_COMPLIANT}
                    </Tag>
                  )) ||
                null}
            </h4>
            {(isRespondView && !_.isEmpty(reShareData.extractedDooRemarks) && !isEvidenceOnlyRequest) ? <div style={{marginLeft: "4px"}}>
               <Checkbox checked={selectedPolicyTypes.some(type=>type.extractedDooAdded)} onChange={_insertExtractedDooHandler}>Insert 'Description of Operation' from uploaded COI</Checkbox>
            </div> : null}
            </>
          )) ||
          null
        }
        extra={
          (isTitleRequired && showKebabMenu && (
            <>
            <KebabMenu
              actions={[CERTIFICIAL.POLICY_ACTIONS.HANDLE_EXCEPTION]}
              dataList={"data"}
              disableMenuOptions={disableMenuOptions}
              doActions={"doActions"}
              additionalRemarks={_.pick(multiAgentDetails, [
                "specialEndorsements",
                "specialEndorsementsCompliance"
              ])}
            />
            </>
          )) ||
          null
        }
      >
        {/* {(_areOtherAgentsInvovledInRespond && isAgent && (
          <Alert
            type="warning"
            message={
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    CERTIFICIAL_LABEL.WIDGETS.ADDITIONAL_REMARKS
                      .MULTI_AGENT_INVOLVMENT_MESSAGE
                }}
              />
            }
            banner={true}
            style={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderBottomColor: CERTIFICIAL_THEME.WARNING,
              borderTopColor: CERTIFICIAL_THEME.WARNING
            }}
          />
        )) ||
          null} */}
        {isMultiAgentSupport ? (
          <>
            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              expandIcon={({ isActive }) => (
                <Icon
                  type="down-circle"
                  className="color-primary"
                  theme="filled"
                  style={{ fontSize: "20px" }}
                  rotate={isActive ? 180 : 0}
                />
              )}
              destroyInactivePanel={true}
              className="multi-agent-collapse"
              expandIconPosition={"right"}
            >
              <Panel
                header={
                  <div className="multi-agent-info">
                    <Row type="flex" justify="start" align="middle">
                      <Col span={8}>
                        <div>
                          <Animate transitionName="fade" transitionAppear>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={4}>
                                {!!multiAgentDetails.agentName ? (
                                  <CertAvatar
                                    shape="square"
                                    size={38}
                                    key="logo"
                                    style={{ float: "left", marginRight: 12 }}
                                    alt={multiAgentDetails.agencyName}
                                    src={multiAgentDetails._agencyLogoURL}
                                  >
                                    <h3
                                      style={{
                                        fontSize: "1.25em",
                                        color: "#fafafa"
                                      }}
                                    >
                                      {(multiAgentDetails &&
                                        multiAgentDetails.agencyName &&
                                        _.capitalize(
                                          multiAgentDetails.agencyName
                                        ).substring(0, 1)) ||
                                        (multiAgentDetails.agentName &&
                                          _.capitalize(
                                            multiAgentDetails.agentName
                                          ).substring(0, 1)) ||
                                        null}
                                    </h3>
                                  </CertAvatar>
                                ) : null}
                              </Col>
                              <Col span={20}>
                                <div key="agencyName">
                                  {multiAgentDetails &&
                                  multiAgentDetails.agencyName ? (
                                    <Typography.Text
                                      strong
                                      title={multiAgentDetails.agencyName}
                                      className="fs-12x"
                                    >
                                      {titleMaintainer(
                                        multiAgentDetails.agencyName,
                                        45
                                      )}
                                    </Typography.Text>
                                  ) : null}
                                </div>
                                <div key="agentName" style={{ lineHeight: 1 }}>
                                  {multiAgentDetails &&
                                  multiAgentDetails.agentName ? (
                                    <Typography.Text
                                      strong
                                      title={multiAgentDetails.agentName}
                                      className="fs-12x"
                                      type="secondary"
                                    >
                                      {titleMaintainer(
                                        multiAgentDetails.agentName,
                                        45
                                      )}
                                    </Typography.Text>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Animate>
                        </div>
                      </Col>
                      <Col span={15} className="text-right">
                        <Animate transitionName="fade" transitionAppear>
                          {multiAgantPolicyInfo && multiAgantPolicyInfo.length
                            ? multiAgantPolicyInfo.map((policyInfo) => {
                                const { policyType, policyNumber } = policyInfo;
                                return (
                                  <div
                                    className="agent-information"
                                    key={policyNumber}
                                  >
                                    <Typography.Text
                                      strong
                                      title={policyType}
                                      className="fs-12x addtional-remarks-policy"
                                    >
                                      {titleMaintainer(policyType, 50)}
                                    </Typography.Text>
                                    <Typography.Text
                                      code
                                      className="margin-left-sm addtional-remarks-policy"
                                      title={policyNumber}
                                    >
                                      {titleMaintainer(policyNumber, 50)}
                                    </Typography.Text>
                                  </div>
                                );
                              })
                            : null}
                        </Animate>
                      </Col>
                    </Row>
                  </div>
                }
                key="1"
                style={{ marginBottom: 24, border: 0 }}
              >
                {_remarksBody()}
              </Panel>
            </Collapse>
          </>
        ) : (
          _remarksBody()
        )}
      </Card>
    </div>
  );
};

export const KebabMenu = ({ actions, dataList, additionalRemarks, disableMenuOptions }) => {
  const dispatch = useDispatch();
  const doActions = (action, policy) => {
    switch (action) {
      case CERTIFICIAL.POLICY_ACTIONS.HANDLE_EXCEPTION.ACTION: {
        dispatch(
          exceptionHandlingModal({
            showModal: true,
            policyDetails: {
              handleAdditionalRemarksException: true
            },
            selectedAddtionalRemarks: additionalRemarks
          })
        );
        break;
      }
      default:
        break;
    }
  };

  return (
    <Popover
      overlayClassName="policy-action"
      placement="leftTop"
      title={null}
      content={
        <div style={{ margin: "-12px -16px" }}>
          <List
            rowKey={(r) => r.action}
            size="small"
            className="action-items"
            itemLayout="horizontal"
            dataSource={(actions && actions) || []}
            renderItem={(policyAction) => (
              <List.Item
                className={
                  !disableMenuOptions
                    ? "disable-action-icon"
                    : ""
                }
                onClick={(e) => {
                  e.stopPropagation();
                  doActions(policyAction.ACTION, dataList);
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      shape="square"
                      src={getActionIcon(policyAction.ICON)}
                      size="small"
                    >
                      <Icon style={{ fontSize: 18 }} type={policyAction.ICON} />
                    </Avatar>
                  }
                  title={policyAction.TITLE}
                />
              </List.Item>
            )}
          />
        </div>
      }
      destroyTooltipOnHide={true}
    >
      <Icon
        type="menu"
        className="policy-item-menu"
        style={{ marginRight: 3 }}
      />
      {/* <Button type="primary" ghost shape="circle" icon="more" /> */}
    </Popover>
  );
};
