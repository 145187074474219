import React from "react";
import { Input, Tooltip } from "antd";

function formatNumber(value) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

class NumericInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^[0-9*#+]+$/;
    if (
      (!isNaN(value) && reg.test(value) && value !== "0") ||
      value === "" ||
      value === "#"
    ) {
      this.props.onChange(value);
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">
        {value !== "-" ? formatNumber(value) : "-"}
      </span>
    ) : (
      "Enter a count greater than 0"
    );
    return (
      <Tooltip
        trigger={["focus"]}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          size="small"
          maxLength={3}
          disabled={this.props.disabled}
        />
      </Tooltip>
    );
  }
}

class EndorsmentManagement extends React.Component {
  onChange = (value) => {
    this.props.onEndorsmentChange(value);
  };

  render() {
    return (
      <div className="endorsements-count">
        <NumericInput
          style={{ width: 40 }}
          value={this.props.value}
          onChange={this.onChange}
          disabled={this.props.disabled}
        />
        <label
          style={{
            display: "initial",
            marginLeft: 12,
            marginBottom: 0,
            fontSize: 13,
            fontWeight: "400 !important",
            color: "rgba(0, 0, 0, 0.8)"
          }}
        >
          Count (# = Unlimited)
        </label>
      </div>
    );
  }
}

export default EndorsmentManagement;
