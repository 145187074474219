import React from "react";
import { Checkbox } from "antd";

export const AppliedPolicyType = ({
  onAppliedPolicyTypeCheck,
  appliedPolicyType,
  readOnlyMode,
  isRequesterText
}) => {
  return (
    <Checkbox
      disabled={readOnlyMode}
      onChange={(e) =>
        onAppliedPolicyTypeCheck(e.target.checked, appliedPolicyType)
      }
      checked={appliedPolicyType.applied}
    >
      {(appliedPolicyType &&
        isRequesterText &&
        "Requestor Accepts " + appliedPolicyType.name) ||
        (appliedPolicyType && "Accept " + appliedPolicyType.name) ||
        null}
    </Checkbox>
  );
};
