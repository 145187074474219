import {
  Avatar,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Typography
} from "antd";
import _ from "lodash";
import Animate from "rc-animate";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { CertificialResultsEmpty } from "../../..";
import certificial from "../../../Assets/Logos/certificial-logo-old.png";
import ContactUsViaEmail from "../../../Components/ContactUsViaEmail/ContactUsViaEmail";
import { onSuccessLogin } from "../../../Store/Modules/Application/Application.asyncRequest";
import { titleMaintainer } from "../../../Utils/utility/app.utility";
const { Search } = Input;
const { Paragraph, Text, Title } = Typography;
const { Meta } = Card;

const CompanySelection = memo(function CompanySelection({
  companySelectionModalVisible,
  availableCompany,
  availableUsers
}) {
  const [filteredCompanies, setFilteredCompanies] = useState(availableCompany);
  const dispatch = useDispatch();
  const logoStyle = {
    height: 18,
    width: "100%",
    backgroundImage: "url(" + certificial + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  };

  const selectedCompanyLogin = (comp) => {
    if (!comp) return;
    const _userData = availableUsers.find(
      (o) => o.company && o.company.id === comp.id
    );
    if (_userData) {
      dispatch(onSuccessLogin(_userData));
    } else {
      message.warn(
        "Company information is missing, please contact service administrator."
      );
    }
  };

  const handleSearchCompanies = (searchedCompanyName) => {
    let value = searchedCompanyName.toLowerCase();
    let result = [];
    result = availableCompany.filter((company) => {
      return company.name
        ? company.name.toLowerCase().search(value) != -1
        : false;
    });
    setFilteredCompanies(result);
  };

  const companyLength = availableCompany && availableCompany.length;

  const compareValue = companyLength > 1 && 5 >= companyLength;

  const companyContainerMarginTop = compareValue
    ? "5%"
    : companyLength > 5 && 15 >= companyLength
    ? "9%"
    : "0%";

  const companyCardWidth = compareValue ? "16%" : "24%";

  return (
    <div className="firstLogin">
      <Modal
        width="90%"
        wrapClassName="company-selection-wrapper"
        className="company-selection"
        title={null}
        visible={companySelectionModalVisible}
        maskClosable={false}
        closable={false}
        footer={null}
        style={{ top: 24 }}
      >
        <div>
          <div id="company-selection-body">
            <Card
              title={
                <Title level={4} className="margin-0 color-primary">
                  Select Company
                </Title>
              }
              bordered={false}
              extra={
                <Search
                  className="company-search"
                  placeholder="Search company here ..."
                  enterButton
                  allowClear
                  onSearch={(value) => handleSearchCompanies(value)}
                />
              }
              size="small"
            >
              <div>
                <Text className="margin-bottom-sm" style={{ display: "block" }}>
                  More than one company is associated with your profile. 
                    Please select the company you’d like to manage
                </Text>
                <div style={{ marginTop: companyContainerMarginTop }}>
                  <div className="company-card-container">
                    {(filteredCompanies && filteredCompanies.length && (
                      <Animate transitionName="fade" transitionAppear>
                        {filteredCompanies.map((comp, idx) => (
                          <div key={comp.id}>
                            <Card.Grid
                              className="company-card"
                              style={{ width: companyCardWidth }}
                            >
                              <CompanyInformation
                                company={comp}
                                companyLength={companyLength}
                                selectedCompanyLogin={selectedCompanyLogin}
                              />
                            </Card.Grid>
                          </div>
                        ))}
                      </Animate>
                    )) || (
                      <div key="no-results" style={{ marginTop: "15%" }}>
                        <CertificialResultsEmpty
                          text={"No searched results found."}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div id="company-selection-footer">
            <div>
              <Row type="flex" align="middle" justify="end">
                <Col span={6}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span style={logoStyle}></span>
                  </div>
                </Col>
                <Col span={18} className="text-right">
                  <ContactUsViaEmail />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default CompanySelection;

const CompanyInformation = ({
  company,
  companyLength,
  selectedCompanyLogin
}) => {
  const { name, logo, address1, address2, city, postCode, state, email } =
    company;
  const _logoURL =
    logo && process.env.REACT_APP_IMAGE_BASE_URL + encodeURIComponent(logo);

  const _companyDescription = _.filter([
    address1,
    address2,
    city,
    state,
    postCode
  ]).join(", ");

  const logoContent = (
    <Avatar
      shape="square"
      alt={name}
      size={48}
      className="cert-avatar"
      src={company && _logoURL}
    >
      {
        <h3
          style={{
            fontSize: "1.25em",
            color: "#fafafa"
          }}
        >
          {(company && name && _.capitalize(name).substring(0, 1)) ||
            (company && email && _.capitalize(email).substring(0, 1)) ||
            null}
        </h3>
      }
    </Avatar>
  );
  const _initials = (
    <Avatar
      shape="square"
      alt={name}
      size={"auto"}
      style={{ minHeight: 120, borderRadius: 0 }}
      className="cert-avatar"
      src={company && _logoURL}
    >
      {
        <h3
          style={{
            fontSize: "5.25em",
            color: "#fafafa",
            lineHeight: "120px"
          }}
        >
          {(company && name && _.capitalize(name).substring(0, 1)) ||
            (company && email && _.capitalize(email).substring(0, 1)) ||
            null}
        </h3>
      }
    </Avatar>
  );

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: "12px 6px", background: "transparant" }}
      cover={companyLength <= 5 ? _initials : null}
      onClick={() => selectedCompanyLogin(company)}
    >
      <Meta
        avatar={companyLength > 5 ? logoContent : null}
        title={
          <Paragraph title={name} style={{ marginBottom: 2 }}>
            {titleMaintainer(name, 25)}
          </Paragraph>
        }
        description={
          <Paragraph title={_companyDescription} style={{ marginBottom: 0 }}>
            {titleMaintainer(_companyDescription, 25)}
          </Paragraph>
        }
      />
    </Card>
  );
};
