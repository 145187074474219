import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Icon, Button, Spin } from 'antd';
import { useLocation } from "react-router-dom";
import { _modalService } from '../../../../Utils/wrappers/Modal/ModalWrapper';
import { isEmpty } from 'lodash';
//../../../Assets/Logos/certificial-logo-old.png
import EmailHeader from '../../../Template/EmailHeader.js';
import './RemoveClient.less';
import { removeClientAsyncRequest, removeClientValidateEmail } from '../../../../Store/Modules/Application/Application.asyncRequest.js'
export const RemoveClient = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const [showButton, setShowButton] = useState(false);
	const agentId = params.get('agentId');
	const emailKey = params.get('emailKey');
	const clientCompanyId = params.get('clientCompanyId');
	const uploadedBy = params.get('uploadedBy');

	const [loading, setLoading] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const removeClientError = { title: "Expired Link", content: "Please use a current open request email to execute this process", onOk: () => { } }
	const removeClientSuccess = { centered: false, title: "Remove Client", content: <><div>Client removed successfully</div><div>You may now close this window</div></>, onOk: () => { } };
	const [removeClientSuccessStatus, setRemoveClientSuccess] = useState(false);
	const validateLink = async () => {
		setLoading(true);
		try {
			const validationResponse = await removeClientValidateEmail({
				agentId: agentId,
				emailKey: emailKey,
				clientCompanyId: clientCompanyId,
				uploadedBy: uploadedBy
			});
			if (validationResponse.error) {
				setShowButton(true);
				_modalService.error(removeClientError)
			} else {
				setShowButton(false);
				setCompanyName(validationResponse.companyName || '');
			}

		} catch (err) {
			setShowButton(false);
			_modalService.error(removeClientError)
		}
		setLoading(false)
	}

	useEffect(() => {
		if (isEmpty(agentId) || isEmpty(emailKey) || isEmpty(clientCompanyId)) {
			setShowButton(true);
		} else {
			validateLink();
		}
	}, []);

	const removeClient = async () => {
		setShowButton(true);
		try {
			setLoading(true);
			removeClientAsyncRequest(
				{
					agentId: agentId,
					emailKey: emailKey,
					clientCompanyId: clientCompanyId,
					uploadedBy: uploadedBy
				}).then((response) => {
					setShowButton(true);
					setLoading(false);
					//_modalService.success(removeClientSuccess);
					setRemoveClientSuccess(true);
				}).catch(error => {
					setLoading(false);
					setShowButton(true);
					_modalService.error(removeClientError)
				});
		} catch (error) {
			setLoading(false);
			setShowButton(true);
			_modalService.error(removeClientError)
		}
	}

	return (
		<>
			<EmailHeader />
			<Spin
				spinning={loading}
				wrapperClassName=""
				indicator={
					<Icon
						type="loading-3-quarters"
						className="color-primary fs-36x"
						spin
					/>
				}
				tip={'Loading..'}
				size="large"
			>
				<Row type="flex" justify="center">
					<Col className='outer-container outer-border' span={11} >
						<Card title={`Client Name: ${companyName}`} bordered={false}>
							{removeClientSuccessStatus === false ? (<>
								<Row>
									<Col lg={5}>
										<Icon theme="filled" className={"cert-icon-size"} type="warning" />
									</Col>
									<Col lg={19}>
										<p>
											Use this feature only if your Agency is no longer providing coverage for this client.
										</p>
										<p>
											Your Agency will no longer receive requests for this Client. Certificial will notify the Cert Holder so updated Agency information can be obtained.
										</p>
										<p>
											Close this window to cancel this process.
										</p>
									</Col>
								</Row>
								<Row type="flex" justify="end">
									<Col>
										<Button
											disabled={showButton}
											onClick={() => {
												removeClient()
											}} type="primary">Remove Client</Button>
									</Col>
								</Row>
							</>) : <div className={"message-design"}>
								<Row>
									<Col span={"auto"}>
										<Icon type="check-circle" className={"cert-icon-size-success"} theme="filled" />
									</Col>

								</Row>
								<Row>
									<Col>
										<p className='remove-success'>
											Client removed successfully
										</p>
										<p>
											You may now close this window
										</p>

									</Col>
								</Row>
							</div>}
						</Card>
					</Col>
				</Row>
			</Spin>
		</>
	)
}