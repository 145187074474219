export const API_PATH = {
  deployment: {
    UPLOAD_SIGNATURE: "certificial-api/user/uploadSignature",
    LOGIN: "certificial-api/authenticate",
    SSO_AUTHENTICATION: "certificial-api/token/authenticate",
    LOGOUT: "certificial-api/logout",
    USER: "certificial-api/user/userdetails",
    GET_ALL_POLICIES: "certificial-api/policy/list",
    GET_POLICY: "certificial-api/policy",
    ADD_POLICY: "certificial-api/policy/addnewpolicy",
    UPDATE_POLICY: "certificial-api/policy/editpolicy",
    DELETE_POLICY: "certificial-api/policy/deletePolicy",
    GET_PROJECT_TEMPLATES: "certificial-api/projecttemplate/list",
    DELETE_PROJECT_TEMPLATE: "certificial-api/projecttemplate/delete",
    CREATE_PROJECT_TEMPLATE: "certificial-api/projecttemplate/create",
    GET_USER_COMPANY_PROJECTS_LIST: "certificial-api/project/list",
    CREATE_USER_COMPANY_PROJECT: "certificial-api/project/create",
    UPDATE_USER_COMPANY_PROJECT: "",
    DELETE_USER_COMPANY_PROJECT: "",
    COMPANY_AVAILABLE_PROJECT_LIST: "certificial-api/sharedpolicy/projects",
    EDIT_CC_OPTION: "certificial-api/sharedpolicy/updateCert/email",
    UPDATE_EMAIL: "certificial-api/sharedpolicy/populate/claim/email",
    CHECK_ASSOCIATE_COMPANY: "certificial-api/sharedpolicy/add/email/companies",
    REFERENCE_DATA: "certificial-api/referenceData",
    PROFILE: "",
    MAINTAINANCE: "certificial-api/maintenance",
    UPDATE_USER_PROFILE: "certificial-api/user/profile/",
    UPDATE_PASSWORD: "certificial-api/user/updatepassword",
    REGISTRATION: "certificial-api/user/signup",
    FORGOT_PASSWORD: "certificial-api/user/resetpassword",
    VERIFY_PASSWORD: "certificial-api/user/grms/verifyuser",
    TRACKED_COMPANIES: "certificial-api/dashboard",
    GET_AGENT: "certificial-api/agent",
    TRACKED_POLICIES_COMPANY_BY_ID: "certificial-api/dashboard/tracekedpolicies",
    CANCEL_TRACKED_POLICIES_BY_ID: "certificial-api/dashboard/canceltracking",
    COMPANY_HISTORY: "certificial-api/file/history",
    GENERATE_COMPANY_COI: "certificial-api/file/generatecoi",
    // POST_GENERATE_COI: "common/coirequest",
    POST_GENERATE_COI: "certificial-api/common/coirequest2",
    POST_PREVIEW_COI: "certificial-api/common/coipreview",
    POST_INSURED_AGENT_PREVIEW_COI: "certificial-api/common/previewcoi",
    SHARE_POLICY_LIST: "certificial-api/sharedpolicy/listview",
    MY_SHAREABLE_POLICIES: "certificial-api/policy/sharelist",
    MY_RESHAREABLE_POLICIES: "certificial-api/policy/resharelist",
    GET_ALL_SHARED_DOCS: "certificial-api/policy/getPreviousDocs",
    SHARE_MY_POLICIES: "certificial-api/sharedpolicy/share/company",
    SHARE_POLICY_GRAPH: "certificial-api/sharedpolicy/list",
    SHARE_POLICY_COUNT: "certificial-api/sharedpolicy/totalshares",
    SHARE_POLICY_DELETE: "certificial-api/sharedpolicy/delete",
    SHARE_POLICY_GRAPH_COMPANY: "certificial-api/sharedpolicy/company",
    DOWNLOAD_DOCUMENTS_ZIP: "certificial-api/sharedpolicy/download/document/zip",
    DOWNLOAD_ALL_COIS: "certificial-api/sharedpolicy/download",
    GET_COVEREAGETYPES: "certificial-api/RequirementTemplate/CoverageType",
    GET_TEMPLATE: "certificial-api/policy/template",
    GET_COI_BUTTON_ENABLE: 'certificial-api/dashboard/check/supplier/limit/exceeded',
    CONFIRM_COI: 'certificial-api/sharedpolicy/claim/coi',
    GET_TEMPLATE_LATEST: "certificial-api/policy/template/latest",
    GET_TEMPLATE_DETAIL: "certificial-api/policy/template/details",
    GET_TEMPLATE_LIST_REGION: "certificial-api/policy/template",
    GET_SUPPLIER_AGENT_INFO: "certificial-api/requestor/getSupplierAndAgentInfo",
    GET_ALL_INSURED_REQUEST: "certificial-api/policy/request/pendinglist",
    GET_INSURED_REQUEST_DETAILS: "certificial-api/policy/request/policytypes",
    INSURED_REQUEST_DENY: "certificial-api/policy/request/deny",
    RESPOND_REQUEST_SHAREABLE_POLICIES: "certificial-api/policy/respond/list",
    RE_RESPOND_REQUEST_SHAREABLE_POLICIES: "certificial-api/policy/rerespond/list",
    GET_COI_INFO_BY_TEMPLATE_ID: "certificial-api/policy/response/companycoiholder",
    RESPOND_REQUEST_SHAREABLE_DOCS: "certificial-api/policy/request/pendinglist/docs",
    RESPOND_REQUEST_SUBMIT: "certificial-api/policy/response/verification/respondToPolicyRequest",
    RESET_PASSWORD: "certificial-api/user/resetpassword",
    ON_DEMAND_GET_COMPANIES: "certificial-api/referenceData/companies",
    ON_DEMAND_GET_AGENCY: "certificial-api/referenceData/agency",
    VARIFY_COMPANY_BY_EMAIL: "certificial-api/policy/request/company/byemail",
    SEARCH_ADDRESSBOOK_EMAIL: "certificial-api/sharedpolicy/companies",
    SEARCH_ADDRESSBOOK_COMPANY: "certificial-api/sharedpolicy/companies",
    GET_NOTIFICATION: "certificial-api/notifications",
    MARK_NOTIFICATION_AS_READ: "certificial-api/notifications/read",
    GET_ALL_PENDING_USER_REQUEST: "certificial-api/user/pendingusers",
    POST_PENDING_USER_ITEM_ACTION: "certificial-api/user/pendingusers",
    POST_INVITE_USER_EMAIL_REQUEST: "certificial-api/user/inviteuser",
    USER_PROFILE_AGENTS: "certificial-api/user/profileagents",
    GET_AGENT_LIST: "certificial-api/referenceData/agentByAgency",
    POST_ADD_AGENT_TO_USER: "certificial-api/user/agent",
    POST_ADD_AGENCY_TO_USER: "certificial-api/user/agency",
    INVITE_AGENT_BY_EMAIL: "certificial-api/user/inviteagency",
    UPDATE_AGENT_PRIORITY: "certificial-api/user/profileagents",
    REQUIREMENT_TEMPLATE_ADD: "certificial-api/policy/template/create",
    REQUIREMENT_TEMPLATE_EDIT: "certificial-api/policy/template/update",
    REQUIREMENT_TEMPLATE_HISTORY: "certificial-api/policy/template/history",
    SHOW_DOC_UPDATE_HISTORY: "certificial-api/policy/response/requestor/uploadDocument",
    DELETE_DOC_HISTORY_DATA: "certificial-api/policy/response/requestor/documents/delete",
    DOC_UPDATED_HISTORY: "certificial-api/policy/response/requestor/documents",
    UPLOAD_SINGLE_FILE: "certificial-api/file/uploadFile",
    UPLOAD_MULTIPLE_FILE: "certificial-api/file/uploadMultipleFiles",
    GET_UPLOADCERT_LIST: "certificial-api/grms/excelList",
    EXCEL_FILE_UPLOAD: "certificial-api/grms/uploadExcelPath",
    PDF_FILE_UPLOAD: "certificial-api/grms/uploadFile",
    TOGGLE_TUTORIAL_POPUP: "certificial-api/user/toggle/tutorial",
    USER_FIRST_LOGIN: "certificial-api/user/userFirstLogin",
    SEARCH: {
      INSURER: "certificial-api/policy/details/insurer",
      AGENCIES: "certificial-api/referenceData/agency",
      AGENTS: "certificial-api/referenceData/agent",
      COMPANIES: "certificial-api/referenceData/companies",
      ENROLLMENT_COMPANY: "certificial-api/enrollment/companies"
    },
    GET_STATE: "certificial-api/referenceData/state",
    VERIFICARTION_EMAIL_TOKEN: "certificial-api/user/verification-user/email/",
    DELETE_TEMPLATE: "certificial-api/policy/template/delete",
    CREATE_REQUEST_FOR_INSURANCE: "",
    GET_AGENT_COMPANIES_LIST: "certificial-api/agent/dashboard",
    GET_CLIENT_COMPANIES_LIST: "certificial-api/agent/dashboard",
    GET_CLIENT_POLICY_LIST: "certificial-api/agent/dashboard",
    GET_CLIENT_ADDRESS_BOOK_GROUP_LIST: "certificial-api/agent/addressbook/group",
    GET_ALL_ADDRESS_BOOK_GROUP_LIST_SHARE: "certificial-api/agent/addressbook/share/groups",
    DELETE_CLIENT_POLICY: "certificial-api/agent/policy/delete",
    DELETE_CLIENT_ADDRESS_GROUP: "certificial-api/agent/addressbook/group",
    GET_AGENT_POLICY_LIST: "certificial-api/agent/dashboard",
    POST_AGENT_POLICY_ACTION: "certificial-api/agent/actions",
    GET_AGENT_REQUEST_LIST: "certificial-api/agent/pendingrequest",
    GET_AGENT_RESPOND_LIST: "certificial-api/agent/pendingrequest",
    GET_POLICY_REQUEST_LIST: "certificial-api/agent/pendingrequest/sharepolicy",
    GET_POLICY_RESPOND_LIST: "certificial-api/agent/pendingrequest/policyrequest",
    POST_AGENT_REQUEST_POLICY_ACTION: "certificial-api/agent/approve/sharedpolicy",
    POST_AGENT_RESPOND_POLICY_ACTION: "certificial-api/agent/approve/requestpolicy",
    GET_ALL_VERIFICATION_REQUESTS: "certificial-api/policy/request",
    GET_REQUESTED_POLICIES: "certificial-api/policy/request/projects",
    GET_VERIFICATION_REQUEST: "certificial-api/policy/request/policytypes",
    CREATE_VERIFICATION_REQUEST: "certificial-policy-request-management/policy/request/verification/newInsuranceRequest",
    DELETE_VERIFICATION_REQUEST: "certificial-api/policy/request/verification/deleteInsuranceRequest",
    APPROVE_VERIFICATION_REQUEST: "",
    DENY_VERIFICATION_REQUEST: "",
    UPGRADE_PLAN: "certificial-api/common/upgrade",
    GET_ENROLLMENT_TRANSACTION_ID: "certificial-api/enrollment/transaction",
    CONTACT_US: "certificial-api/common/upgrade/contactus",
    HANDLE_EXCEPTION_REQUEST: "certificial-api/policy/request/exception",
    DELETE_EXCEPTION_REQUEST: "certificial-api/policy/request/exception/disable",
    GET_EXCEPTION_DETAILS: "certificial-api/policy/request/exception/data",
    GET_COMPANY_STATUS_DETAILS: "certificial-api/agent/company/status",
    GET_COMPANY_GET_STATUS: "certificial-api/dashboard/getCompanyStatus",
    GET_COMPLIANTCOUNT: "certificial-api/agent/compliancestatus",
    UPGRADE_PROFFESSIONAL: "certificial-api/upgradeplan/professionalPlan",
    REGISTRATION_GRMS_AGENT: "certificial-api/user/agent-signup",
    GET_AGENT_AMS_LIST: "certificial-api/user/amsList",
    GET_INSURED_LIST_TO_RESPOND: "certificial-api/agent/dashboard/companylist",
    DOWNLOAD_UPLOADED_FILE: "certificial-api/file/download",
    REFERENCE_COUNTRY: "certificial-api/referenceData/country",
    REFERENCE_REGION: "certificial-api/referenceData/region",
    DELETE_AGENT_SIGNTURE: "certificial-api/user/signature",
    GET_POLICY_TYPES_BY_COUNTRY: "certificial-api/referenceData",
    QUICK_REF_GUIDE: "certificial-api/media/QuickReferenceGuide.c07f0570.pdf",
    QUICK_REF_GUIDE_NAME: "certificial-api/QuickReferenceGuide.c07f0570.pdf",
    RESEND_ACTIVATION_LINK: "certificial-api/user/resetActivationLink",
    RESPOND_COMPLIANCE_SUMMARY: "certificial-api/policy/summarycompliancelist",
    CERTIFICATE_UPLOAD_STATUS: "certificial-api/policy/response/certificateuploadstatus",
    CERTIFICATE_UPLOAD_STATUS_RE_RESPOND: "certificial-api/policy/response/certificateuploadstatus/rerespond",
    POST_CERTIFICATE_INSURANCE: "certificial-api/policy/response/certificateupload",
    POST_CERTIFICATE_UPLOAD_REQUEST: "certificial-api/policy/response/renewal/certificate/upload",
    POST_COI_UPLOAD_REQUEST: "certificial-api/policy/response/renewal/certUploadStatus",
    SHARE_ENABLE: "certificial-api/policy/shareenable",
    GET_ALL_PROJECTS: "certificial-api/agent/sharedproject",
    GET_ALL_REQUEST_PROJECTS: "certificial-api/agent/requesterproject",
    GET_INSURER_PENDING_REQUEST_DETAILS: "certificial-api/policy/request/pendinglist/policies",
    ISSUE_COI: "certificial-api/agent/client/issuecoi",
    ISSUE_COI_FOR_SHARE: "certificial-api/agent/client/sharepolicy/issuecoi",
    AGENT_DELETE_SHARE: "certificial-api/sharedpolicy/deleteShare",
    SAVE_COMPLIANCE_STATUS: "certificial-api/dashboard/compliance/review",
    OPEN_REQUEST_COUNT: "certificial-api/agent/openrequest/count",
    SUPPLIER_DATA_CHECK: "certificial-api/dashboard/checkSupplierShare",
    VERIFY_INSURER: "certificial-api/policy/details/insurer/verify",
    DOWNLOAD_COIS_ZIP: "certificial-api/agent/generate/zip",
    CLIENT_FOR_AGENT_FROM_REQUESTID: "certificial-api/agent/client",
    CREATE_CLIENT_FROM_AGENT: "certificial-api/agent/client/register",
    ADD_NEW_SUPPLIER: "certificial-api/requestor/addSupplier",
    CHECK_COMPANY_CREATION: "certificial-api/requestor/checkCompanyCreation",
    EXISTING_USER_VERFICATION: "certificial-api/referenceData/existinguser",
    ADDRESS_BOOK: "certificial-api/agent/addressbook",
    CLIENT_ADDRESS_BOOK: "certificial-api/agent/client/addressbook",
    GET_ADDRESSES: "certificial-api/agent/addressbook/certholders",
    GET_GROUP_ADDRESSES_FOR_A_CLIENT: "certificial-api/agent/addressbook/certholders/group",
    SEARCH_IN_ADDRESSBOOK: "certificial-api/sharedpolicy/certholders",
    GET_ADDRESS_SAMPLE_PATH: "certificial-api/common/sampletemplatepath",
    DELETE_ADDRESSESS_OF_CLIENT_ADDRESS_GROUP: "certificial-api/agent/addressbook/mappings/delete",
    DELETE_ADDRESSESS_OF_AGENCY_ADDRESS_BOOK: "certificial-api/agent/addressbook/agency/delete",
    POST_UNAVAILABLE_COVERAGE: "certificial-api/policy/response/update/unavailableCoverages",
    GET_MANAGE_TEAM_TOGGLE: "certificial-api/agent/manageclients",
    UPDATE_USER_ROLE: "certificial-api/user/updateUserRole",
    GET_CONTACTS: "certificial-api/agent/myteam",
    AGENT_TRANSFER: "certificial-api/agent/transfer",
    AGENT_AUTO_TRANSFER:"certificial-api/policy/response/agent/auto/transfer",
    EDIT_COMPANY_PROFILE: "certificial-api/user/company",
    DELETE_COMPANY_LOGO: "certificial-api/user/logo",
    GET_ALL_USERS: "certificial-api/user",
    GET_COMPLIANCE_TIPS_POPUP_STATUS: "certificial-api/agent/compliance/tips/",
    SAVE_COMPLIANCE_TIPS_POPUP_STATUS: "certificial-api/agent/compliance",
    GET_CERT_HOLDERS_FOR_A_CLIENT: "certificial-api/agent/certHolders",
    BULK_SHARE_POLICY: "certificial-api/sharedpolicy/bulk/share",
    REPLACE_SHARED_POLICY: "certificial-policy-replace/replace/policies",
    CHANGE_TEMPLATE: "certificial-api/dashboard/change/template",
    PUT_SUPPLIER_CONTACT_INFO: "certificial-api/dashboard/updateAgentSupplierInfo",
    CREATE_POLICY_REQUEST: "certificial-api/policy/request/template/createPolicyRequest",
    FETCH_ACTIVE_COMPANY: "certificial-api/user/activeCompanies",
    FETCH_ASSOCIATED_COMPANY: "certificial-api/user/associated",
    REGISTRATION_POST_LOGIN: "certificial-api/user/add/company",
    GET_COMPLIANCE_FOR_REQUEST: "certificial-api/requestor/compliance",
    GET_ALL_ADDRESS_BOOKS_FOR_CLIENT: "certificial-api/application/addressbook",
    GET_REGENERATE_CAPTCHA:'certificial-api/getcaptcha',
    REQUESTORCOMPLIANCE: "certificial-api/sharedpolicy/company/details2/byAgent",
    GET_ADDRESS_SHARE_DETAILS_FOR_AGENT: "certificial-api/sharedpolicy/addressBook/results",
    POST_SAGITTA_CLIENT_CONFIG: "certificial-api/sagitta/clientconfig",
    GET_SAMPLETEMPLATE_COMPANYCSV: "certificial-api/common/sampletemplatepath",
    PENDING_APPROVAL_COUNT: "certificial-api/agent/getPendingApproval",
    GET_SUPPLIER_ADDRESS_BOOK_GROUPS: "certificial-api/requestor/addressbook/group",
    GET_GROUP_ADDRESSES_FOR_REQUESTOR: "certificial-api/requestor/addressbook/groupDetails",
    DELETE_GROUP_REQUESTOR: "certificial-api/requestor/addressbook/deleteGroup",
    DELETE_ADDRESS_REQUESTOR: "certificial-api/requestor/addressbook/supplier/delete",
    VALIADTE_PROJECTNAME_SUPPLIER_REQUEST: "certificial-api/requestor/addressbook/project-validate",
    SUPPLIER_ADDRESS_BOOK_UPLOAD: "certificial-api/requestor/addressbook",
    UPLOAD_NAMED_INSURED_DOC: "certificial-api/namedinsured/document",
    NAMED_INSURED: "certificial-api/namedinsured",
    DELETE_NAMED_INSURED:"certificial-bulk-removes/namedinsured",
    NAMED_INSURED_AND_CLIENT: "certificial-api/namedinsured/insuredandclient",
    NAMED_INSURED_AVAILABLE: "certificial-api/namedinsured/available",
    NAMED_INSURED_SELECTION: "certificial-api/namedinsured/selection",
    NAMED_INSURED_MAP_CLIENT: "certificial-api/namedinsured/mapclient",
    NAMED_INSURED_MAPPED_CLIENT: "certificial-api/namedinsured/mappedclient",
    SEARCH_COI_ID_DATA: "certificial-api/dashboard/get/claim/certholder",
    NAMED_INSURED_SELECTION_AND_CLIENT: "certificial-api/namedinsured/selection/insuredandclient",
    NAMED_INSURED_CHECK_INSURED_IS_PRIMARY_IN_SHARE: "certificial-api/namedinsured/verifyprimary",
    TEMPLATE_VERSION_OPEN_DRAWER: "certificial-api/policy/template/status",
    CHANGE_TEMPLATE_VALIDATE: "certificial-api/dashboard/change/template/validate",
    POST_ARCHIVE_CLIENT: "certificial-api/agent/archive",
    REQUESTOR_VERIFICATION_PROGRAME: "certificial-api/enrollment/checkEligibility",
    REQUESTOR_VERIFICATION_COMPANIES: "certificial-api/enrollment/checkEligibility/companies",
    CREATE_TRANSACTION_FOR_PAYMENT: "certificial-api/enrollment/transaction",

    ADD_LOCATION_LIMIT: "certificial-api/policy/location",
    GET_LOCATIONS: "certificial-api/policy/locations",
    GET_COMMON_LOCATION:"certificial-api/policy/getCommonLocations",
    PROPERTY_LIMITS_BY_KIND: "certificial-api/policy/getLimitDetails",
    POST_LOCATION: "certificial-api/policy/locations/selection",
    GET_SELECTED_LOCATIONS_POLICY: "certificial-api/policy/locations/selected",
    GET_LOCATION_BUILDINGS: "certificial-api/policy/location/buildings",
    CHANGE_TEMPLATE_VALIDATE: "certificial-api/dashboard/change/template/validate",
    POLICY_LOCATION_APPROVAL: "certificial-api/policy/locations/approval",
    ADD_NEW_PROJECT:"certificial-api/requestor/add",
    FETCH_ALL_PROJECTS:"certificial-api/requestor/projects",
    DELETE_PROJECT:"certificial-api/requestor/delete",
    UPDATE_PROJECT:"certificial-api/requestor/update/project",
    PROGRESS:"certificial-api/eventEmitter/progress",
    REQUESTOR_DASHBOARD_URL:"certificial-api/requestor-db",
    REQUESTOR_CHANGE_PROJECT_TEMPLATE: "certificial-api/requestor/change/projects",
    REQUESTOR_ADD_REQUEST_PROJECT_LIST: "certificial-api/requestor/projectLists",
    TRANSACTION_LIST:"certificial-api/policy/response/transaction/details",
    GET_COMPLIANCE_OVERRIDE:"certificial-api/policy/request/get/compliance/override",
    POST_COMPLIANCE_OVERRIDE:"certificial-api/policy/request/add/compliance/override",
    REMOVE_SHARED_POLICY:"certificial-api/sharedpolicy/remove/policy",
    GET_EXTRACTED_DATA:"certificial-api/extract/coiExtraction",
    GET_INACTIVE_POLICIES_DATA:"certificial-api/agent/inactive/policies",
    OPT_OUT_COI_UPLOAD:"certificial-api/policy/response/optout/upload/coi",
    SAVE_EXTRACTED_DATA:"certificial-api/policy/extracted/addPolicy",
    GET_EXISTING_CLIENT:"certificial-api/agent/get/company/merge/list",
    GET_MERGE_PROGRESS:"certificial-api/agent/get/company/merge/status",
    MERGE_CLIENTS:"certificial-api/agent/company/merge",
    MERGE_CLIENTS_FAILURE_REPORT:"certificial-api/agent/send/company/merge/email",
    EMAIL_UPLOAD_COI:{
      VALIDATE:"certificial-api/policy/response/email/coi/upload/validate",
      UPLOAD:"certificial-api/policy/response/email/coi/upload"
    },    
    AEGNT_ADDRESS_BOOK:{
      UPLOAD:"certificial-api/agent/addressbook/upload",
      FETCH_GROUPS:"certificial-api/agent/addressbook/group",
      FETCH_GROUP_DETAILS:"certificial-api/agent/addressbook/certholders",
      DELETE:"certificial-api/agent/addressbook/agency/group"
    },
    EMAIL_REMOVE_CLIENT: {
      REMOVE_CLIENT: "certificial-api/policy/response/email/removeClient", 
      VALIDATE: "certificial-api/policy/response/email/removeClient/validate"
    },
    GET_VEHICLES: "certificial-api/policy/vehicles",
  
    CREATE_AGENT_TEMPLATE: "certificial-coverage-template/agenttemplates",
    EDIT_AGENT_TEMPLATE: "certificial-coverage-template/agenttemplates",
    DELETE_AGENT_PROJECT_TEMPLATE: "certificial-coverage-template/agenttemplates/agents",
    GET_AGENT_TEMPLATES: "certificial-coverage-template/agenttemplates/agents",
    TEMPLATE_AVAIALABLE_FOR_INSURED_USE:"certificial-coverage-template/agenttemplates/insured/available",
    GET_CLIENT_PROJECT_TEMPLATES_FOR_AGENT: "certificial-coverage-template/agenttemplates/share/agents",
    COVERAGE_TEMPLATE_LIST:"certificial-coverage-template/agenttemplates/coverage/filter/data",
    PRIMARY_INSURES_LIST:"certificial-api/namedinsured/filter",
    CHARTS:{
      GET_RESPONSE_RATE_DATA:"certificial-api/dashboard/response/rate",
      GET_COMPLIANCE_RATE_DATA:"certificial-api/dashboard/compliance/rate/trend",
      GET_COMPLIANCE_BY_COVERAGE_TYPE_DATA:"certificial-api/dashboard/compliance/coverage",
      GET_COMPLIANCE_BY_TEMPLATE_DATA:"certificial-api/dashboard/compliance/template",      
    },
    SUPPLIER_FILTERS:{
      POLICY_TYPE_LIST:"certificial-api/dashboard/policyType/list",
      PROJECT_LIST:"certificial-api/dashboard/project/list",
      TEMPLATE_LIST:"certificial-api/dashboard/template/list",
    },
    REQUEST_COVERAGE_FILTERS:{
      POLICY_TYPE_LIST:"certificial-api/policy/request/policyType/list",
      PROJECT_LIST:"certificial-api/policy/request/project/list",
      TEMPLATE_LIST:"certificial-api/policy/request/template/list",
    },
    AUTO_RESPONSE:{
      VALIDATE_LINK:"certificial-coverage-template/autoresponse/validate",
      OPT_IN:"certificial-coverage-template/autoresponse/optin",
      OPT_IN_WITH_RESPOND:"certificial-api/policy/response/autoresponse/optin"
    }
  }
};
