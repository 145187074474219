import { Avatar, Card, Icon, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { instructionsIcon } from "../../Assets/assets";
const { Paragraph } = Typography;

export const InstructionText = ({
  defaultValue,
  handleInstructionTextChanges,
  readOnlyMode,
  rows = 1
}) => {
  return (
    <Card
      size="small"
      bordered={true}
      className="widget-card"
      title={
        <h4>
          <Avatar
            src={instructionsIcon}
            size="small"
            shape="square"
            className="margin-right-sm"
          />
          Instructions
        </h4>
      }
      extra={null}
    >
      {readOnlyMode ? (
        <Paragraph
          style={{ whiteSpace: "pre-line" }}
          ellipsis={{ rows: 3, expandable: true }}
        >
          {(defaultValue && defaultValue) || (
            <>
              <Icon type="info-circle" className="margin-right-sm" /> No
              instructions available
            </>
          )}
        </Paragraph>
      ) : (
        <TextArea
          value={defaultValue}
          disabled={readOnlyMode}
          rows={rows}
          name="instructionText"
          onChange={handleInstructionTextChanges}
        />
      )}
    </Card>
  );
};
