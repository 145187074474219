export const CERTIFICIAL_CSS = {
  UI: {
    COLLAPSE: {
      background: "rgba(245, 245, 245, 0.1)",
      marginBottom: 7,
      border: "1px solid #d9d9d9",
      overflow: "hidden",
      borderRadius: 3
    },
    NESTED_COLLAPSE: {
      background: "rgba(83, 105, 248, 0.1)",
      marginBottom: 7,
      border: "1px solid #d9d9d9",
      overflow: "hidden",
      borderRadius: 3
    },
    COLLAPSENONCOMP: {
      background: "rgba(245, 245, 245, 0.1)",
      marginBottom: 7,
      border: "1px solid #d9d9d9",
      overflow: "hidden",
      borderRadius: 3
    },
    COLLAPSE_CARD_HEADER: {
      TYPE: {
        marginBottom: 3,
        fontSize: "12px",
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.7)",
        lineHeight: 1
      },
      TITLE: {
        marginBottom: 0,
        lineHeight: 1.2
      }
    },
    STEPS: {
      HEADER: {
        padding: "12px 0px 0px",
        borderBottom: "1px solid rgba(83, 105, 248, 0.1)",
        background: "rgba(83, 105, 248, 0.04)"
      }
    }
  }
};
