export const MY_TEAM_TYPES = {
  GET_ADDRESSBOOK: {
    SUCCESS: "GET_ADDRESSBOOK_SUCCESS",
    ERROR: "GET_ADDRESSBOOK_ERROR"
  },
  POST_ADDRESSBOOK: {
    SUCCESS: "POST_ADDRESSBOOK_SUCCESS",
    ERROR: "POST_ADDRESSBOOK_ERROR"
  },
  GET_CONTACTS: {
    SUCCESS: "GET_CONTACTS_SUCCESS",
    ERROR: "GET_CONTACTS_ERROR"
  },
  CLEAR_CONTACTS: "CLEAR_CONTACTS",
  SET_LOADING: "SET_LOADING",
  SET_USERS: {
    SUCCESS: "SET_USERS_SUCCESS",
    ERROR: "SET_USERS_ERROR"
  },
  GET_ALL_ASSOCIATED_COMPANY:{
    SUCCESS: "ALL_ASSOCIATED_COMPANY_SUCCESS",
    ERROR: "ALL_ASSOCIATED_COMPANY_ERROR"
  },
  CURRENT_ACTIVE_TAB:{
    SET: "SET_CURRENT_ACTIVE_TAB"
  }
};
