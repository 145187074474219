import React, { useState } from "react";
import { Icon, Empty, Card, message, Avatar, Col, Modal } from "antd";
import { showFile } from "../../../Utils/utility/app.utility";
import { downloadUploadedFile } from "../../../Store/Modules/Application/Application.asyncRequest";
import { emptyImage, showDocumentsIcon } from "../../../Assets/assets";
import { PolicyUploadedDocumentModal } from "./PolicyUploadedDocumentmodal";

export const PolicyUploadedDocuments = ({
  isTitleRequred,
  documentsList,
  onClickOfDocument,
  removeDoc,
  isBordered,
  bodyStyle,
  readOnly = true,
  title,
  selectedProjectDetails
}) => {
  const [loading, setLoading] = useState(false);
  const [onPopUpOpen, setOnPopUpOpen] = useState(false);
  const downLoadFile = (file, fileName) => {
    setLoading(true);
    downloadUploadedFile(file).then(
      (res) => {
        message.success(fileName + " download successfully.");
        showFile(res, fileName);
        setLoading(false);
      },
      () => {
        message.error(fileName + " download failed.");
        setLoading(false);
      }
    );
  };
  return (
    <><PolicyUploadedDocumentModal readOnly={readOnly} setOnPopUpOpen={setOnPopUpOpen} isTitleRequred={isTitleRequred} title={title} onPopUpOpen={onPopUpOpen} selectedProjectDetails = {selectedProjectDetails} documentsList={documentsList} removeDoc={removeDoc}/>
    <Card
      size="small"
      bordered={isBordered}
      className="widget-card"
      bodyStyle={
        bodyStyle
          ? bodyStyle
          : {
              padding: "6px 0",
              borderTop: "1px solid rgba(83, 105, 248, 0.2)"
            }
      }
      title={
        isTitleRequred && (
          <h4 className="margin-0">
            <Avatar
              src={showDocumentsIcon}
              size="small"
              shape="square"
              className="margin-right-sm"
            />
            {title ? title : "Policy Documents"}
            <Col style={{ fontSize: "16px", color: "#3596db", float: "right", paddingLeft: "8px" }} span={2}>
            <Icon type={"eye"} onClick={() => setOnPopUpOpen(true)} />
          </Col>
          </h4>
        )
      }
    /></>);
};
