import Loadable from "react-loadable";
import { AppLoader } from "../Components/Loader/AppLoader";

export const LazyCreatePolicy = Loadable({
  loader: () => import("../Components/Policy/CreatePolicy/CreatePolicy"),
  loading: AppLoader
});
export const LazyCompanyDetails = Loadable({
  loader: () =>
    import(
      "../Modules/SharedPolicies/ShowCompanySharedPolicyDetails/ShowCompanySharedPolicyDetails"
    ),
  loading: AppLoader
});
export const LazyShareMyPolicy = Loadable({
  loader: () => import("../Modules/SharedPolicies/ShareMyPolicy/ShareMyPolicy"),
  loading: AppLoader
});
export const LazyCreateRequestForInsurance = Loadable({
  loader: () =>
    import(
      "../Modules/RequestPolicies/CreateRequestForInsurance/CreateRequestForInsurance"
    ),
  loading: AppLoader
});

export const LazyAgentRepondPolicyPendingRequests = Loadable({
  loader: () =>
    import(
      "../Modules/Agent/PendingApprovals/AgentRepondPolicyPendingRequests/AgentRepondPolicyPendingRequests"
    ),
  loading: AppLoader
});

export const LazyAgentSharePolicyPendingRequests = Loadable({
  loader: () =>
    import(
      "../Modules/Agent/PendingApprovals/AgentSharePolicyPendingRequests/AgentSharePolicyPendingRequests"
    ),
  loading: AppLoader
});
export const LazyAgentPendingRequestBehalfOfInsured = Loadable({
  loader: () =>
    import(
      "../Modules/Agent/AgentPendingRequests/AgentPendingRequestBehalfOfInsured/AgentPendingRequestBehalfOfInsured"
    ),
  loading: AppLoader
});
export const LazyAgentPendingVerification = Loadable({
  loader: () =>
    import(
      "../Modules/Agent/PendingApprovals/AgentPendingVerification/AgentPendingVerification"
    ),
  loading: AppLoader
});

export const LazyPolicyEndorsementGrouping = Loadable({
  loader: () =>
    import(
      "../Components/Policy/RespondPolicyList/PolicyEndorsementGrouping/PolicyEndorsementGrouping"
    ),
  loading: AppLoader
});
