import { Avatar, Card, Icon, List, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { verifiedOwnerImage } from '../../../Assets/assets'
//import { instructionsIcon } from "../../Assets/assets";
const { Paragraph } = Typography;

export const CertHolderInfo = ({ certHolderInfo }) => {
	return certHolderInfo !== undefined && Object.keys(certHolderInfo).length ? (
		<Card
			size="small"
			bordered={true}
			className="widget-card"
			title={
				<h4>
					<Avatar
						src={verifiedOwnerImage}
						size="small"
						shape="square"
						className="margin-right-sm"
					/>
					Certificate Holder Information
				</h4>
			}
			extra={null}
		>

			<List size="small">
				<List.Item style={{border:'none'}}>
					<label style={{ fontWeight: 'bold' }}>Name:</label> {certHolderInfo.name}
				</List.Item>
				<List.Item style={{border:'none'}}>
					<label style={{ fontWeight: 'bold' }}>Address:</label> {certHolderInfo.address}
				</List.Item>
			</List>

		</Card>
	) : null;
};
