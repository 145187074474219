export const API_METHODS = {
    REQUEST: "request",
    GET: "get",
    POST: "post",
    DELETE: "delete",
    PUT: "put",
    PATCH: "patch",
    OPTIONS: "options",
    HEAD: "head",
    JSON_FORM_DATA: "jsonFormData",
    ALL:'all'
};
