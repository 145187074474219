export const CERTIFICIAL_LABEL = {
  APP: {},
  MODULES: {
    INSURED: {
      SHARED_POLICY: {},
      MY_POLICIES: {
        PROJECT_TEMPLATES: {
          ACTION_BUTTON_ON_POLICY_PAGE: "Coverage Templates",
          ACTION_BUTTON_ON_CLIENT_PAGE: "Manage Templates",
          DRAWER: {
            TITLE: "My Coverage Templates",
            ADD_NEW_TEMPLATE: "Add New Template"
          },
          CREATE_TEMPLATE: {
            TITLE: "Coverage Template",
            ADD_NEW_TEMPLATE: "Add New Coverage Template"
          },
          DELETE_TEMPLATE: {
            TITLE: "Are you sure you want to delete coverage template ? "
          }
        }
      },
      PENDING_REQUESTS: {}
    },
    REQUESTOR: {
      DASHBOARD: {},
      TEMPLATES: {
        DELETE_TEMPLATE: " Are you sure you want to delete this template?"
      },
      REQUEST_POLICY: {
        DELETE_POLICY_REQUEST:
          "Are you sure want to delete this policy request ?"
      },
      COIID: "COI ID",
      EXCLUSION: {
        ENABLE_EXCLUSION : "Enable Exclusion Detection"
      }
    },
    AGENT: {
      ACTIONS: {
        MANAGE_COMPLIANCE: "Manage Compliance"
      },
      CLIENTS: {
        ENDORSEMENT_UPLOAD_DOCUMENT: {
          NEW_DOCUMENT_SAVE: {
            HEADER: "New Documents Saved",
            BODY: "If you've already responded to a request for insurance and would like to include newly added documents, click on Manage Compliance and launch the Re-respond process.",
            BODY_UPDATE: "Documents are automatically updated for Certificate Holders granted this endorsement."
          },
          DOCUMENT_UPDATE: {
            HEADER: "Document Successfully Updated"
          },
          DOCUMENT_DELETE: {
            HEADER: "Document Removed"
          }
        },
        EDIT_POLICY_WARNING: {
          HEADER: "Compliance Alert",
          BODY: 'Click "Manage Compliance" to provide an updated COI reflecting all policy changes. Click "Close" if you would to make additional updates prior to updating the COI on file.'
        }
      },

      PENDING_APPROVALS: {},
      OPEN_REQUESTS: {
        REQUEST_COMPLETE: {
          HEADER: "Thank you for responding!",
          BODY: 'Click "Manage Compliance" to view this response and the current compliance status'
        }
      }
    }
  },
  COMPONENTS: {
    ENDORSEMENT_GROUPING: {
      TITLE: "Endorsement Document Utility",
      ADD_NEW_GROUP: "Add New Group ",
      SAVE_GROUP_CONFIRMATION: "Do you want to save and continue?",
      DELETE_GROUP_CONFIRMATION: "Are you sure you want to delete this group?",
      DELETE_DOCUMENT_TITLE: "Are you sure you want to delete this document?",
      ASSOCIATED_POLICY_LABEL: "Associated Policy Endorsements",
      SELECTED_DOCUMENT_LABEL: "Selected Endorsement Document",
      EXTRA_ENDORSEMENT_SATISFY_LABEL:
        "Does the uploaded document satisfy any of the below listed endorsements?"
    },
    POLICY_SELECTION: {
      CC_OPTION_SHORT: "Cc..",
      CC_OPTION: "Carbon Copy (Cc..)",
      CC_OPTION_INPUT_FORMAT:
        "Please enter email addresses separated by a semicolon",
      REQUEST_COMPANY:
        "Search our database for registered companies you can request policy info",
      AGENT_SHARE_OPTIONS:
        "Please select an option below to share policy information",
      SHARE_COMPANY:
        "Search our database for registered companies you can share policy info",
      AGENT_SHARE_COMPANY: `Search for available companies, enter an email address, or select "Print Only"`,
      PROJECT_REQUEST: "Please enter a project name for this request",
      PROJECT_SHARE: "Please enter a project name for this request",
      ADDRESS_BOOK_SEARCH: "Following companies found in the address book",
      PROJECT_SELECTION: "Please select a project associated to this company",
      ALREADY_SHARED_PROJECT_TITLE: "<p class='margin-0'>A share already exists for this company. You can edit the existing share or add a new project if you require more than one active COI on file.</p>",
      EXISTING_PROJECT: "This project name is already taken, Please try another one.",
      ENDORSEMENT_DOCUMENT_DROWER_MESSAGE: 'Endorsement documents are required for this response.  If any required endorsement documents are missing, this response will be non-compliant'
    },
    EMAIL_SELECTION: {
      REQUEST_EMAIL:
        "Enter recipient's email address to request policy info with an unregistered user",
      SHARE_EMAIL:
        "Enter recipient's email address to share policy info with an unregistered user",
      AGENT_SHARE_EMAIL: "Enter recipient email address"
    },
    BULK_UPLOAD_IN_REQUEST: {
      REQUEST_TITLE: "Raise request to Supplier address book"
    },
    AGENT_CLIENT_ADDRESS_BOOK_SELECTION: "Share with address book",
    LOADING: {
      MESSAGE: "Please Wait"
    },
    ADD_NEW_CLIENT: {
      TITLE: "Add Client",
      SEARCH_REQID_TITLE:
        "Use one of the below options to search for a pending DIV request",
      NEW_CLIENT_INFO_TITLE:
        '<span>NOT INTENDED FOR DIV REQUESTS. If your Client has received a DIV request, Please obtain <br/>  the assigned Request ID and enter it on the "Search Client" tab.<span/>',
      NEW_CLIENT_TITLE: "Enter client account details.",
      CONTAINER_TITLE:
        "Enter a Policy Request ID to redirect a Client request to this account"
    },
    POLICY_REQUESTS: {
      REQUEST_SUFFIX: "Insurance Requirements"
    }
  },
  WIDGETS: {
    POLICY_ENDORSMENT: {
      ENDORSEMENT_DOCUMENT_TITLE: "Blanket Endorsement Documents",
      CLIENT_SELF_MANAGEMENT_TITLE: "Enable Client Self-Management",
      SHOW_ENDORSMENT_DOCUMENT: "Show endorsement document",
      DOWNLOAD_ATTACHED_POLICY_ENDORSEMENT_DOCUMENT:
        "Download attached endorsement document.",
      DELETE_ENDORSEMENT_DOCUMENT: "Delete attached endorsement document.",
      SELECT_ENDORSEMENT_TO_DELETE_DOCUMENT:
        "Please select the endorsement to delete the document.",
      EMPTY_POLICY_ENDORSMENT: "No policy endorsements available",
      MISSING_ENDORSEMENT_DOCUMENT: "Missing Endorsement Document",
      MISSING_ADDITIONAL_ENDORSEMENT_DOCUMENT:"Missing Additional Endorsement Document",
      SELECT_ENDORSEMENT_TO_UPLOAD_DOCUMENT:
        "Please Select Endorsement to Upload Document",
      CLICK_ENDORSEMENT_TO_UPLOAD_DOCUMENT:
        "Click Here to Upload Blanket Endorsement Documents",
      AGENT_REVIEW_REQUIRED_ON_ENDORSEMENT_MESSAGE:
        "This endorsement requires agent review. ",
      MAXIMUM_SHARE_REACHED_MESSAGE:
        "You have reached the maximum limit of number of endorsements that you can grant. This endorsement requires agent review."
    },
    COI: {
      PREVIEW_COI_LABEL: "COI preview",
      SAMPLE_COI_LABEL: "View a sample COI (not for use)",
      GENERATE_DOWNLOAD_LABEL: "Generate & Download COI",
      ISSUE_COI: {
        MANAGE_COMPLIANCE: {
          TITLE: "Confirm Issue COI",
          BODY: "Would you like to update the Certificate of Insurance and supporting documents for this share?"
        },
        AGENT_CLIENTS: {
          TITLE: "Confirm Issue COI",
          BODY: "Would you like to update the Certificate of Insurance and supporting documents for this share?"
        },
        COI_DIALOG: {
          TITLE: "Your policies do not meet the requirements needed to generate a COI."
        }
      }
    },
    ADDITIONAL_REMARKS: {
      TITLE: "Additional Remarks",
      REQUESTED_TITLE:
        "Requested Remarks (Accept or edit remarks language during response process)",
      NO_REMARKS_AVAILABLE_LABEL: "No Remarks Required", //CER-32967 changed label, check comments
      NO_REMARKS_PROVIDED: "No Remarks Provided", //CER-32967 changed label, check comments

      DYNAMIC_POLICIES_NOT_SUPPORTED_FOR_THIS_REGION_LABEL:
        "Dynamic policies are not supported for this region. Please refer attached COI for more details.",
      MULTI_AGENT_INVOLVMENT_MESSAGE:
        "<span>Please note that other agents are involved on this request as well. Please respond to the policies for which you are responsible. <span/>"
    }
  }
};
