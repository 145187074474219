import React from "react";
import { AddPolicyInsurerDetailsModal } from "./AddPolicyInsurerDetailsModal";

class AddPolicyInsurerDetails extends React.Component {
  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const insurerDetails = {
        ...values,
        companyNaic: values.companyNaic || 0
      };
      this.props.onAddNewInsurer(insurerDetails);
      //this.props.handleCancel();
      //form.resetFields();
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div>
        <AddPolicyInsurerDetailsModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.handleCancel}
          onCreate={this.handleCreate}
          modalConfirmLoading={this.props.confirmLoadingCustomInsurerModal}
          addInsurerModalMessage={this.props.addInsurerModalMessage}
        />
      </div>
    );
  }
}

export default AddPolicyInsurerDetails;
