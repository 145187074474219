import React, { useState } from 'react'
import { Button } from 'antd';
import LearnMoreDetails from './LearnMoreDetails';
import { CERTIFICIAL_LABEL } from '../../../Utils/Constants/App.labels';
import { APPLICATION_MESSAGES } from '../../../Utils/Constants/ApplicationMessages.constants';


const LearnMore = ()=>{

	const [visible, setVisible] = useState(false);

	return <>
			<Button onClick={()=>setVisible(true)} style={{paddingLeft:"0px"}} type='link'><span style={{textDecoration:"underline"}}>{APPLICATION_MESSAGES.AGENT.AUTO_RESPONSE.LEARN_MORE_TEXT}</span></Button>
			<LearnMoreDetails visible={visible} onOk={()=>setVisible(false)}/>
		</>
}

export default LearnMore;