import React, { useEffect, useState } from "react";
import { Icon, Card, Row, Col, Checkbox, Radio, Avatar } from "antd";
import { shallowEqual, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { coverageIcon } from "../../../../Assets/assets";
import CertInfoTooltip from "../../../UI/CertTooltip/CertInfoTooltip";
import { APPLICATION_MESSAGES } from "../../../../Utils/Constants/ApplicationMessages.constants";
import less from "./PolicyAnyProprietor.module.less";

export const PolicyAnyProprietor = ({
  bordered,
  readOnlyMode,
  isTitleRequired,
  onCheckBoxChanges,
  onRadioChange,
  onCommentsChanges,
  defaultValueCheckbox,
  defaultValueRadio,
  defaultValueComment,
  companyTrackedPolicy,
  policy,
  notShowBelowField,
  isColorRed,
  isIbuttonDisable,
  isOverSizeText
}) => {
  const [getAnyProprietor, setAnyProprietor] = useState([]);

  const { role: currentSessionRole } = useSelector(
    (state) => state.app.currentSession,
    shallowEqual
  );

  useEffect(() => {
    setProprietorData();
    if (!policy) {
      return;
    }
    const policyData = companyTrackedPolicy.find(
      (value) =>
        policy?.policyId === value?.policyId ||
        policy?.policy_type_id === value?.policyId
    );
    setAnyProprietor(policyData);
  }, [companyTrackedPolicy, policy]);

  const getComplianceStatus = (data) => {
    let status = data?.exclusioncomparison;
    if (!!status?.require_review) {
      return "rgb(250, 173, 20)";
    } else if (!!status?.exclusion_compliance_issue) {
      return "#f30b17";
    } else {
      return null;
    }
  };

  const setProprietorData = () => {
    if (companyTrackedPolicy?.compliance) {
      const data = JSON.parse(companyTrackedPolicy?.compliance);
      setAnyProprietor(data);
    } else if (companyTrackedPolicy?.iscomplianceissue) {
      setAnyProprietor(companyTrackedPolicy?.iscomplianceissue);
    } else {
      setAnyProprietor(companyTrackedPolicy);
    }
  };

  return (
    <Card
      size="small"
      bordered={bordered}
      className="widget-card"
      title={
        (isTitleRequired && (
          <h4 className="margin-0">
            <Icon type="form" style={{ color: "#3596db", marginRight: '10px'}} />Policy Any Values
          </h4>
        )) || (
          <h4>
            <Avatar
              src={coverageIcon}
              size="small"
              shape="square"
              className="margin-right-sm"
            />
            Exclusions
            {!!getAnyProprietor?.exclusioncomparison
              ?.exclusion_compliance_issue && currentSessionRole === "Agent" && !isIbuttonDisable ? (
              <CertInfoTooltip
                placement="topLeft"
                title={
                  APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO
                    .EXCLUSION_INFORMATION
                }
                className="compliance-issue-info"
              />
            ) : null}
            {!!getAnyProprietor?.exclusioncomparison
              ?.exclusion_compliance_issue && (
              <span
                style={ isColorRed ? isOverSizeText ? { color: "#f30b17", marginLeft: "-2px", fontSize: "9px" } : {color: "#f30b17", marginLeft: "12px"} :
                  currentSessionRole === "Agent" && !isIbuttonDisable
                    ? { color: "#f30b17" }
                    : { marginLeft: "10px" }
                }
              >
                (Requestor does not permit exclusions)
              </span>
            )}
          </h4>
        )
      }
      extra={null}
    >
      <Row>
        <Col span={24}>
          <Checkbox
            disabled={readOnlyMode}
            name="anyWorkerIncluded"
            onChange={onCheckBoxChanges}
            defaultChecked={defaultValueCheckbox}
            checked={defaultValueCheckbox}
          >
            <span style={{ color: getComplianceStatus(getAnyProprietor) }}>
              Any Proprietor / Partner / Executive / Officer / Member Excluded?
            </span>
          </Checkbox>
        </Col>
        <Col
          span={24}
          className={less.checkboxAlign}
        >
          {defaultValueCheckbox && !notShowBelowField && (
            <Radio.Group
              disabled={readOnlyMode}
              onChange={onRadioChange}
              name="anyWorkerIncludedRadio"
              value={defaultValueRadio}
            >
              <Radio value={"yes"}>
                <span
                  style={{
                    color:
                      defaultValueRadio === "yes" &&
                      getComplianceStatus(getAnyProprietor),
                  }}
                >
                  Yes
                </span>
              </Radio>
              <Radio value={"no"}>
                <span
                  style={{
                    color:
                      defaultValueRadio === "no" &&
                      getComplianceStatus(getAnyProprietor),
                  }}
                >
                  No
                </span>
              </Radio>
            </Radio.Group>
          )}
        </Col>
        <Col span={24}>
          {defaultValueCheckbox && !notShowBelowField && (
            <TextArea
              disabled={defaultValueRadio === "no" || readOnlyMode}
              defaultValue={defaultValueComment}
              value={defaultValueComment}
              // placeholder={"Names"}
              rows={2}
              name={"any"}
              onChange={onCommentsChanges}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};
