import { STORAGE } from "../Utils/utility/Storage.utility";

const AUTH_HEADER = () => {
    let user = STORAGE.getItem("currentUserInformation");
    if (user && user.key) {
        return { Authorization: "Token " + user.key };
    } else {
        return {};
    }
};

export const COMMON_HEADERS = () => ({
    Accept: "*/*",
    "Content-Type": "application/json",
    "Access-Control-Expose-Headers": "Content-Type,  Origin, JWT_REFRESHED",
    ...AUTH_HEADER()
});
