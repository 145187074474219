import { Modal } from "antd";
import _ from "lodash";

const basicModalConfig = {
  className: "certificial-modal",
  centered: true,
  maskClosable: false,
  destroyOnClose: true,
  width: "50%",
  keyboard: false,
  cancelButtonProps: {},
  okButtonProps: {}
};

export const _modalService = {
  success: ({ title, content, onOk, ...rest }) => {
    const providedProps = {
      ..._.omit(rest, ["title", "content", "onOk"])
    };
    return Modal.success({
      title,
      content,
      ...basicModalConfig,
      ...providedProps,
      onOk() {
        onOk && onOk();
      }
    });
  },

  error: ({ title, content, onOk, ...rest }) => {
    const providedProps = {
      ..._.omit(rest, ["title", "content", "onOk"])
    };
    return Modal.error({
      title,
      content,
      ...basicModalConfig,
      ...providedProps,
      onOk() {
        onOk && onOk();
      }
    });
  },

  info: ({ title, content, onOk, ...rest }) => {
    const providedProps = {
      ..._.omit(rest, ["title", "content", "onOk"])
    };
    return Modal.info({
      title,
      content,
      ...basicModalConfig,
      ...providedProps,
      onOk() {
        onOk && onOk();
      }
    });
  },

  warning: ({ title, content, onOk, ...rest }) => {
    const providedProps = {
      ..._.omit(rest, ["title", "content", "onOk"])
    };
    return Modal.warning({
      title,
      content,
      ...basicModalConfig,
      ...providedProps,
      onOk() {
        onOk && onOk();
      }
    });
  },

  warn: ({ title, content, onOk, ...rest }) => {
    const providedProps = {
      ..._.omit(rest, ["title", "content", "onOk"])
    };
    return Modal.warn({
      title,
      content,
      ...basicModalConfig,
      ...providedProps,
      onOk() {
        onOk && onOk();
      }
    });
  },

  confirm: ({ title, content, onOk, onCancel, ...rest }) => {
    const providedProps = {
      ..._.omit(rest, ["title", "content", "onOk", "onCancel"])
    };
    return Modal.confirm({
      title,
      content,
      ...basicModalConfig,
      ...providedProps,
      onOk() {
        onOk && onOk();
      },
      onCancel() {
        onCancel && onCancel();
      }
    });
  },

  update: ({ title, content, ...rest }) => {
    const providedProps = { ..._.omit(rest, ["title, content"]) };
    return Modal.update({
      title,
      content,
      ...basicModalConfig,
      ...providedProps
    });
  },
  destroyAll: () => Modal.destroyAll()
};
