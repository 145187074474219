import React, { useState } from "react";
import GenerateSignature from "../../../Components/GenerateSignature/GenerateSignature";
import { Modal } from "antd";

const GenerateSignatureModal = ({visible, userData, onCancel, onUploadGenerateSignature}) => {

  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const suggSelection = (selectedSuggestion)=>{
    setSelectedSuggestion(selectedSuggestion);
  }

  return(<Modal
      width={900}
      visible={true}
      onCancel={onCancel}
      onOk={()=>onUploadGenerateSignature(selectedSuggestion)}
      okButtonProps={{
        disabled:
          selectedSuggestion == null ? true : false
      }}
      destroyOnClose={true}
    >
        
            <div style={{ padding: "40px 10px 10px" }}>
            <GenerateSignature
                source="myProfile"
                signName={
                  userData.firstName +
                  " " +
                  userData.lastName
                }
                suggSelection={suggSelection}
                selectedSuggestion={
					selectedSuggestion
                }
                agreeESign={true}
             ></GenerateSignature>
            </div>
    </Modal>);
 
};

export default GenerateSignatureModal;
