import {
  Alert,
  Avatar,
  Badge,
  Button,
  Col,
  Icon,
  Layout,
  Menu,
  message,
  notification,
  Popover,
  Row,
  Switch,
  Tag,
  Tooltip
} from "antd";
import Text from "antd/lib/typography/Text";
import RcQueueAnim from "rc-queue-anim";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { profileCompletionIcon } from "../../Assets/assets";
import certificial from "../../Assets/Logos/certificial-logo-old.png";
import NotificationPopoverContent from "../../Components/NotificationPopOver/Notification_Popover";
import ProfileDropdown from "../../Components/ProfileDropdown/ProfileDropdown";
import CertAvatar from "../../Components/UI/CertAvatar/CertAvatar";
import { ENDPOINTS } from "../../REST/API.endpoints";
import {
  downloadUploadedFile,
  handleUserFirstLoginonLogout,
  logout_action
} from "../../Store/Modules/Application/Application.asyncRequest";
import { NavbarMenu } from "../../Utils/Configurations/Navbar.config";
import { APPLICATION_USER_ROLES } from "../../Utils/Constants/Application.constants";
import { APPLICATION_MESSAGES } from "../../Utils/Constants/ApplicationMessages.constants";
import { appHistory } from "../../Utils/Helpers/History";
import { showFile, getBadgeOffset } from "../../Utils/utility/app.utility";
import { _modalService } from "../../Utils/wrappers/Modal/ModalWrapper";
const { Header } = Layout;

const AppHeader = ({
  roleChange,
  currentRole,
  location,
  userInfo,
  appLoaderHandler,
  getOpenRequestscount,
  getPendingApprovalCount
}) => {
  const dispatch = useDispatch();
  const xsUserWrapperWdt = currentRole !== APPLICATION_USER_ROLES.AGENT ? 6 : 7;
  const xsNotiWrapperWdt = currentRole !== APPLICATION_USER_ROLES.AGENT ? 3 : 3;
  const xsQuesWrapperWdt = currentRole !== APPLICATION_USER_ROLES.AGENT ? 2 : 2;
  const getMenuItems = () => NavbarMenu[currentRole];
  const popoverInitialState = {
    visible: false
  };
  const [loading, setLoading] = useState(false);
  let { url } = useRouteMatch();
  const [popoverState, popoverSetstate] = useState(popoverInitialState);
  const { selectedNotification, openRequestCount, pendingApprovalCount } =
    useSelector((state) => state.app, shallowEqual);
  const { userData, isAgentList, agentList } = useSelector(
    (state) => state.myProfile
  );
  const { id, role, firstName, lastName, userFirstLogin } = userData;
  const [isAlert, setIsAlert] = useState(false);
  const [showManageProjectsModal, setShowManageProjectsModal] = useState(false);
  const agentSignNoteStyle = {
    paddingTop: 3,
    paddingRight: 9,
    lineHeight: "36px"
  };
  const agentTagStyle = {
    cursor: "pointer",
    background: "white",
    border: "1px solid rgba(237, 20, 61, 0.6)",
    width: "100%"
  };

  // const handleBrowserCloseEvent = (e) => {
  //   const message =
  //     "ALERT! Pending Request for Insurance /n You have not yet responded to your Client's request for insurance. Click LogOut to continue or Respond to view pending requests. ";
  //   (e || window.event).returnValue = message; //Gecko + IE
  //   return message;
  // };
  // useEffect(() => {
  //   if (role === APPLICATION_USER_ROLES.AGENT) {
  //     window.addEventListener("beforeunload", handleBrowserCloseEvent, false);
  //   }
  //   return () => {
  //     if (role === APPLICATION_USER_ROLES.AGENT) {
  //       window.removeEventListener(
  //         "beforeunload",
  //         handleBrowserCloseEvent,
  //         false
  //       );
  //     }
  //   };
  // }, [role]);

  useEffect(() => {
    if (selectedNotification && selectedNotification.activePath) {
      changeToggleOnNotificationCheck(
        selectedNotification.switchFlag,
        selectedNotification.activePath
      );
    }
  }, [selectedNotification]);

  const openNotification = () => {
    notification.open({
      message: "All Notifications has been marked as read.",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />
    });
  };

  const seeAllNotificationHandler = (event) => {
    popoverSetstate({
      visible: false
    });
    appHistory.push("/app/notifications");
  };

  const handlePopoverVisibleChange = (visible) => {
    popoverSetstate({ visible });
  };

  const changeToggleOnNotificationCheck = (visible, acdtivePath) => {
    const role = visible
      ? APPLICATION_USER_ROLES.INSURER
      : APPLICATION_USER_ROLES.REQUESTER;
    roleChange(role, acdtivePath);
  };

  const handleLogout = () => {
    if (id) {
      const executeLogout = () => {
        appLoaderHandler(true);
        if (userFirstLogin) {
          dispatch(
            handleUserFirstLoginonLogout(
              {
                userId: id,
                flag: false,
                user: firstName + " " + lastName
              },
              () => appLoaderHandler(false)
            )
          );
        } else {
          dispatch(logout_action(id, () => appLoaderHandler(false)));
        }
      };
      if (
        role === APPLICATION_USER_ROLES.AGENT &&
        openRequestCount &&
        openRequestCount > 0
      ) {
        _modalService.confirm({
          title: (
            <h4 className="margin-0 color-danger">
              {
                APPLICATION_MESSAGES.APP.SESSION
                  .AGENT_OPEN_REQUEST_TITLE_MESSAGE
              }
            </h4>
          ),
          content: (
            <p
              dangerouslySetInnerHTML={{
                __html:
                  APPLICATION_MESSAGES.APP.SESSION
                    .AGENT_OPEN_REQUEST_BODY_MESSAGE
              }}
            />
          ),
          icon: <Icon type="warning" theme="twoTone" twoToneColor="#faad14" />,
          keyboard: false,
          destroyOnClose: true,
          okButtonProps: { type: "danger", icon: "poweroff" },
          cancelButtonProps: { type: "primary", icon: "issues-close" },
          cancelText: "Respond To Request",
          okText: "Logout",
          centered: true,
          width: "40%",
          onOk() {
            executeLogout();
          },
          onCancel() {
            appHistory.push("/app/agent-insured-requests");
          }
        });
      } else {
        executeLogout();
      }
    }
  };

  const profileMenuHandler = (event) => {
    if (event && event.key === "/app/myProfile") {
      appHistory.push("/app/myProfile");
    } else if (event && event.key === "/app/company-manage") {
      appHistory.push("/app/company-manage");
    } else if (event && event.key === "/app/company-admin") {
      appHistory.push("/app/company-admin");
    } else if (event && event.key === "/app/my-team") {
      appHistory.push("/app/my-team");
    } else if (event && event.key === "/app/my-address-book") {
      appHistory.push("/app/my-address-book");
    } else if (event && event.key === "/app/supplier-address-book") {
      appHistory.push("/app/supplier-address-book");
    } else if (event?.key == "manage-projects") {
      appHistory.push("/app/manage-projects");
    } else {
      handleLogout();
    }
  };
  const toggleUserSwitch = (event) => {
    const role = event
      ? APPLICATION_USER_ROLES.INSURER
      : APPLICATION_USER_ROLES.REQUESTER;
    roleChange(role, (event && "SHARED_POLICIES") || "DASHBOARD", true);
  };
  const onQuestionMarkClick = (file, fileName) => {
    setLoading(true);
    downloadUploadedFile(file).then(
      (res) => {
        message.success(fileName + " download successfully.");
        showFile(res, fileName);
        setLoading(false);
      },
      () => {
        message.error(fileName + " download failed.");
        setLoading(false);
      }
    );
  };
  const getLogo = (url) => (
    <Link to={url}>
      <div className="app-logo">
        <img src={certificial} />
      </div>
    </Link>
  );
  const refreshBadgeCounts = () => {
    getOpenRequestscount();
    getPendingApprovalCount();
  };
  const refreshOpenReqBadgeCounts = () => {
    getOpenRequestscount();
  };
  const agentSignatureNotAvailable =
    currentRole === APPLICATION_USER_ROLES.AGENT &&
    (userData.esignature === "" || userData.esignature === null);

    const onClickNavigation = () => {
      appHistory.push("/app/myProfile");
    };

  const checkArchived = useMemo(() => {
    if (currentRole !== APPLICATION_USER_ROLES.INSURER) {
      return false;
    }
    if (agentList?.length > 0) {
      return agentList?.every((item) => item.status === "Archived")
    } else {
      return true;
    }
  }, [agentList, currentRole]);

  return (
   
    <RcQueueAnim type="top" delay={50}>
      {(isAlert && (
        <Alert
          type="warning"
          message="Certificial will be undergoing planned maintenance activity in 2 hours. Kindle save your all changes."
          banner
          closable
          closeText={<Icon style={{ fontSize: 18 }} type="close-circle"></Icon>}
          style={{ borderBottom: "2px solid #f5a505" }}
        />
      )) ||
        null}
      <Header
        className={["app-header", "certificial-app-header", currentRole].join(
          " "
        )}
        style={{ position: "fixed", width: "100%" }}
        key="header"
      >
        <Row type="flex" justify="start">
          <Col xl={4} xs={3}>
            {currentRole === APPLICATION_USER_ROLES.AGENT
              ? getLogo("/app/agent-clients")
              : currentRole === APPLICATION_USER_ROLES.REQUESTER
              ? getLogo("/app/dashboard")
              : getLogo("/app/shared-policies")}
          </Col>
          <Col
            xl={8}
            xs={8}
          >
            <Menu
              theme="light"
              mode="horizontal"
              selectedKeys={[location.pathname]}
              style={{ lineHeight: "43px" }}
            >
              {getMenuItems() &&
                getMenuItems().map((item) =>
                  currentRole !== APPLICATION_USER_ROLES.REQUESTER &&
                  item.LABEL == "Open Requests" ? (
                    <Menu.Item
                      key={[url, item.LINK].join("/")}
                      onClick={currentRole !== APPLICATION_USER_ROLES.AGENT ? refreshBadgeCounts : refreshOpenReqBadgeCounts}
                    >
                      <Badge
                        title={
                          (openRequestCount > 999 &&
                            APPLICATION_MESSAGES.AGENT.OPEN_REQUESTS.TOTAL) ||
                          null
                        }
                        overflowCount={999}
                        offset={getBadgeOffset(openRequestCount, 999)}
                        count={openRequestCount}
                      >
                        {item.LABEL}
                      </Badge>
                      <Link to={[url, item.LINK].join("/")} />
                    </Menu.Item>
                  ) : currentRole === APPLICATION_USER_ROLES.AGENT &&
                    item.LABEL == "Pending Approvals" ? (
                    <Menu.Item
                      key={[url, item.LINK].join("/")}
                      onClick={refreshBadgeCounts}
                    >
                      <Badge
                        title={
                          (pendingApprovalCount &&
                            pendingApprovalCount.totalPendingApproval > 999 &&
                            APPLICATION_MESSAGES.AGENT.PENDING_APPROVALS
                              .TOTAL) ||
                          null
                        }
                        overflowCount={999}
                        offset={getBadgeOffset(
                          pendingApprovalCount &&
                            pendingApprovalCount.totalPendingApproval,
                          999
                        )}
                        count={
                          pendingApprovalCount &&
                          pendingApprovalCount.totalPendingApproval
                        }
                      >
                        {item.LABEL}
                      </Badge>
                      <Link to={[url, item.LINK].join("/")} />
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={refreshBadgeCounts}
                      key={[url, item.LINK].join("/")}
                    >
                      {item.LABEL}
                      <Link to={[url, item.LINK].join("/")} />
                    </Menu.Item>
                  )
                )}
            </Menu>
          </Col>
          <Col
            xl={12}
            xs={13}
          >
            <Row type="flex" justify="end">
              {agentSignatureNotAvailable && (
                <Col xl={10} xs={10} style={agentSignNoteStyle}>
                  <Row type="flex" justify="end">
                    <Tooltip
                      arrowPointAtCenter
                      placement="bottomRight"
                      title={
                        "Uploading your signature enables the ability to generate Certificates of Insurance on Certificial."
                      }
                    >
                      <Link to={"/app/myProfile"}>
                        <Tag style={agentTagStyle}>
                          <Row type="flex" align="top">
                            <Col span={3}>
                              {" "}
                              <Avatar
                                src={profileCompletionIcon}
                                size={24}
                                shape="square"
                                style={{ margin: "4px -2px" }}
                              />{" "}
                            </Col>
                            <Col span={21}>
                              <p
                                className="margin-0 font-family-workSans"
                                style={{
                                  lineHeight: 1.1,
                                  whiteSpace: "nowrap",
                                  width: "100%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  padding: 3,
                                  fontWeight: 100
                                }}
                              >
                                Profile incomplete, <br />
                                <span className="fs-12x">
                                  please click here to upload signature
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </Tag>
                      </Link>
                    </Tooltip>
                  </Row>
                </Col>
              )}
              {/* <Col
                xl={2}
                xs={xsNotiWrapperWdt}
                className="text-center"
                id="app-tabs"
              >
                <ApplicationTabs />
              </Col> */}
              <Col
                xl={2}
                xs={xsNotiWrapperWdt}
                className="notification-wrapper"
              >
                <NotificationPopoverContent
                  openNotification={openNotification}
                  visible={popoverState.visible}
                  handlePopoverVisibleChange={handlePopoverVisibleChange}
                  changeToggleOnNotificationCheck={
                    changeToggleOnNotificationCheck
                  }
                  allNotificationHandler={(event) =>
                    seeAllNotificationHandler(event)
                  }
                />
              </Col>
              {currentRole !== APPLICATION_USER_ROLES.AGENT ? (
                <Col xl={3} xs={3}>
                  <Menu
                    theme="light"
                    mode="horizontal"
                    selectedKeys={[location.pathname]}
                    style={{
                      lineHeight: "43px",
                      borderRight: "1px solid #d6d6d6"
                    }}
                  >
                    <Menu.Item
                      key="/app/upgrade"
                      style={{ width: "100%", textAlign: "center", padding: 0 }}
                    >
                      <Link to="/app/upgrade">Upgrade</Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              ) : null}

              {false && (
                <Col xl={2} xs={xsQuesWrapperWdt} className="question-wrapper">
                  <Tooltip
                    arrowPointAtCenter
                    placement="bottomRight"
                    title={"Quick Reference Guide "}
                  >
                    <div className="question-section">
                      <a
                        onClick={() =>
                          onQuestionMarkClick(
                            ENDPOINTS.APPLICATION.QUICK_REF_GUIDE,
                            ENDPOINTS.APPLICATION.QUICK_REF_GUIDE_NAME
                          )
                        }
                        rel="noopener noreferrer"
                      >
                        <Icon
                          type={loading ? "loading" : "question-circle"}
                          theme={loading ? "" : "filled"}
                          style={loading ? { color: "#3596db" } : {}}
                        />
                      </a>
                    </div>
                  </Tooltip>
                </Col>
              )}
              <Popover
                content={
                  <ProfileDropdown
                    profileMenuHandler={(event) => profileMenuHandler(event)}
                    location={location.pathname}
                  />
                }
                arrowPointAtCenter={true}
                destroyTooltipOnHide={true}
              >
                <Col
                  xl={agentSignatureNotAvailable ? 6 : 7}
                  xs={agentSignatureNotAvailable ? 6 : 7}
                  className="user-wrapper"
                >
                  <div className="user-section">
                    <Row>
                      <Col span={7}>
                        <CertAvatar
                          className="profile-avatar"
                          src={
                            userInfo &&
                            userInfo.company &&
                            userInfo.company.logo
                          }
                          shape={"square"}
                          size="large"
                        >
                          <Icon type="user" style={{ fontSize: 18 }} />
                        </CertAvatar>
                      </Col>
                      <Col span={17}>
                        <div className="user-block">
                          <Text className="user-company-block">
                            {userInfo && userInfo.firstName}{" "}
                            {userInfo && userInfo.lastName}
                          </Text>

                          <Tooltip
                            arrowPointAtCenter
                            placement="left"
                            title={
                              userInfo &&
                              userInfo.company &&
                              userInfo.company.name
                            }
                          >
                            <Text
                              type="secondary"
                              className="user-company-block"
                            >
                              {userInfo &&
                                userInfo.company &&
                                userInfo.company.name}
                            </Text>
                          </Tooltip>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Popover>
              {!!currentRole &&
              currentRole !== APPLICATION_USER_ROLES.AGENT &&
              currentRole !== APPLICATION_USER_ROLES.CLIENT ? (
                <Col xl={5} xs={5} className="switch-btn-wrapper">
                  <div className="switch-btn-section">
                    <Switch
                      className={currentRole.toLowerCase()}
                      onChange={(event) => toggleUserSwitch(event)}
                      checked={currentRole === APPLICATION_USER_ROLES.INSURER}
                    />

                    <span
                      style={{
                        width: 60,
                        marginLeft: 5,
                        display: "inline-block"
                      }}
                    >
                      {currentRole}
                    </span>
                  </div>
                </Col>
              ) : null}
              {!!currentRole && currentRole === APPLICATION_USER_ROLES.AGENT ? (
                <Col xl={4} xs={4} className="logout-btn-wrapper">
                  <Tooltip title="Log Out">
                    <div
                      className={[
                        "logout-btn-section",
                        ((userData.esignature === "" ||
                          userData.esignature === null) &&
                          "no-signature") ||
                          ""
                      ].join(" ")}
                      onClick={() => profileMenuHandler()}
                    >
                      <h3
                        className="margin-0"
                        style={{ display: "inline-flex" }}
                      >
                        <Icon
                          type="poweroff"
                          style={{
                            fontSize: "1.2rem",
                            marginRight: 9
                          }}
                        />
                        Logout
                      </h3>
                    </div>
                  </Tooltip>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Header>
      {checkArchived ? (
        <Alert
          key="pending-insurance-request"
          type="warning"
          message={
            <span>
              Please add your Agent(s). Once completed, they will be notified
              and able to respond to your pending requests. Please
              <Button type="link" size="small" style={{ padding: "0 3px" }} onClick={() => onClickNavigation()}>
                <Link>click here</Link>
              </Button>
              to add your Agent(s).
            </span>
          }
          banner
          className={"pending-insurance-requst-warning"}
        />
      ) : null}

      {openRequestCount &&
      openRequestCount > 0 &&
      currentRole !== APPLICATION_USER_ROLES.REQUESTER ? (
        <Alert
          type="warning"
          key="pending-insurance-requst"
          message={
            <span>
              You have {openRequestCount} pending requests for insurance.
              {currentRole === APPLICATION_USER_ROLES.AGENT &&
              <>{" "}
              Please
              <Button type="link" size="small" style={{ padding: "0 3px" }}>
                <Link to={role == APPLICATION_USER_ROLES.AGENT ? "/app/agent-insured-requests" : "/app/pending-requests"}>click here</Link>
              </Button>
              to respond.
              </>}
            </span>
          }
          banner
          className={"pending-insurance-requst-warning"}
        />
      ) : null}
    </RcQueueAnim>
  );
};

export default AppHeader;
