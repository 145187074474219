import { API_METHODS } from "../../../Utils/Constants/API.constants";
import { ENDPOINTS } from "../../../REST/API.endpoints";
import { makeRequest } from "../../../REST/API.wrapper";
import {
  requestedGetAllPoliciesSuccess,
  requestedGetAllPoliciesError,
  requestedGetPolicySuccess,
  requestedGetPolicyError,
  getUserProjectTemplatesSuccess,
  getAgentProjectTemplatesSuccess
} from "./Mypolicies.actions";
import { message } from "antd";
import {
  enableListLoading,
  disableListLoading
} from "../Application/Application.actions";
import {
  APPLICATION_USER_ROLES,
  PROPERTY_LIMITS_KINDS
} from "../../../Utils/Constants/Application.constants";
import _ from "lodash";

/**
 * Get all the policies for a perticular user
 */
export const getAllPolicies = (refresh = true) => {
  return (dispatch, getState) => {
    dispatch(enableListLoading());
    const { userData } = getState().myProfile;
    if (userData && userData.company && userData.company.id) {
      return makeRequest({
        type: API_METHODS.GET,
        url: [ENDPOINTS.MY_POLICIES.GET_ALL_POLICIES, userData.company.id].join(
          "/"
        )
      }).then(
        (response) => {
          dispatch(requestedGetAllPoliciesSuccess(response));
          refresh && dispatch(disableListLoading());
        },
        (error) => {
          dispatch(requestedGetAllPoliciesError(error));
          dispatch(disableListLoading());
        }
      );
    } else {
      dispatch(disableListLoading());
      dispatch(requestedGetAllPoliciesSuccess([]));
    }
  };
};

/**
 * Get policy Details for a perticular user
 */
export const getPolicy = (id) => {
  return (dispatch, getState) =>
    makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.MY_POLICIES.GET_POLICY, id].join("/")
    }).then(
      (response) => {
        const {
          userData: { id: agentId },
          role
        } = getState().myProfile;
        let policyDetails = response;

        if (role === APPLICATION_USER_ROLES.AGENT) {
          const { allClientPolicyList } = getState().AgentDashboard;
          const _allPolicyIds =
            allClientPolicyList && allClientPolicyList.length
              ? allClientPolicyList.map((p) => p.policyId)
              : [];
          policyDetails = {
            ...policyDetails,
            policiesCovered:
              policyDetails.policiesCovered &&
              policyDetails.policiesCovered.length
                ? policyDetails.policiesCovered.map((pol) => ({
                    ...pol,
                    isPolicyOfSameAgent: _.includes(
                      _allPolicyIds,
                      pol.coveredPolicyId
                    )
                  }))
                : []
          };
        }
        dispatch(requestedGetPolicySuccess(policyDetails));
        if (
          (policyDetails.policyLimitCount > 0 ||
            policyDetails.blanketLimitCount > 0) &&
          policyDetails.policyId
        ) {
          let policyLimsPr;
          let blanketLimsPr;
          if (policyDetails.policyLimitCount > 0) {
            policyLimsPr = getPropertyByKind(
              PROPERTY_LIMITS_KINDS.POLICY_LIMIT,
              policyDetails.policyId
            );
          }
          if (policyDetails.blanketLimitCount > 0) {
            blanketLimsPr = getPropertyByKind(
              PROPERTY_LIMITS_KINDS.BLANKET,
              policyDetails.policyId
            );
          }
          Promise.all([policyLimsPr, blanketLimsPr]).then(
            (data) => {
              const policyLimits = data[0];
              const blanketLimits = data[1];
              dispatch(
                requestedGetPolicySuccess({
                  ...policyDetails,
                  policyLimits:
                    (policyLimits && policyLimits.policyLimts) || [],
                  blanketLimits:
                    (blanketLimits && blanketLimits.blanketLimits) || []
                })
              );
            },
            (err) => {}
          );
        }
        return response;
      },
      (error) => {
        dispatch(requestedGetPolicyError(error));
        return error;
      }
    );
};

/**
 * Request for creating a new policy
 */
export const createPolicy = (policyDetails) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.MY_POLICIES.ADD_POLICY,
      data: policyDetails,
      options: { headers: { "content-type": undefined } }
    }).then(
      (response) => {
        dispatch(getAllPolicies());
        message.success(response);
      },
      (error) => {
        message.error("Unable to create new policy ! Please try later !!");
      }
    );
};

/**
 * Request to update an existing policy
 */
export const updatePolicy = (id, policyDetails) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.PUT,
      url: [ENDPOINTS.MY_POLICIES.UPDATE_POLICY, id].join("/"),
      data: policyDetails
    }).then(
      (response) => {
        dispatch(getAllPolicies());
        message.success(response);
      },
      (error) => {}
    );
};

/**
 * Request to delete an existing policy by id
 */
export const deletePolicy = (id, loggedInUserId) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.DELETE,
      url: [ENDPOINTS.MY_POLICIES.DELETE_POLICY, id].join("/"),
      options: { params: { loggedInUserId: loggedInUserId } }
    }).then(
      (response) => {
        message.success(response);
        dispatch(getAllPolicies());
      },
      (error) => {}
    );
};

/**
 * request to get all the user project templates
 */
export const getUserProjectTemplates = (id, callback) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.MY_POLICIES.GET_PROJECT_TEMPLATES, id].join("/")
    }).then(
      (response) => {
        callback && callback();
        dispatch(getUserProjectTemplatesSuccess(response));
      },
      (error) => {
        callback && callback();
      }
    );
};

/**
 * request to get all the agent project templates
 */
export const getAgentProjectTemplates = (id, callback) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.GET,
      url: [ENDPOINTS.MY_POLICIES.GET_PROJECT_TEMPLATES, id].join("/")
    }).then(
      (response) => {
        callback && callback();
        dispatch(getAgentProjectTemplatesSuccess(response));
      },
      (error) => {
        callback && callback();
      }
    );
};

/**
 * request to create  user project templates
 */
export const createUserProjectTemplate = (projectDetails) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.POST,
      url: ENDPOINTS.MY_POLICIES.CREATE_PROJECT_TEMPLATE,
      data: projectDetails
    });
};
/**
 * request to create agent project templates
 */
export const createAgentProjectTemplate = (projectDetails, id) => {
  return (dispatch) =>
    makeRequest({
      type: id ? API_METHODS.PUT : API_METHODS.POST,
      url: id
        ? ENDPOINTS.AGENT_DASHBOARD.EDIT_AGENT_TEMPLATE
        : ENDPOINTS.AGENT_DASHBOARD.CREATE_AGENT_TEMPLATE,
      data: projectDetails
    });
};
/**
 * request to update  user project templates
 */
export const updateUserProjectTemplate = (id) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.POST,
      url: [ENDPOINTS.MY_POLICIES.CREATE_PROJECT_TEMPLATE, id].join("/")
    });
};
/**
 * request to delete the user project templates
 */
export const deleteUserProjectTemplates = (id) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.DELETE,
      url: [ENDPOINTS.MY_POLICIES.DELETE_PROJECT_TEMPLATE, id].join("/")
    });
};
/**
 * request to delete the user project templates
 */
/**
 * request to delete the Agent project templates
 */
export const deleteAgentProjectTemplates = (agentId, id) => {
  return (dispatch) =>
    makeRequest({
      type: API_METHODS.DELETE,
      url: `${ENDPOINTS.AGENT_DASHBOARD.DELETE_AGENT_PROJECT_TEMPLATE}/${agentId}/templates/${id}`
    });
};
/**
 * request to delete the Agent project templates
 */
export const getUserCompanyProjectsList = (
  insuredCompanyId,
  requestorCompanyId,
  requestorUser,
  insuredUser,
  agentId,
  name
) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.MY_POLICIES.GET_USER_COMPANY_PROJECTS_LIST,
    data: {
      insuredCompanyId,
      requestorCompanyId,
      requestorUser,
      insuredUser,
      agentId,
      name
    }
  });
};

export const createUserCompanyProject = (data) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.MY_POLICIES.CREATE_USER_COMPANY_PROJECT,
    data,
    options: {
      shouldNotShowError: true
    }
  });
};

export const verifyInsurer = (data) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.MY_POLICIES.VERIFY_INSURER,
    options: {
      params: {
        insurerName: data.insurerName,
        insurerNaic: data.insurerNaic
      }
    }
  });
};

export const getAllLocations = (
  policyId,
  page,
  size,
  keyword,
  projectId,
  isReresponded
) => {
  const url = [ENDPOINTS.MY_POLICIES.GET_LOCATIONS, policyId].join("/");
  return makeRequest({
    type: API_METHODS.GET,
    url: url,
    options: {
      params: {
        page: page,
        size: size,
        keyword: keyword || "",
        reshare: isReresponded ? true : false,
        projectId: projectId || "",
      },
    },
    //   ...{ ...payload, companyNaic: payload.companyNaic || 0 },
    //   documentList
    // },
  });
};

export const getAllSelectedLocations = (policyId, projectId, page, size, keyword) => {
  const url = [ENDPOINTS.MY_POLICIES.GET_SELECTED_LOCATIONS_POLICY, policyId].join("/");
  return makeRequest({
    type: API_METHODS.GET,
    url: url,
    options: {
      params: {
        page: page,
        size: size,
        projectId: projectId,
        keyword: keyword || ""
      }
    }
  });
};

export const postAllLocations = (policyId, page, size, dataObj) => {
  const url = [ENDPOINTS.MY_POLICIES.POST_LOCATION, policyId].join("/");
  return makeRequest({
    type: API_METHODS.POST,
    url: url,
    options: {
      params: { page: page, size: size }
    },
    data: dataObj
  });
};

export const getAllBuildingByLocation = (locationId, page, size) => {
  const url = [ENDPOINTS.MY_POLICIES.GET_LOCATION_BUILDINGS, locationId].join(
    "/"
  );
  return makeRequest({
    type: API_METHODS.GET,
    url: url,
    options: {
      params: { 
        page: page
        , 
        size: size 
      },
    },
  });
};

export const getPropertyByKind = (kind, policyId, coverageTemplateId, projectId) => {
  const url = [ENDPOINTS.MY_POLICIES.PROPERTY_LIMITS_BY_KIND];
  const dataObj = { limitType: kind, policyId: policyId, pageSize: 10, ...(coverageTemplateId && {coverageTemplateId}), ...(projectId && {projectId})};
  return makeRequest({
    type: API_METHODS.POST,
    url: url,
    data: dataObj
  });
};
export const getLocationById = (id) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: [ENDPOINTS.MY_POLICIES.ADD_LOCATION_LIMIT, id].join("/")
  });
};

export const handleArchiveClient = (data) => {
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.POST_ARCHIVE_CLIENT,
    data: data,
  });
};

export const downloadCois = (data) => {
  return makeRequest({
    type: API_METHODS.GET,
    url: ENDPOINTS.MY_POLICIES.DOWNLOAD_COIS_ZIP,
    options: {
      params: {
        insuredCompanyId: data.insuredCompanyId,
        agentId: data.agentId 
      }
    }
  });
};

export const setAvailableForInsured = (data)=>{
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.AGENT_DASHBOARD.TEMPLATE_AVAIALABLE_FOR_INSURED_USE,
    data: data,
  });
}

export const getCommonLocations = (data)=>{
  return makeRequest({
    type: API_METHODS.POST,
    url: ENDPOINTS.MY_POLICIES.GET_COMMON_LOCATION,
    data,
  });
}