import { SUPPLIER_ADRESSSBOOK_TYPES } from "./SupplierAddressBook.types";
import * as _ from "lodash";
const supplierAddressBookInitialState = {
  sampleFilePath: "",
  documents: [],
  addresses: {},
  addressesLoading: false,
  supplierAddressGroupList: [],
  selectedSupplierAddressGroup: "",
  selectedSupplierAddressGroupID: {}
};

const Base_requestorDashboardIitialState = {
  ...supplierAddressBookInitialState
};

const SupplierAddressBookReducer = (
  state = supplierAddressBookInitialState,
  { type, payload }
) => {
  switch (type) {
    case SUPPLIER_ADRESSSBOOK_TYPES.GET_ADDRESSBOOK.SUCCESS: {
      return {
        ...state,
        documents: payload.documents,
        sampleFilePath: payload.sampleFilePath
      };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.CLEAR_ADDRESSBOOK: {
      return { ...state, documents: [] };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.SET_LIST_OF_ADDRESS_BOOK_GROUP.SUCCESS: {
      return {
        ...state,
        supplierAddressGroupList: [...payload]
      };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.SET_LIST_OF_ADDRESS_BOOK_GROUP.ERROR: {
      return {
        ...state,
        supplierAddressGroupList: []
      };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.SET_SELECTED_ADDRESS_BOOK_GROUP.SUCCESS: {
      return {
        ...state,
        selectedSupplierAddressGroup: payload ? { ...payload } : null
      };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.SET_SELECTED_ADDRESS_BOOK_GROUP_ID.SUCCESS: {
      return {
        ...state,
        selectedSupplierAddressGroupID: payload
      };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.SET_SUPPLIER_ADDRESSES: {
      return { ...state, addresses: payload };
    }
    case SUPPLIER_ADRESSSBOOK_TYPES.SET_LOADING: {
      return { ...state, addressesLoading: payload };
    }
    default:
      return state;
  }
};

export default SupplierAddressBookReducer;
