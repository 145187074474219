import React, { useEffect, useState } from "react";
import { Select, AutoComplete, Spin, Icon, Typography, Input } from "antd";
import _, { toLower, toUpper } from "lodash";
import ErrorBoundary from "../../../HOCs/ErrorBoundary/ErrorBoundary";
import { CustomSelectWithNewEntry } from "./CustomSelectWithNewEntry";
import { APPLICATION_USER_ROLES } from "../../../Utils/Constants/Application.constants";
const { Option } = AutoComplete;
const { Text } = Typography;

const CertificialAutoComplete = React.forwardRef((props, ref) => {
  
  let isValidEmail;
  if(props.iscreateRequest === "request")  {
    isValidEmail = !!(props.sharePolicyUser === 'email' && props?.coiHolder?.email) || !!props.isSameEmailError;
  }
  else if (props.iscreateRequest === "agent-share"){
    const coiHolder = props.coiHolder;
    isValidEmail = coiHolder?.companies?.length<1 && coiHolder?.type === 'email' &&  coiHolder?.resOfAutoCompleteRequest == false;
  }

  const [key, setKey] = useState(1);
  const noRecordFoundOptionStyling = {color: '#cbc3c3',fontWeight:"500", pointerEvents: "none",background:"transparent" };
  const getSignUpOptions = (data) => {
    if (data.id == 'new') {
      return <span style={{ color: 'blue' }}>{data.name}</span>
    } else if (data.id == 'no_record_found') {
      return <span>{data.name}</span>
    } else {
      if (props.requestedRegion && data?.country?.countryName !== "United States of America") {
        return [
          data.name || data.companyName,
          "(",
          data.country ? data.country.countryName : "",
          ")"
        ].join(" ");
      }
      return data.name || data.companyName;
    }


  }

  const onClickHandler = ()=>{
    setKey(Math.random());
    props.onChange()
  }

  useEffect(()=>{
    setKey(Math.random());
  },[isValidEmail])



  if (props.iscreateRequest === "request") {
    return (
      <ErrorBoundary>
       {<Select
          key={key}
          suffixIcon={ isValidEmail ? <Icon type="close-circle" onClick={onClickHandler} className="search-icon" theme="filled"></Icon>:<Icon type="search" className="search-icon"></Icon>}
          ref={ref}
          mode={props.isMultiSelection &&  !isValidEmail  ? "multiple" : 'default'}
          onPopupScroll={props.onPopupScroll}
          defaultValue={props.defaultVal}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
          onChange={props.onChange}
          disabled={(props.sharePolicyUser == 'email' && props?.coiHolder?.email) || !!props.isSameEmailError }
          maxTagCount={10}
          labelInValue = {!isValidEmail}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />{" "}
                Fetching results ...
              </div>
            ) : null
          }
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          filterOption={
            isValidEmail
          }
          optionFilterProp="children"
          value={!!props.isSameEmailError ? props.isSameEmailError : (isValidEmail ? props?.coiHolder?.email : props.defaultVal)}
        >

             {props.source.map(
              (data) =>
                (
                  <Option
                    value={data.id}
                    key={data.id}
                    label={data.name || data.countryName}
                  >
                    {props.requestedRegion &&
                      data.country.countryName !== "United States of America"
                      ? [
                          data.name || data.companyName,
                          "(",
                          data.country ? data.country.countryName : "",
                          ")"
                        ].join(" ")+`${data.fromSupplierAddressBook? " (Supplier Address Book)":""}`
                      : (data.name || data.companyName || data.countryName)+`${data.fromSupplierAddressBook?" (Supplier Address Book)":""}`}
                  </Option>
                ) || null
            )}                
        </Select>} 
      </ErrorBoundary>
    );
  } else if (props.iscreateRequest === "agent-share") {
    return (
      <ErrorBoundary>
         <Select
          key={key}
          className="share-selection"
          suffixIcon={isValidEmail ? <Icon type="close-circle" onClick={onClickHandler} className="search-icon" theme="filled"></Icon>:<Icon type="search" className="search-icon"></Icon>}
          ref={ref}
          mode={props.isMultiSelection && !isValidEmail ? "multiple" : 'default'}
          onPopupScroll={props.onPopupScroll}
          defaultValue={props.defaultVal}
          disabled={props.disabled}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
          onChange={props.onChange}
          showSearch
          maxTagCount={10}
          allowClear={props.isNotClear ? false : true}
          labelInValue = {!isValidEmail}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />{" "}
                Fetching results ...
              </div>
            ) : null
          }
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          filterOption={
            props?.sharePolicyUser === "email"
          }
          optionFilterProp="children"
          value={isValidEmail ? props?.coiHolder?.email : props.defaultVal}
        >
          {props.source &&
            props.source.map(
              (data) =>
                (
                  <Option
                    key={data.id}
                    value={
                      data.id ||
                      data.externalCertId + "A" ||
                      data.agencyId ||
                      props.defaultVal
                    }
                    disabled={
                      props.sharingWith
                        ? props.sharingWith === "addressBook"
                          ? !data.externalCertId
                          : props.sharingWith === "companies"
                            ? data.externalCertId
                            : false
                        : false
                    }
                  >
                    {props.requestedRegion &&
                      data.country &&
                      data.country.countryName !== "United States of America"
                      ? [
                        data.name || data.companyName,
                        "(",
                        data.country ? data.country.countryName : "",
                        ")",
                        data.externalCertId
                          ? data.agencyAddress
                            ? " (Agency Address Book)"
                            : " (Client Address Book)"
                          : ""
                      ].join(" ")
                      : [
                        data.name,
                        data.externalCertId
                          ? data.agencyAddress
                            ? " (Agency Address Book)"
                            : " (Client Address Book)"
                          : ""
                      ].join(" ") || [
                        data.companyName,
                        data.externalCertId
                          ? data.agencyAddress
                            ? " (Agency Address Book)"
                            : " (Client Address Book)"
                          : ""
                      ]}
                  </Option>
                ) || null
            )}
        </Select>
      </ErrorBoundary>
    );
  } else if (props.iscreateRequest === "custom-selection-Autocomplete-entry") {
    const OptionsForAutoComplete =
      props.source &&
      props.source.map(
        (data) =>
          (
            <Option key={data.id} label={data.name || data.countryName}>
              {props.requestedRegion &&
                data.country.countryName !== "United States of America"
                ? [
                  data.name || data.companyName,
                  "(",
                  data.country ? data.country.countryName : "",
                  ")"
                ].join(" ")
                : data.name || data.companyName || data.countryName}
            </Option>
          ) || null
      );

    return (
      <ErrorBoundary>
        <AutoComplete
          defaultValue={props.defaultVal}
          dataSource={OptionsForAutoComplete}
          placeholder={props.placeholder}
          allowClear={props.isNotClear ? false : true}
          dropdownMatchSelectWidth={false}
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          onChange={props.onChange}
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
        >
          <Input
            suffix={
              <Icon
                type={props.fetching ? "loading" : "search"}
                style={props.fetching ? { color: "#3596db", fontSize: 14 } : {}}
              />
            }
          />
        </AutoComplete>
      </ErrorBoundary>
    );
  } else if (props.iscreateRequest === "countrySelection") {
    return (
      <ErrorBoundary>
        <Select
          suffixIcon={<Icon type="search" className="search-icon"></Icon>}
          ref={ref}
          mode={props.isMultiSelection ? "multiple" : null}
          defaultValue={props.defaultVal}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          onChange={props.onChange}
          showSearch
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
          disabled={props.disabled}
          maxTagCount={10}
          allowClear={props.isNotClear ? false : true}
          labelInValue
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />{" "}
                Fetching results ...
              </div>
            ) : null
          }
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
          value={props.defaultVal}
        >
          {props.source &&
            props.source.map(
              (data) =>
                (
                  <Option
                    key={data.id}
                    label={data.name || data.countryName}
                    disabled={
                      props.requestedRegion &&
                      data.country &&
                      props.requestedRegion !== data.country.countryName
                    }
                  >
                    {props.requestedRegion &&
                      data.country.countryName !== "United States of America"
                      ? [
                        data.name || data.companyName,
                        "(",
                        data.country ? data.country.countryName : "",
                        ")"
                      ].join(" ")
                      : data.name || data.companyName || data.countryName}
                  </Option>
                ) || null
            )}
        </Select>
      </ErrorBoundary>
    );
  } else if (props.iscreateRequest === "regionSelection") {
    return (
      <ErrorBoundary>
        <Select
          ref={ref}
          mode={props.isMultiSelection ? "multiple" : null}
          defaultValue={props.defaultVal}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          onChange={props.onChange}
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
          showSearch
          disabled={props.disabled || props.loading}
          maxTagCount={10}
          allowClear={props.isNotClear ? false : true}
          labelInValue
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />{" "}
                Fetching results ...
              </div>
            ) : null
          }
          loading={props.loading}
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
          value={props.defaultVal}
        >
          {props.source &&
            props.source.map(
              (data) =>
                (
                  <Option
                    key={data.regionId}
                    label={data.name || data.regionName}
                    disabled={
                      props.requestedRegion &&
                      data.country &&
                      props.requestedRegion !== data.country.regionName
                    }
                  >
                    {props.requestedRegion && data.regionName !== "US"
                      ? [
                        (data.name || data.companyName,
                          "(",
                          data.regionName ? data.regionName : "",
                          ")")
                      ].join(" ")
                      : data.regionName}
                  </Option>
                ) || null
            )}
        </Select>
      </ErrorBoundary>
    );
  } else if (props.iscreateRequest === "SignUp") {
    return (
      <ErrorBoundary>
        <Select
          suffixIcon={!props.disabled && <Icon type="search" className="search-icon"></Icon>}
          mode={props.isMultiSelection ? "multiple" : null}
          value={props.defaultVal || props.defaultValue || props.value}
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
          showArrow={!props.disabled}
          style={{ width: props.width || "100%" }}
          placeholder={props.placeholder}
          onChange={props.onChange}
          showSearch
          defaultOpen={props.defaultOpen}
          defaultActiveFirstOption={false}
          disabled={props.disabled}
          maxTagCount={10}
          loading={props.loading}
          allowClear={props.isNotClear ? false : true}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />
                {props && props.fetchText
                  ? props.fetchText
                  : "Fetching results ..."}
              </div>
            ) : null
          }
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          filterOption={false}
        >
          {props.source &&
            props.source.map(
              (data) => {
                let address=''
                address = [data?.street, data?.city, data?.state, data?.postalCode || data?.postCode, data?.country?.iso2CountryCode].filter(Boolean).join(", ");
                return (
                  <Option
                    // key={data.street}
                    {...(data.id == 'no_record_found' ? {style :noRecordFoundOptionStyling} : {})}
                    key={data.id}
                    value={data.id || data.agencyId || props.defaultVal}
                    title={props?.showAddress && address !== '' && address.length > 0?address:null}
                  >
                    {getSignUpOptions(data)}&nbsp; &nbsp;{props?.showAddress && address !== '' && address.length > 0 ? (<span style={{ fontSize: "12px" }}>{address}</span>) : ''}
                  </Option>
                ) || null
              }
            )}
        </Select>
      </ErrorBoundary>
    );
  } else if (props.iscreateRequest === "custom-selection-with-new-entry") {
    return (
      <CustomSelectWithNewEntry
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        onPopupScroll={props.onPopupScroll}
        suffixIcon={<Icon type="search" className="search-icon"></Icon>}
        showSearch
        // dropdownStyle={{ width: 450 }}
        style={{ width: "100%" }}
        placeholder={props.placeholder}
        value={props.value}
        loading={props.loading || props.fetching}
        onChange={props.onChange}
        getPopupContainer={
          props.containerId
            ? () => document.getElementById(props.containerId)
            : () => document.body
        }
        onAddNewInsurer={props.onAddNewInsurer}
        onDropdownVisibleChange={props.onDropdownVisibleChange}
        notFoundContent={
          props.fetching ? (
            <div
              className="margin-0"
              style={{ color: "#3596db", fontSize: 14 }}
            >
              <Spin
                indicator={
                  <Icon
                    type="loading"
                    style={{ color: "#3596db", fontSize: 14 }}
                    spin
                  />
                }
                className="margin-right-sm"
              />
              {props && props.fetchText
                ? props.fetchText
                : "Fetching results ..."}
            </div>
          ) : null
        }
        isAPIRan={props.isAPIRan}
        onSearch={
          props.searchOptions &&
          _.debounce(props.searchOptions, 750, { maxWait: 3000 })
        }
        filterOption={false}
        dataSource={props.source}
        customInsurerHandleCancel={props.customInsurerHandleCancel}
        customInsurerVisible={props.customInsurerVisible}
        confirmLoadingCustomInsurerModal={
          props.confirmLoadingCustomInsurerModal
        }
        isOnDemandRequest={props.isOnDemandRequest}
        hasNextPage={props.hasNextPage}
        addInsurerModalMessage={props.addInsurerModalMessage}
      />
    );
  } else if (props.iscreateRequest === "policies") {
    const inValidStatus = ["rejected", "canceled", "expired"];
    const { accessingRole, accessingAgentId } = props;
    return (
      <ErrorBoundary>
        <Select
          suffixIcon={<Icon type="search" className="search-icon"></Icon>}
          ref={ref}
          onPopupScroll={props.onPopupScroll}
          mode={props.isMultiSelection ? "multiple" : null}
          defaultValue={props.defaultVal}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          onChange={props.onChange}
          showSearch
          disabled={props.disabled}
          maxTagCount={10}
          loading={props.loading}
          allowClear={props.isNotClear ? false : true}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          autoClearSearchValue={props.autoClearSearchValue}
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />
                {props && props.fetchText
                  ? props.fetchText
                  : "Fetching results ..."}
              </div>
            ) : null
          }
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          onBlur={props.onBlurHandler}
          filterOption={false}
          defaultOpen={props.defaultOpen}
        >
          {(props.source &&
            props.source.length &&
            props.source.map((data) => {
              const { policyNumber } = data;
              const _polNumber = (policyNumber && policyNumber.trim()) || null;
              return (
                <Option
                  style={
                    !!(
                      inValidStatus.includes(
                        data.verificationStatus &&
                        data.verificationStatus.toLowerCase()
                      ) ||
                      inValidStatus.includes(
                        data.status && data.status.toLowerCase()
                      )
                    )
                      ? { display: "none" }
                      : {}
                  }
                  key={data.policyId || data.id}
                  value={data.policyId || data.id}
                >
                  <Text>
                    {(data.policyType && data.policyType.name) ||
                      data.policyType}
                  </Text>
                  {" : "}
                  {accessingRole === APPLICATION_USER_ROLES.AGENT &&
                    data.agent &&
                    accessingAgentId !== data.agent.id ? (
                    <span>
                      <Text mark className="agent-covered-policies">
                        ############
                      </Text>
                      {(_polNumber &&
                        _polNumber.slice(
                          _polNumber.length > 5
                            ? -5
                            : _polNumber.length > 2 && _polNumber.length <= 5
                              ? -2
                              : 0
                        )) ||
                        null}
                    </span>
                  ) : (
                    <Text>{data.policyNumber}</Text>
                  )}
                </Option>
              );
            })) ||
            null}
        </Select>
      </ErrorBoundary>
    );
  } else {
    return (
      <ErrorBoundary>
        <Select
          // key={props.defaultVal}
          key={"certAuto4" + props.defaultVal}
          suffixIcon={<Icon type="search" className="search-icon"></Icon>}
          ref={ref}
          onPopupScroll={props.onPopupScroll}
          mode={props.isMultiSelection ? "multiple" : null}
          defaultValue={props.defaultVal}
          style={{ width: "100%" }}
          placeholder={props.placeholder}
          onChange={props.onChange}
          getPopupContainer={
            props.containerId
              ? () => document.getElementById(props.containerId)
              : () => document.body
          }
          showSearch
          disabled={props.disabled}
          maxTagCount={10}
          loading={props.loading}
          allowClear={props.isNotClear ? false : true}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          notFoundContent={
            props.fetching ? (
              <div
                className="margin-0"
                style={{ color: "#3596db", fontSize: 14 }}
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ color: "#3596db", fontSize: 14 }}
                      spin
                    />
                  }
                  className="margin-right-sm"
                />
                {props && props.fetchText
                  ? props.fetchText
                  : "Fetching results ..."}
              </div>
            ) : null
          }
          onSearch={
            props.searchOptions &&
            _.debounce(props.searchOptions, 750, { maxWait: 3000 })
          }
          filterOption={
            props.sharePolicyUser && props.sharePolicyUser === "email"
          }
          optionFilterProp="children"
        >
          {props.source &&
            props.source.map(
              (data) =>
                (
                  <Option
                    key={data.id}
                    value={
                      data.id ||
                      data.externalCertId + "A" ||
                      data.agencyId ||
                      props.defaultVal
                    }
                  >
                    {props.requestedRegion &&
                      data.country &&
                      data.country.countryName !== "United States of America"
                      ? [
                        data.name || data.companyName,
                        "(",
                        data.country ? data.country.countryName : "",
                        ")",
                        data.externalCertId
                          ? data.agencyAddress
                            ? " (Agency Address Book)"
                            : " (Client Address Book)"
                          : ""
                      ].join(" ")
                      : [
                        data.name,
                        data.externalCertId
                          ? data.agencyAddress
                            ? " (Agency Address Book)"
                            : " (Client Address Book)"
                          : ""
                      ].join(" ") || [
                        data.companyName,
                        data.externalCertId
                          ? data.agencyAddress
                            ? " (Agency Address Book)"
                            : " (Client Address Book)"
                          : ""
                      ]}
                  </Option>
                ) || null
            )}
        </Select>
      </ErrorBoundary>
    );
  }
});

export default CertificialAutoComplete;
