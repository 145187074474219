import {
  Avatar,
  Col,
  Divider,
  Icon,
  Layout,
  Row,
  Tag,
  Tooltip,
  Typography
} from "antd";
import _ from "lodash";
import moment from "moment";
import RcQueueAnim from "rc-queue-anim";
import React from "react";
import { certLogoImage } from "../../Assets/assets";
import AMBestDisclaimer from "../../Components/AMBestDisclaimer/AMBestDisclaimer";
import TermsCondition from "../../Components/TermsCondition/TermsCondition";
import { APPLICATION_USER_ROLES } from "../../Utils/Constants/Application.constants";
import ContactUsViaEmail from "../../Components/ContactUsViaEmail/ContactUsViaEmail";
const { Footer } = Layout;
const { Text } = Typography;

const AppFooter = ({ style, currentUserRole }) => {
  return (
    <Footer
      style={
        style || {
          position: "fixed",
          bottom: 0,
          padding: "4px 50px",
          background: "white",
          fontWeight: 500,
          fontSize: 12,
          borderTop: "1px solid #e8e8e8",
          lineHeight: 1,
          zIndex: 1
        }
      }
      key="footer"
    >
      <RcQueueAnim
        type={"bottom"}
        delay={200}
        component={Row}
        componentProps={{
          type: "flex",
          align: "middle",
          justify: "end"
        }}
      >
        <Col span={12} key="cert-copyrights">
          <Avatar
            src={certLogoImage}
            shape="square"
            size={18}
            className="margin-right-sm"
          />
          <Text>Certificial © {moment().format("YYYY")}</Text>
          <Divider type="vertical" />
          <TermsCondition
            isAgent={currentUserRole === APPLICATION_USER_ROLES.AGENT}
          />
        </Col>
        <Col span={12} style={{ textAlign: "right" }} key="cert-features">
          <AMBestDisclaimer />
          <Divider type="vertical" />
          <ContactUsViaEmail />
          {process.env.REACT_APP_ENV === "dev" ||
            process.env.REACT_APP_ENV === "qa"
           ? (
            <>
              <Divider type="vertical" />
              <Tooltip
                title={
                  <div style={{ margin: "6px 24px 12px" }}>
                    <h3 style={{ color: "white" }}>Environment Details</h3>
                    <Text style={{ color: "white" }}>
                      <strong>Name : </strong>{" "}
                      {_.toUpper(process.env.REACT_APP_ENV)}
                    </Text>
                    <br />
                    <Text
                      copyable={{ text: process.env.REACT_APP_BASE_URL }}
                      style={{ color: "white" }}
                    >
                      <strong>Base URL : </strong>{" "}
                      {process.env.REACT_APP_BASE_URL}
                    </Text>
                    <br />
                    <Text
                      copyable={{
                        text: [
                          process.env.REACT_APP_BASE_URL,
                          "swagger-ui.html"
                        ].join("/")
                      }}
                      style={{ color: "white" }}
                    >
                      <strong>Swagger URL : </strong>{" "}
                      {[process.env.REACT_APP_BASE_URL, "swagger-ui.html"].join(
                        "/"
                      )}
                    </Text>
                    <br />
                    <Text style={{ color: "white" }}>
                      <strong>Image URL : </strong>{" "}
                      {process.env.REACT_APP_IMAGE_BASE_URL}
                    </Text>
                    <br />
                    <Text style={{ color: "white" }}>
                      <strong>Cert URL : </strong>{" "}
                      {process.env.REACT_APP_CERT_GEN_URL}
                    </Text>
                  </div>
                }
                placement="topLeft"
                arrowPointAtCenter
                autoAdjustOverflow
                destroyTooltipOnHide
                overlayStyle={{ maxWidth: "45%" }}
              >
                <Tag style={{ lineHeight: "15px" }} color="#49ad17">
                  {_.toUpper(process.env.REACT_APP_ENV)}
                </Tag>
              </Tooltip>
            </>
          ) : null}
        </Col>
      </RcQueueAnim>
    </Footer>
  );
};

export default AppFooter;
