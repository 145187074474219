import React from "react";
import { Spin, Icon } from "antd";

const LoaderIcon = (size) => (
  <Icon
    type="loading-3-quarters"
    className="color-primary-alt"
    style={{ fontSize: !!size ? size : 24 }}
    spin
  />
);
export const Loader = ({ size, spinning, ...props }) => (
  <Spin
    indicator={LoaderIcon(size)}
    spinning={spinning}
    tip={(!!props.tip && props.tip) || ""}
  >
    {props.children}
  </Spin>
);
