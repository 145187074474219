import React from 'react'
import { Icon, Alert } from 'antd';
import "./EmailComponents.less";


const InvalidLink = ({title, subTitle = null})=>{
	return <div className="validation-msg">
	<Alert
		banner
		className="validation-msg"
		showIcon={false}
		message={
			<>
				<p style={{ textAlign: "center" }}
				><Icon className="icon" style={{ fontSize: "45px", color: "orange" }} theme="filled"
					type="warning"></Icon></p>
				<p className="color-primary title" >{title}</p>
				{subTitle ? <p className="sub-title">{subTitle}</p> :null}
				<p className="sub-title">You may now close this window.</p>
			</>
		}
	/></div>
}

export default InvalidLink;