export const MY_POLICIES_ACTION_TYPES = {
  DUMMY_POLICY: "DUMMY_POLICY",
  CLEAR_MY_POLICIES: "CLEAR_MY_POLICIES",
  RESET_POLICY: "RESET_POLICY",
  GET_ALL_POLICIES: {
    SUCCESS: "GET_ALL_POLICIES_SUCCESS",
    ERROR: "GET_ALL_POLICIES_ERROR"
  },
  GET_POLICY: {
    SUCCESS: "GET_POLICY_SUCCESS",
    ERROR: "GET_POLICY_ERROR"
  },
  ADD_POLICY: {
    SUCCESS: "ADD_POLICY_SUCCESS",
    ERROR: "ADD_POLICY_ERROR"
  },
  DELETE_POLICY: {
    SUCCESS: "DELETE_POLICY_SUCCESS",
    ERROR: "DELETE_POLICY_ERROR"
  }
};
