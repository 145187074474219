export const CLIENT_ADRESSSBOOK_TYPES = {
  GET_ADDRESSBOOK: {
    SUCCESS: "GET_CLIENT_ADRESSSBOOK_SUCCESS",
    ERROR: "GET_CLIENT_ADRESSSBOOK_ERROR"
  },
  POST_ADDRESSBOOK: {
    SUCCESS: "POST_CLIENT_ADRESSSBOOK_SUCCESS",
    ERROR: "POST_CLIENT_ADRESSSBOOK_ERROR"
  },
  GET_ADDRESSES: {
    SUCCESS: "GET_CLIENT_ADDRESSES_SUCCESS",
    ERROR: "GET_CLIENT_ADDRESSES_ERROR"
  },
  CLEAR_ADDRESSBOOK: "CLEAR_GET_CLIENT_ADDRESSBOOK",
  CLEAR_ADDRESSES: "CLEAR_GET_CLIENT_ADDRESSES",
  SET_LOADING: "SET_LOADING"
};
