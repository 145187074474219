import React from "react";
import {
  Icon,
  Empty,
  Card,
  Row,
  Col,
  Popover,
  Tag,
  Checkbox,
  Avatar,
  message,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import {
  emptyImage,
  bookmarkAltIcon,
  bookmarkComplianceIcon
} from "../../../Assets/assets";
import NumberFormat from "react-number-format";
import { CERTIFICIAL_STATUS } from "../../../Utils/Constants/Application.constants";
import CertInfoTooltip from "../../../Components/UI/CertTooltip/CertInfoTooltip";
import { APPLICATION_MESSAGES } from "../../../Utils/Constants/ApplicationMessages.constants";
import { downloadUploadedFile } from "../../../Store/Modules/Application/Application.asyncRequest";
import { CERTIFICIAL_LABEL } from "../../../Utils/Constants/App.labels";
import {
  showFile
} from "../../../Utils/utility/app.utility";
export const GenericListForSelectedDisplay = ({
  isTitleRequred,
  title,
  obj_key,
  comp_key,
  obj,
  isBordered,
  isRequiresReviewApplicable,
  compliance,
  readOnly = true,
  isRequestFromAgent,
  showRequiresReviewIcon
}) => {

  const onDocumentDownload = (doc) => {
    if (doc.file) {
      downloadUploadedFile(doc.file).then(
        (res) => {
          showFile(res, doc.fileName);
          message.success(doc.fileName + " download successfully.");
        },
        () => {
            message.warning(doc.fileName + " error occurred while downloading the file.");
        }
      );
    } else {
      message.error("Unable to download file.");
    }
  };
  const text = () => {
    let _title = isRequiresReviewApplicable ? title : `Missing ${title}`
    return <span>
      <Avatar
        src={bookmarkComplianceIcon}
        size="small"
        shape="square"
        className="margin-right-sm"
      />
      {_title}
    </span>
  };

  const generateView = (array, isRequiresReviewApplicable, popover) => {
    const getComponent = (obj) => {
      let fileNameClass = "flush-margin-bottom end-attachement-link"
      if(isRequestFromAgent && obj["isRequiresReview"]){
        fileNameClass = fileNameClass+" document-requires-review"
      }
      if (!popover) {
        const requiresReviewComponent = <span>
        <Tooltip title={"Requires Review"}>
          <Icon
            type="warning"
            theme="filled"
            className="color-warning"
          />
        </Tooltip>
      </span>;
        return (obj.file ?<Col>
        <p
          onClick={() => onDocumentDownload(obj)}
          title={obj.fileName}
          className={fileNameClass}
        >
          <Icon type="paper-clip" />{" "}
          {obj.fileName}
        </p></Col>: <Col>{(obj["isRequiresReview"] && showRequiresReviewIcon)  ? requiresReviewComponent : null}</Col>)
      } else {
        return (!obj["isRequiresReview"] && !obj.file ? (
          <Col span={12} className="text-ellipsis">
            <Tooltip title={CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
              .MISSING_ADDITIONAL_ENDORSEMENT_DOCUMENT}>
              <Icon
                type="close-circle"
                style={{
                  marginRight: 8
                }}
              />
              {
                CERTIFICIAL_LABEL.WIDGETS.POLICY_ENDORSMENT
                  .MISSING_ADDITIONAL_ENDORSEMENT_DOCUMENT
              }
            </Tooltip>
          </Col>
        ) : "")
      }
    }

    let style = { padding: "3px 12px"}
    if(popover){
      style.width = "350px"
    }

    const getClassName = (document)=>{
      let className = ""
      if(popover){
       className = document["isRequiresReview"] ? "review-issue" : ((document['documentIssue'] || document['isDocumentComplianceIssue']) ? "compliance-issue":"")
      }  
      return className;
    }

    return (
      <div>
        {array &&
          array.length &&
          _.sortBy(array, (c) => c.coverageType).map((document, idx) => {
            return (
              <div className={getClassName(document)} key={idx} style={style}>
                <Row>
                  <Col
                    span={
                      (isRequiresReviewApplicable &&
                        12) ||
                      24
                    }
                  >
                    <Checkbox
                      checked={true}
                      disabled={true}
                      className={getClassName(document)}
                      style={
                        document[comp_key]
                          ? {
                            color: "rgba(243, 11, 23, 0.8)",
                            display: "inline"
                          }
                          : {
                            display: "inline",
                            color: "rgba(0, 0, 0, 0.5)"
                          }
                      }
                    >
                      {obj_key === 'coverageType' ? <span style={{ textTransform: "capitalize" }}>{_.split(document[obj_key], "_").join(" ")}</span> : _.startCase(_.split(document[obj_key], "_").join(" "))}
                    </Checkbox>
                  </Col>
                  {isRequiresReviewApplicable && (
                    getComponent(document)
                  )}
                  {_.includes(obj, "other") && (
                    <TextArea
                      defaultValue={
                        document.otherValue || document.otherCtDesc1
                      }
                      style={{ margin: "6px auto" }}
                      disabled={!document.isApplied || readOnly}
                      rows={2}
                      name={
                        document.otherValue
                          ? "otherDescription"
                          : "otherCtDesc1"
                      }
                      value={document.otherValue || document.otherCtDesc1}
                    />
                  )}
                  {document &&
                    document.dataType &&
                    // document.dataType.name === "percent" &&
                    // !isRestrictedCoverageInput &&
                    document.coverageType === "co-insurance" && (
                      <NumberFormat
                        style={{
                          margin: "6px auto",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          padding: "4px 11px",
                          width: "100%"
                        }}
                        disabled={!document.isApplied || readOnly}
                        suffix={"%"}
                        value={document.coInsuranceDesc}
                        decimalScale={0}
                        allowEmptyFormatting
                        isAllowed={(values) => {
                          const { value } = values;
                          return value <= 100;
                        }}
                        allowNegative={false}
                        onFocus={(e) => {
                          if (e.target.value === "0%") {
                            e.target.value = "%";
                          }
                        }}
                      />
                    )}
                  {document &&
                    document.dataType &&
                    // document.dataType.name === "percent" &&
                    // !isRestrictedCoverageInput &&
                    document.coverageType === "margin_clause" && (
                      <NumberFormat
                        style={{
                          margin: "6px auto",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          padding: "4px 11px",
                          width: "100%"
                        }}
                        disabled={!document.isApplied || readOnly}
                        suffix={"%"}
                        value={document.marginClauseDesc}
                        decimalScale={0}
                        allowEmptyFormatting
                        isAllowed={(values) => {
                          const { value } = values;
                          return value <= 100;
                        }}
                        allowNegative={false}
                        onFocus={(e) => {
                          if (e.target.value === "0%") {
                            e.target.value = "%";
                          }
                        }}
                      />
                    )}
                </Row>
              </div>
            );
          })}
      </div>
    );
  };

  const compContent = generateView(compliance, isRequiresReviewApplicable, true);
  const complainceStatus = ()=>{
    if(compliance.some(complianceIssue=>complianceIssue.documentIssue || complianceIssue.isDocumentComplianceIssue)){
      return {
        color:"#f30b17",
        status:CERTIFICIAL_STATUS.POLICY_STATUS.HYPHEN_NON_COMPLIANT
      }
    }

    if(compliance.some(complianceIssue=>complianceIssue.isRequiresReview)){
      return {
        color:"#f5a505",
        status:CERTIFICIAL_STATUS.POLICY_STATUS.REQUIRES_REVIEW
      }
    }
  }
  return (
    <Card
      size="small"
      bordered={isBordered}
      className="widget-card"
      bodyStyle={{
        padding: "6px 0",
        borderTop: "1px solid rgba(83, 105, 248, 0.2)"
      }}
      title={
        isTitleRequred && (
          <div>
            <h4>
              {/* <Icon type="file-done" style={{ color: "#3596db" }} />
              &nbsp;&nbsp; */}
              <Avatar
                src={bookmarkAltIcon}
                size="small"
                shape="square"
                className="margin-right-sm"
              />
              {title}
              {(isRequestFromAgent &&
                title === "Applicable Documents" &&
                compliance &&
                compliance.length && (
                  <CertInfoTooltip
                    placement="topLeft"
                    title={
                      APPLICATION_MESSAGES.COMPLIANCE_ISSUE_INFO.ADDITIONAL_DOCS
                    }
                    className="compliance-issue-info"
                  />
                )) ||
                null}
              {(compliance && compliance.length && (
                <Popover
                  placement="rightTop"
                  title={text()}
                  overlayClassName="compliance-container"
                  destroyTooltipOnHide={true}
                  content={compContent}
                >
                  <Tag color={complainceStatus().color} style={{ float: "right" }}>
                    {complainceStatus().status}
                  </Tag>
                </Popover>
              )) ||
                null}
            </h4>
          </div>
        )
      }
      extra={null}
    >
      {(obj && obj.length && generateView(obj, isRequiresReviewApplicable)) || (
        <Empty
          image={emptyImage}
          imageStyle={{
            height: 36
          }}
          description={"No " + title + " available"}
        />
      )}
    </Card>
  );
};
