import React from "react";
import { Modal, Form, Input, InputNumber, Alert } from "antd";

export const AddPolicyInsurerDetailsModal = Form.create({
  name: "add_insurer_form"
})(
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        modalConfirmLoading,
        addInsurerModalMessage
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Add Insurer Details"
          okText="Add Insurer"
          onCancel={onCancel}
          onOk={onCreate}
          destroyOnClose={true}
          centered={true}
          mask={true}
          bodyStyle={{ padding: 0 }}
          confirmLoading={modalConfirmLoading || false}
        >
          <Alert
            message="Please note that AMBest rating is not available for newly added insurer and it may require review for compliance."
            banner
          />
          <div style={{ padding: "16px 12px" }}>
            <Form layout="vertical">
              <Form.Item label="Insurer Name">
                {getFieldDecorator("companyName", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter valid insurer name!",
                      whitespace: true
                    }
                  ]
                })(
                  <Input autoFocus={true} maxLength={50} autoComplete="off" />
                )}
              </Form.Item>
              <Form.Item
                label="Insurer NAIC"
                help="Insurer NAIC should be numbers only"
              >
                {getFieldDecorator("companyNaic", {
                  rules: [
                    {
                      pattern: new RegExp(/^[0-9]*$/g),
                      message: ``
                    }
                  ]
                })(<InputNumber min={0} maxLength={10} />)}
              </Form.Item>
            </Form>
            {addInsurerModalMessage ? (
              <Alert message={addInsurerModalMessage} banner type="info" />
            ) : null}
          </div>
        </Modal>
      );
    }
  }
);
