import { combineReducers } from "redux";
import ApplicationReducer from "./Modules/Application/Application.reducer";
import MyPoliciesReducer from "./Modules/MyPolicies/Mypolicies.reducer";
import MyProfileReducer from "./Modules/MyProfile/MyProfile.reducer";
import RequestorDashboardReducer from "./Modules/RequestorDashboard/RequestorDashboard.reducer";
import InsurerSharedPoliciesReducer from "./Modules/InsurerSharedPolicies/InsurerSharedPolicies.reducer";
import TemplateReducer from "./Modules/Template/Template.reducer";
import PendingInsuredRequestsReducer from "./Modules/pendingInsuredRequests/pendingInsuredRequests.reducer";
import MyPendingUsersReducer from "./Modules/MyPendingUsers/MyPendingUsers.reducer";
import AgentDashboardReducer from "./Modules/AgentPendingVerification/AgentPendingVerification.reducer";
import RequestorRequestPoliciesReducer from "./Modules/RequestorRequestPolicies/RequestorRequestPolicies.reducer";
import AgentPendingRequestReducer from "./Modules/AgentPendingRequests/AgentPendingRequests.reducer";
import UploadCertReducer from "./Modules/UploadCert/UploadCert.reducer";
import MyAddressBookReducer from "./Modules/MyAddressBook/MyAddressBook.reducer";
import MyTeamReducer from "./Modules/MyTeam/MyTeam.reducer";
import SupplierAddressBookReducer from "./Modules/SupplierAddressBook/SupplierAddressBook.reducer";
import SmartCoiReducerReducer from "./Modules/SmartCoi/SmartCoi.reducer";


const CLIENT_REDUCERS = {
  insurerSharedPolicies: InsurerSharedPoliciesReducer,
  myPolicies: MyPoliciesReducer,
  pendingInsuredRequests: PendingInsuredRequestsReducer,
  requestorDashboard: RequestorDashboardReducer,
  policyTemplate: TemplateReducer,
  requestorRequestPolicies: RequestorRequestPoliciesReducer,
  SmartCoi:SmartCoiReducerReducer
};

const AGENT_REDUCERS = {
  AgentDashboard: AgentDashboardReducer,
  AgentPendingRequests: AgentPendingRequestReducer
};

const UPLOADCERT_REDUCERS = {
  uploadCert: UploadCertReducer
};

export const rootReducer = combineReducers({
  app: ApplicationReducer,
  myProfile: MyProfileReducer,
  myPendingUsers: MyPendingUsersReducer,
  myAddressBooks: MyAddressBookReducer,
  myTeam: MyTeamReducer,
  supplierAddressBook: SupplierAddressBookReducer,
  ...CLIENT_REDUCERS,
  ...AGENT_REDUCERS,
  ...UPLOADCERT_REDUCERS
});
