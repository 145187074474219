import { Icon, Popconfirm, Table, Tooltip } from "antd";
import React from "react";

export const UploadedDocument = ({
  fileList,
  isRowSelection,
  selectedRowKeys,
  handleFileDelete,
  onSelectedRowKeysChange,
  isSmartCoiFilePresent
}) => {
  const fileListColumns = [
    {
      title: "",
      dataIndex: "type",
      width: 50,
      key: "type",
      render: (type, item) => (
        <Icon
          type={type === "application/pdf" ? "file-pdf" : "file-image"}
          theme="twoTone"
          twoToneColor={item.id ? "#52c41a" : "#3596db"}
        />
      )
    },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true
    },
  ];

  if(!isSmartCoiFilePresent){
    fileListColumns.push({
      title: "",
      dataIndex: "",
      key: "actions",
      width: 30,
      render: (text, item) => (
        <div className="additional-document-actions">
          <Tooltip
            arrowPointAtCenter
            placement="topRight"
            title={"Delete Document"}
          >
            <Popconfirm
              title={<p> Are you sure you want to delete this document ?</p>}
              onConfirm={() => handleFileDelete(item)}
              onCancel={() => {}}
              okText={"Yes"}
              cancelText={"No"}
              placement="bottomRight"
              arrowPointAtCenter={true}
            >
              <Icon type="delete" />
            </Popconfirm>
          </Tooltip>
        </div>
      )
    })
  }

  const onSelectChange = (selectedKeys) => {
    onSelectedRowKeysChange(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  return fileList && fileList.length ? (
    <div>
      <Table
        rowKey="uid"
        dataSource={fileList}
        columns={fileListColumns}
        rowSelection={isRowSelection ? rowSelection : false}
        size="small"
         bordered={false}
        className={"additional-files--container"}
        pagination={{
          size: "small",
          className: "additional-files-pagination",
          total: fileList.length,
          hideOnSinglePage: true
        }}
        {...(isRowSelection && {footer:()=> 
            <p className="margin-0">
              <Icon type="warning" theme="filled" className="color-warning" />{" "}
              <span className="fs-12x">
                Please select documents that require review
              </span>
            </p>
        })}
      />
    </div>
  ) : null;
};
