import { TEMPLATE_TYPES } from "./Template.types";
export const getPolicyTemplateSuccess = (data, size) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.GET_TEMPLATE_LIST.SUCCESS,
      payload: { data, size }
    });
};
export const createPolicyTemplateSuccess = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.CREATE_TEMPLATE.SUCCESS,
      payload: obj
    });
};
export const createPolicyTemplateError = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.CREATE_TEMPLATE.ERROR,
      payload: obj
    });
};
export const editPolicyTemplateSuccess = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.EDIT_TEMPLATE.SUCCESS,
      payload: obj
    });
};
export const editPolicyTemplateError = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.EDIT_TEMPLATE.ERROR,
      payload: obj
    });
};
export const getPolicyTemplateError = (error) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.GET_TEMPLATE_LIST.ERROR,
      payload: error
    });
};
export const setActiveTab = (id) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.UI.SET_ACTIVE_TAB,
      payload: id
    });
};
export const setSelectedTemplate = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.UI.SET_SELECTED_TEMPLATE,
      payload: obj
    });
};

export const setSelectedVersion = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.UI.SET_SELECTED_VERSION,
      payload: obj
    });
};

export const clearSelectedTemplate = () => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.UI.CLEAR_SELECTED_TEMPLATE
    });
};

export const clearSelectedVersion = () => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.UI.CLEAR_SELECTED_VERSION
    });
};

export const getTemplateCoverageTypesSuccess = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.GET_TEMPLATE_COVERAGE_TYPES.SUCCESS,
      payload: obj
    });
};
export const getTemplateCoverageTypesError = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.GET_TEMPLATE_COVERAGE_TYPES.ERROR,
      payload: obj
    });
};
export const deleteRequirementTemplateSuccess = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.DELETE_REQUIREMENT_TEMPLATE.SUCCESS,
      payload: obj
    });
};
export const deleteRequirementTemplateError = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.DELETE_REQUIREMENT_TEMPLATE.ERROR,
      payload: obj
    });
};
export const resetTemplates = (obj) => {
  return (dispatch) =>
    dispatch({
      type: TEMPLATE_TYPES.CLEAR_TEMPLATES
    });
};
export const setLoading = (val) => {
  return (dispatch) => {
    dispatch({
      type: TEMPLATE_TYPES.SET_LOADING
    });
  };
};
export const setTemplateHistorySuccess = (value) => {
  return (dispatch) => {
    dispatch({
      type: TEMPLATE_TYPES.GET_TEMPLATE_HISTORY.SUCCESS,
      payload: value
    });
  };
};
export const setTemplateHistoryError = () => {
  return (dispatch) => {
    dispatch({
      type: TEMPLATE_TYPES.GET_TEMPLATE_HISTORY.ERROR
    });
  };
};
export const setTemplateNameList = (val) => {
  return (dispatch) => {
    dispatch({
      type: TEMPLATE_TYPES.SET_TEMPLATE_NAME_LIST,
      payload: val
    });
  };
};
export const setAddEditLoading = (val) => {
  return (dispatch) => {
    dispatch({
      type: TEMPLATE_TYPES.SET_ADDEDITLOADING,
      payload: val
    });
  };
};
