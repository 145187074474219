import {
  Col,
  Icon,
  List,
  message,
  Popover,
  Row,
  Spin,
  typography,
  Button
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { ENDPOINTS } from "../../REST/API.endpoints";
import { makeRequest } from "../../REST/API.wrapper";
import { API_METHODS } from "../../Utils/Constants/API.constants";
import { APPLICATION_USER_ROLES } from "../../Utils/Constants/Application.constants";
import { appHistory } from "../../Utils/Helpers/History";
import NotificationBell from "./NotificationBell";
import "./Notification_Popover.css";
import { scrollBarThumb } from "../UI/ScrollBarThumb/ScrollBarThumb";
import { CERTIFICIAL_LABEL } from "../../Utils/Constants/App.labels";
const { Text } = typography;

const NotificationPopoverContent = (props) => {
  const { userData } = useSelector((state) => state.myProfile);
  const { role } = useSelector((state) => state.app.currentSession);
  const [visible, SetVisible] = useState(false);
  const initialStateData = {
    pageSize: 1,
    data: [],
    totalNotificationsCount: undefined,
    unreadNotificationCount: undefined
  };

  const initialStateLoading = {
    loading: false
  };

  const initialStateHasmore = {
    hasMore: true
  };

  const [stateData, setstateData] = useState(initialStateData);
  const [stateLoading, setstateLoading] = useState(initialStateLoading);
  const [stateHasmore, setstateHasmore] = useState(initialStateHasmore);

  useEffect(() => {
    SetVisible(props.visible);
    setstateHasmore({
      hasMore: true
    });
  }, [props.visible]);

  const fetchData = (page, callback) => {
    if (userData && userData.customer) {
      setstateLoading({
        loading: true
      });
      makeRequest({
        type: API_METHODS.GET,
        url: ENDPOINTS.APPLICATION.GET_NOTIFICATION,
        options: {
          params: {
            page,
            size: 10,
            userId: userData.customer.userId
          }
        }
      }).then(
        (res) => {
          callback(res);
          setstateLoading({
            loading: false
          });
        },
        () => {
          setstateLoading({
            loading: false
          });
        }
      );
    } else {
      message.warn(
        "Unable to process request, please contact service administrator. "
      );
    }
  };

  const handleInfiniteOnLoad = () => {
    let { data, totalNotificationsCount, pageSize } = stateData;
    setstateLoading({
      loading: true
    });
    if (data.length >= totalNotificationsCount) {
      setstateLoading({
        loading: false
      });
      setstateHasmore({
        hasMore: false
      });
      setstateData({
        ...stateData,
        pageSize: 1
      });
      return;
    }
    const pageIdx = pageSize + 1;
    fetchData(pageIdx, (res) => {
      data = data.concat(res.notifications);
      setstateData({
        data,
        pageSize: pageIdx,
        totalNotificationsCount: res.totalNotificationsCount,
        unreadNotificationCount: res.unreadNotificationCount
      });
      setstateLoading({
        loading: false
      });
    });
  };
  const openNotificationPanel = (panelVisible) => {
    if (userData && userData.customer) {
      props.handlePopoverVisibleChange(panelVisible);
    }
    if (panelVisible) {
      fetchData(1, (res) => {
        setstateData({
          pageSize: 1,
          data: res.notifications,
          totalNotificationsCount: res.totalNotificationsCount,
          unreadNotificationCount: res.unreadNotificationCount
        });
      });
    } else {
      setstateData({
        pageSize: 1,
        data: [],
        totalNotificationsCount: undefined,
        unreadNotificationCount: undefined
      });
    }
  };

  const markNotificationAsRead = (notification, callback) => {
    makeRequest({
      type: API_METHODS.PUT,
      url: ENDPOINTS.APPLICATION.MARK_NOTIFICATION_AS_READ,
      options: {
        params: {
          notificationId: notification.id,
          userId: notification.recipientId
        }
      }
    }).then((res) => {
      callback(res);
    });
  };

  const markAllAsRead = () => {
    markNotificationAsRead(
      {
        recipientId: userData.customer.userId
      },
      () => {
        const updatedList = stateData.data.map((d) => ({
          ...d,
          ...{ unread: false }
        }));

        setstateData({
          ...stateData,
          data: updatedList
        });
      }
    );
  };

  const checkInsuredRequestor = (isInsurer, activePath) => {
    if (isInsurer === true && role) {
      if (role !== APPLICATION_USER_ROLES.INSURER) {
        props.changeToggleOnNotificationCheck(true, activePath);
      }
    } else if (isInsurer === false && role) {
      if (role !== APPLICATION_USER_ROLES.REQUESTER) {
        props.changeToggleOnNotificationCheck(false, activePath);
      }
    }
  };

  const selectedNotificationRedirectTo = (action) => {
    switch (action) {
      case "insuredMyPolicies": {
        appHistory.push("/app/my-policies");
        checkInsuredRequestor(true, "MY-POLICIES");
        break;
      }
      case "agentClient": {
        appHistory.push("/app/agent-clients");
        checkInsuredRequestor(null, null);
        break;
      }
      case "agentDashboard": {
        appHistory.push("/app/agent-clients");
        checkInsuredRequestor(null, null);
        break;
      }
      case "companyAdmin": {
        appHistory.push("/app/company-admin");
        this.checkInsuredRequestor(null, null);
        break;
      }
      case "companyDashboard": {
        appHistory.push("/app/company-manage");
        this.checkInsuredRequestor(null, null);
        break;
      }
      case "supplierAddressBook": {
        appHistory.push("/app/supplier-address-book");
        this.checkInsuredRequestor(null, null);
        break;
      }
      case "agentShareAprroval": {
        appHistory.push("/app/agent-insured-requests");
        checkInsuredRequestor(null, null);
        break;
      }
      case "agentPendingRequestApproval": {
        appHistory.push("/app/agent-insured-requests");
        checkInsuredRequestor(null, null);
        break;
      }
      case "requesterDashboard": {
        appHistory.push("/app/dashboard");
        checkInsuredRequestor(false, "DASHBOARD");
        break;
      }
      case "insuredPendingRequests": {
        appHistory.push("/app/pending-requests");
        checkInsuredRequestor(true, "PENDING-REQUESTS");
        break;
      }

      default:
        break;
    }
  };

  const selectedNotification = (notification) => {
    if (notification.unread) {
      markNotificationAsRead(
        notification,
        () => {
          const updatedNotificationIndex = _.findIndex(
            stateData.data,
            function (o) {
              return o.id === notification.id;
            }
          );
          const updatedList = [...stateData.data];
          updatedList.splice(updatedNotificationIndex, 1, {
            ...notification,
            ...{ unread: false }
          });

          setstateData({
            ...stateData,
            data: updatedList
          });
        },
        (err) => {
          message.error(err.message);
        }
      );
    }
    if (notification && notification.data) {
      selectedNotificationRedirectTo(notification.data);
    }
  };

  return (
    <Popover
      content={
        <div className="ant-infinite-container">
          <Scrollbars
            universal={true}
            renderThumbVertical={scrollBarThumb}
            renderThumbHorizontal={scrollBarThumb}
          >
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={handleInfiniteOnLoad}
              hasMore={!stateLoading.loading && stateHasmore.hasMore}
              useWindow={false}
            >
              {(stateData.data && stateData.data.length && (
                <List
                  dataSource={stateData.data}
                  className={"notification-list"}
                  renderItem={(item) => (
                    <List.Item
                      onClick={() => selectedNotification(item)}
                      key={item.id}
                      className={item.unread && "notification-item-unread"}
                    >
                      <List.Item.Meta
                        avatar={null}
                        title={<Text>{item.title}</Text>}
                        description={
                          <>
                            <p className="margin-0">{item.description}</p>
                            {(!!item.timestamp && (
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: 12
                                }}
                              >
                                <Icon type="clock-circle" />{" "}
                                <i>{moment(item.timestamp).fromNow()}</i>
                              </div>
                            )) ||
                              null}
                          </>
                        }
                      />
                    </List.Item>
                  )}
                >
                  {stateLoading.loading && stateHasmore.hasMore && (
                    <div className="ant-loading-container">
                      <Spin
                        tip={CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE}
                      />
                    </div>
                  )}
                </List>
              )) ||
                (stateLoading.loading && (
                  <div style={{ textAlign: "center", marginTop: 80 }}>
                    <Spin tip={CERTIFICIAL_LABEL.COMPONENTS.LOADING.MESSAGE} />
                  </div>
                )) || <></>}
            </InfiniteScroll>
          </Scrollbars>

          <footer className="popover-footer">
            <p onClick={props.allNotificationHandler}>
              Click here to see All Notifications
            </p>
          </footer>
        </div>
      }
      title={
        <Row>
          <Col span={12}>
            <h3> Notifications</h3>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              ghost
              className="markAllRead-btn"
              onClick={markAllAsRead}
              size={"small"}
            >
              Mark all as read
            </Button>
          </Col>
        </Row>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={openNotificationPanel}
      overlayStyle={{ width: 350 }}
      overlayClassName={"notifications-container"}
      destroyTooltipOnHide={true}
    >
      <NotificationBell {...props}></NotificationBell>
    </Popover>
  );
};

export default NotificationPopoverContent;
